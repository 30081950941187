import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";

import mapImage from '../../../assets/img/map/map_trublaini.png';

import { MAIN_PATH, LIBTRUBLAINI_INFO_PATH, LIBTRUBLAINI_CONTACTS_PATH, CONTACTS_PATH } from '../../../util/routes';
import { LIBTRUBLAINI } from '../../../util/consts';

class LibTrublainiContactsPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBTRUBLAINI.title, url: LIBTRUBLAINI_INFO_PATH, selected: false },
			{title: 'Координати', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBTRUBLAINI_CONTACTS_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBTRUBLAINI_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={{width: '100%'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBTRUBLAINI.title}<br/>
				Координати
			</div>
			<div style={{width: '750px', marginLeft: 'auto', marginRight: 'auto'}}>
				<div style={{fontSize: '14pt', fontWeight: 'bold', marginBottom: '10px' }}>Карта</div>
				<img src={mapImage}/>

			<div style={{marginTop: '20px', fontWeight: 'bold'}}>
				Адреса та телефони
			</div>

			<div style={{marginTop: '20px'}}>
				Адреса: бул. Марії Приймаченко (колишній бул. Ліхачова), 7<br />
				Тел.: 285-65-69<br />
			</div>

			<div style={{marginTop: '20px', marginBottom: '30px'}}>
				<a href={CONTACTS_PATH}>Інші бібліотеки ЦБС на карті</a>
			</div>
			</div>
		</div>
	);
}

export default LibTrublainiContactsPage;
