import React, { Component, createRef } from 'react';
import stylesAdmin from './admin.module.css';
import stylesPage from '../page.module.css';

import AdminHeader from "./components/AdminHeader";
import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";
import MessageConfirm from "../../components/MessageConfirm";

import { 
	performApiRequest, performApiRequestForm, 
	EVENT_LIST_API_URL, EVENT_DELETE_API_URL, SLIDE_SAVE_API_URL, SLIDE_MOVE_API_URL 
} from "../../util/restapi"
import { ADMIN_SLIDE_LIST_PATH, ADMIN_EVENT_PATH } from "../../util/routes";
import { CBS, ANY, ONLY_TRUE, ONLY_FALSE } from "../../util/consts";
import { getEventImageUrl } from "../../util/tools";

import iconMoveUp from '../../assets/img/arrow_up.png';
import iconMoveDown from '../../assets/img/arrow_down.png';
import iconDelete from '../../assets/img/sign_cross.png';

const STATE_WORKING = 'working';
const STATE_READY = 'ready';
const STATE_SAVING_SLIDE = 'savingSlide';

class AdminSlideListPage extends Component {

	constructor(props) {
		super(props);
		this.eventList = [];
		this.fileRef = createRef();
		this.refMessageConfirm = createRef();
		this.eventToDeleteId = null;
		this.state = {
			formState: STATE_READY,
			eventList: [],
			savingEvent: null,
			ok: true
		};
	}
	componentDidMount = () => {
		this.refresh();
	}

	refresh = async () => {
		this.setState({formState: STATE_WORKING});
		var request = {
			page: 1,
			pageSize: 10000,
			skipCountRequest: true,
			libraryId: CBS.cipher,
			active: ANY,
			virtualExhibition: ONLY_FALSE,
			slided: ONLY_TRUE
		};
		var response = await performApiRequest(EVENT_LIST_API_URL, request);
		this.ok = response.ok;
		if( response.ok ) {
			for(let i=response.eventList.length-1; i >= 0; i--)
				this.eventList.push( response.eventList[i] );
		}
		this.setState({formState: STATE_READY, eventList: this.eventList, ok: response.ok});
	}
	
	handleFileSelect = async (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			this.setState({formState: STATE_SAVING_SLIDE});
			var file = files[0];
			const form = new FormData();
			form.append('image', file);
			form.append('slideOrder', this.eventList.length + 1);
			var response = await performApiRequestForm(SLIDE_SAVE_API_URL, form);
			if( response.ok ) {
				this.eventList.push( response.event );
				this.setState({eventList: this.eventList});
			}
			this.setState({formState: STATE_READY});
		}
	}
	moveSlideUp = async (index) => {
		if( index == 0 )
			return;
		var eventId = this.eventList[index].eventId;
		this.setState({savingEvent: eventId});
		var request = {
			eventId: eventId,
			direction: -1
		};
		var response = await performApiRequest(SLIDE_MOVE_API_URL, request);
		if( response.ok ) {
			var e = this.eventList[index];
			this.eventList[index] = this.eventList[index-1];
			this.eventList[index-1] = e;
		}
		this.setState({savingEvent: null, eventList: this.eventList});
	}
	moveSlideDown = async (index) => {
		if( index == this.eventList.length-1 )
			return;
		var eventId = this.eventList[index].eventId;
		this.setState({savingEvent: eventId});
		var request = {
			eventId: eventId,
			direction: 1
		};
		var response = await performApiRequest(SLIDE_MOVE_API_URL, request);
		if( response.ok ) {
			var e = this.eventList[index];
			this.eventList[index] = this.eventList[index+1];
			this.eventList[index+1] = e;
		}
		this.setState({savingEvent: null, eventList: this.eventList});
	}
	deleteSlide = (index) => {
		this.eventToDeleteId = this.eventList[index].eventId;
		this.refMessageConfirm.current.openModalMessage("Вилучити слайд?");
	}
	doDelete = async () => {
		var eventId = this.eventToDeleteId;
		this.eventToDeleteId = null;
		if( this.refMessageConfirm.current.confirmed ) {
			this.setState({savingEvent: eventId});
			var request = {
				eventId: eventId
			};
			var response = await performApiRequest(EVENT_DELETE_API_URL, request);
			if( response.ok ) {
				for( let i=this.eventList.length-1; i >= 0 ; i--) {
					if( this.eventList[i].eventId == eventId )
						this.eventList.splice( i, 1 );
				}
			}
			this.setState({savingEvent: null, eventList: this.eventList});
		}
	}

	render() {
		var leftSide = (<AdminLeftSide path={ADMIN_SLIDE_LIST_PATH} />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
	
}

function Body(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_WORKING ) {
		var contentHtml = (<Spinner />);
	} else {
		var contentHtml = (<Content holder={holder} />);
	}
	return (
		<React.Fragment>
			<div className={stylesAdmin.divContentHeader}>
				Слайди<br/>
				(перелік слайдів на головній сторінці)
			</div>
			{contentHtml}
			<MessageConfirm ref={holder.refMessageConfirm} doAfterClose={holder.doDelete} />
		</React.Fragment>
	)
}

function Content(props) {
	var holder = props.holder;
	var listSlideHtml = holder.eventList.map( (event, index) => {
		var urlImage = getEventImageUrl(event.logoImageId);
		if( event.eventId == holder.state.savingEvent ) {
			var buttonsHtml = (<Spinner />);
		} else {
			var buttonsHtml = (
				<div>
					<button 
						type='button' style={{marginRight: '20px'}}
						title='Підняти зображення вверх'
						onClick={ () => {holder.moveSlideUp(index) } }
						disabled={index==0}
					>
						<img src={iconMoveUp} />
					</button>
					<button 
						type='button' style={{marginRight: '20px'}}
						title='Опустити зображення вниз'
						onClick={ () => {holder.moveSlideDown(index) } }
						disabled={index==holder.eventList.length-1}
					>
						<img src={iconMoveDown} />
					</button>
					<button 
						type='button' style={{marginRight: '20px'}}
						title='Вилучити зображення'
						onClick={ () => {holder.deleteSlide(index) } }
					>
						<img src={iconDelete} />
					</button>
				</div>
			);
		}
		return (
			<div key={index}>
				<hr className={stylesPage.hr} />
				<div style={{display: 'flex'}}>
					<div style={{width: '200px', marginRight: '20px'}}>
						<img src={urlImage} height='100' />
					</div>
					{buttonsHtml}
				</div>
			</div>
		)
	} );
	if( holder.state.formState == STATE_SAVING_SLIDE )
		var divAdd = (<Spinner />);
	else {
		var divAdd = (
			<div>
				<button type='button' onClick={e => holder.fileRef.current.click()}>Додати</button>
				<input ref={holder.fileRef} type={'file'} style={{display: 'none'}} onChange={e => holder.handleFileSelect(e)} />
			</div>
		);
	}
	return(
		<div style={{marginTop: '30px', marginBottom: '30px'}}>
			{listSlideHtml}
			<hr className={stylesPage.hr} />
			{divAdd}
		</div>
	);
}

export default AdminSlideListPage;
