import React from 'react';

import FastSearchForm from './FastSearchForm';
import {
	MAIN_PATH, 
	
	CONTACTS_PATH,
	SCHEDULE_PATH,

	LIBCRB_INFO_PATH,
	LIB8_INFO_PATH,
	LIB149_INFO_PATH,
	LIB150_INFO_PATH,
	LIBBILOKUR_INFO_PATH,
	LIBESTET_INFO_PATH,
	LIBJERELO_INFO_PATH,
	LIBKNYGOLYUB_INFO_PATH,
	LIBSVITOGLYAD_INFO_PATH,
	LIBTRUBLAINI_INFO_PATH,
	LIBVESELKA_INFO_PATH,
	LIBVSESVIT_INFO_PATH,
	LIBVYSHNI_INFO_PATH,

	EVENT_LIST_PATH,
	EXHIBITION_LIST_PATH,
	POSTER_LIST_PATH,
	CLUBS_PATH,
	BLOG_PATH,
	ECATALOG_PATH,
	ONLINE_PATH,
	SKS_PATH,
	KKS_PATH,
	BIBLIOGRAPHIC_PATH,
	BOOKS_NEW_PATH,
	BOOKS_INTERESTING_PATH,
	SERVICES_PATH,
	RULES_PATH,
	ADMINISTRATION_PATH,
} from '../util/routes';

import logo from '../assets/img/pechersk_logo.png';
import title from '../assets/img/header/header_title.png';
import icon_ecatalog from '../assets/img/header/ecatalog.png';
import icon_exhibitions from '../assets/img/header/exhibitions.png';
import icon_onlineinfo from '../assets/img/header/onlineinfo.png';
import icon_posters from '../assets/img/header/posters.png';
import icon_services from '../assets/img/header/services.png';
import iconAddress from '../assets/img/header/icon_address.png';
import iconPhone from '../assets/img/header/icon_phone.png';
import iconLetter from '../assets/img/header/icon_letter.png';

const BG_LIGHT_GREEN = '#ebf6dd';
const BG_MIDDLE_GREEN = '#84c135';
const BG_DARK_GREEN = '#1e3900';
const BLOCK_BORDER_COLOR = '#588b0e';
const MAIN_LINK_BG_COLOR = '#588b0e';
const ACTIVE_LINK_COLOR = 'green';

class Header extends React.Component {

	render() {
		// todo: lOGO SHADOW 
		return (
			<div style={localStyles.divMain} >
				<div style={{display: 'flex', width: '100%', paddingTop: '5px'}}>
					<div style={{marginTop: '10px', width: '75px'}}>
						<a href={MAIN_PATH}><img src={logo} /></a>
					</div>
					<div style={{marginTop: '15px', marginLeft: '15px'}}>
						<img src={title} />
					</div>
					<FastSearchForm />
				</div>
				
				<div style={{
					position: 'absolute', 
					top: '70px', left: '850px', 
					width: '200px', height: '85px',
					backgroundColor: BG_MIDDLE_GREEN,
					paddingLeft: '12px', paddingTop: '5px'
				}}>
					<a href={CONTACTS_PATH} style={localStyles.aContactLink}>Контакти</a>
					<a href={SCHEDULE_PATH} style={localStyles.aContactLink}>Графік роботи</a>
					<div style={{fontSize: '10pt', fontWeight: 'bold', fontFamily: 'Arial, sans-serif'}}>
						<div style={{display: 'flex', alignItems: 'center', marginTop: '3px'}}>
							<img src={iconAddress} />&nbsp;м.Київ, бул. Л. Українки,7
						</div>
						<div style={{display: 'flex', alignItems: 'center', marginTop: '3px'}}>
							<img src={iconPhone} />&nbsp;(044) 234-25-22
						</div>
						<div style={{display: 'flex', alignItems: 'center', marginTop: '3px'}}>
							<img src={iconLetter} />&nbsp;2351667@ukr.net
						</div>
					</div>
				</div>

				<LibrariesAdult x='5px' y='100px' w='283px' h='265px' header={this} />
				<LibrariesChild x='300px' y='330px' w='225px' h='145px' header={this} />
				<Events x='515px' y='165px' w='225px' h='165px' header={this} />
				<Services x='770px' y='255px' w='255px' h='260px' header={this} />
			
				<MainLink x='535px' y='85px' w='175px' h='55px' 
					icon={icon_ecatalog} name='ЕЛЕКТРОННИЙ КАТАЛОГ' href={ECATALOG_PATH}
				/>
				<MainLink x='325px' y='255px' w='160px' h='50px' 
					icon={icon_posters} name='АФІША' href={POSTER_LIST_PATH}
				/>
				<MainLink x='105px' y='395px' w='160px' h='50px' 
					icon={icon_onlineinfo} name='ON-LINE ДОВІДКА' href={ONLINE_PATH}
				/>
				<MainLink x='800px' y='170px' w='160px' h='50px' 
					icon={icon_services} name='ПОСЛУГИ БІБЛІОТЕК' href={SERVICES_PATH}
				/>
				<MainLink x='580px' y='350px' w='160px' h='50px' 
					icon={icon_exhibitions} name='ВІРТУАЛЬНІ ВИСТАВКИ' href={EXHIBITION_LIST_PATH}
				/>
				
				<Rectangle x='330px' y='90px' w='95px' h='145px' bg={BG_LIGHT_GREEN} />
				<Rectangle x='455px' y='95px' w='65px' h='60px' bg={BG_DARK_GREEN} />
				<Rectangle x='440px' y='175px' w='70px' h='65px' bg={BG_MIDDLE_GREEN} />
				<Rectangle x='10px' y='410px' w='80px' h='60px' bg={BG_DARK_GREEN} />
				<Rectangle x='20px' y='485px' w='165px' h='65px' bg={BG_LIGHT_GREEN} />
				<Rectangle x='200px' y='485px' w='80px' h='65px' bg={BG_MIDDLE_GREEN} />
				<Rectangle x='315px' y='515px' w='245px' h='30px' bg={BG_DARK_GREEN} />
				<Rectangle x='580px' y='445px' w='105px' h='105px' bg={BG_LIGHT_GREEN} />
				<Rectangle x='700px' y='440px' w='55px' h='50px' bg={BG_MIDDLE_GREEN} />
				<Rectangle x='710px' y='500px' w='45px' h='40px' bg={BG_DARK_GREEN} />
				<Rectangle x='760px' y='70px' w='75px' h='80px' bg={BG_LIGHT_GREEN} />
				<Rectangle x='990px' y='165px' w='55px' h='80px' bg={BG_DARK_GREEN} />

			</div>
		);
	}
}

function LibrariesAdult(props) {
	let style = {
		position: 'absolute', 
		top: props.y, 
		left: props.x, 
		width: props.w, 
		height: props.h,
		paddingTop: '15px',
		paddingLeft: '10px',
		paddingRight: '10px',
		borderWidth: '5px',
		borderStyle: 'solid',
		borderColor: BLOCK_BORDER_COLOR,
	}
	return (
		<div style={style}>
			<div style={localStyles.divBlockHeader}>
				БІБЛІОТЕКИ ДЛЯ ДОРОСЛИХ
			</div>
			<LinkLibAdult href={LIBCRB_INFO_PATH} name='ЦРБ "Печерська"' header={props.header} />
			<LinkLibAdult href={LIBVYSHNI_INFO_PATH} name='Бібліотека ім.О.Вишні' header={props.header} />
			<LinkLibAdult href={LIBBILOKUR_INFO_PATH} name='Бібліотека ім.К.Білокур' header={props.header} />
			<LinkLibAdult href={LIBKNYGOLYUB_INFO_PATH} name='Бібліотека "Книголюб"' header={props.header} />
			<LinkLibAdult href={LIB149_INFO_PATH} name='Бібліотека №149' header={props.header} />
			<LinkLibAdult href={LIB150_INFO_PATH} name='Бібліотека №150' header={props.header} />
			<LinkLibAdult href={LIBVSESVIT_INFO_PATH} name='Бібліотека "Всесвіт"' header={props.header} />
			<LinkLibAdult href={LIBSVITOGLYAD_INFO_PATH} name='Бібліотека "Світогляд"' header={props.header} />
			<LinkLibAdult href={LIBESTET_INFO_PATH} name='Бібліотека естетичного виховання' header={props.header} />
		</div>
	);
}
function LinkLibAdult(props) {
	var style = {
		marginTop: '4px',
		marginBottom: '4px',
		paddingBottom: '3px',
		fontFamily: 'Arial, sans-serif',
		fontSize: '12pt',
		borderBottom: '1px solid #d4dfc0'
	}
	let color = 'black';
	if( props.header.props.activeMenuPath == props.href) {
		color = ACTIVE_LINK_COLOR;
	}
	return (
		<div style={style}>
			<a style={{color: color, textDecoration: 'none', fontWeight: 'bold'}} href={props.href}>
				{props.name}
			</a>
		</div>
	);
}

function LibrariesChild(props) {
	let style = {
		position: 'absolute', 
		top: props.y, 
		left: props.x, 
		width: props.w, 
		height: props.h,
		paddingTop: '20px',
		paddingLeft: '20px',
		paddingRight: '20px',
		borderWidth: '5px',
		borderStyle: 'solid',
		borderColor: BLOCK_BORDER_COLOR,
	}
	return (
		<div style={style}>
			<div style={localStyles.divBlockHeader}>
				БІБЛІОТЕКИ ДЛЯ ДІТЕЙ
			</div>
			<LinkLibChild href={LIBVESELKA_INFO_PATH} name='Бібліотека "Веселка"' header={props.header} />
			<LinkLibChild href={LIBTRUBLAINI_INFO_PATH} name='Бібліотека ім.М.Трублаїні' header={props.header} />
			<LinkLibChild href={LIB8_INFO_PATH} name='Бібліотека №8' header={props.header} />
			<LinkLibChild href={LIBJERELO_INFO_PATH} name='Бібліотека "Джерело"' header={props.header} />
		</div>
	);
}
function LinkLibChild(props) {
	var style = {
		marginTop: '4px',
		marginBottom: '4px',
		paddingBottom: '3px',
		fontFamily: 'Arial, sans-serif',
		fontSize: '12pt',
		borderBottom: '1px solid #d4dfc0'
	}
	let color = 'black';
	if( props.header.props.activeMenuPath == props.href) {
		color = ACTIVE_LINK_COLOR;
	}
	return (
		<div style={style}>
			<a style={{color: color, textDecoration: 'none', fontWeight: 'bold'}} href={props.href}>
				{props.name}
			</a>
		</div>
	);
}

function Events(props) {
	let style = {
		position: 'absolute', 
		top: props.y, 
		left: props.x, 
		width: props.w, 
		height: props.h,
		paddingTop: '5px',
		paddingLeft: '20px',
		paddingRight: '20px',
		borderWidth: '5px',
		borderStyle: 'solid',
		borderColor: BLOCK_BORDER_COLOR,
	}
	return (
		<div style={style}>
			<div style={localStyles.divBlockHeader}>
				ЗАХОДИ
			</div>
			<LinkEvents href={EVENT_LIST_PATH} name='Новини та події' header={props.header} />
			<LinkEvents href={EXHIBITION_LIST_PATH} name='Віртуальні виставки' header={props.header} />
			<LinkEvents href={POSTER_LIST_PATH} name='Афіша' header={props.header} />
			<LinkEvents href={MAIN_PATH} name='Клуби та гуртки' header={props.header} />
			{/* <LinkEvents href={MAIN_PATH} name='Блог' /> */}
		</div>
	);
}
function LinkEvents(props) {
	var style = {
		marginTop: '2px',
		marginBottom: '2px',
		paddingBottom: '2px',
		fontFamily: 'Arial, sans-serif',
		fontSize: '12pt',
		borderBottom: '1px solid #d4dfc0'
	}
	let color = 'black';
	if( props.header.props.activeMenuPath == props.href) {
		color = ACTIVE_LINK_COLOR;
	}
	return (
		<div style={style}>
			<a style={{color: color, textDecoration: 'none', fontWeight: 'bold'}} href={props.href}>
				{props.name}
			</a>
		</div>
	);
}

function Services(props) {
	let style = {
		position: 'absolute', 
		top: props.y, 
		left: props.x, 
		width: props.w, 
		height: props.h,
		paddingTop: '10px',
		paddingLeft: '20px',
		paddingRight: '8px',
		borderWidth: '5px',
		borderStyle: 'solid',
		borderColor: BLOCK_BORDER_COLOR,
	}
	return (
		<div style={style}>
			<div style={localStyles.divBlockHeader}>
				СЕРВІСИ
			</div>
			<LinkServices href={ECATALOG_PATH} name='Електронний каталог' header={props.header} />
			<LinkServices href={ONLINE_PATH} name='On-line довідка' header={props.header} />
			<LinkServices href={SKS_PATH} name='Систематична картотека статей' header={props.header} />
			<LinkServices href={KKS_PATH} name='Краєзнавча картотека статей' header={props.header} />
			<LinkServices href={BIBLIOGRAPHIC_PATH} name='Бібліографічні видання' header={props.header} />
			<LinkServices href={BOOKS_INTERESTING_PATH} name='Бібліотека рекомендує' header={props.header} />
			<LinkServices href={SERVICES_PATH} name='Наші послуги' header={props.header} />
			<LinkServices href={RULES_PATH} name='Правила користування' header={props.header} />
			<LinkServices href={ADMINISTRATION_PATH} name='Адміністрація' header={props.header} />
		</div>
	);
}
function LinkServices(props) {
	let style = {
		marginTop: '2px',
		marginBottom: '3px',
		paddingBottom: '3px',
		fontFamily: 'Arial, sans-serif',
		fontSize: '12pt',
		borderBottom: '1px solid #d4dfc0'
	}
	let color = 'black';
	if( props.header.props.activeMenuPath == props.href) {
		color = ACTIVE_LINK_COLOR;
	}
	return (
		<div style={style}>
			<a style={{color: color, textDecoration: 'none', fontWeight: 'bold'}} href={props.href}>
				{props.name}
			</a>
		</div>
	);
}

function MainLink(props) {
	let style = {
		display: 'flex',
		alignItems: 'center',
		padding: '10px',
		position: 'absolute', 
		top: props.y, 
		left: props.x, 
		width: props.w, 
		height: props.h,
		backgroundColor: MAIN_LINK_BG_COLOR
	};
	let aStyle = {
		marginLeft: '10px',
		color: 'white', 
		textDecoration: 'none',
		fontSize: '14pt',
		fontFamily: 'impact',
		fontWeight: 'normal',
		letterSpacing: '2px'
	}
	return (
		<div style={style}>
			<img src={props.icon} />
			<a style={aStyle} href={props.href}>
				{props.name}
			</a>
		</div>
	);
}

function Rectangle(props) {
	let style = {
		position: 'absolute', 
		top: props.y, 
		left: props.x, 
		width: props.w, 
		height: props.h,
		backgroundColor: props.bg
	};
	return (
		<div style={style} />
	);
}

//		border: '1px solid blue',
const localStyles = {
	divMain: {
		position: 'relative',
		width: '1100px',
		height: '570px',
		margin: 'auto'
	},
	divBlockHeader: {
		fontSize: '12pt',
		fontFamily: 'impact',
		fontWeight: 'normal',
		color: '#430045',
		marginTop: '2px',
		marginBottom: '7px',
	},
	aContactLink: {
		textDecoration: 'none',
		color: '#430045',
		cursor: 'pointer',
		
		marginRight: '10px',
		fontSize: '11pt',
		fontWeight: 'normal',
		fontFamily: 'impact',
		letterSpacing: '0.8px'
	}
}

export default Header;
