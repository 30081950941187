import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";

import {
	MAIN_PATH,
	LIBKNYGOLYUB_INFO_PATH, 	
} from '../../../util/routes';

import mainImage from '../../../assets/img/library/libknygolyub/hello.jpg';

class LibKnygolyubInfoPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліотека "Книголюб"', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBKNYGOLYUB_INFO_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBKNYGOLYUB_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={{width: 'calc(100% - 80px)', paddingLeft: '40px', fontFamily: 'Arial, sans-serif', fontSize: '12pt'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Бібліотека "Книголюб"
			</div>
			<div>
				<img style={localStyles.imageCentered} src={mainImage} />
			</div>
			<div style={{marginTop: '30px'}}>
				Площа приміщення бібліотеки 442 кв.м., <br/>
				книжковий фонд - 35,6 тис. примірників. <br/>
				Щорічно обслуговує 4 тис. користувачів, <br/>
				кількість відвідувань за рік - 23 тис., <br/>
				книговидач - 85,8 тис. примірників.
			</div>
			<div style={{marginTop: '30px', marginBottom: '30px'}}>
				Бібліотека заснована в 1959 році. <br/>
				Бібліотечне обслуговування: абонемент, читальний зал, МБА. <br/>
				Надаються бібліографічні довідки універсальної тематики, в т.ч. по телефону. 
			</div>
		</div>
	);
}

const localStyles = {
	imageCentered:	{
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
}

export default LibKnygolyubInfoPage;
