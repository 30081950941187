import React, { Component } from 'react';
import stylesPage from '../page.module.css';

const STATE_COLLAPSED = 'collapsed';
const STATE_EXPANDED = 'expanded';

class Book extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			formState: STATE_COLLAPSED
		}
	}
	collapse = () => {
		this.setState({formState: STATE_COLLAPSED});
	}
	expand = () => {
		this.setState({formState: STATE_EXPANDED});
	}
	
	render() {
		var book = this.props.book;
		if( this.state.formState == STATE_COLLAPSED ) {
			var divCollapseOrExpand = (
				<div style={localStyles.divCollapseOrExpand} onClick={this.expand}>+</div>
			);
			var fieldsHtml = (null);
		} else {
			var divCollapseOrExpand = (
				<div style={localStyles.divCollapseOrExpand} onClick={this.collapse}>-</div>
			);
			var fieldsHtml = book.fields.map( (field, index) => {
				return (
					<div key={index} style={{display: 'flex'}}>
						<div style={localStyles.divFieldTitle}>{field.field_title}</div>
						<div>{field.field_value}</div>
					</div>
				);
			} );
		}
		
		var storageHtml = '';
		for( let storage of book.storages ) {
			if( storageHtml.length > 0 )
				storageHtml += ', ';
			storageHtml += storage.libTitle + ' (' + storage.title + ')';
		}
		
		return (
			<React.Fragment>
				<div style={localStyles.divBookPresentation}>
					{divCollapseOrExpand}
					<div style={{flexGrow: 100}}>
						{book.presentation}
						{fieldsHtml}
						<div>
							<span style={{color: '#674268', fontWeight: 'bold'}}>Наявність: </span>{storageHtml}
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const localStyles = {
	divCollapseOrExpand: {
		width: '20px',
		minWidth: '20px',
		height: '20px',
		marginRight: '10px',
		paddingBottom: '2px',
		backgroundColor: '#eee',
		textAlign: 'center',
		fontWeight: 'bold',
		border: '1px solid black',
		borderRadius: '3px',
		cursor: 'pointer'
	},
	divBookPresentation: {
		display: 'flex', 
		width: '100%', 
		marginTop: '10px', 
		paddingBottom: '10px', 
		borderBottom: '2px solid #588b0e'
	},
	divFieldTitle: {
		width: '150px',
		minWidth: '150px',
		paddingLeft: '20px',
		color: '#674268'
	}
};
export default Book;
