import React, { Component } from 'react';
import stylesAdmin from './admin.module.css';
import stylesPage from '../page.module.css';

import AdminHeader from "./components/AdminHeader";
import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";
import MessageConfirm from "../../components/MessageConfirm";

import { 
	performApiRequest, performApiRequestForm, 
} from "../../util/restapi"
import { ADMIN_USER_LIST_PATH } from "../../util/routes";

import iconDelete from '../../assets/img/sign_cross.png';

class AdminUserListPage extends Component {

	constructor(props) {
		super(props);
		this.userList = [];
		this.state = {
			userList: [],
			ok: true
		};
	}

	render() {
		var leftSide = (<AdminLeftSide path={ADMIN_USER_LIST_PATH} />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	var holder = props.holder;
	return (
		<React.Fragment>
			<div className={stylesAdmin.divContentHeader}>
				Перелік користувачів
			</div>
		</React.Fragment>
	)
}

export default AdminUserListPage;
