import React, { Component } from 'react';
import styles from '../bibliographic.module.css';

import BibliographicPage from "../BibliographicPage";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH
} from '../../../util/routes';

import image01 from './image01.png';

class B201 extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, selected: false },
			{title: 'Григорій Сковорода. Український Сократ', url: '', selected: true }
		];
		var body = (<Body />);
		return (
			<BibliographicPage breadCrumbs={breadCrumbs} body={body} />
		);
	}
}

function Body(props) {
return(

<div style={{width: '100%'}}>
	<div className={styles.divBibliographicHeader}>Григорій Сковорода. Український Сократ</div>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image01} style={{width: '600px'}} />
	</div>
	<div className={styles.divHeaderBibliographicComment}>
		До 300-річчя від дня народження українського  просвітителя-гуманіста, філософа, поета
	</div>
	<div className={styles.divHeaderBibliographicType}>
		Дайджест
	</div>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	До уваги читачів. 
	</p>

	<p className={styles.pCommon}>
	Григорій Савич Сковорода (1722-1794) мав значний вплив на сучасників і подальші покоління своїми байками, піснями, філософськими творами, а також способом життя, через що його називали «Сократом».
	</p>
	<p className={styles.pCommon}>
	Вам пропонується ознайомитися з творами видатного українського філософа, мандрівника та гуманіста  Г.С. Сковороди та матеріалами про його життя і творчість. 
	</p>
	<p className={styles.pCommon}>
	Матеріал дайджесту розміщений за тематичним принципом.
	</p>
	<p className={styles.pCommon}>
	Біографічна довідка прийде на допомогу тим, хто цікавиться життям та творчістю українського просвітителя або вивчає її за вузівськими чи шкільними програмами. 
	</p>
	<p className={styles.pCommon}>
	Приводимо деякі цитати Григорія Сковороди, вірш «Всякому місту – звичай і права». 
	</p>
	<p className={styles.pCommon}>
	У межах окремих розділів – алфавітне розташування за авторами та назвами книг і статей. 
	</p>
	<p className={styles.pCommon}>
	Бібліографічний дайджест адресований широкому колу читачів. 
	</p>
	<p className={styles.pCommon}>
	Відповідальний за випуск: Т.І. Шевченко
	</p>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	ГРИГОРІЙ САВИЧ СКОВОРОДА (1722-1794). 
	</p>
	<p className={styles.epigraph}>
	«Мені моя сопілка і вівця дорожчі від царського вінця». 
	</p>

	<p className={styles.pCommon}>
	В  2022  році  відзначають  300-річний  ювілей  Григорія  Савича  Сковороди. Філософ, ерудит, письменник, поет, співак… Він мав великий життєвий вибір: залишитися в Європі, виїхати до столиці Російської імперії й стати державним мужем, зрештою – знайти прихисток у Києво-Печерській лаврі… Не схотів. Бо головне для нього – залишитися самим собою: «Всяк мусить пізнати свій народ і в народі себе. Чи ти рус?.. Будь ним… Чи ти лях? Ляхом будь. Чи ти німець? Німечествуй. Француз? Францюзюй. Татарин? Татарствуй. Все добре на своєму місці і в своєму образі, і все красно, що чисто, природно, що не є підробкою, не перемішано з чужим по роду…» 
	</p>
	<p className={styles.pCommon}>
	Григорій Савич Сковорода народився 3 грудня 1722 р. в селі Чорнухах на Полтавщині в сім'ї малоземельного козака. Рано залишився сиротою. Виховував його чернець, певно, освічений чоловік, бо прищепив здібній дитині любов до науки. 
	</p>
	<p className={styles.pCommon}>
	У 1738 р. він вступив до Києво-Могилянської академії. З 1742 по 1744 pp. жив у Петербурзі, був співаком придворної капели, прославився чудовим басом, майстерною грою на скрипці, флейті, бандурі, цимбалах і сопілці та композиторським талантом, створював музику на власні вірші. Навчання продовжив в Київській академії. 
	</p>
	<p className={styles.pCommon}>
	У 1750 р. у складі російської місії Сковорода виїжджав за кордон і три роки мандрував Угорщиною, Словаччиною, Польщею, відвідав Братиславу, Відень, Будапешт; бував в університетах, слухав лекції знаменитих професорів, працював у бібліотеках, студіював філософські праці й, володіючи багатьма мовами, дискутував із ученими різних країн. 
	</p>
	<p className={styles.pCommon}>
	Повернувся в Україну у 1753р., викладав поетику в Переяславському колегіумі. Написав для слухачів курс поетики «Роздуми про поезію і порадник до майстерності оної». 
	</p>
	<p className={styles.pCommon}>
	Протягом 1754—1759 pp. жив у селі Коврай на Переяславщині, працюючи домашнім учителем у поміщика Степана Томари. Написав значну частину віршів збірки «Сад божественних пісень». Працював викладачем (спочатку поетики, а згодом етики) у Харківському колегіумі. Учителюючи в Харкові, латинськими і українськими віршами написав «Байку Езопову» (1760 р.), склав дві вступні лекції-проповіді до курсу етики. 
	</p>
	<p className={styles.pCommon}>
	У 1766р. філософ написав трактат «Вхідні двері до християнської доброчинності», наступного року філософські твори «Наркіс. Розмова про те: взнай себе» і «Симфонія, названа книга Асхань про пізнання самого себе». 
	</p>
	<p className={styles.pCommon}>
	Протягом 1769—1774 pp. Сковорода написав збірку прозових байок «Байки харківські», «Бесіду, названу двоє, про те, що блаженним бути легко», і «Діалог, чи Розмова про стародавній світ», а також твори: «Розмова п'яти подорожніх про справжнє щастя в житті» («Розмова дружня про душевний світ»), «Кільце», «Розмова, звана алфавіт, чи буквар світу». 
	</p>
	<p className={styles.pCommon}>
	У 1775—1776 pp. були написані твір «Книжечка, названа Si-lenus Alcibiadis, сиріч Ікона Алківіадська («Ізраїльський змій»)та «Книжечка про читання святого письма, названа Дружина Лотова». 
	</p>
	<p className={styles.pCommon}>
	Твори «Суперечка архістратига Михайла з сатаною про те: легко бути благим», «Пря Біса з Варсавою» були написані в 1783—1784 рр. 
	</p>
	<p className={styles.pCommon}>
	У 1785 р. Сковорода об'єднав тридцять віршів, написаних у різний час, у збірку «Сад божественних пісень». 
	</p>
	<p className={styles.pCommon}>
	У 1787р. він написав «Вдячного Еродія» і «Убогого Жайворонка», а у 1791р. завершив філософський твір «Діалог. Ім'я йому — Потоп зміїний». 
	</p>
	<p className={styles.pCommon}>
	Протягом життя Григорій Сковорода уникав усього, що могло б уярмити його дух. 
	</p>
	<p className={styles.pCommon}>
	Вражає і такий факт останнього дня життя Сковороди. За свідченням господаря хати, що стала мандрівникові прихистком на порозі смерті, він був веселим і балакучим. Обідав з друзями, читав власні вірші. Потім вийшов на подвір’я й викопав могилу… Для себе. Тоді переодягся в чисту білизну, поклав під голову торбинку з пожитками, рукописи і ліг на лаву, склавши руки. І відійшов у вічність. Це сталося 9 листопада 1794 року в селі Іванівці (нині Сковородинівка) на Харківщині. Так завершила земний путь людина, яка понад усе цінувала свободу. 
	</p>
	<p className={styles.pCommon}>
		<span style={{fontWeight: 'bold'}}>«Світ ловив мене, та не спіймав»</span>, - просив він написати на могилі… 
	</p>
	<p className={styles.pCommon}>
	Щоб зрозуміти суть цієї епітафії, слід детально познайомитися з життєвим шляхом поета, його філософськими поглядами. 
	</p>
	<p className={styles.pCommon}>
	Григорій Сковорода давно вже став символом мудрості українського народу. Про великого філософа України сказано і багато, і замало водночас. Він був у всьому незвичайною людиною. За спогадами його сучасників, одягався він охайно, але просто. Їжу вживав виключно рослинну та ще молочні продукти, і то раз на день після заходу сонця, м'яса й риби зовсім не вживав. Кажуть, що це не була примха чи якісь забобони, це так відбувалося через властивість його організму. Спав Григорій не більше чотирьох годин на добу, вставав удосвіта і йшов собі або за місто, або в сади. 
	</p>
	<p className={styles.pCommon}>
	Ще малим хлопчиком він сприймав слово, як музичний і живописний елемент, а до письма він ставився, як до образного мистецтва. Слово було для нього, ніби музика: пізнати, що там у книжках, сторінки яких укриті писемними знаками, було для нього те ж саме, що відчути красу мелодії, насолодитися нею. Його геніальна творчість настільки проста у сприйнятті, наскільки складна у тому, щоб пристосувати її до себе. До Сковороди дуже тяглася молодь, про нього писали в листах, обговорювали його думки, навіть сперечалися щодо його мудрих висловлювань, які потім помандрували у світ афоризмів. Гучна слава про мандрівного філософа поширювалася при тому, що за життя із його творів не було надруковано нічого. 
	</p>
	<p className={styles.pCommon}>
	Мудрий Сковорода бентежно ставився до таких речей, на які інші просто не звертали уваги, що для звичайних людей було буденним і звичайним. Йому хотілося прочитати книгу буття людини, дійти до розуміння сенсу життя кожної людини, осягнути загадкову людську душу й ті зміни, які з нею відбуваються, і як це впливає на характер і життя загалом. Він сказав про світ і людину таке, чого не сказали інші, навчав людину жити так, як не вчили інші, а найголовніше — сам жив так, як навчав. Філософські твори великий мудрець писав доступною мовою, він уводив у їхній текст притчі, байки, часом навіть прикрашав власними малюнками. І все це було заради Людини, заради розкриття й удосконалювання її духовного світу, а значить, і повної гармонії з усім матеріальним світом. Він був учителем — творцем, а не вчителем — ремісником. Жив так, як навчав, а навчав через мудрість світову, творив своє вчення в живих і зрозумілих образах за допомогою слова, музики, малюнку. 
	</p>
	<p className={styles.pCommon}>
	«Світ ловив мене, та не спіймав» — у цьому вся проста мудрість великого філософа, бо Григорій Савич Сковорода прагнув жити згідно зі своїми моральними переконаннями й залишатися морально чистим. Його творчість — не лише надбання українського народу, за кордоном створені цілі інститути, які досліджують, вивчають, зіставляють його творчий доробок. 
	</p>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Цитати Григорія Сковороди
	</p>

	<p className={styles.pCommon}>
	«Шукаємо щастя по країнах, століттях, а воно скрізь і завжди з нами; як риба в воді, так і ми в ньому, і воно біля нас шукає нас самих. Нема його ніде від того, що воно скрізь. Воно схоже до сонячного сяйва – відхили лише вхід у душу свою.» 
	</p>
	<p className={styles.pCommon}>
	«Чи не дивина, що один у багатстві бідний, а інший у бідності багатий?» 
	</p>
	<p className={styles.pCommon}>
	«Що вподобав, на те й перетворився». 
	</p>
	<p className={styles.pCommon}>
	«Наступний, весело освітлений день – плід учорашнього, так само як добра старість – нагорода гарної юності.» 
	</p>
	<p className={styles.pCommon}>
	«Ні про що не турбуватись, ні за чим не турбуватись – значить, не жити, а бути мертвим, адже турбота – рух душі, а життя – се рух.» 
	</p>
	<p className={styles.pCommon}>
	Джерело: <a href="http://forum.reactor.org.ua/showthread.php?t=4689">http://forum.reactor.org.ua/showthread.php?t=4689</a>
	</p>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Григорій Сковорода «Всякому місту – звичай і права» 
	</p>

	<pre className={styles.pCommon} style={{paddingLeft: '50px'}}>{`
	Всякому місту — звичай і права, 
	Всяка тримає свій ум голова: 
	Всякому серцю — любов і тепло, 
	Всякеє горло свій смак віднайшло. 
	Я ж у полоні нав’язливих дум: 
	Лише одне непокоїть мій ум. 

	Панські Петро для чинів тре кутки, 
	Федір-купець обдурити прудкий, 
	Той зводить дім свій на модний манір, , 
	Інший гендлює, візьми перевір! 
	Я ж у полоні нав’язливих дум: 
	Лише одне непокоїть мій ум. 

	Той безперервно стягає поля, 
	Сей іноземних заводить телят. 
	Ті на ловецтво готують собак,
	В сих дім, як вулик, гуде від гуляк, 
	Я ж у полоні нав’язливих дум: 
	Лише одне непокоїть мій ум. 
							   
	Ладить юриста на смак свій права, 
	З диспутів учню тріщить голова. 
	Тих непокоїть Венерин амур, 
	Всякому голову крутить свій дур. 
	В мене ж турботи тільки одні, 
	Як з ясним розумом вмерти мені. 

	Знаю, що смерть — як коса замашна, 
	Навіть царя не обійде вона. 
	Байдуже смерті, мужик то чи цар, — 
	Все пожере, як солому пожар. 
	Хто ж бо зневажить страшну її сталь? 
	Той, в кого совість, як чистий кришталь... 
	`}</pre>

	<p className={styles.pCommon}>
	У вірші "Всякому місту — звичай і права..." Г. Сковорода розмірковує над тим, що багато людей перебувають у полоні своїх згубних пристрастей, марно витрачають життя, обманюють інших, наживаються за їхній рахунок. У ліричного ж героя поезії є одна турбота, один клопіт - як прожити життя чесно, по совісті. Але смерть нікого не пощадить — ні царя, ні мужика, і постати перед нею спокійно може лише той, у кого "совість, як чистий кришталь". Кожна строфа вірша закінчується однією думкою — про що має турбуватися людина. У творі багато перелічень, порівнянь, дотепних висловів, які характеризують марнославство людей. Цей вірш виконується як пісня, використаний Іваном Котляревським у п’єсі "Наталка Полтавка". 
	</p>
	<p className={styles.pCommon}>
	Джерело: <a href="www.ukrlib.com.ua">www.ukrlib.com.ua</a>
	</p>

	<p className={styles.epigraph}>
	«Не розум від книг, а книги від розуму створились».<br/>
	Г.С. Сковорода. 
	</p>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Твори Г. С. Сковороди 
	</p>

	<ul>
		<li className={styles.liCommon}>
		Сковорода, Г. С. Повне зібрання творів: у 2-х т. - К.: Наук, думка, 1973.<br/>
		Т. 1: Пісні. Вірші. Байки. Трактати. Діалоги.- 531 с.<br/>
		Т. 2: Трактати. Діалоги. Притчі. Переклади. Листи. Різне.- 574 с.<br/>
		Увійшли літературні та філософські твори Сковороди, написані з середини 50-х років XVIII ст. до кінця життя. 
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. Твори: в 2-х т. - К.: АО "Обереги", 1994.<br/>
		Т. 1: Поезії. Байки. Трактати. Діалоги.- 527 с.<br/>
		Т. 2.: Трактати. Діалоги. Притчі. Переклади. Листи.- 479 с.<br/>
		Написані книжною староукраїнською мовою і вперше повністю перекладені на нову укр. літературну мову, твори відкривають сучасному читачеві дивосвіт думок і образів великого народного любомудра. 
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г.С. Твори: у 2-х т. / пер. зі староукр. і прим. М. Кашуби, В. Шевчука.- 2-е вид., випр.-К.: Обереги, 2005.<br/>
		Т. 1: Поезії. Байки. Трактати. Діалоги.- 528 с.<br/>
		Т. 2: Трактати. Діалоги. Притчі. Переклади. Листи.- 320 с.
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. Твори: для ст. шк. віку. - К.: Веселка, 1996. - 271 с: іл.<br/>
		До книги ввійшли вірші, байки, найвиразніші фрагменти діалогів і трактатів, притчі, листи, перекладені на сучасну українську мову.
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г.С. Вибрані твори / Григорій Сковорода; упоряд., передм., прим. та комент. Л. Ушкалова.- Харків: Прапор, 2007.- 382 с. 
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г.С. Вибрані твори в українських перекладах / упоряд. текстів, передм. та прим. Л. Ушкалова.- Х.: Ранок, 2003.- 144 с. 
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. С. Вибране / Г. С. Сковорода, І. П. Котляревський.- 3-тє вид.- Харків: Прапор, 1990.- 301 с.: іл.., портр.- (Шкільна бібліотека).
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. С. Байки Харківські / Григорій Сковорода; переклад Назара Федорака. – Львів: Тегга  Іncognita, 2019.- 107 с. : іл..- (Українська езотерика).
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. С. Вірші. Пісні. Байки. Діалоги. Трактати. Притчі. Прозові переклади. Листи. -К.: Наук, думка, 1983. - 544 с.<br/>
		Григорій Сковорода виступив як виразник етико-гуманістичних ідеалів людей праці. 
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. С. Літературні твори. - К.: Наук, думка, 1972. - 423 с.<br/>
		Літературна спадщина Сковороди - це філософські й педагогічні трактати, проповіді, притчі, байки, оригінальна і перекладна поезія. 
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. С. Сковорода. Найкраще:[ переклади]  / Григорій Сковорода.- Львів: Тегга  Іncognita, 2018.- 319 с. : іл.., портр.- (Українська езотерика).
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. Пізнай в собі людину / пер. М. Кашуба, пер. поезії В.Войтович. - Львів: Світ, 1995. - 528 с. 
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. С. Поезія / упоряд. текстів та приміт. В. В. Яременка. -К.: Рад. Письменник, 1971. -239 с.<br/>
		Cпадщина Сковороди є свідченням тієї істини, що в тяжкі часи історичних роздоріж український народ носив у собі невгасиму жагу волелюбства, що виливалась у поезії і роздумах великого мислителя. 
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г.С. Розмова про істинне щастя / пер. укр. мовою, прим. В.О. Шевчука.- Х.: Прапор, 2002.- 280 с.
		</li>
		<li className={styles.liCommon}>
		Сковорода, Г. С. Сад пісень: вибрані твори: для старшого  шкільного  віку. - К.: Веселка, 1983.- 190 с.<br/>
		У збірник увійшли поезії, байки, уривки з філософських творів, афоризми. 
		</li>
	</ul>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Життя і творчість Г. С. Сковороди 
	</p>
	<ul>
		<li className={styles.liCommon}>
		Багалій, Д. І. Український мандрований філософ Григорій Сковорода / Д. І. Багалій .  - К.: Орій: Кобза, 1992. - 472 с. 
		</li>
		<li className={styles.liCommon}>
		Барабаш, Ю.Я. Вибрані студії: Сковорода. Гоголь. Шевченко / Ю.Я. Барабаш.- К.: Видавничий дім «Києво-Могилянська академія», 2007.- 744 с.: портр. 
		</li>
		<li className={styles.liCommon}>
		Барабаш, Ю. Я. "Знаю человека..": Григорий Сковорода. Поезия. Философия. Жизнь / Ю.Я. Барабаш. - М.: Худ. лит., 1989. - 333 с. 
		</li>
		<li className={styles.liCommon}>
		Григорій Сковорода від А до Я / тексти Леоніда Ушкалова; графічне опрацювання Л. Стецькович, В. Стецьковича.- Львів: Видавництво Старого Лева, 2019.- 71 с. : іл.
		</li>
		<li className={styles.liCommon}>
		Григорій Сковорода, Тарас Шевченко, Іван Франко, Леся Українка / [Л. Ушкалов, Т. Панасенко]  .- Луцьк: Волинська обласна друкарня, 2012.- 472 с. 
		</li>
		<li className={styles.liCommon}>
		Драч, І. Ф., Кримський, С. Б., Попович, М. В. Григорій Сковорода  : біографічна  повість / І. Ф. Драч ,  С. Б. Кримський, М. В. Попович.  - К.: Молодь, 1984. -216 с. 
		</li>
		<li className={styles.liCommon}>
		Мишанич, О. В. Григорій Сковорода і усна народна творчість / О. В. Мишанич . - К.: Наук. думка, 1976. - 152 с.<br/>
		Досліджуються погляди Сковороди на усну народну словесність, простежується шлях активного освоєння ним у філософських трактатах і літературних творах різних фольклорних жанрів, робиться спроба з'ясувати вплив народного світогляду на філософські, морально-етичні і педагогічні погляди видатного українського мислителя другої половини XVIII ст. 
		</li>
		<li className={styles.liCommon}>
		Поліщук, Ф. М. Григорій Сковорода: семінарій / Ф. М. Поліщук . - К.: Вища школа, 1972.- 208 с.<br/>
		Семінарій висвітлює історію вивчення життя і творчості Г. С. Сковороди, містить висловлювання прогресивних діячів культури про нього і його творчість. 
		</li>
		<li className={styles.liCommon}>
		Сковорода Григорій: образ мислителя: збірка наукових праць / упоряд. В. М. Нічик, В. Є. Бішовець, Я. М. Стратий.- Київ: Інститут філософії: НАН України, 1997.- 455 с.
		</li>
		<li className={styles.liCommon}>
		Стадниченко, В.Я. Іду за Сковородою: сповідь у любові до вчителя: докум. повість-подорож / В. Я. Стадниченко .- К.: Криниця, 2002.- 17 с. 
		</li>
		<li className={styles.liCommon}>
		Ушкалов, Л.В. Григорій Сковорода / Л. Ушкалов.- Харків: Фоліо, 2009.- 12 с. - (Знамениті українці). 
		</li>
		<li className={styles.liCommon}>
		Ушкалов, Л. Українське барокове богомислення: сім етюдів про Григорія Сковороду / Л. Ушкалов.- Харків : Акта, 2001.- 218 с. 
		</li>
		<li className={styles.liCommon}>
		Чижевський, Д.І. Філософія Г.С. Сковороди /  Д. І. Чижевський; підгот.тексту Л. Ушкалова.- Харків: Прапор, 2004.- 270 с. 
		</li>
		<li className={styles.liCommon}>
		Шевчук, В. А. Предтеча: роман: [про Григорія Сковороду] / В. А. Шевчук  <br/>// К.: Дніпро, 1982.-471 с. 
		</li>
		<li className={styles.liCommon}>
		Шевчук, В.О. Пізнаний і непізнаний Сфінкс : Григорій Сковорода сучасними очима: розмисли / В.О. Шевчук.- К.: Пульсари, 2008.- 526 с. 
		</li>
		<li className={styles.liCommon}>
		Бірюкова, І. Найстаріший житловий будинок столиці: дім, де гостював Сковорода і ховався Махно / Інна Бірюкова // Хрещатик.- 2021.- 9 квіт. ( № 12).- С. 16.<br/>
		Автор розповідає історію будинку в Києві, який побудовано ще в 1760 році по вулиці Контрактовій, 7. Зараз будинок приватизований і має статус пам’ятки архітектури.
		</li>
		<li className={styles.liCommon}>
		Борисенко, К. Найважливіше про Сковороду / Катерина Борисенко <br/>// Українська культура.- 2020.- № 3.- С. 22-23.
		</li>
		<li className={styles.liCommon}>
		Галаур, С. Муравським шляхом у Сковородинівку: сьогодні народився український Першорозум – Григорій Сковорода  / С. Галаур <br/>// Урядовий  кур’єр.- 2010.- 3 груд. - С. 11. 
		</li>
		<li className={styles.liCommon}>
		Гнатюк, Л. Чому вивчення мови Григорія Сковороди актуальне для українців у ХХІ столітті / Лідія Гнатюк <br/>// Дивослово.- 2013.- № 5.- С. 53-58.
		</li>
		<li className={styles.liCommon}>
		Гринь, Т. Музей любомудра на його батьківщині / Т. Гринь  <br/>// Дивослово.- 2010.- № 9.- С. 62-63.<br/>
		Розповідається про Чорнухинський літературно-меморіальний музей Григорія Сковороди, експозиції музею, їх історичну цінність. 
		</li>
		<li className={styles.liCommon}>
		Дзюбенко-Мейс, Н. Сковорода :[ поема] / Наталія Дзюбенко-Мейс <br/>// Дзвін. – 2021.- № 1 .- С. 5-26.
		</li>
		<li className={styles.liCommon}>
		Каналюк, В. Байки  Григорія Сковороди / В. Каналюк  <br/>// Дивослово.- 2011.- № 10.- С. 24-28. 
		</li>
		<li className={styles.liCommon}>
		Кралюк, П. Чи є українська філософія європейською ? / П. Кралюк  <br/>// День. – 2014. - 17-18 січ.- С. .8.
		</li>
		<li className={styles.liCommon}>
		Куценко, П. Сковорода: [вірш]  / Петро Куценко <br/>// Літературна Україна.- 2015.- 21 трав. (№ 21).- С. 8.
		</li>
		<li className={styles.liCommon}>
		Лелітка, С. «Не той дурний, хто не знає, але той, хто знати не хоче» /  Світлана Лелітка <br/>// Голос України.-  2020. - 15 квіт.( № 65).- С. 16.
		</li>
		<li className={styles.liCommon}>
		Лютий, Т. Спадок античності /Тарас Лютий <br/>// Український тиждень.- 2022.- № 7.-  С. 37.
		</li>
		<li className={styles.liCommon}>
		Маляренко, О. «Він – молодий, не зловлений світом, він – це свобода без меж…»  / О. Маляренко <br/>// Українська культура.- 2012.- № 7. – С. 56-59. 
		</li>
		<li className={styles.liCommon}>
		Мельничук, Б. Письменник як паспорт народу: деякі  проблеми художнього  відтворення  постатей  українських  класиків  / Б. Мельничук <br/>// Дзвін.- 2010.- № 8.- С. 127-132. 
		</li>
		<li className={styles.liCommon}>
		Олійник, Л.  Флейта Сковороди / Л. Олійник  <br/>// День.-  2014.- 7-8 лист. -  С. 22.
		</li>
		<li className={styles.liCommon}>
		Савенко,  О.  Великодні вірші Григорія Сковороди  / О. Савенко <br/>// Дивослово.- 2019.- № 11. – С. 56-58.
		</li>
		<li className={styles.liCommon}>
		Скрутень, І. Повернення Григорія Сковороди  / І. Скрутень <br/>// Віче.- 2012.- № 15-16.- С.70-71. 
		</li>
		<li className={styles.liCommon}>
		Сокур, А. Українські народні перекази : Григорій Сковорода  / А. Сокур <br/>// Дивослово.- 2012.- № 9. – С. 25. 
		</li>
		<li className={styles.liCommon}>
		Стадниченко, В. Наш отчий дім: яким бути музею Григорія Сковороди на його Батьківщині – в селищі Чорнухи на Полтавщині  / В. Стадниченко <br/>// Урядовий кур’єр.- 2010.- 5 бер.- С.6-7. 
		</li>
		<li className={styles.liCommon}>
		Субота, В. «Світ ловив мене, та не спіймав» / В. Субота  <br/>// Літературна Україна.- 2012.- 6 вер.- С. 5.<br/>
		У Національному літературно-меморіальному музеї Григорія Сковороди пройшов фестиваль «De libertate», присвячений 290-річчю від дня народження великого філософа, просвітителя і поета. 
		</li>
		<li className={styles.liCommon}>
		Токмань, Г. Місце поетичної сковородіани у системі діалогоекзистенційного вивчення української літератури / Ганна Токмань <br/>// Дивослово.- 2015.- № 1.- С. 32-38.
		</li>
		<li className={styles.liCommon}>
		Унікалов, Л. Людина межі: Григорій Сковорода в рецепції Михайла Драгоманова / Леонід Унікалов <br/>// Дивослово.- 2014.- № 10.- С. 52-57.
		</li>
		<li className={styles.liCommon}>
		Усатенко, Г. Про Григорія Сковороду, ідентичність і давню літературу / Галина Усаченко <br/>// Дивослово.- 2013.- № 5.- С. 47-51. 
		</li>
		<li className={styles.liCommon}>
		Федорак, Н. «Пташиний базар» Григорія Сковороди / Назар Федорак <br/>// Слово і Час.- 2017.- № 9.- С. 11-20.
		</li>
		<li className={styles.liCommon}>
		Чорна, С. Ювілей Григорія Сковороди – чинник єднання громад / Свтлана Чорна <br/>// Голос України. – 2022. – 4 лют. – С. 2.                  
		</li>
		<li className={styles.liCommon}>
		Шевчук, Т. Барокова парадигма творчості Григорія Сковороди  / Т. Шевчук <br/>// Дивослово.- 2011.- № 11.- С. 48-52. 
		</li>
		<li className={styles.liCommon}>
		Шевчук, Т. Григорій Сковорода : шлях мудреця: п’єса / Т. Шевчук  <br/>// Дивослово.- 2011.- № 1.- С. 29-32. 
		</li>
		<li className={styles.liCommon}>
		Шевчук, Т. Музичний код творчості Григорія Сковороди  / Т. Шевчук <br/>// Слово і Час.- 2011.- № 5.- С. 24-35.<br/>
		У статті досліджується сучасний стан і перспективи студіювання наукової проблеми «Г. Сковорода і музика». Подано історіографію питання та аналіз музичної топіки у псальмах і симфоніях Г. Сковороди в контексті актуальних проблем інтермедіальної поетики. 
		</li>
	</ul>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Про Григорія Сковороду в мережі Інтернет
	</p>
	<ul>
		<li className={styles.liCommon}>
		Афоризми Григорія Сковороди: <a href="http://scovoroda.info/aphorism.php">http://scovoroda.info/aphorism.php</a>
		</li>
		<li className={styles.liCommon}>
		Г. Сковорода – перший український філософ: <a href="http://www.ukrlit.vn/ua/making/82rvd5.html">http://www.ukrlit.vn/ua/making/82rvd5.html</a> 
		</li>
		<li className={styles.liCommon}>
		Григорій Сковорода: <a href="http://www.aratta-ukraine.com/sacred_ua.php?id=28">http://www.aratta-ukraine.com/sacred_ua.php?id=28</a> 
		</li>
		<li className={styles.liCommon}>
		Григорій Сковорода: <a href="http://www.ukrlit.vn.ua/biography/scovoroda.html">http://www.ukrlit.vn.ua/biography/scovoroda.html</a> 
		</li>
		<li className={styles.liCommon}>
		Григорій Сковорода: біографія: <a href="http://www.parta.com.ua/ukr/stories/writers/34/">http://www.parta.com.ua/ukr/stories/writers/34/</a> 
		</li>
		<li className={styles.liCommon}>
		Григорій Сковорода: реферат: <a href="http://www.parta.com.ua/ukr/referats/view/2109/">http://www.parta.com.ua/ukr/referats/view/2109/</a> 
		</li>
		<li className={styles.liCommon}>
		Григорий Сковорода: <a href="http://www.history.vn.ua/book/person/38.html">http://www.history.vn.ua/book/person/38.html</a> 
		</li>
		<li className={styles.liCommon}>
		Життя і творчість Григорія Савича Сковороди: <a href="http://scovoroda.info">http://scovoroda.info</a> 
		</li>
		<li className={styles.liCommon}>
		КГБ: Григорій Сковорода – видатний український письменник, просвітитель-демократ: <a href="http://lib.misto.kiev.ua/UKR/TVIR/SKOVORODA/scovoroda6.txt">http://lib.misto.kiev.ua/UKR/TVIR/SKOVORODA/scovoroda6.txt</a> 
		</li>
		<li className={styles.liCommon}>
		КГБ: Повчальний характер притч Сковороди: <a href="http://lib.misto.kiev.ua/UKR/TVIR/SKOVORODA/scovoroda202.txt">http://lib.misto.kiev.ua/UKR/TVIR/SKOVORODA/scovoroda202.txt</a> 
		</li>
		<li className={styles.liCommon}>
		КГБ: Як розуміє щастя Григорій Сковорода?: <a href="http://lib.misto.kiev.ua/UKR/TVIR/SKOVORODA/scovoroda9.txt">http://lib.misto.kiev.ua/UKR/TVIR/SKOVORODA/scovoroda9.txt</a> 
		</li>
		<li className={styles.liCommon}>
		Своєрідність відображення народних ідеалів у творчості Григорія Сковороди: <a href="http://ukrtvory.ru">http://ukrtvory.ru</a> 
		</li>
		<li className={styles.liCommon}>
		Cковорода Григорій Савич: матеріал із Вікіпедії – вільної енциклопедії: <a href="http://uk.wikipedia.org/wiki/Сковорода_Григорій_Савич">http://uk.wikipedia.org/wiki/Сковорода_Григорій_Савич</a> 
		</li>
	</ul>

</div>
		
);
}

export default B201;
