import React, { Component } from 'react';

class Pagination extends Component {

	/*
		props
			pageCount
			pageNumber
			onPageClick
			skipTitle
			style
	*/
	constructor(props) {
		super(props);
	}
	
	initPages = () => {
		var count = this.props.pageCount;
		var page = this.props.pageNumber;
		// 1: 1,2,...,10,11
		// 2: 1,2,3,...,10,11
		// 3: 1,2,3,4,...,10,11
		// 4: 1,2,3,4,5,...,10,11
		// 5: 1,2,...4,5,6,...,10,11
		// 7: 1,2,...6,7,8,...,10,11
		// 8: 1,2,...,7,8,9,10,11
		// 9: 1,2,...,8,9,10,11
	
		var pages = [];
		if( count <= 10 ) {
			for (let i = 1; i <= count; i++) {
				pages.push(i);
			}
			return pages;
		}
		if(page <= 4 ) {
			for (let i = 1; i <= page+1; i++) {
				pages.push(i);
			}
			pages.push(0);
			for (let i = count-1; i <= count; i++) {
				pages.push(i);
			}
			return pages;
		}
		if(page >= count-3 ) {
			for (let i = 1; i <= 2; i++) {
				pages.push(i);
			}
			pages.push(0);
			for (let i = page-1; i <= count; i++) {
				pages.push(i);
			}
			return pages;
		}
		
		for (let i = 1; i <= 2; i++) {
			pages.push(i);
		}
		pages.push(0);
		for (let i = page-1; i <= page+1; i++) {
			pages.push(i);
		}
		pages.push(0);
		for (let i = count-1; i <= count; i++) {
			pages.push(i);
		}
		
		return pages;
	}
	
	render() {
		if( this.props.pageCount <= 1 )
			return (null);
		var pageNumbers = this.initPages();
		var pages = pageNumbers.map( (number, index) => {
			if( number == 0 ) {
				var style = localStyles.divPageEmpty;
				var text = '. . . ';
				var onClick = () => {};
			} else if( number == this.props.pageNumber) {
				var style = localStyles.divPageCurrent;
				var text = number;
				var onClick = () => {};
			} else {
				var style = localStyles.divPage;
				var text = number;
				var onClick = () => { this.props.onPageClick(number); };
			}
			return (
				<div key={index} style={style} onClick={onClick}>{text}</div>
			);
		});
		
		var styleDivMain = this.props.style;
		if( styleDivMain == null )
			styleDivMain = {};
		styleDivMain.display = 'flex';
		var title = this.props.skipTitle ? (null) :
			(<div style={{paddingRight: '20px', paddingTop: '5px'}}>СТОРІНКИ:</div>);
		
		return(
			<div style={styleDivMain}>
				{title}
				{pages}
			</div>
		);
	}
}

var localStyles = {
	divPageEmpty: {
		width: '40px',
		marginLeft: '5px',
		marginRight: '5px',
		textAlign: 'center'
	},
	divPage: {
		width: '50px',
		marginLeft: '5px',
		marginRight: '5px',
		paddingTop: '5px',
		paddingBottom: '5px',
		textAlign: 'center',
		border: '1px solid black',
		borderRadius: '7px',
		fontSize: '12pt',
		cursor: 'pointer'
	},
	divPageCurrent: {
		width: '50px',
		marginLeft: '5px',
		marginRight: '5px',
		paddingTop: '5px',
		paddingBottom: '5px',
		textAlign: 'center',
		border: '1px solid black',
		borderRadius: '7px',
		fontSize: '12pt',
		fontWeight: 'bold',
		color: 'green',
		backgroundColor: '#e0e0e0'
	}
};

export default Pagination;
