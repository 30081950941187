import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";

import mapImage from '../../assets/img/map/map_all.png';

import {
	MAIN_PATH,
	CONTACTS_PATH,
	SCHEDULE_PATH,
	SERVICES_PATH,
	RULES_PATH,
	ADMINISTRATION_PATH,

	LIBCRB_CONTACTS_PATH,
	LIB149_CONTACTS_PATH,
	LIB150_CONTACTS_PATH,
	LIB8_CONTACTS_PATH,
	LIBBILOKUR_CONTACTS_PATH,
	LIBESTET_CONTACTS_PATH,
	LIBJERELO_CONTACTS_PATH,
	LIBKNYGOLYUB_CONTACTS_PATH,
	LIBSVITOGLYAD_CONTACTS_PATH,
	LIBTRUBLAINI_CONTACTS_PATH,
	LIBVESELKA_CONTACTS_PATH,
	LIBVSESVIT_CONTACTS_PATH,
	LIBVYSHNI_CONTACTS_PATH,
} from '../../util/routes';

import {
	LIBCRB, LIBVYSHNI, LIBBILOKUR, LIBKNYGOLYUB, LIB149, LIB150, LIBVSESVIT, LIBSVITOGLYAD, LIBESTET, 
	LIBVESELKA, LIBTRUBLAINI, LIB8, LIBJERELO 
} from '../../util/consts';

class ContactsPage extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Контакти', url: '', selected: true }
		];
		var menu = [
			{ title: 'Контакти', url: CONTACTS_PATH, isGroupHeader: false, isActive: true },
			{ title: 'Наші послуги', url: SERVICES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Правила користування', url: RULES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліотеки для дорослих', isGroupHeader: true, isActive: false },
			{ title: LIBCRB.title, url: LIBCRB_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBVYSHNI.title, url: LIBVYSHNI_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBBILOKUR.title, url: LIBBILOKUR_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBKNYGOLYUB.title, url: LIBKNYGOLYUB_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIB149.title, url: LIB149_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIB150.title, url: LIB150_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBVSESVIT.title, url: LIBVSESVIT_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBSVITOGLYAD.title, url: LIBSVITOGLYAD_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBESTET.title, url: LIBESTET_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліотеки для дітей', isGroupHeader: true, isActive: false },
			{ title: LIBVESELKA.title, url: LIBVESELKA_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBTRUBLAINI.title, url: LIBTRUBLAINI_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIB8.title, url: LIB8_CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBJERELO.title, url: LIBJERELO_CONTACTS_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} menu={menu} body={body} />);
	}
	
}

function Body(props) {
	return (
		<div style={{width: '100%'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>Контакти</div>
			<div style={{width: '750px', marginLeft: 'auto', marginRight: 'auto'}}>
				<div style={{fontSize: '14pt', fontWeight: 'bold', marginBottom: '10px' }}>Карта</div>
				<img src={mapImage} usemap="#logomap"/>
				<map name="logomap">
					<area shape="circle" coords="363,245,25" href={LIBCRB_CONTACTS_PATH} />
					<area shape="circle" coords="500,29,25" href={LIBVYSHNI_CONTACTS_PATH} />
					<area shape="circle" coords="584,577,25" href={LIBBILOKUR_CONTACTS_PATH} />
					<area shape="circle" coords="366,363,25" href={LIBKNYGOLYUB_CONTACTS_PATH} />
					<area shape="circle" coords="372,77,25" href={LIB149_CONTACTS_PATH} />
					<area shape="circle" coords="373,479,25" href={LIB150_CONTACTS_PATH} />
					<area shape="circle" coords="276,53,25" href={LIBSVITOGLYAD_CONTACTS_PATH} />
					<area shape="circle" coords="230,226,25" href={LIBVSESVIT_CONTACTS_PATH} />
					<area shape="circle" coords="504,587,25" href={LIBESTET_CONTACTS_PATH} />
					<area shape="circle" coords="182,342,25" href={LIBVESELKA_CONTACTS_PATH} />
					<area shape="circle" coords="469,497,25" href={LIBTRUBLAINI_CONTACTS_PATH} />
					<area shape="circle" coords="409,292,25" href={LIB8_CONTACTS_PATH} />
					<area shape="circle" coords="228,126,25" href={LIBJERELO_CONTACTS_PATH} />
				</map>
				<ol>
					<li>
						Центральна районна бібліотека "Печерська": бульвар Лесі Українки, 7
					</li>
					<li>
						Бібліотека ім. О.Вишні: вул. Грушевського, 9
					</li>
					<li>
						Бібліотека ім. К.Білокур: вул. Катерини Білокур, 8
					</li>
					<li>
						Бібліотека "Книголюб": вул. Генерала Алмазова, 14
					</li>
					<li>
						Бібліотека №149: вул. Лiпська, 12/5
					</li>
					<li>
						Бібліотека №150: вул. Іоанна Павла ІІ, 19
					</li>
					<li>
						Бібліотечний комплекс для всієї сім'ї "Світогляд":<br/> 
						вул. Городецького/Франка/Станіславського, 12/2/3
					</li>
					<li>
						Бібліотека "Всесвіт": вул. Шота Руставелі, 17
					</li>
					<li>
						Бібліотека естетичного виховання: бул. Дружби Народів, 32
					</li>
					<li>
						Бібліотека "Веселка" для дітей: пер. Лабораторний, 24
					</li>
					<li>
						Бібліотека ім. М.Трублаїні для дітей: бул. Марії Приймаченко, 7
					</li>
					<li>
						Бібліотека №8 для дітей: вул. Арсенальна, 17
					</li>
					<li>
						Бібліотека "Джерело" для дітей: вул. Шовковична, 46/48
					</li>
				</ol>
			</div>
		</div>
	);
}

export default ContactsPage;
