import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Gallery from "../../../components/Gallery";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIBBILOKUR_INFO_PATH, LIBBILOKUR_GALLERY_PATH, } from '../../../util/routes';
import { LIBBILOKUR } from '../../../util/consts';

class LibBilokurGalleryPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBBILOKUR.title, url: LIBBILOKUR_INFO_PATH, selected: false },
			{title: 'Фотогалерея', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBBILOKUR_GALLERY_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBBILOKUR_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var imageList = [
		{ url: '/libbilokur/001.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libbilokur/002.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libbilokur/003.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libbilokur/004.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libbilokur/005.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libbilokur/006.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libbilokur/007.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libbilokur/008.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libbilokur/009.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libbilokur/010.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
	];
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBBILOKUR.title}<br/>
				Фотогалерея
			</div>
			<Gallery imageUrlPrefix={'/img/'} images={imageList} />
		</div>
	);
}

export default LibBilokurGalleryPage;
