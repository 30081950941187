import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIBCRB_INFO_PATH, LIBCRB_SERVICES_PATH, } from '../../../util/routes';
import { LIBCRB } from '../../../util/consts';

class LibCrbServicesPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBCRB.title, url: LIBCRB_INFO_PATH, selected: false },
			{title: 'Наші послуги', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBCRB_SERVICES_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBCRB_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				До послуг користувачів ЦРБ "Печерська"
			</div>
			
			<p style={{fontWeight: 'bold'}}>Загальні послуги</p>
			<ul>
				<li>можливість користуватися єдиним фондом системи та фондами інших бібліотек міста по МБА;</li>
				<li>оперативне інформування про адміністративні та соціальні служби району, навчальні та культосвітні заклади, органи правового захисту населення;</li>
				<li>доступ до світових інформаційних ресурсів через мережу Інтернет.</li>
				<li>електронна база даних з питань краєзнавства;</li>
			</ul>

			<hr className={stylesPage.hr} />

			<p style={{fontSize: '14pt', fontWeight: 'bold', textAlign: 'center'}}>
				Відділ довідково - бібліографічної та інформаційної роботи
			</p>
			<p>
				Тел. 235-49-16<br/>
				Працює: 10-00 до 18-00<br/>
				Вихідний день: п'ятниця<br/>
			</p>
			<p style={{fontWeight: 'bold'}}>До послуг користувачів</p>
			<ul>
				<li>Електронна картотека статей;</li>
				<li>Краєзнавча картотека</li>
				<li>Поточний бібліографічний список "Печерськ на сторінках преси"</li>
				<li>"Важливі телефони м. Києва"</li>
				<li>"Знай свої пільги" (допомога інвалідам, багатодітним сім'ям, дітям-сиротам, малозабезпеченим киянам).</li>
				<li>"Що потрібно знати, щоб захиститися від раку молочної залози": тематична підборка</li>
				<li>"Кроки  у світ професії". тематична підборка</li>
				<li>"Увага! Надруковано про нас". (Печерська ЦБС)</li>
			</ul>
			<p>
				В разі необхідності тематичного підбору літератури або оперативного розшуку публікацій 
				в періодичних виданнях, виконання інформаційно-бібліографічних довідок, 
				підготовки бібліографічних посібників та рекомендованих списків -  
				до Ваших послуг черговий бібліограф Центральної районної бібліотеки "Печерська".
			</p>
			<hr className={stylesPage.hr} />

			<p style={{fontSize: '14pt', fontWeight: 'bold', textAlign: 'center'}}>
				Читальний зал
			</p>
			<p>
				Тел. 234-25-22<br/>
				Працює: 10-00 до 18-00<br/>
				Вихідний день: п'ятниця<br/>
			</p>
			<p style={{fontWeight: 'bold'}}>До послуг користувачів</p>
			<ul>
				<li>Алфавітний і систематичний каталоги</li>
				<li>Систематична картотека статей</li>
				<li>Картотека назв художніх творів</li>
				<li>Електронна картотека статей</li>
				<li>Можливість користуватись послугами бібліотек міста МБА в(міжбібліотечного абонементу)</li>
			</ul>
			<hr className={stylesPage.hr} />

			<p style={{fontSize: '14pt', fontWeight: 'bold', textAlign: 'center'}}>
				Абонемент
			</p>
			<p>
				Тел. 234-25-22<br/>
				Працює: 10-00 до 18-00<br/>
				Вихідний день: п'ятниця<br/>
			</p>
			<p style={{fontWeight: 'bold'}}>До послуг користувачів</p>
			<ul>
				<li>Можливість користуватись єдиним фондом ЦБС - ВСО  (внутрішнього системного обміну)</li>
				<li>Дитячий куточок</li>
				<li>Оперативне інформування про нові надходження літератури</li>
				<li>Пригодницька, фантастична література, любовні романи, біографії видатних і талановитих людей, поетичні збірки</li>
				<li>Популярні періодичні видання:</li>
				<ul>
					<li>літературно художні журнали</li>
					<li>видання по садівництву та городництву</li>
					<li>видання для рукоділля, популярні жіночі журнали</li>
				</ul>
			</ul>
			<hr className={stylesPage.hr} />
			
			<p style={{fontSize: '14pt', fontWeight: 'bold', textAlign: 'center'}}>
				Internet центр "Печерський"
			</p>
			<p>
				тел. 235-16-67<br/>
				Працює: 10-00 до 18-00<br/>
				Вихідний день: п'ятниця, неділя<br/>
			</p>
			<p style={{fontWeight: 'bold'}}>До послуг користувачів</p>
			<ul>
				<li>Пропонує доступ в Internet</li>
				<li>Виконує функції інформативної служби</li>
				<li>Здійснює автоматизований пошук законодавчих та нормативних документів України і надає їх тексти у друкованому або електронному вигляді</li>
				<li>Складає списки літератури до дипломних та курсових робіт</li>
				<li>Надає допомогу у складанні каталогів та картотек для особистих бібліотек та бібліотек установ і організацій</li>
				<li>Здійснює ксерокопіювання документів</li>
				<li>Безкоштовні консультації "Основи користування Internet"</li>
			</ul>
			<hr className={stylesPage.hr} />

			<p style={{fontSize: '14pt', fontWeight: 'bold', textAlign: 'center'}}>
				Відділл організації і використання книжкового фонду
			</p>
			<p>
				Якщо видання, яке Вас цікавить відсутнє в фондах Централізованої бібліотечної системи – 
				радимо скористатися послугою міжбібліотечного абонементу (МБА), 
				ви отримаєте замовлення на протязі тижня 
				(для користування в читальній залі вашої бібліотеки).
			</p>
			<p>
				Якщо в бібліотеці відсутнє видання, яке Вам потрібно, але воно є у фонді 
				Централізованої бібліотечної системи  – 
				скористайтеся послугою внутрішнього системного обміну (ВСО), 
				ви отримаєте замовлення на протязі декількох діб.
			</p>
			
		</div>
	);
}

export default LibCrbServicesPage;
