import React, { Component } from 'react';
import stylesPage from '../page.module.css';
import stylesBibliographic from './bibliographic.module.css';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";

import {
	MAIN_PATH, 
	B209_PATH,
	B208_PATH,
	B207_PATH,
	B206_PATH,
	B205_PATH,
	B204_PATH,
	B203_PATH,
	B202_PATH,
	B201_PATH,

	BIBLIOGRAPHIC_PATH,
	ONLINE_PATH,
	SKS_PATH,
	KKS_PATH,
} from '../../util/routes';

import b201Logo from './b201/logo.png';
import b202Logo from './b202/logo.png';
import b203Logo from './b203/logo.jpg';
import b204Logo from './b204/logo.jpg';
import b205Logo from './b205/logo.jpg';
import b206Logo from './b206/logo.jpg';
import b207Logo from './b207/logo.jpg';
import b208Logo from './b208/logo.jpg';
import b209Logo from './b209/logo.jpg';

class BibliographicListPage extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: '', selected: true },
		];
		var menu = [
			{ title: 'Бібліографічні видання', url: '', isGroupHeader: false, isActive: true },
			{ title: 'On-line довідка', url: ONLINE_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Систематична картотека статей', url: SKS_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Краєзнавча картотека статей', url: KKS_PATH, isGroupHeader: false, isActive: false },
		];
		return (
			<div>
				<Header activeMenuPath={BIBLIOGRAPHIC_PATH} />
				<div className={stylesPage.divPageGeneral}>
					<div className={stylesPage.divPageBody}>
						<BreadCrumbs breadCrumbs={breadCrumbs} />
						<div style={{width: '100%', display: 'flex'}} >
							<div style={{width: '220px'}} >
								<LeftSideMenu menu={menu} />
							</div>
							<div style={{width: '880px'}} >
								<Body />
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

function Body(props) {
	return (
		<div style={{padding: '20px'}}>
			<div className={stylesBibliographic.divBibliographicHeader}>Бібліографічні видання</div>
			<hr className={stylesPage.hr} />
			<Barrierless />
			<Relax />
			<MentalHealth />
			<Oberig />
			<Mova />
			<AntoniyPechersky />
			<Skoropadsky />
			<Porady />
			<Skovoroda />
		</div>
	);
}

function Barrierless(props) {
	return (
		<React.Fragment>
			<div style={{display: 'flex'}}>
				<div style={{width: '200', marginRight: '20px'}}>
					<a href={B209_PATH}>
						<img src={b209Logo} width='180' height='130' />
					</a>
				</div>
				<div>
					<a className={stylesBibliographic.aBibliographicLink} href={B209_PATH}>
						Безбар’єрність – це про нас і для нас
					</a>
					<div className={stylesBibliographic.divBibliographicType}>
						Вебліографічний інформаційний дайджест
					</div>
				</div>
			</div>
			<hr className={stylesPage.hr} />
		</React.Fragment>
	);
}

function Relax(props) {
	return (
		<React.Fragment>
			<div style={{display: 'flex'}}>
				<div style={{width: '200', marginRight: '20px'}}>
					<a href={B208_PATH}>
						<img src={b208Logo} width='180' height='130' />
					</a>
				</div>
				<div>
					<a className={stylesBibliographic.aBibliographicLink} href={B208_PATH}>
						ЧИТАЙ УКРАЇНСЬКОЮ ТА РЕЛАКСУЙ
					</a>
					<div className={stylesBibliographic.divBibliographicType}>
						Бібліографічний список літератури
					</div>
				</div>
			</div>
			<hr className={stylesPage.hr} />
		</React.Fragment>
	);
}

function MentalHealth(props) {
	return (
		<React.Fragment>
			<div style={{display: 'flex'}}>
				<div style={{width: '200', marginRight: '20px'}}>
					<a href={B207_PATH}>
						<img src={b207Logo} width='180' height='130' />
					</a>
				</div>
				<div>
					<a className={stylesBibliographic.aBibliographicLink} href={B207_PATH}>
						Ментальне здоров’я: як подбати про себе
					</a>
					<div className={stylesBibliographic.divBibliographicType}>
						Дайджест
					</div>
				</div>
			</div>
			<hr className={stylesPage.hr} />
		</React.Fragment>
	);
}

function Oberig(props) {
	return (
		<React.Fragment>
			<div style={{display: 'flex'}}>
				<div style={{width: '200', marginRight: '20px', paddingLeft: '40px', paddingRight: '40px'}}>
					<a href={B206_PATH}>
						<img src={b206Logo} height='130' />
					</a>
				</div>
				<div>
					<a className={stylesBibliographic.aBibliographicLink} href={B206_PATH}>
						ОБЕРЕГИ українського народу – символи любовi й незрадливостi
					</a>
					<div className={stylesBibliographic.divBibliographicType}>
						Бібліографічний список літератури
					</div>
				</div>
			</div>
			<hr className={stylesPage.hr} />
		</React.Fragment>
	);
}

function Mova(props) {
	return (
		<React.Fragment>
			<div style={{display: 'flex'}}>
				<div style={{width: '200', marginRight: '20px'}}>
					<a href={B205_PATH}>
						<img src={b205Logo} width='180' height='130' />
					</a>
				</div>
				<div>
					<a className={stylesBibliographic.aBibliographicLink} href={B205_PATH}>
						Як дорослій людині вивчити українську мову
					</a>
					<div className={stylesBibliographic.divBibliographicType}>
						Вебліографічне повідомлення
					</div>
				</div>
			</div>
			<hr className={stylesPage.hr} />
		</React.Fragment>
	);
}

function AntoniyPechersky(props) {
	return (
		<React.Fragment>
			<div style={{display: 'flex'}}>
				<div style={{width: '200', marginRight: '20px', paddingLeft: '40px', paddingRight: '40px'}}>
					<a href={B204_PATH}>
						<img src={b204Logo} width='100' height='130' />
					</a>
				</div>
				<div>
					<a className={stylesBibliographic.aBibliographicLink} href={B204_PATH}>
						Преподобний Антоній, чудотворець Печерський 
					</a>
					<div className={stylesBibliographic.divBibliographicComment}>
						1040 років із часу народження церковного діяча Київської Русі (983-1073)					
					</div>
					<div className={stylesBibliographic.divBibliographicType}>
						Вебліографічний список літератури
					</div>
				</div>
			</div>
			<hr className={stylesPage.hr} />
		</React.Fragment>
	);
}

function Skoropadsky(props) {
	return (
		<React.Fragment>
			<div style={{display: 'flex'}}>
				<div style={{width: '200', marginRight: '20px', paddingLeft: '40px', paddingRight: '40px'}}>
					<a href={B203_PATH}>
						<img src={b203Logo} width='100' height='130' />
					</a>
				</div>
				<div>
					<a className={stylesBibliographic.aBibliographicLink} href={B203_PATH}>
						Павло Скоропадський та його державотворча діяльність
					</a>
					<div className={stylesBibliographic.divBibliographicComment}>
						До 150-річчя із дня народження ( 1873 – 1945 )<br/>
						українського державного і політичного діяча, талановитого воєначальника.
					</div>
					<div className={stylesBibliographic.divBibliographicType}>
						Бібліографічне повідомлення
					</div>
				</div>
			</div>
			<hr className={stylesPage.hr} />
		</React.Fragment>
	);
}

function Porady(props) {
	return (
		<React.Fragment>
			<div style={{display: 'flex'}}>
				<div style={{width: '200', marginRight: '20px'}}>
					<a href={B202_PATH}>
						<img src={b202Logo} width='180' height='130' />
					</a>
				</div>
				<div>
					<a className={stylesBibliographic.aBibliographicLink} href={B202_PATH}>
						Поради, які завжди порятують
					</a>
					<div className={stylesBibliographic.divBibliographicType}>
						Рекомендаційний список літератури
					</div>
				</div>
			</div>
			<hr className={stylesPage.hr} />
		</React.Fragment>
	);
}

function Skovoroda(props) {
	return (
		<React.Fragment>
			<div style={{display: 'flex'}}>
				<div style={{width: '200', marginRight: '20px'}}>
					<a href={B201_PATH}>
						<img src={b201Logo} width='180' height='130' />
					</a>
				</div>
				<div>
					<a className={stylesBibliographic.aBibliographicLink} href={B201_PATH}>
						Григорій Сковорода. Український Сократ
					</a>
					<div className={stylesBibliographic.divBibliographicComment}>
						До 300-річчя від дня народження українського  просвітителя-гуманіста, філософа, поета
					</div>
					<div className={stylesBibliographic.divBibliographicType}>
						Дайджест
					</div>
				</div>
			</div>
			<hr className={stylesPage.hr} />
		</React.Fragment>
	);
}

export default BibliographicListPage;
