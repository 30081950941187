import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";

import {
	MAIN_PATH,
	LIBBILOKUR_INFO_PATH, 	
} from '../../../util/routes';

import mainImage from '../../../assets/img/library/libbilokur/hello.jpg';

class LibBilokurInfoPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліотека ім. К.Білокур', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBBILOKUR_INFO_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBBILOKUR_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={{width: 'calc(100% - 80px)', paddingLeft: '40px', fontFamily: 'Arial, sans-serif', fontSize: '12pt'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Бібліотека ім. К.Білокур
			</div>
			<div>
				<img style={localStyles.imageCentered} src={mainImage} />
			</div>
			<div style={{marginTop: '30px'}}>
				Площа приміщення бібліотеки 182,4 кв.м., <br/>
				книжковий фонд - 21,5 тис. примірників. <br/>
				Щорічно обслуговує 3,2 тис. користувачів, <br/>
				кількість відвідувань за рік - 23 тис., <br/>
				книговидач - 68 тис. примірників.
			</div>
			<div style={{marginTop: '30px', marginBottom: '30px'}}>
				Бібліотека заснована в 1949 році. 
				Бібліотечне обслуговування: абонемент, читальний зал, послуги МБА. 
			</div>
		</div>
	);
}

const localStyles = {
	imageCentered:	{
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
}

export default LibBilokurInfoPage;
