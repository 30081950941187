import React, { Component } from 'react';

import LeftSideMenu from "../../../components/LeftSideMenu";

import {
	ROLE_ADMIN,
	ROLE_MANAGER,
	ROLE_LIB,
	ROLE_BIBLIO,
} from '../../../util/consts'; 
import {
	ADMIN_PATH,
	ADMIN_IMPORTANT_INFO_PATH,
	ADMIN_SLIDE_LIST_PATH,
	ADMIN_EVENT_LIST_PATH,
	ADMIN_EXHIBITION_LIST_PATH,
	ADMIN_POSTER_LIST_PATH,
	ADMIN_BOOK_LIST_PATH,
	ADMIN_BOOK_INTERESTING_LIST_PATH,
	ADMIN_CLUB_LIST_PATH,
	ADMIN_BOOK_NEW_LIST_PATH,
	ADMIN_ONLINE_INFO_PATH,
	ADMIN_USER_LIST_PATH,
	ADMIN_LOGOUT_PATH,
} from '../../../util/routes';
import {getUserInfo} from '../../../util/tools';

class AdminLeftSide extends Component {

	constructor(props) {
		super(props);
		this.userInfo = getUserInfo();
	}

	menuItem = (title, url) => {
		return {title: title, url: url, isGroupHeader: false, isActive: url == this.props.path};
	}
	
	render() {
		let role = this.userInfo.userRole;
		let menu;
		if( role == ROLE_ADMIN ) {
			menu = [
				this.menuItem('Важлива інформація', ADMIN_IMPORTANT_INFO_PATH),
				this.menuItem('Новини та події', ADMIN_EVENT_LIST_PATH),
				this.menuItem('Віртуальні виставки', ADMIN_EXHIBITION_LIST_PATH),
				this.menuItem('Афіша', ADMIN_POSTER_LIST_PATH),
				this.menuItem('Нові надходження', ADMIN_BOOK_NEW_LIST_PATH),
				this.menuItem('Цікаве на полицях', ADMIN_BOOK_INTERESTING_LIST_PATH),
				this.menuItem('Гуртки', ADMIN_CLUB_LIST_PATH),
				this.menuItem('Питання читачів', ADMIN_ONLINE_INFO_PATH),
				this.menuItem('Користувачі', ADMIN_USER_LIST_PATH),
			]
		} else if( role == ROLE_MANAGER ) {
			menu = [
				this.menuItem('Важлива інформація', ADMIN_IMPORTANT_INFO_PATH),
				this.menuItem('Новини та події', ADMIN_EVENT_LIST_PATH),
				this.menuItem('Віртуальні виставки', ADMIN_EXHIBITION_LIST_PATH),
				this.menuItem('Афіша', ADMIN_POSTER_LIST_PATH),
				this.menuItem('Нові надходження', ADMIN_BOOK_NEW_LIST_PATH),
				this.menuItem('Цікаве на полицях', ADMIN_BOOK_INTERESTING_LIST_PATH),
				this.menuItem('Гуртки', ADMIN_CLUB_LIST_PATH),
				];
		} else if( role == ROLE_LIB ) {
			menu = [
				this.menuItem('Новини та події', ADMIN_EVENT_LIST_PATH),
			];
		} else if( role == ROLE_BIBLIO ) {
			menu = [
				this.menuItem('Питання читачів', ADMIN_ONLINE_INFO_PATH),
			];
		} else {
			menu = [];
		}
		menu.push( this.menuItem('Вийти', ADMIN_LOGOUT_PATH) );
		return (
			<React.Fragment>
				<div style={{marginLeft: '10px', marginTop: '20px'}}>
					Користувач: <span style={{fontWeight: 'bold'}}>{this.userInfo.username}</span>
				</div>
				<LeftSideMenu menu={menu} />
			</React.Fragment>
		);
	}
}

export default AdminLeftSide;