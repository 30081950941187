import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";

import mapImage from '../../../assets/img/map/map_8.png';

import { MAIN_PATH, LIB8_INFO_PATH, LIB8_CONTACTS_PATH, CONTACTS_PATH } from '../../../util/routes';
import { LIB8 } from '../../../util/consts';

class Lib8ContactsPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIB8.title, url: LIB8_INFO_PATH, selected: false },
			{title: 'Координати', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIB8_CONTACTS_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIB8_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={{width: '100%'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIB8.title}<br/>
				Координати
			</div>
			<div style={{width: '750px', marginLeft: 'auto', marginRight: 'auto'}}>
				<div style={{fontSize: '14pt', fontWeight: 'bold', marginBottom: '10px' }}>Карта</div>
				<img src={mapImage}/>

			<div style={{marginTop: '20px', fontWeight: 'bold'}}>
				Адреса та телефони
			</div>

			<div style={{marginTop: '20px'}}>
				Адреса: вул. Арсенальна, 17<br />
				Тел.: 285-02-16<br />
			</div>

			<div style={{marginTop: '20px', marginBottom: '30px'}}>
				<a href={CONTACTS_PATH}>Інші бібліотеки ЦБС на карті</a>
			</div>
			</div>
		</div>
	);
}

export default Lib8ContactsPage;
