import React, { Component } from 'react';
import LeftSideMenu from "../../../components/LeftSideMenu";
import stylesLibrary from "../library.module.css";

import {
	LIBSVITOGLYAD_CONTACTS_PATH, 
	LIBSVITOGLYAD_ECATALOG_PATH, 
	LIBSVITOGLYAD_EVENTS_PATH, 
	LIBSVITOGLYAD_GALLERY_PATH, 
	LIBSVITOGLYAD_INFO_PATH, 
} from '../../../util/routes';

import iconLiBullet from '../../../assets/img/mainpage/icon_li_bullet.png';

class LeftSide extends Component {

	constructor(props) {
		super(props);
	}

	menuItem = (title, url) => {
		return {title: title, url: url, isGroupHeader: false, isActive: url == this.props.path};
	}
	
	render() {
		var menu = [
			this.menuItem('Загальна інформація', LIBSVITOGLYAD_INFO_PATH),
			this.menuItem('Новини та події', LIBSVITOGLYAD_EVENTS_PATH),
			this.menuItem('Фотогалерея', LIBSVITOGLYAD_GALLERY_PATH),
			this.menuItem('Електронний каталог', LIBSVITOGLYAD_ECATALOG_PATH),
			this.menuItem('Координати', LIBSVITOGLYAD_CONTACTS_PATH),
		]
		return (
			<React.Fragment>
				<LeftSideMenu menu={menu} />
				
				<div className={stylesLibrary.divLeftSideHeader}>
					Адреса бібліотеки
				</div>
				<ul className={stylesLibrary.ulLeftSideContacts}>
					<li style={{marginTop: '15px'}}>
						<span style={{fontWeight: 'bold'}}>Адреса:</span> вул. Городецького/Франка/Станіславського, 12/2/3
					</li>

					<li style={{marginTop: '15px'}}>
						<span style={{fontWeight: 'bold'}}>Телефон:</span> 279-56-01, 279-75-20
					</li>
				</ul>

				<div className={stylesLibrary.divLeftSideHeader}>
					Графік роботи	
				</div>
				<table className={stylesLibrary.tableWorkSchedule}>
					<tr>
						<td>&nbsp;</td>
						<td valign="top">Абонемент</td>
						<td valign="top">Читальний зал</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>понеділок</div></td>
						<td valign="top">11:00-19:00</td>
						<td valign="top">11:00-19:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>вівторок</div></td>
						<td valign="top">11:00-19:00</td>
						<td valign="top">11:00-19:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>середа</div></td>
						<td valign="top">11:00-19:00</td>
						<td valign="top">11:00-19:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>четвер</div></td>
						<td valign="top">11:00-19:00</td>
						<td valign="top">11:00-19:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>п'ятниця</div></td>
						<td colSpan="2" valign="top">вихідний</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>субота</div></td>
						<td valign="top">11:00-18:00</td>
						<td valign="top">11:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>неділя</div></td>
						<td valign="top">11:00-18:00</td>
						<td valign="top">11:00-18:00</td>
					</tr>
				</table>
				
			</React.Fragment>
		);
	}
}

export default LeftSide;