import React, { Component } from 'react';
import stylesAdmin from './admin.module.css';

import AdminHeader from "./components/AdminHeader";
import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";
import DivInnerHtml from '../../components/DivInnerHtml';
import Message from "../../components/Message";

import { performApiRequest, POSTER_LIST_API_URL, POSTER_DELETE_API_URL } from "../../util/restapi";
import { getAdminPosterUrl } from "../../util/tools";
import { ADMIN_POSTER_LIST_PATH, ADMIN_POSTER_PATH } from "../../util/routes";

const STATE_WORKING = 'working';
const STATE_READY = 'ready';

class AdminPosterListPage extends Component {
	constructor(props) {
		super(props);
		
		this.posterList = [];
		this.messageHandler = {
			showMessage: null,
			doAfterClose: this.doDelete,
			confirm: false,
			posterId: null
		};
		this.state = {
			formState: STATE_READY,
			posterList: this.posterList,
			deletingPoster: null,
			ok: true
		};
	}
	componentDidMount = async () => {
		this.setState({formState: STATE_WORKING});
		var response = await performApiRequest(POSTER_LIST_API_URL, {});
		this.ok = response.ok;
		if( response.ok ) {
			this.posterList = response.posterList;
		}
		this.setState({formState: STATE_READY, ok: response.ok});
	}

	deletePoster = (posterId) => {
		this.messageHandler.posterId = posterId;
		this.messageHandler.doAfterClose = this.doDelete;
		this.messageHandler.showMessage( "Вилучити вибраний анонс?" );
	}
	doDelete = async () => {
		if( this.messageHandler.confirm ) {
			var posterId = this.messageHandler.posterId;
			this.setState({deletingPoster: posterId});
			var request = { posterId: posterId };
			var response = await performApiRequest(POSTER_DELETE_API_URL, request);
			if( response.ok ) {
				for( let i=this.posterList.length-1; i >= 0 ; i--) {
					if( this.posterList[i].posterId == posterId )
						this.posterList.splice( i, 1 );
				}
			}
			this.setState({deletingPoster: null, posterList: this.posterList});
		}
		this.messageHandler.confirm = false;
		this.messageHandler.posterId = null;
	}
	render() {
		var leftSide = (<AdminLeftSide path={ADMIN_POSTER_LIST_PATH} />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	var holder = props.holder;
	return (
		<div style={{marginBottom: '30px'}}>
			<div className={stylesAdmin.divContentHeader}>
				Афіша
			</div>
			<div style={{marginTop: '20px', marginBottom: '10px'}}>
				<a href= {ADMIN_POSTER_PATH + '/0'} target='_blank'>Додати</a>
			</div>
			<PosterList holder={holder} />
		</div>
	)
}

function PosterList(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_WORKING ) {
		return ( <Spinner /> );
	}
	if( ! holder.state.ok ) {
		return (
			<div style={{textAlign: 'center'}}>
				Під час виконання виникла помилка
			</div>
		);
	}
	var posterListHtml = holder.posterList.map( (poster, index) => {
		return (<Poster key={index} poster={poster} holder={holder} />);
	} );
	return (
		<React.Fragment>
			{posterListHtml}
		</React.Fragment>
	);
}

function Poster(props) {
	var holder = props.holder;
	var poster = props.poster;
	var hrefEdit = getAdminPosterUrl( poster.posterId );
	if( holder.state.deletingPoster == poster.posterId ) {
		return (
			<div>
				<Spinner />
			</div>
		);
	}
	return (
		<div style={{borderTop: '2px solid green', padding: '10px 0 10px 0'}}>
			<div style={{fontSize: '14pt', fontWeight: 'bold'}}>
				{poster.title}
			</div>
			<DivInnerHtml text={poster.description} />		
			<div style={{marginTop: '10px'}}>
				<a href={hrefEdit}>Змінити</a>&nbsp;
				<button type='button' onClick={ () => {holder.deletePoster(poster.posterId);} }>Вилучити</button>
			</div>
			<Message messageHandler={holder.messageHandler}/>
		</div>
	);
}

const localStyles = {
	divPageTitle: {
		textAlign: 'center',
		fontSize: '20pt',
		fontFamily: 'AkvitaniaModern',
		fontWeight: 'bold'
	},
	divMessage: {
		marginTop: '20px',
		textAlign: 'center',
		fontSize: '16pt',
		color: 'red',
		fontWeight: 'bold'
	}
}

export default AdminPosterListPage;

