import React, { Component, createRef } from 'react';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Slider from "./Slider";
import ImportantInfo from "./ImportantInfo";
import Events from "./Events";
import Exhibitions from "./Exhibitions";
import Bibliographic from "./Bibliographic";
import AdvertisementLine from "./AdvertisementLine";
import Recommendations from "./Recommendations";

import { performApiRequest, MAIN_PAGE_API_URL } from "../../util/restapi"

export const STATE_INIT = 'init';
export const STATE_READY = 'ready';

class MainPage extends Component {

	constructor(props) {
		super(props);
		this.refSlider = createRef();
		this.sliderComponent = null;
		this.state = {
			formState: STATE_INIT
		};
	}
	
	componentDidMount = async () => {
		var request = {task: 'main'};
		var response = await performApiRequest(MAIN_PAGE_API_URL, request);
		this.pageData = response;
		this.refSlider.current.startSliding();
		this.setState({formState: STATE_READY});
	}
	
	render() {
		console.log('Main page props', this.props);
		return (
			<div>
				<Header />
				<div style={{width: '100%', backgroundColor: '#efefef', marginBottom: '40px'}}>
					<div style={localStyles.divSliderInfoEvents}>
						<Slider ref={this.refSlider} holder={this} />
						<div style={{width: '550px', height: '530px'}}>
							<ImportantInfo holder={this}/>
							<Events holder={this} />
						</div>
					</div>
					<div style={localStyles.divExhibitionsBibliographic}>
						<div style={{width: '500px', marginLeft: '20px', marginRight: '30px'}}>
							<Exhibitions holder={this} />
						</div>
						<div style={{width: '530px', marginRight: '20px'}}>
							<Bibliographic holder={this} />
						</div>
					</div>
				</div>
				<div style={{width: '100%'}}>
					<AdvertisementLine />
				</div>
				<Recommendations holder={this} />
				<Footer />
			</div>
		)
	}
}

const localStyles = {
	divSliderInfoEvents: {
		display: 'flex',
		borderTop: '15px solid #efefef', 
		paddingBottom: '30px', 
		width: '1100px', 
		backgroundColor: 'white', 
		margin: 'auto'
	},
	divExhibitionsBibliographic: {
		display: 'flex',
		width: '1100px', 
		margin: 'auto',
		backgroundColor: 'white', 
	}
};

export default MainPage;
