import React, { Component } from 'react';
import stylesAdmin from './admin.module.css';

import AdminHeader from "./components/AdminHeader";
import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";

import { ADMIN_IMPORTANT_INFO_PATH } from "../../util/routes";

import { 
	performApiRequest, 
	IMPORTANT_INFO_LOAD_API_URL, 
	IMPORTANT_INFO_SAVE_API_URL 
} from "../../util/restapi"

const STATE_READY = 'ready';
const STATE_WAIT = 'wait';

class AdminImportantInfoPage extends Component {

	constructor(props) {
		super(props);

		this.importantInfo = [
			{text: ''},
			{text: ''},
			{text: ''},
			{text: ''},
			{text: ''},
		];
		
		this.state = {
			formState: STATE_READY,
			dirty: false, 
			importantInfo: this.importantInfo
			
		};
	}
	componentDidMount = async () => {
		this.setState({formState: STATE_WAIT});
		var request = {
			task: 'loadImportantInfo'
		};
		var response = await performApiRequest(IMPORTANT_INFO_LOAD_API_URL, request);
		if( response.ok ) {
			this.importantInfo = response.info;
			while( this.importantInfo.length < 5 )
				this.importantInfo.push( {text: ''} );
		}
		this.setState({formState: STATE_READY, importantInfo: this.importantInfo});
	}
	
	handleEdit = (e) => {
		const {name,value} = e.target;
		this.importantInfo[name].text = value;
		this.setState({dirty: true});
	}
	addElement = (index) => {
		for( let i=this.importantInfo.length-1; i > index; i--)
			this.importantInfo[i] = this.importantInfo[i-1];
		this.importantInfo[index] = {text: ''};
		this.setState({dirty: true, importantInfo: this.importantInfo});
	}
	deleteElement = (index) => {
		for( let i=index; i < this.importantInfo.length-1; i++)
			this.importantInfo[i] = this.importantInfo[i+1];
		this.importantInfo[ this.importantInfo.length-1 ] = {text: ''};
		this.setState({dirty: true, importantInfo: this.importantInfo});
	}
	save = async () => {
		this.setState({formState: STATE_WAIT});
		var request = this.importantInfo;
		var response = await performApiRequest(IMPORTANT_INFO_SAVE_API_URL, request);
		this.setState({formState: STATE_READY});
	}
	
	render() {
		var leftSide = (<AdminLeftSide path={ADMIN_IMPORTANT_INFO_PATH} />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	var elementsHtml = props.holder.state.importantInfo.map( (element, index) => {
		return <Element key={index} index={index} holder={props.holder} />
	} );
	var saveOrSpinnerHtml = props.holder.state.formState == STATE_READY ?
		<div>
			<button type='button' style={{marginTop: '20px'}} onClick={props.holder.save}>Записати</button>
		</div> :
		<Spinner />;
		
	return (
		<div style={{marginBottom: '30px'}}>
			<div className={stylesAdmin.divContentHeader}>
				Важлива інформація
			</div>
			{elementsHtml}
			{saveOrSpinnerHtml}
		</div>
	)
}

function Element(props) {
	var holder = props.holder;
	var index = props.index;
	var element = holder.importantInfo[index];
	return (
		<div style={{marginTop: '20px'}}>
			Елемент {index + 1}
			<div style={{display: 'flex'}}>
				<textarea name={index} rows='3' cols='80' onChange={holder.handleEdit} value={element.text} />
				<div style={{marginLeft: '30px'}}>
					<button type='button' onClick={ () => {holder.addElement(index)} }>Додати</button>
				</div>
				<div style={{marginLeft: '30px'}}>
					<button type='button' onClick={ () => {holder.deleteElement(index)} }>Прибрати</button>
				</div>
			</div>
		</div>
	);
}

const localStyles = {
	divPageTitle: {
		textAlign: 'center',
		fontSize: '20pt',
		fontFamily: 'AkvitaniaModern',
		fontWeight: 'bold'
	},
	divMessage: {
		marginTop: '20px',
		textAlign: 'center',
		fontSize: '16pt',
		color: 'red',
		fontWeight: 'bold'
	}
}

export default AdminImportantInfoPage;
