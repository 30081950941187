const dev = {
  appPrefix: 'dev.',
  PATH: '/new',
  apiGateway: {
    URL: 'http://www.pechersklibss.ua/new/api/',
  },
  logging: {
    apiRequests: true,
  },
};

const prod = {
  appPrefix: 'prod.',
  PATH: '/new',
  apiGateway: {
    URL: 'https://pechersklibss.kiev.ua/new/api/'
  },
  logging: {
    apiRequests: false,
  },
};

let config;

switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = dev;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    config = dev;
    break;
}

export default config;
