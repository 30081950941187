import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";

import {
	MAIN_PATH,
	SERVICES_PATH,
	RULES_PATH,
	ADMINISTRATION_PATH
} from '../../util/routes';

import directorImage from '../../assets/img/administration/chulaevska_photo.jpg';
import zastupnykImage from '../../assets/img/administration/vavdichek_photo.jpg';

class AdministrationPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Адміністрація', url: '', selected: true }
		];
		var menu = [
			{ title: 'Наші послуги', url: SERVICES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Правила користування', url: RULES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Адміністрація', url: '', isGroupHeader: false, isActive: true },
		];
		var body = (<Body holder={this} />);
		return ( 
			<GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={ADMINISTRATION_PATH} menu={menu} body={body} />
		);
	}
}

function Body(props) {

	return(
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Адміністрація ЦБС
			</div>
			<div style={{display: 'flex', marginBottom: '20px'}}>
				<img src={directorImage} style={{width: '60%'}} />
				<div style={{width: '40%', textAlign: 'center'}}>
					Директор ЦБС<br/>
					<span style={{fontWeight: 'bold'}}>Чулаєвська Олена Анатоліївна</span><br/>
					email: saltukovka@ukr.net
				</div>
			</div>
			<hr className={stylesPage.hr} />
			<div style={{display: 'flex', marginTop: '20px'}}>
				<div style={{width: '40%', textAlign: 'center'}}>
					Заступник директора ЦБС<br/>
					<span style={{fontWeight: 'bold'}}>Вавдічик Людмила Володимирівна</span><br/>
					email: saltukovka@ukr.net
				</div>
				<img src={zastupnykImage} style={{width: '60%'}} />
			</div>
		</div>
	);

}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	}
}

export default AdministrationPage;
