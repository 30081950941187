import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import SearchEngine from "./SearchEngine";
import { KKS_SEARCH_FORM } from "./SearchForm";

import {
	MAIN_PATH,
	ECATALOG_PATH,
	SKS_PATH,
	KKS_PATH
} from '../../util/routes';

class KksPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Краєзнавча картотека статей', url: '', selected: true }
		];
		var menu = [
			{ title: 'Електронний каталог', url: ECATALOG_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Систематична картотека статей', url: SKS_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Краєзнавча картотека статей', url: KKS_PATH, isGroupHeader: false, isActive: true },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={KKS_PATH} menu={menu} body={body} />);
	}
}

function Body(props) {
	return(
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Краєзнавча картотека статей
			</div>
			<SearchEngine searchFormId={KKS_SEARCH_FORM} />
		</div>
	);
}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	}
}

export default KksPage;
