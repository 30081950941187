import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import Spinner from "../../components/Spinner";
import BookBlock from "./BookBlock";

import {
	MAIN_PATH,
	ECATALOG_PATH,
	BIBLIOGRAPHIC_PATH,
	BOOKS_NEW_PATH,
	BOOKS_INTERESTING_PATH
} from '../../util/routes';
import { performApiRequest, BOOK_LIST_API_URL } from "../../util/restapi";
import { ANY, ONLY_TRUE, ONLY_FALSE } from '../../util/consts';

const STATE_INIT = 'init';
const STATE_WORKING = 'working';
const STATE_READY = 'ready';

class BookListNewPage extends Component {
	
	constructor(props) {
		super(props);
		this.ok = true;
		this.bookList = [];
		this.state = {
			formState: STATE_INIT,
		};
	}
	componentDidMount = async () => {
		this.setState({formState: STATE_WORKING});
		var request = {
			query: {
				type: '',
				isNew: ONLY_TRUE,
				isInteresting: ANY,
				showOnMainPage: ANY,
				showOnBookList: ONLY_TRUE,
				skip: 0,
				count: 0
			}
		}
		var response = await performApiRequest(BOOK_LIST_API_URL, request);
		this.ok = response.ok;
		if( response.ok ) {
			var currentDate = null;
			var currentBlock = null;
			var first = true;
			for(let b of response.bookList) {
				if( b.bookDate != currentDate ) {
					currentDate = b.bookDate;
					currentBlock = {bookDate: currentDate, books: [], expanded: first}
					this.bookList.push( currentBlock );
					first = false;
				}
				currentBlock.books.push( b );
			}
		}
		this.setState({formState: STATE_READY, ok: response.ok});
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Нові надходження', url: '', selected: true }
		];
		var menu = [
			{ title: 'Електронний каталог', url: ECATALOG_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліотека рекомендує', url: '', isGroupHeader: true, isActive: false },
			{ title: 'Цікаве на полицях', url: BOOKS_INTERESTING_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Нові надходження', url: BOOKS_NEW_PATH, isGroupHeader: false, isActive: true },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={BOOKS_INTERESTING_PATH} menu={menu} body={body} />);
	}
	
}
function Body(props) {
	return (
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Нові надходження
			</div>
			<BookList holder={props.holder} />
		</div>
	);
}
function BookList(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_INIT ) {
		return (null);
	}
	if( holder.state.formState == STATE_WORKING ) {
		return ( <Spinner /> );
	}
	if( ! holder.ok ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				Вибачте, сервіс тимчасово не доступний.
			</div>
		);
	}
	if( holder.bookList.length == 0 ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				Вибачте, інформація відсутня.
			</div>
		);
	}
	var bookBlockListHtml = holder.bookList.map( (block, index) => {
		return (<BookBlock key={index} holder={holder} block={block} />);
	});
	return (
		<div>
			{bookBlockListHtml}
		</div>
	);
}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	},
}

	
export default BookListNewPage;
