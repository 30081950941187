import React, { Component } from 'react';
import styles from '../bibliographic.module.css';

import BibliographicPage from "../BibliographicPage";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH
} from '../../../util/routes';

import image01 from './image01.jpg';
import image02 from './image02.jpg';
import image03 from './image03.jpg';

class B203 extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, selected: false },
			{title: 'Павло Скоропадський та його державотворча діяльність', url: '', selected: true }
		];
		var body = (<Body />);
		return (
			<BibliographicPage breadCrumbs={breadCrumbs} body={body} />
		);
	}
}

function Body(props) {
return(

<div style={{width: '100%'}}>
	<div className={styles.divBibliographicHeader}>Павло Скоропадський та його державотворча діяльність</div>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image01} style={{width: '600px'}} />
	</div>
	<div className={styles.divHeaderBibliographicComment}>
		До 150-річчя із дня народження ( 1873 – 1945 )<br/>
		українського державного і політичного діяча, талановитого воєначальника.
	</div>
	<div className={styles.divHeaderBibliographicType}>
		Рекомендаційний список літератури
	</div>

	<p className={styles.pCommon}>
	Бібліографічний список літератури “Павло Скоропадський та його державотворча діяльність“  ознайомлює читачів із матеріалами про  життя та діяльність українського державного і політичного діяча, талановитого воєначальника, гетьмана України Павла Скоропадського.
	</p>
	<p className={styles.pCommon}>
	Література  подана в алфавітному порядку за авторами книг і статей.
	</p>
	<p className={styles.pCommon}>
	При підготовці бібліографічного видання використано книжковий фонд  Центральної бібліотеки “Печерська” та Інтернет-ресурси .
	</p>
	<p className={styles.pCommon}>
	Маємо надію, що запропоноване видання стане у пригоді всім, хто вивчає історію України .
	</p>
	
	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Скоропадський Павло Петрович
	</p>	

	<p className={styles.pCommon}>
		<img src={image02} />
	</p>	
	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	ПАВЛО СКОРОПАДСЬКИЙ - ГЕТЬМАН, ЯКИЙ ЗРОБИВ СИЛЬНУ І САМОДОСТАТНЮ УКРАЇНУ
	</p>	
	<p className={styles.pCommon}>
	Павло Скоропадський народився 3 (15) травня 1873 року у Вісбадені (Німеччина), а загинув у віці 72 років 26 квітня 1945 року в баварському містечку Меттен. Князь по праву народження, значну частину свого життя він присвятив боротьбі за Українську державу.
	</p>	
	<p className={styles.pCommon}>
	Павло Петрович Скоропадський - останній гетьман України, талановитий воєначальник і палкий патріот України, якому вдалося в надзвичайно важких умовах революції та громадянської війни провести фундаментальні державні реформи в Україні, змінивши на чолі країни "романтиків" Центральної Ради на чолі з Михайлом Грушевським, зайнявшись державним будівництвом молодої Української держави.
	</p>	
	<p className={styles.pCommon}>
	Колишній царський генерал-лейтенант Скоропадський зумів зробити з України сильну, самодостатню державу, на яку вимушені були зважати сусіди як на Заході, так і на Сході. Нащадок гетьманського роду, шанувальник козацької слави, він зміг відродити національні традиції, створивши моду на все українське.
	</p>	

	<p className={styles.pCommon} style={{fontWeight: 'bold', textAlign: 'center'}}>
	Подвиги Павла Скоропадського в ім'я України.
	</p>	
	<p className={styles.pCommon}>
	З самого початку національно-демократичної революції (1917-1920 рр.) Павло Петрович підтримав український національно-визвольний рух, перевівши його в практичне русло побудови та захисту незалежної Української держави.
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>1. Скоропадський став засновником і командиром Першого Українського військового корпусу, спираючись на багнети якого, Центральна Рада могла проголосити незалежність України в IV Універсалі. </span>
	Корпус Скоропадського складався більш ніж з 20 (!) дивізій чисельністю в 14 тис., а потім 60000 солдатів, прекрасно озброєних не тільки гвинтівками, а й кулеметами, гарматами (2 артилерійських полки), з кіннотою, броневиками, власними військово-саперними підрозділами та т. д., з ​​кадровими офіцерами - прихильниками Української держави на чолі кожного з підрозділів.
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>Як Скоропадському вдалося створити до липня (!) 1917 р. боєздатний національний корпус</span> 
	і дати Центральній Раді фору не тільки в часі (тобто за 4 місяці до Жовтневого перевороту більшовиків у Петербурзі), а й реальний військовий козир щодо захисту незалежності України від Москви в умовах, коли уряд Леніна висилав ЦР один ультиматум за іншим?
	</p>	
	<p className={styles.pCommon}>
	Відповідь проста... Скоропадський сформував його на базі 34-го армійського корпусу царської армії, яким командував на Південно-Західному фронті, заручившись підтримкою комфронта генерала Корнілова. Останній погодився в умовах повсюдного розброду і хитання солдатів і молодшого комскладу на "експеримент", при якому українці будуть захищати на фронті свою землю від окупантів.
	</p>	
	<p className={styles.pCommon}>
	Важко переоцінити значення цього великого військового з'єднання в умовах повсюдного хаосу і анархії 1917-1918 рр., коли в Україні вперше з козацьких часів відродилося не просто власне військо, а боєздатне й ідеологічно мотивоване крупне військове з'єднання, що представляло собою різкий контраст з недисциплінованими військовими частинами російської армії і деякими українськими полками, які напряму присягнули в Києві на вірність Центральній Раді, брали участь у всіх мітингах, вибирали командирів і голосуванням вирішували, з ким воювати і коли.
	</p>	

	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	2. Придушив два перших більшовицьких заколоти в Україні, коли за наказом Леніна і Троцького з Петербурга
	</p>	
	<p className={styles.pCommon}>
	- наприкінці жовтня 1917 р. очолений більшовичкою Є. Бош (майбутній більшовицький прем'єр України) 2-й армійський корпус самовільно знявся з фронту і рушив на Київ, щоб повалити Центральну Раду, воїни Першого Українського військового корпусу зупинили їх під Жмеринкою і ... повністю роззброїли;
	</p>	
	<p className={styles.pCommon}>
	- 3 грудня 1917 р. війська Скоропадського, зайнявши залізничні станції Шепетівка і Старокостянтинів, роззброїли і розігнали більшовицький Військово-революційний комітет в 11-й армії і захопили в полон червоного полковника командарма Єгорова (майбутнього радянського маршала).
	</p>	
	<p className={styles.pCommon}>
	Таким чином, бійці під керівництвом П. Скоропадського не тільки врятували українську владу, а й зуміли налякати агресивних більшовиків, змушених перенести "столицю України" до Харкова, оскільки західніше і ЦР, і Скоропадський не залишили більшовикам практично ніяких шансів. Однак соціалісти з Центральної Ради побачили в небувалому зростанні популярності українського генерала прояв бонапартизму і напередодні 1918 р. змусили його подати у відставку.
	</p>	

	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>3. Очолив Україну в дуже складний період її історії (29 квітня - 14 січень 1918 р.).</span> 
	В умовах австро-німецької військової присутності в Україні уряд УНР і Центральна Рада демонстрували повну нездатність до ефективного управління. Нескінченні дискусії та внутрішньопартійні суперечки Центральної Ради приводили до безупинної втрати авторитету української влади, коли Україна та її столиця жили "абсолютно різними життями".
	</p>	
	<p className={styles.pCommon}>
	У таких умовах виникла небезпека ліквідації німцями України як суб'єкта і перетворення її в банальну колонію. Скоропадський тоді очолив опозиційну до Центральної Ради організацію "Українська Громада" і 29 квітня 1918 року - державний переворот, який був підтриманий німцями і дозволив зберегти українську державність.
	</p>	
	<p className={styles.pCommon}>
	В цілому це був мудрий крок Скоропадського, який в умовах, коли Німеччина і Австро-Угорщина програвали Першу світову війну, дав можливість отримати перепочинок від військового вторгнення більшовицької Росії, щоб створити незалежну українську державу і боєздатну армію.
	</p>	

	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	4. За часів правління гетьмана Скоропадського був здійснений цілий ряд кардинальних реформ у багатьох сферах, покликаних закріпити незалежність молодої Української держави:
	</p>	
	<p className={styles.pCommon}>
	- було прийнято більше 400 державних актів, що закріплювали незалежність України;
	</p>	
	<p className={styles.pCommon}>
	- відновлено право приватної власності. Дозволялося вільне підприємництво. Розвивалася торгівля. Голодні місяці кінця 1917 року, і особливо часів більшовиків, пішли в минуле. Магазини були заповнені товарами, Україна перетворилася на острів добробуту в морі злиденної постреволюційної Росії;
	</p>	
	<p className={styles.pCommon}>
	- встановлено державне регулювання промисловості, транспорту, торгівлі з метою боротьби з анархією на місцях. Нарешті в країні з'явився господар, і стало ясно, хто і за що відповідає;
	</p>	
	<p className={styles.pCommon}>
	- удосконалено грошову системи, сформовано державний бюджет, почали виплачувати пенсії;
	</p>	
	<p className={styles.pCommon}>
	- прийнятий закон про загальний військовий обов'язок, формувалися українські збройні сили (повинні складатися з 8 армійських корпусів) , розпочато розвиток українського морського флоту, національної авіації;
	</p>	
	<p className={styles.pCommon}>
	- створена українська поліція - гетьманська варта;
	</p>	
	<p className={styles.pCommon}>
	- налагоджена робота пошти і залізниці;
	</p>	
	<p className={styles.pCommon}>
	- прийняті закони про українську державну символіку і українське громадянство;
	</p>	
	<p className={styles.pCommon}>
	- створювалися українські початкові школи, відкрито 50 нових українських середніх шкіл, 150 українських гімназій, відкривалися курси української мови для педагогів, видавалися величезним тиражем українські підручники;
	</p>	
	<p className={styles.pCommon}>
	- відкрито два українські університети - в Києві та в Кам'янець-Подільському. При цьому російські університети продовжували свою роботу;
	</p>	
	<p className={styles.pCommon}>
	- заснована Українська академія наук і Національний архів;
	</p>	
	<p className={styles.pCommon}>
	- збільшилася кількість українських театральних закладів, музичних і культурних установ;
	</p>	
	<p className={styles.pCommon}>
	- покладено початок Української Автокефальної Православної Церкви;
	</p>	
	<p className={styles.pCommon}>
	- Українську державу офіційно визнали майже тридцять країн світу;
	</p>	
	<p className={styles.pCommon}>
	- підписаний мирний договір з Радянською Росією, що забезпечило мир на сході країни;
	</p>	
	<p className={styles.pCommon}>
	- визначені і, що головне, визнані державні кордони України.
	</p>	

	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>5. Дізнавшись, що Центральна Рада на переговорах у Бресті відмовилася від Криму, Скоропадський сказав: </span>
	"Україна не може існувати без Криму - це буде якийсь тулуб без ніг".
	</p>	
	<p className={styles.pCommon}>
	І, ставши гетьманом України, він домігся повернення півострова до складу України на правах автономії.
	</p>	

	<p className={styles.pCommon}>
		<img src={image03} />
	</p>	
	
	<p className={styles.pCommon} style={{fontWeight: 'bold', textAlign: 'center'}}>
	Прорахунки Павла Скоропадського, що мали фатальні наслідки для нього і Української держави.
	</p>	
	<p className={styles.pCommon}>
	Однак у політиці гетьмана важко не помітити і численні помилки, наприклад:
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>1. Повернення поміщиків у свої маєтки і репресії по відношенню до селян.</span> 
	У результаті на селі почався потужний повстанський рух. Цим відразу ж скористалися більшовики для своєї антиурядової агітації.
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>2. Зосередження в своїх руках майже диктаторських повноважень.</span> 
	Заборона діяльності партій та фактична ліквідація українського парламентаризму.
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>3. Аграрне питання на практиці залишився невирішеним.</span> 
	Селяни, які складали переважну більшість населення України, так нічого і не отримали.
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>4. Збільшення тривалості робочого дня до 12 годин в умовах, коли більшовики в Росії обіцяли лише 8. </span>
	Відповідно, росло соціальне невдоволення. Заборона страйків викликала опір робітників.
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>5. Зайняття чиновницьких посад переважно російськими фахівцями,</span> 
	які ставилися до української справи досить нещиро.
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>6. Орієнтація тільки на заможні верстви населення, </span>
	нехтування інтересами бідноти і середнього класу. В умовах постреволюційної дійсності така політика призводила до невдоволення.
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>7. Повна залежність від німецько-австрійської військової адміністрації. </span>
	Передача їй багатьох урядових функцій, наприклад, дозвіл на здійснення військово-польових судів над українськими громадянами. В результаті, втративши німецьку підтримку, гетьманський уряд не зміг втримати владу і дуже швидко пав.
	</p>	
	<p className={styles.pCommon}>
	<span style={{fontWeight: 'bold'}}>8. Ухвалення П. Скоропадським Акту про федерацію з майбутньою небільшовицькою Росією в останні дні свого правління. </span>
	Тим самим перекреслені головні досягнення на шляху державного будівництва.
	</p>	

	<p className={styles.pCommon} style={{fontWeight: 'bold', textAlign: 'center'}}>
	Сторінки біографії гетьмана Української держави.
	</p>	
	<p className={styles.pCommon}>
	З раннього дитинства Павло Скоропадський довгий час жив в містечку Тростянець Харківської губернії, де його оточувало багато предметів старовинного козацького побуту. Саме з юних років, за згадками гетьмана, і почала в ньому формуватися любов до української історії та культури.
	</p>	
	<p className={styles.pCommon}>
	Відповідно до сформованої традиції, в сім'ї Скоропадських молодий спадкоємець, повинен був отримати військову освіту. У 1893 р. молодий дворянин закінчує Пажеський корпус у Санкт-Петербурзі і отримує призначення у лейб-гвардії Кавалергардський полк, де раніше служив його батько Петро Іванович. Там молодий офіцер згодом зробив блискучу кар'єру, дослужившись до посади заступника командира полку.
	</p>	
	<p className={styles.pCommon}>
	У 1904-1905 рр. Скоропадський бере участь у Російсько-японській війні. За виявлену хоробрість український дворянин був нагороджений орденом святого Володимира і Золотою Георгіївською зброєю. Згодом Павло Петрович був призначений флігель-ад'ютантом самого імператора Миколи II, а у 1906 р. він отримав звання полковника. У 1911 році Скоропадський вже командує 20-м драгунським Фінляндським полком в чині генерал-майора.
	</p>	
	<p className={styles.pCommon}>
	З самого початку Першої світової війни генерал Скоропадський був на фронті, взявши участь у наступальній операції російських військ у Східній Пруссії. Він тоді командував 1-ю Гвардійською кавалерійською бригадою і був удостоєний ордена Святого Георгія 4-го ступеня. У 1917 р. Скоропадський зустрів на посаді командира XXXIV армійського корпусу, який за розпорядженням Тимчасового уряду був перейменований в I Український.
	</p>	
	<p className={styles.pCommon}>
	Взяв активну участь в Українській революції. З кінця квітня до середини грудня 1918 р. очолював Українську державу.
	</p>	
	<p className={styles.pCommon}>
	Після виходу австро-німецьких військ з України та захоплення повстанцями Директорії Києва Скоропадський був змушений зректися влади і спішно емігрувати до Німеччини.
	</p>	
	<p className={styles.pCommon}>
	За кордоном колишній гетьман активно включився в політичне життя української діаспори. Скоропадський всіляко підтримував ідею встановлення монархістської влади в Україні, навіть створював політичну партію. Він організував у Берліні Український науковий інститут, Українське товариство, допомагав матеріально українським студентам. Під час Другої світової війни П. Скоропадський співпрацював з нацистами і 16 квітня 1945 р. отримав смертельне поранення під час американського авіанальоту на баварське містечко Платтлінг. Помер колишній український гетьман через десять днів, 26 квітня 1945 р., в містечку Меттен. Похований у місті Оберстдорфі в сімейному склепі Скоропадських.
	</p>	

	<p className={styles.pCommon} style={{fontWeight: 'bold', textAlign: 'center'}}>
	Цікаве про Павла Скоропадського.
	</p>	
	<p className={styles.pCommon}>
	- У молоді роки юний офіцер багато подорожував по країнах Європи та Близького Сходу. Слухав лекції в Сорбонні;
	</p>	
	<p className={styles.pCommon}>
	- Павло Скоропадський був одружений з російською дворянкою Олександрою Дурново, яка походила зі знаменитого українського роду Кочубеїв;
	</p>	
	<p className={styles.pCommon}>
	- під час Російсько-японської війни кавалерійські підрозділи під керівництвом П. Скоропадського здійснювали блискавичні розвідувальні і диверсійні рейди по тилах супротивника. Сам командир неодноразово демонстрував приклади особистої мужності і завзятості;
	</p>	
	<p className={styles.pCommon}>
	- під час наступу більшовиків на Київ у січні 1918 року колишній командир Першого Українського корпусу був змушений рятуватися, переодягнувшись простим селянином. А протягом місяця під час більшовицької окупації Києва він переховувався в квартирі, не виходячи на вулицю;
	</p>	
	<p className={styles.pCommon}>
	- Скоропадський відмовився зайняти під свою резиденцію колишній царський палац, де раніше розміщувався ревком більшовиків;
	</p>	
	<p className={styles.pCommon}>
	- Скоропадський дружив з іншим колишнім російським офіцером, а потім керівником незалежної Фінляндії маршалом Г. Маннергеймом;
	</p>	
	<p className={styles.pCommon}>
	- австрійська окупаційна влада намагалася скомпрометувати гетьмана П. Скоропадського, замінивши його на свого висуванця Василя Вишиваного, який походив з імператорської сім'ї Габсбургів. Але керівнику Української держави, користуючись підтримкою німців, вдалося перемогти інтриганів;
	</p>	
	<p className={styles.pCommon}>
	- деякі дослідники вважають, що саме Скоропадський домігся звільнення з німецького концтабору Бандери, Я. Стецька, Мельника.
	</p>	
	<p className={styles.pCommon}>
	Історична пам'ять про Павла Скоропадського поки відображена тільки в назві однієї з вулиць Києва і в меморіальній дошці на вулиці Інститутській в українській столиці.
	</p>	
	<p className={styles.pCommon}>
	Джерело: <a href="http://heroes.profi-forex.org/ua/skoropadskij-pavlo-petrovich">http://heroes.profi-forex.org/ua/skoropadskij-pavlo-petrovich</a>
	</p>	

	<p className={styles.pCommon} style={{fontWeight: 'bold', textAlign: 'center'}}>
	Література про життя та діяльність Павла Скоропадського
	</p>	
	<ul>
		<li className={styles.liCommon}>
		Білодід, О., Панченко, В. Павло Скоропавдський і Україна.- К.: Бібліотека українця, 1997.- 87 с.
		</li>
		<li className={styles.liCommon}>
		Гетьманат П. Скоропадського. <br/>
		// Історія України ХХ – початку ХХІ століття : навч.посіб. / П.П.Панченко, Н. П.Барановська, С.С.Падалка та ін.; за заг. ред.. В.А.Смолія.- К.: Знання, 2004.- С.40-43
		</li>
		<li className={styles.liCommon}>
		Кудряченко, А.І. Українська держава гетьмана П. Скоропадського. <br/>
		// Кудряченко, А.І. Політична історія України ХХ століття : підруч. для студ. вищ. навч. закл.- К.: МАУП, 2006.- С.126-136
		</li>
		<li className={styles.liCommon}>
		Реєнт, О.П. Павло Скоропадський. / за ред. В.А. Смолія.- К.: Альтернативи, 2003.- 304 с.
		</li>
		<li className={styles.liCommon}>
		Скоропадський, П. П. Спомини : кінець 1917- грудень 1918 року / Павло Скоропадський; [упоряд.Т. Осташко, Ю. Терещенко; пер.з рос. Н.Світличної, Т. Осташко, Ю.Терещенка ].- [Київ] : Темпора, 2019.- 756 с., 30 с. фотоіл., портр.
		</li>
		<li className={styles.liCommon}>
		Скоропадський, П.П. Спомини.- К : Україна, 1992.- 112 с.
		</li>
		<li className={styles.liCommon}>
		Яневський, Д. Українська держава : альтернатива соціалізмові та національно-соціалістична боротьба за її повалення. ( 29 квітня – 15 листопада 1918 р. ) / Яневський, Д. Політичні системи України 1917-1920 років : спроби створення і причини поразки.- К. : Дух і літера, 2003.- С.186-210
		</li>
		<li className={styles.liCommon}>
		Бойко, В. Гетьманат та « чинник Берліна» : Україна – Німеччина : 100 років міждержавних взаємин / Володимир Бойко <br/>
		// День.-2018.- 3-4 серпня.- С.21
		</li>
		<li className={styles.liCommon}>
		Волинська, Л. Що встиг Павло Скоропадський / Л. Волинська <br/>
		// День.- 2018.- 7 лютого.- С.4.
		</li>
		<li className={styles.liCommon}>
		«Заборонена» історія, або чому в радянській та пострадянській свідомості не було місця гетьману <br/>
		// День.- 2018.- 19-20 січня.- С 5.
		</li>
		<li className={styles.liCommon}>
		Криса,О., Ладика, І. Скоропадський зрікся влади, але не зрікся гетьманства / Ольга криса, Ірина Ладика <br/>
		// День.- 2018.- 3-4 серпня ( № 137-138 ).- С.18 – 19.
		</li>
		<li className={styles.liCommon}>
		Кульчицький, С. Українська держава гетьмана Скоропадського / Станіслав Кульчицький <br/>
		// Український тиждень.- 2018.- № 13 ( 30.03 -5.04 ).- С.40.
		</li>
		<li className={styles.liCommon}>
		Михайлова, О. З’єднати зв’язок часів, або де має стояти пам’ятник Скоропадському / О. Михайлова <br/>
		// День.- 2018.- 19-20 січня .- С.6-7.
		</li>
		<li className={styles.liCommon}>
		Осташко, Т. Альтернативи 1918 року : до 100-річчя гетьманату Павла Скоропадського / Тетяна Осташко <br/>
		// Український тиждень.-2018.- № 17  (27.04-3.05 ).- С.40-43.
		</li>
		<li className={styles.liCommon}>
		Панченко, В. Скоропадський і Винниченко, або чому гетьман не втримав булаву / Володимир Панченко <br/>
		// День.- 2018.- 3-4 серпня ( № 137-138 ).- С.20.
		</li>
		<li className={styles.liCommon}>
		Папакін, Г. Ідея, час якої назрів : Павло Скоропадський та Українська академія наук / Г. Папакін <br/>
		// День.- 2018.- 16-17 листопада; 23-24 листопада; 30 листопада – 1 грудня.- С.21.
		</li>
		<li className={styles.liCommon}>
		Папакін, Г. Роки випробувань : Павло Скоропадський на еміграції : альтернатива 1919-1921 років / Георгій Папакін <br/>
		// День.- 2021.- 9-10 липня ( № 101-102). - С.21; 16-17 липня ( № 103-104 ).- С. 21; 23-24 липня ( № 105-106 ).- С.21
		</li>
		<li className={styles.liCommon}>
		Сулима, Л. Останній гетьман : до 100-річчя постання Держави Скоропадського / Людмила Сулима  <br/>
		//Голос України.-2018.-28 квітня ( № 80 ) -С.15.
		</li>
		<li className={styles.liCommon}>
		Сюндюков, І. Зрікся влади, але не гетьманату / І. Сюндюков <br/>
		// День.- 2017.- 15-16 грудня.- С.7.
		</li>
		<li className={styles.liCommon}>
		Сюндюков, І. Його сповідь перед історією / І. Сюндюков <br/>
		// День.- 2018.- 12-13 січня; 19-20 січня.- С.20.
		</li>
		<li className={styles.liCommon}>
		Сюндюков,І. Розповідь про (не ) втрачені можливості / І. Сюндюков <br/>
		// День.-2016.- 7 грудня.- С.5.
		</li>
		<li className={styles.liCommon}>
		Терещенко, Ю.Консервативна революція гетьмана Павла Скоропадського / Ю. Терещенко <br/>
		// Український тиждень.-2017.- № 8.- С.36-40.
		</li>
		<li className={styles.liCommon}>
		Тесленко, Л. Гетьман  повернеться на коні : [український гетьман Павло Скоропадський ] / Ліна Тесленко <br/>
		// Україна молода.-  2021.- 25 травня ( № 50 ).- С.7.
		</li>
		<li className={styles.liCommon}>
		Тихомиров,В. «Не для своєї користі беру я на себе тяготи тимчасової влади…» : до вікового ювілею проголошення Павла Скоропадського гетьманом України та 145-річчя від дня його народження / Віктор Тихомиров <br/>
		// Голос України.- 2018.- 19 травня ( № 91 ).-С.12.
		</li>
		<li className={styles.liCommon}>
		Шуткевич, О. Перший на Поділлі, другий в Україні : до 100-ліття заснування Кам’янець-Подільського державного українського університету / Олеся Шуткевич <br/>
		// День.- 2018.- 17-18 серпня ( № 147-148 ).-С.20.
		</li>
	</ul>

</div>
		
);
}

export default B203;
