import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Gallery from "../../../components/Gallery";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIB8_INFO_PATH, LIB8_GALLERY_PATH, } from '../../../util/routes';
import { LIB8 } from '../../../util/consts';

class Lib8GalleryPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIB8.title, url: LIB8_INFO_PATH, selected: false },
			{title: 'Фотогалерея', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIB8_GALLERY_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIB8_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var imageList = [
		{ url: '/lib8/26.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/27.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/28.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/29.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/p2129389.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/p2129391.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/pc030002.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/pc060004.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/pc060005.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/pc060009.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/pc060010.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/pc060015.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/pc060018.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/img_3302.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib8/img_3303.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
	];
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIB8.title}<br/>
				Фотогалерея
			</div>
			<Gallery imageUrlPrefix={'/img/'} images={imageList} />
		</div>
	);
}

export default Lib8GalleryPage;
