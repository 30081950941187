import React, { Component } from 'react';

import { POSTER_LIST_PATH, } from '../../util/routes';
import { getPosterUrl, getEventImageUrl } from '../../util/tools';

import circleGreen from '../../assets/img/mainpage/icon_circle_green.png';
import circlePurple from '../../assets/img/mainpage/icon_circle_purple.png';
import iconPrevSlide from '../../assets/img/mainpage/icon_slide_prev.png';
import iconNextSlide from '../../assets/img/mainpage/icon_slide_next.png';

class Slider extends Component {

	constructor(props) {
		super(props);

		this.slideCount = 0;
		this.slideWidth = 370;
		this.delay = 10000;
		this.activeTimer = null;
		this.ignoreSetAciveSlide = false;
		this.slideDirection = 1;
		this.slideIndex = 0;
		this.slideNewIndex = 0;
		this.slideExplicitNewIndex = -1;
		this.pos = 0;
		this.posNew = 0;
		this.posShift = 10;
		
		this.slides = [];
		this.state = {
			leftPosition: '',
			slideIndex: 0
		}
	}

	startSliding = () => {
		let posterList = this.props.holder.pageData.posterList;
		let slideIndex = 0;
		for( let p of posterList ) {
			let useSlide = false;
			let srcImage = null;
			if( p.showOnMainPage ) {
				if( p.logoMode == 'id' ) {
					if( p.logoId > 0) {
						srcImage = getEventImageUrl(p.logoId);
						useSlide = true;
					}
				} else {
					srcImage = p.logoUrl;
					useSlide = true;
				}
			}
			if( useSlide ) {
				this.slides.push( {
					imageIndex: slideIndex++,
					urlImage: srcImage,
					urlEvent: getPosterUrl( p.posterId)
				});
			}
		}
		this.slideCount = this.slides.length;
		
		if( this.slideCount > 1 )
			this.activeTimer = setTimeout(this.setActiveSlide, this.delay);
	}
	prevSlide = () => {
		if( this.ignoreSetAciveSlide )
			return;
		if( this.slideIndex == 0 )
			return;
		this.slideExplicitNewIndex = this.slideIndex - 1;
		this.setActiveSlide();
	}
	nextSlide = () => {
		if( this.ignoreSetAciveSlide )
			return;
		if( this.slideIndex == this.slideCount-1 )
			return;
		this.slideExplicitNewIndex = this.slideIndex + 1;
		this.setActiveSlide();
	}
	setExplicitSlide = (index) => {
		if( this.ignoreSetAciveSlide )
			return;
		if( this.slideIndex == index )
			return;
		this.slideExplicitNewIndex = index;
		this.setActiveSlide();
	}
	setActiveSlide = () => {
		if( this.ignoreSetAciveSlide )
			return;
		this.ignoreSetAciveSlide = true;
		if( this.activeTimer != null )
			clearTimeout(this.activeTimer);
		
		if( this.slideExplicitNewIndex >= 0 ) {
			this.slideNewIndex = this.slideExplicitNewIndex;
			this.slideExplicitNewIndex = -1;
		}
		this.posNew = - this.slideNewIndex * this.slideWidth;
		if( this.posNew < this.pos )
			this.posShift = -10;
		else
			this.posShift = 10;
		
		this.shiftSlide();
	}
	shiftSlide = () => {
		if( this.pos == this.posNew ) {
			this.slideIndex = this.slideNewIndex;
			if( this.slideExplicitNewIndex >= 0 ) {
				this.slideNewIndex = this.slideExplicitNewIndex;
				this.slideExplicitNewIndex = -1;
			} else {
				if( this.slideIndex == 0 )
					this.slideDirection = 1;
				else if( this.slideIndex == this.slideCount-1 )
					this.slideDirection = -1;
				this.slideNewIndex = this.slideIndex + this.slideDirection;
			}
			this.activeTimer = setTimeout(this.setActiveSlide, this.delay);
			this.ignoreSetAciveSlide = false;
			this.setState({slideIndex: this.slideIndex});
			return;
		}
		this.pos += this.posShift;
		this.setState({leftPosition: this.pos + 'px'});
		setTimeout(this.shiftSlide, 10);
	}
	

	render() {
		if( this.slides.length == 0 ) {
			return <div style={{width: '550px', height: '550px'}}>&nbsp;</div>;
		}
		
		var listSlideHtml = this.slides.map( (slide, index) => {
			return (
				<div key={index} style={{width: '370px'}}>
					<a href={POSTER_LIST_PATH}> {/*slide.urlEvent */}
						<img src={slide.urlImage} width='370' height='520'/>
					</a>
				</div>
			);
		} );

		var divPrevSlideHtml = null;
		var divNextSlideHtml = null;
		var divCirclesHtml = null;
		if( this.slideCount > 1 ) {
			if( this.state.slideIndex > 0 ) {
				divPrevSlideHtml = 
					<div style={{position: 'absolute', top: '250px', left: '40px'}}>
						<img src={iconPrevSlide} style={{cursor: 'pointer'}} onClick={this.prevSlide} />
					</div>
				
			}
			if( this.state.slideIndex < this.slideCount-1 ) {
				divNextSlideHtml = 
					<div style={{position: 'absolute', top: '250px', left: '490px'}}>
						<img src={iconNextSlide} style={{cursor: 'pointer'}} onClick={this.nextSlide} />
					</div>
				
			}
			var listCircleHtml = this.slides.map( (slide, index) => {
				var srcImage = ( index == this.state.slideIndex ) ? circleGreen : circlePurple;
				var style = {cursor: 'pointer'};
				style.marginLeft = ( index == 0 ) ? '0' : '20px';
				return (
					<img src={srcImage} style={style} onClick={ () => {this.setExplicitSlide(index)} } /> 
				);
			} );
		}
		
		return (
			<div style={{position: 'relative', width: '550px', paddingLeft: '90px'}}>
				<div style={{position: 'relative', width: '370px', overflow: 'hidden', margin: '7px'}}>
					<div style={{display: 'flex', position: 'relative', width: '370px', left: this.state.leftPosition}}>
						{listSlideHtml}
					</div>
					<div style={{position: 'absolute', width: '370px', textAlign: 'center', top: '470px'}}>
						{listCircleHtml}
					</div>
				</div>
				{divPrevSlideHtml}
				{divNextSlideHtml}
			</div>
		);
	}
}

export default Slider;
