import React, { Component } from 'react';
import styles from './mainPage.module.css';

import SpanInnerHtml from '../../components/SpanInnerHtml';

import logo from '../../assets/img/pechersk_logo.png';

import b208Logo from '../bibliographic/b208/logo.jpg';
import b209Logo from '../bibliographic/b209/logo.jpg';

import {
	B209_PATH,
	B208_PATH,
} from '../../util/routes';


class Bibliographic extends Component {
	
	constructor(props) {
		super(props);
		this.bibliographic = [
			{
				url: B208_PATH,
				urlImage: b208Logo,
				imgWidth: '220',
				marginLeft: '1px',
				dateText: '01.07.2023',
				h1Text: 'ЧИТАЙ УКРАЇНСЬКОЮ ТА РЕЛАКСУЙ',
				h2Text: ''
			},
			{
				url: B209_PATH,
				urlImage: b209Logo,
				imgWidth: '220',
				marginLeft: '1px',
				dateText: '',
				h1Text: 'Безбар’єрність – це про нас і для нас',
				h2Text: ''
			},
		];
	}
	
	render() {
		return (
			<div>
				<div style={{display: 'flex', marginTop: '10px', marginLeft: '15px', marginBottom: '15px'}}>
					<div style={{width: '200px'}}>
						<hr style={{border: '2px solid #430045'}} />
					</div>
					<div className={styles.divCentralBlockHeader} style={{width: '350px'}}>
						Мандрівка в світ бібліографії
					</div>
				</div>
				<div className={styles.divCentralDoubleBlock}>
					<div style={{width: '50%'}}>
						<BibRecord bibliographic={this.bibliographic[0]} />
					</div>
					<div style={{width: '50%'}}>
						<BibRecord bibliographic={this.bibliographic[1]} />
					</div>
				</div>
			</div>
		);
	}
}

function BibRecord(props) {
	var style = {
		spanH1: {
			fontSize: '9pt',
			fontFamily: 'Arial, sans-serif',
			fontWeight: 'bold'
		},
		spanH2: {
			fontSize: '9pt',
			fontFamily: 'Arial, sans-serif',
			fontWeight: 'normal'
		},
		divDate: {
			position: 'absolute',
			top: '5px',
			left: '5px', 
			width: '100px',
			height: '20px',
			backgroundColor: '#588b0e',
			color: 'white',
			paddingTop: '5px',
			textAlign: 'center',
			fontSize: '10pt',
			fontFamily: 'Arial, sans-serif',
			fontWeight: 'bold'
		}
	}

	var b = props.bibliographic;
	//<div style={style.divDate}>{b.dateText}</div>
	return(
		<div className={styles.divCentralBlock}>
			<a href={b.url} style={{color: 'black', textDecoration: 'none'}}>
				<div style={{width: '225px', boxShadow: '3px 3px 5px 3px LightGrey' }}>
					<img src={b.urlImage} width={b.imgWidth} height="138" style={{marginLeft: b.marginLeft}}/>
				</div>
				<SpanInnerHtml style={style.spanH1} text={b.h1Text} /><br/>
				<SpanInnerHtml style={style.spanH2} text={b.h2Text} />
			</a>
		</div>
	);
}

export default Bibliographic;
