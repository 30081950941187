import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import { performApiRequest, QUESTION_LIST_API_URL, QUESTION_SAVE_API_URL } from "../../util/restapi"

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import Spinner from "../../components/Spinner";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH,
	ONLINE_PATH,
	SKS_PATH,
	KKS_PATH,
	RULES_PATH
} from '../../util/routes';

const STATE_INIT = 'init';
const STATE_FORM = 'form';
const STATE_WAITING = 'waiting';
const STATE_RESPONSE = 'response';

class OnlineInfoPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formState: STATE_INIT,
			dirty: false
		}
		this.question = {
			name: '',
			email: '',
			text: ''
		};
		this.listQuestion = [];
		this.ok = true;
		this.message = '';
	}
	componentDidMount = async () => {
		var request = {
			filter: { useNew: 0, useResp: 1, useBan: 0 }
		}
		var response = await performApiRequest(QUESTION_LIST_API_URL, request);
		console.log( response );
		this.ok = response.ok;
		if( response.ok ) {
			this.listQuestion = response.listQuestion
		}
		this.setState({formState: STATE_FORM});
	}

	handleFormChange = (e) => {
		const {name, value} = e.target;
		this.question[name] = value;
		this.setState({dirty: true});
	}
	sendQuestion = async () => {
		if( ! this.question.name ) {
			this.ok = false;
			this.message = 'Не вказано і`мя читача';
			this.setState({formState: STATE_RESPONSE});
			return;
		}
		if( ! this.question.email ) {
			this.ok = false;
			this.message = 'Не вказано email';
			this.setState({formState: STATE_RESPONSE});
			return;
		}
		if( ! this.question.text ) {
			this.ok = false;
			this.message = 'Не вказано запитання';
			this.setState({formState: STATE_RESPONSE});
			return;
		}
		this.setState({formState: STATE_WAITING});
		var response = await performApiRequest(QUESTION_SAVE_API_URL, this.question);
		this.ok = response.ok;
		this.setState({formState: STATE_RESPONSE});
	}
	setStateForm = (clearForm) => {
		if( clearForm ) {
			this.question = {
				name: '',
				email: '',
				text: ''
			};
		}
		this.ok = true;
		this.message = '';
		this.setState({formState: STATE_FORM});
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Online довідка', url: '', selected: true }
		];
		var menu = [
			{ title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, isGroupHeader: false, isActive: false },
			{ title: 'On-line довідка', url: ONLINE_PATH, isGroupHeader: false, isActive: true },
			{ title: 'Систематична картотека статей', url: SKS_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Краєзнавча картотека статей', url: KKS_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={ONLINE_PATH} menu={menu} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				ONLINE довідка
			</div>
			<Content holder={props.holder} />
		</div>
	);
}

function Content(props) {
	var holder = props.holder;
	var state = holder.state.formState;
	
	if( state == STATE_INIT ) {
		return (<Spinner /> );
	}
	if( state == STATE_FORM ) {
		if( ! holder.ok ) {
			return (
				<div style={{width: '100%', textAlign: 'center', marginTop: '30px'}}>
					Вибачте, сервіс тимчасово недоступний
				</div>
			);
		}
		return (
			<div>
				<div style={localStyles.divInfoText}>
					Шановні користувачі! 
					За допомогою наших бібліографів ви зможете безкоштовно отримати відповіді 
					на запити з усіх галузей знань, якщо вони задовольняють <a href={RULES_PATH}>ПРАВИЛАМ</a> нашого сервісу.
				</div>
				<div style={localStyles.divInfoText}>
					Заповніть наведену нижче форму (всі поля є обов'язковими). 
					Відповідь бібліографа буде відправлена на зазначену вами адресу електронної пошти, 
					тому ретельно перевірте значення цього поля перед тим, 
					як надіслати запит (наведена адреса є конфіденційною і службою довідки не розголошується).
				</div>
				<div style={localStyles.divInfoText}>
					Якщо все ж таки адреса електронної скриньки була вказана невірно, 
					або з якихось технічних причин відповідь не надійшла, 
					зайдіть знову на сторінку нашої служби та перегляньте останні опубліковані відповіді. 				
				</div>
				<div style={localStyles.divFormInput}>
					<div style={localStyles.divFormLabel}>Ваше ім'я</div>
					<div>
						<input type='text' name='name' size='70' value={holder.question.name} onChange={holder.handleFormChange} />
					</div>
				</div>
				<div style={localStyles.divFormInput}>
					<div style={localStyles.divFormLabel}>Email</div>
					<div>
						<input type='text' name='email' size='70' value={holder.question.email} onChange={holder.handleFormChange} />
					</div>
				</div>
				<div style={localStyles.divFormInput}>
					<div style={localStyles.divFormLabel}>Запитання</div>
					<div>
						<textarea name='text' rows='4' cols='70' value={holder.question.text} onChange={holder.handleFormChange} />
					</div>
				</div>
				<div style={{marginTop: '20px'}}>
					<button type='button' onClick={holder.sendQuestion}>Надіслати питання</button>
				</div>
				<QuestionList holder={holder} />
			</div>
		);
	}
	if( state == STATE_WAITING ) {
		return (
			<React.Fragment>
				<Spinner />
				<QuestionList holder={holder} />
			</React.Fragment>
		);
	}
	if( state == STATE_RESPONSE ) {
		if( ! holder.ok ) {
			var msg = holder.message;
			if( ! msg )
				msg = 'Вибачте, щось пішло не так. Спробуйте, будь-ласка, пізніше.';
			return (
				<div>
					<div style={{width: '100%', textAlign: 'center', marginTop: '30px'}}>
						{msg}
					</div>
					<div style={localStyles.divInfoText}>
						<span className={stylesPage.spanClickable} onClick={ () => {holder.setStateForm(false);}}>
							Повернутись до сервісу on-line довідки
						</span> 
					</div>
				</div>
			);
		}
		return (
			<div>
				<div style={localStyles.divInfoText}>
					Шановний читач {holder.question.name}.
				</div>
				<div style={localStyles.divInfoText}>
					Ми отримали Ваше запитання
				</div>
				<div style={localStyles.divInfoTextItalic}>
					{holder.question.text}
				</div>
				<div style={localStyles.divInfoText}>
					Якщо воно задовольняє <a href={RULES_PATH}>ПРАВИЛАМ</a> нашого сервісу, 
					Ви отримаєте відповідь на вказану адресу електронної пошти.
				</div>
				<div style={localStyles.divInfoText}>
					Якщо адреса електронної скриньки була вказана невірно, 
					або з якихось технічних причин відповідь не надійшла, 
					зайдіть через деякий час на сторінку нашої служби 
					та перегляньте останні опубліковані відповіді.
				</div>
				<div style={localStyles.divInfoText}>
					<span className={stylesPage.spanClickable} onClick={ () => {holder.setStateForm(true); }}>
						Повернутись до сервісу on-line довідки
					</span> 
				</div>
				<QuestionList holder={holder} />
			</div>
		);
	}
}

function QuestionList(props) {
	var holder = props.holder;
	var listQuestionHtml = holder.listQuestion.map( (question, index) => {
		return (
			<div key={index} style={{width: '100%'}}>
				<div style={{display: 'flex'}}>
					<div style={{width: '100px', fontWeight: 'bold'}}>Читач:</div>
					<div style={{textAlign: 'justify', fontStyle: 'italic'}}>{question.readerName}</div>
				</div>
				<div style={{display: 'flex'}}>
					<div style={{width: '100px', fontWeight: 'bold', flexShrink: 0}}>Запитання:</div>
					<div style={{textAlign: 'justify', fontStyle: 'italic'}}>{question.questionText}</div>
				</div>
				<div style={{display: 'flex', marginTop: '7px'}}>
					<div style={{width: '100px', fontWeight: 'bold', flexShrink: 0}}>Відповідь:</div>
					<div style={{textAlign: 'justify'}}>{question.answerText}</div>
				</div>
				<hr className={stylesPage.hrThin} />
			</div>
		)
	} );
	return (
		<div style={{marginBottom: '30px'}}>
			<div style={{width: '100%', marginTop: '20px', textAlign: 'center', fontWeight: 'bold', fontSize: '18pt'}}>
				Про що питали раніше
			</div>
			<hr className={stylesPage.hr} />
			{listQuestionHtml}
		</div>
	);
}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	},
	divInfoText: {
		marginTop: '20px',
		textAlign: 'justify'
	},
	divInfoTextItalic: {
		marginTop: '20px',
		textAlign: 'justify',
		fontStyle: 'italic',
		marginLeft: '50px'
	},
	divFormInput: {
		display: 'flex',
		marginTop: '15px'
	},
	divFormLabel: {
		width: '100px'
	}
}

export default OnlineInfoPage;
