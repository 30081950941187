import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";

import {
	MAIN_PATH,
	SERVICES_PATH,
	RULES_PATH,
	ADMINISTRATION_PATH,
} from '../../util/routes';

class RulesPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Правила користування', url: '', selected: true }
		];
		var menu = [
			{ title: 'Наші послуги', url: SERVICES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Правила користування', url: RULES_PATH, isGroupHeader: false, isActive: true },
			{ title: 'Адміністрація', url: ADMINISTRATION_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={RULES_PATH} menu={menu} body={body} />);
	}
}

function Body(props) {

	return(
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Правила користування бібліотекою
			</div>

			<div style={localStyles.divBlockHeader}>
				Обов'язки користувачів:
			</div>
			<ul>
				<li>Відносьтесь ввічливо до співробітників бібліотеки та інших користувачів.</li>
				<li>Приходьте в бібліотеку за довідковим та інформаційним обслуговуванням.</li>
				<li>Пам’ятайте, що читачі-діти мають такі самі права та обов’язки,
					як і дорослі користувачі бібліотеки.</li>
				<li>Дбайливо ставтесь до книжок, журналів та інших бібліотечних документів.</li>
				<li>Рідкісні та цінні документи надаються лише для користування у читальному залі.</li>
				<li>При наданні користувачам документів, які мають підвищений попит, додому,
					бібліотекою визначається розмір коштів, що передаються користувачами як
					забезпечення виконання ними зобов’язань щодо повернення одержаних у
					бібліотеці документів. За дітей кошти вносять батьки або особи,
					під наглядом яких перебувають діти. </li>
				<li>У разі порушення термінів користування документами,
					виданими користувачу додому, за кожний прострочений день сплачуються
					пеня. Розмір пені визначається бібліотекою.</li>
				<li>В разі пошкодження майна бібліотеки, адміністрація має право
					стягнути з винуватого повну вартість пошкодженого майна.</li>
				<li>Бібліотека не несе відповідальність за особисті речі користувачів,
					залишені без нагляду.</li>
				<li>Бібліотека не несе відповідальності за зрив роботи, що виник
					не з вини бібліотеки.</li>
				<li>Бібліотека має право відмовити в обслуговуванні користувачу,
					що порушує правила користування бібліотекою.</li>
			</ul>

			<div style={localStyles.divBlockHeader}>
				В бібліотеці забороняється:
			</div>
			<ul>
				<li>Галасувати та голосно розмовляти, заважаючи іншим користувачам.</li>
				<li>Курити та вживати алкогольні напої.</li>
				<li>Знаходитися в неохайному вигляді та в стані алкогольного чи
					наркотичного сп’яніння.</li>
			</ul>

			<div style={localStyles.divBlockHeader}>
				Правила користування Інтернет-центром при бібліотеці:
			</div>
			<ul>
				<li>Інтернет-центр надає послуги по доступу до мережі Інтернет,
					скануванню і роздрукуванню документів та інші.</li>
				<li>Послуги Інтернет-центру надаються на платній підставі, згідно тарифів,
					затверджених адміністрацією бібліотеки.</li>
				<li>Звертайтеся за поміччю до адміністраторів Інтернет-центру у разі
					виникнення проблем.</li>
				<li>Пам’ятайте, що після перезавантаження файли користувачів знищуються,
					тому зберігайте їх на власних зовнішніх носіях.</li>
				<li>Для відправлення та прийняття електронної пошти,
					користуйтесь власними електронними скриньками.</li>
				<li>Інтернет-центр не несе відповідальності за дії користувачів та
					зміст і достовірність інформації сайтів, які вони відвідують.</li>
				<li>Інтернет-центр не несе відповідальність за зрив роботи,
					що виник не з вини співробітників Інтернет-центру.</li>
			</ul>

			<div style={localStyles.divBlockHeader}>
				В Інтернет-центрі забороняється:
			</div>
			<ul>
				<li>Порушувати роботу Інтернет-центру – користуватися мобільним телефоном,
					голосно розмовляти та іншими діями заважати іншим користувачам.</li>
				<li>Знищувати, додавати і модифікувати інстальоване на комп’ютерах
					Інтернет-центру обладнання або програмне забезпечення.</li>
				<li>Встановлювати власне програмне забезпечення.</li>
				<li>Використовувати послуги Інтернет-центру для незаконних дій –
					порушення законів копірайту, розповсюдження заборонених матеріалів, тощо.</li>
				<li>Відвідувати сайти порнографічного змісту, пропагуючі насилля над
					людиною та інші, що порушують законодавство України.</li>
			</ul>

			<div style={localStyles.divBlockHeader}>
				Правила користування on-line довідкою:
			</div>
			<p>Служба «On-line довідка» є довідково-інформаційним сервісом  бібліотеки, що надає послуги в режимі online.</p>
			<p>Скористатися послугою мають можливість всі користувачі, незалежно від того, чи є вони читачами бібліотек Печерської ЦБС.</p>
			<p>Послуга надається на безоплатній основі.</p>
			<p>Прийом запитів від користувачів здійснюється в режимі «24 години на добу/ 7 днів на тиждень».</p>
			<p>До виконання приймаються запити українською та російською мовами. Пошук здійснюється по фондах бібліотек ЦБС та ресурсах Інтернету;</p>
			
			<div style={localStyles.divBlockOnlineHeader}>
				Відповіді на запитання містять таку інформацію
			</div>
			<ul>
				<li>бібліографічні списки літератури та адреси електронних ресурсів за темою (до 15 назв);</li>
				<li>фактографічні відомості (хронологічні, біографічні, адресні, статистичні), конкретна інформація про події, процеси, явища тощо;</li>
				<li>інформація щодо бібліографічного опису (уточнення елементів, які відсутні або неточно наведені);</li>
				<li>відомості про наявність видання у фонді бібліотеки та шифр зберігання документа;</li>
				<li>консультації з використання бібліотечних фондів та електронних ресурсів.</li>
			</ul>

			<div style={localStyles.divBlockOnlineHeader}>
				Правила та рекомендації щодо складання запитів
			</div>
			<ul>
				<li>запит повинен бути чітко сформульований;</li>
				<li>при замовленні списку літератури бажано вказати вид документів (книги, окремі статті, інше);</li>
				<li>у рамках служби «On-line довідка» повні тексти документів не надаються.</li>
				<li>перед надсиланням запиту, перевірте архів виконаних довідок, можливо там вже є необхідна вам інформація;</li>
			</ul>

			<div style={localStyles.divBlockOnlineHeader}>
				При користуванні довідкою забороняється
			</div>
			<ul>
				<li>Задавати питання комерційного харктеру (про ціни товарів та послуг, наявність товарів тощо)</li>
				<li>Задавати питання, що ведуть до порушення чинного законодавства (розкриття конфіденційних даних, розголошення закритої інформації тощо)</li>
				<li>Задавати питання, що спонукають до вирішення конкретних задач з навчальних предметів (математика, фізика, хімія)</li>
				<li>Задавати питання, що спонукають до вирішення розважальних задач (кросворди, вікторини, конкурси)</li>
				<li>Задавати питання, що вимагають глибокого наукового дослідження</li>
				<li>Задавати питання, що містять прохання про юридичні та медичні консультації;</li>
				<li>Задавати питання щодо надання готових дипломних та курсових робіт, рефератів.</li>
				<li>Задавати неетичні питання, використовувати образи, лайку, нецензурну лексику тощо</li>
			</ul>

			<p>Питання, які не задовольняють наведеним вище вимогам, ігноруються</p>
			
		</div>
	);

}

const localStyles = {
	divBlockHeader: {
		fontSize: '14pt',
		fontWeight: 'bold',
		fontStyle: 'italic'
	},
	divBlockOnlineHeader: {
		fontSize: '12pt',
		fontWeight: 'bold',
		fontStyle: 'italic'
	}
}

export default RulesPage;
