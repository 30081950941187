import React from 'react';
import styles from '../admin.module.css';

import { MAIN_PATH } from '../../../util/routes';

import logo from '../../../assets/img/pechersk_logo.png';
import title from '../../../assets/img/header/header_title.png';

class AdminHeader extends React.Component {

	render() {
		return (
			<div className={styles.divMain}>
				<div className={styles.divHeaderBody}>
					<div style={{display: 'flex', width: '100%', paddingTop: '15px'}}>
						<div style={{width: '75px'}}>
							<a href={MAIN_PATH}><img src={logo} /></a>
						</div>
						<div style={{marginTop: '15px', marginLeft: '15px'}}>
							<img src={title} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const localStyles = {
	spanSelected: {
		textDecoration: 'none',
		color: '#674268',
		cursor: 'default',
		
		marginRight: '10px',
		fontSize: '11pt',
		fontWeight: 'normal',
		fontFamily: 'impact'	
	}
}

export default AdminHeader;
