import React, { Component } from 'react';
import stylesPage from '../page.module.css';
import stylesSchedule from './schedule.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";

import {
	MAIN_PATH,
	CONTACTS_PATH,
	SERVICES_PATH,
	RULES_PATH
} from '../../util/routes';

class SchedulePage extends Component {
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Графік роботи', url: '', selected: true }
		];
		var menu = [
			{ title: 'Контакти', url: CONTACTS_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Графік роботи', url: '', isGroupHeader: false, isActive: true },
			{ title: 'Наші послуги', url: SERVICES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Правила користування', url: RULES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Карта сайту', url: '', isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} menu={menu} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={{width: '100%', paddingLeft: '10px', marginBottom: '30px'}}>
			<div className={stylesPage.divPageHeader}>Графік роботи</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='4'>ЦРБ "Печерська"</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Чит. зал</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Інтернет-центр</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
				</table>
			</div>

			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека ім.О.Вишні</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека ім.К.Білокур</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека "Книголюб"</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
				</table>
			</div>

			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека №149</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека №150</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека "Всесвіт"</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека "Світогляд"</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека естетичного виховання</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 19:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>11:00 - 18:00</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека "Веселка"</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека ім.М.Трублаїні</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека №8</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 17:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 17:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
				</table>
			</div>
			
			<div className={stylesSchedule.divTable}>
				<table className={stylesSchedule.tableGraphic}>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderLibrary} colspan='3'>Бібліотека "Джерело"</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>&nbsp;</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Абонемент</td>
						<td className={stylesSchedule.tdGraphicHeaderDepartment}>Читальний зал</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Понеділок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Вівторок</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Середа</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Четвер</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>П'ятниця</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 18:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Субота</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 17:00</td>
						<td className={stylesSchedule.tdGraphicWorkHours}>10:00 - 17:00</td>
					</tr>
					<tr>
						<td className={stylesSchedule.tdGraphicDayOfWeek}>Неділя</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
						<td className={stylesSchedule.tdGraphicWeekend}>Вихідний</td>
					</tr>
				</table>
			</div>
		</div>
	);
}

export default SchedulePage;
