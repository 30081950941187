import React, { Component } from 'react';

class ConditionText extends Component {
	
	/*
		query
	*/
	constructor(props) {
		super(props);
		this.query = props.query;
	}

	render() {
		var empty = true;
		
		var authorHtml = (null);
		var titleHtml = (null);
		var keywordsHtml = (null);
		var anyFieldHtml = (null);

		if( this.query.author ) {
			let comma = empty ? (null) : ', ';
			authorHtml = (
				<React.Fragment>
					{comma}автор <span style={localStyles.spanValue}>&quot;{this.query.author}&quot;</span>
				</React.Fragment>
			);
			empty = false;
		}
		if( this.query.title ) {
			let comma = empty ? (null) : ', ';
			titleHtml = (
				<React.Fragment>
					{comma}назва <span style={localStyles.spanValue}>&quot;{this.query.title}&quot;</span>
				</React.Fragment>
			);
			empty = false;
		}
		if( this.query.keywords ) {
			let comma = empty ? (null) : ', ';
			keywordsHtml = (
				<React.Fragment>
					{comma}ключові слова <span style={localStyles.spanValue}>&quot;{this.query.keywords}&quot;</span>
				</React.Fragment>
			);
			empty = false;
		}
		if( this.query.anyfield ) {
			let comma = empty ? (null) : ', ';
			anyFieldHtml = (
				<React.Fragment>
					{comma}за всіма полями <span style={localStyles.spanValue}>&quot;{this.query.anyfield}&quot;</span>
				</React.Fragment>
			);
			empty = false;
		}
		
		if( empty ) {
			return (
				<React.Fragment>
					Без обмежень
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					{authorHtml}{titleHtml}{keywordsHtml}{anyFieldHtml}
				</React.Fragment>
			);
		}
	}
}

const localStyles = {
	spanValue: {
		fontWeight: 'bold'
	}
}

export default ConditionText;

