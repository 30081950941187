import React, { Component } from 'react';
import LeftSideMenu from "../../../components/LeftSideMenu";
import stylesLibrary from "../library.module.css";

import {
	LIBJERELO_CONTACTS_PATH, 
	LIBJERELO_EVENTS_PATH, 
	LIBJERELO_GALLERY_PATH, 
	LIBJERELO_INFO_PATH, 
} from '../../../util/routes';

class LeftSide extends Component {

	constructor(props) {
		super(props);
	}

	menuItem = (title, url) => {
		return {title: title, url: url, isGroupHeader: false, isActive: url == this.props.path};
	}
	
	render() {
		var menu = [
			this.menuItem('Загальна інформація', LIBJERELO_INFO_PATH),
			this.menuItem('Новини та події', LIBJERELO_EVENTS_PATH),
			this.menuItem('Фотогалерея', LIBJERELO_GALLERY_PATH),
			this.menuItem('Координати', LIBJERELO_CONTACTS_PATH),
		]
		return (
			<React.Fragment>
				<LeftSideMenu menu={menu} />
				
				<div className={stylesLibrary.divLeftSideHeader}>
					Адреса бібліотеки
				</div>
				<ul className={stylesLibrary.ulLeftSideContacts}>
					<li style={{marginTop: '15px'}}>
						<span style={{fontWeight: 'bold'}}>Адреса:</span> вул. Шовковична, 46-48
					</li>

					<li style={{marginTop: '15px'}}>
						<span style={{fontWeight: 'bold'}}>Телефон:</span> 234-69-97
					</li>

				</ul>

				<div className={stylesLibrary.divLeftSideHeader}>
					Графік роботи	
				</div>
				<table className={stylesLibrary.tableWorkSchedule}>
					<tr>
						<td>&nbsp;</td>
						<td valign="top">Абонемент</td>
						<td valign="top">Читальний зал</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>понеділок</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>вівторок</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>середа</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>четвер</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>п'ятниця</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>субота</div></td>
						<td valign="top">10:00-17:00</td>
						<td valign="top">10:00-17:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>неділя</div></td>
						<td valign="top">вихідний</td>
						<td valign="top">вихідний</td>
					</tr>
				</table>
			</React.Fragment>
		);
	}
}

export default LeftSide;