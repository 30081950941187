import React, { Component, createRef } from 'react';
import stylesAdmin from './admin.module.css';
import stylesPage from '../page.module.css';

import AdminHeader from "./components/AdminHeader";
import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";

import { getEventImageUrl } from "../../util/tools";
import { 
	performApiRequest, performApiRequestForm, 
	POSTER_LOAD_API_URL, POSTER_SAVE_API_URL, POSTER_SET_IMAGE_API_URL 
} from "../../util/restapi";
import { ADMIN_POSTER_PATH } from "../../util/routes";

import logo from '../../assets/img/pechersk_logo.png';

const STATE_INIT = 'init';
const STATE_READY = 'ready';
const STATE_SAVING_POSTER = 'saving_poster';
const STATE_SAVING_IMAGE = 'saving_image';

class AdminPosterPage extends Component {

	constructor(props) {
		super(props);
		this.posterId = this.props.match.params.id;
		this.poster = {};
		this.fileRef = createRef();
		this.state = {
			dirty: false,
			formState: STATE_INIT,
			logoMode: 'url',
			ok: true
		};
	}
	componentDidMount = async () => {
		if( this.posterId == 0 ) {
			this.poster = { 
				posterId: 0,
				posterOrder: '',
				showOnMainPage: false,
				showOnPosterList: true,
				logoMode: 'url',
				logoId: 0,
				logoUrl: '',
				title: '',
				beginningTime: '',
				description: ''
			};
		} else {
			var request = { posterId: this.posterId };
			var response = await performApiRequest(POSTER_LOAD_API_URL, request);
			this.poster = response.poster;
		}
		this.setState({formState: STATE_READY, logoMode: this.poster.logoMode});
	}

	handleValueChange = (e) => {
		const {name, value} = e.target;
		var poster = this.poster;
		if( name == 'title' )
			poster.title = value;
		if( name == 'description' )
			poster.description = value;
		if( name == 'beginningTime' )
			poster.beginningTime = value;
		if( name == 'posterOrder' )
			poster.posterOrder = value;
		if( name == 'logoUrl' )
			poster.logoUrl = value;
		this.setState({dirty: true});
	}
	handleCheckBoxChange = (e) =>	{
		const {name, checked} = e.target;
		var poster = this.poster;
		if( name == 'showOnMainPage' )
			poster.showOnMainPage = checked;
		if( name == 'showOnPosterList' )
			poster.showOnPosterList = checked;
		this.setState({dirty: true});
	}
	handleCmbSelected = (e) => {
		const {name, value} = e.target;
		var poster = this.poster;
		if( name == 'cmbLogoMode' )
			poster.logoMode = value;
		this.setState({dirty: true, logoMode: value});
	}

	clearLogo = async () => {
		this.setState({formState: STATE_SAVING_IMAGE});
		if( this.poster.logoId != 0 ) {
			const form = new FormData();
			form.append('previousId', this.poster.logoId);
			var response = await performApiRequestForm(POSTER_SET_IMAGE_API_URL, form);
			if( response.ok ) {
				this.poster.logoId = 0;
			}
		}
		this.setState({formState: STATE_READY});
	}
	handleFileSelect = async (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			this.setState({formState: STATE_SAVING_IMAGE});
			var file = files[0];
			const form = new FormData();
			form.append('previousId', this.poster.logoId);
			form.append('photo', file);
			var response = await performApiRequestForm(POSTER_SET_IMAGE_API_URL, form);
			if( response.ok ) {
				this.poster.logoId = response.imageId;
			}
			this.setState({formState: STATE_READY});
		}
	}
	
	savePoster = async () => {
		this.setState({formState: STATE_SAVING_POSTER});
		let poster = this.poster;
		let request = { poster: poster };
		let isNew = poster.posterId == 0;
		let response = await performApiRequest(POSTER_SAVE_API_URL, request);
		console.log('Save poster result', response)
		poster.posterId = response.posterId;
		this.setState({formState: STATE_READY});
		if( isNew )
			window.open( ADMIN_POSTER_PATH + '/' + response.posterId, '_self' );
	}
	
	render() {
		var leftSide = (<AdminLeftSide />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_INIT ) {
		var contentHtml = (<Spinner />);
	} else {
		var contentHtml = (<Content holder={holder} />);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={stylesAdmin.divContentHeader}>
				Анонс
			</div>
			{contentHtml}
		</div>
	)
}
function Content(props) {
	var holder = props.holder;
	var poster = holder.poster;
	var formState = holder.state.formState;
	if( poster == null ) {
		return (
			<div style={{width:'100%', marginTop: '30px', marginBottom: '30px', textAlign: 'center'}}>
				Вибачте, інформація відсутня
			</div>
		);
	}
	if( formState == STATE_SAVING_POSTER ) {
		var buttonOrSpinnerHtml = <Spinner />;
	} else {
		var disabled = (formState == STATE_SAVING_IMAGE);
		var buttonOrSpinnerHtml = (
			<div style={{marginTop: '15px'}}>
				<button type='button' onClick={holder.savePoster} disabled={disabled}>Записати анонс</button>
			</div>
		);
	}
	
	return (
		<div style={{width:'100%', marginBottom: '30px'}}>
			<div style={{marginTop: '15px'}}>
				Заголовок<br/>
				<textarea 
					name="title" rows="2" cols="100" 
					onChange={holder.handleValueChange}
					value={poster.title}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				Повний опис<br/>
				<textarea 
					name="description" rows="8" cols="100" 
					onChange={holder.handleValueChange}
					value={poster.description}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				Пріоритет (порядковий номер) 
				<input type='text' name='posterOrder' size='4'
					style={{marginLeft: '10px'}}
					onChange={holder.handleValueChange} 
					value={poster.posterOrder}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				<input type='checkbox' name='showOnMainPage' 
					style={{marginRight: '10px'}}
					onChange={holder.handleCheckBoxChange} 
					checked={poster.showOnMainPage} 
				/>
				Відображати на головній сторінці (слайд)
				<input type='checkbox' name='showOnPosterList' 
					style={{marginLeft: '20px', marginRight: '10px'}}
					onChange={holder.handleCheckBoxChange} 
					checked={poster.showOnPosterList} 
				/>
				Відображати в переліку анонсів
			</div>
			<SlideImage holder={holder} />
			{buttonOrSpinnerHtml}
		</div>
	);
}

function SlideImage(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_SAVING_IMAGE )
		return <Spinner />;

	return (
		<div style={{marginTop: '30px'}}>
			<div>
				Слайд
				<select name='cmbLogoMode' 
					style={{marginLeft: '10px'}} value={holder.state.logoMode} 
					onChange={holder.handleCmbSelected}
				>
					<option value='id'>Локальний файл</option>
					<option value='url'>Зовнішній URL</option>
				</select>
			</div>
			{ holder.state.logoMode == 'id' ? <LogoId holder={holder}/> : <LogoUrl holder={holder} /> }
		</div>
	);
}

function LogoUrl(props) {
	var holder = props.holder;
	var poster = holder.poster;
	return (
		<div style={{width:'100%'}}>
			<div style={{marginTop: '15px'}}>
				URL
				<input type='text' name='logoUrl' size='80'
					style={{marginLeft: '10px'}}
					onChange={holder.handleValueChange} 
					value={poster.logoUrl}
				/>
			</div>
			<div style={{width: '200px', marginTop: '10px'}}>
				<img src={poster.logoUrl} height='100' />
			</div>
		</div>
	);
}

function LogoId(props) {
	var holder = props.holder;
	var poster = holder.poster;
	if( poster.logoId > 0) {
		var srcImage = getEventImageUrl(poster.logoId);
		var buttonClear = (
			<div>
				<button type='button' onClick={holder.clearLogo}>Очистити</button>
			</div>
		);
	} else {
		var srcImage = logo;
		var buttonClear = null;
	}
	return (
		<div style={{marginTop: '10px'}}>
			<div style={{display: 'flex'}}>
				<div style={{marginRight: '10px'}}>
					<button type='button' onClick={e => holder.fileRef.current.click()}>Вибрати</button>
					<input ref={holder.fileRef} type={'file'} style={{display: 'none'}} onChange={e => holder.handleFileSelect(e)} />
				</div>
				{buttonClear}
			</div>
			<div style={{width: '200px', marginTop: '10px'}}>
				<img src={srcImage} height='100' />
			</div>
		</div>
	);
}

export default AdminPosterPage;
