import React, { Component } from 'react';
import stylesAdmin from './admin.module.css';

import AdminHeader from "./components/AdminHeader";
import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import CmbLibrary from "./components/CmbLibrary";
import DivInnerHtml from '../../components/DivInnerHtml';
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";
import Message from "../../components/Message";

import { getAdminEventUrl, getLibrary } from "../../util/tools"
import { performApiRequest, EVENT_LIST_API_URL, EVENT_DELETE_API_URL } from "../../util/restapi"
import { ADMIN_EVENT_LIST_PATH, ADMIN_EVENT_PATH } from "../../util/routes";
import { CBS, ANY, ONLY_TRUE, ONLY_FALSE } from "../../util/consts";

const STATE_WORKING = 'working';
const STATE_READY = 'ready';

class AdminEventListPage extends Component {

	constructor(props) {
		super(props);
		this.librarySelection = {
			selectedLibraryId: CBS.cipher
		}
		this.eventList = [];
		this.messageHandler = {
			showMessage: null,
			doAfterClose: this.doDelete,
			confirm: false,
			eventId: null
		};
		this.state = {
			formState: STATE_READY,
			deletingEvent: null,
			ok: true
		};
	}
	componentDidMount = () => {
		this.refresh();
	}
	
	refresh = async () => {
		this.setState({formState: STATE_WORKING});
		var request = {
			page: 1,
			pageSize: 10000,
			skipCountRequest: true,
			libraryId: this.librarySelection.selectedLibraryId,
			active: ANY,
			virtualExhibition: ONLY_FALSE,
			slided: ANY
		};
		var response = await performApiRequest(EVENT_LIST_API_URL, request);
		this.ok = response.ok;
		if( response.ok ) {
			this.eventList = response.eventList;
		}
		this.setState({formState: STATE_READY, ok: response.ok});
	}

	deleteEvent = (eventId) => {
		this.messageHandler.eventId = eventId;
		this.messageHandler.doAfterClose = this.doDelete;
		this.messageHandler.showMessage( "Вилучити вибрану подію?" );
	}
	doDelete = async () => {
		if( this.messageHandler.confirm ) {
			var eventId = this.messageHandler.eventId;
			this.setState({deletingEvent: eventId});
			var request = {
				eventId: this.messageHandler.eventId
			};
			var response = await performApiRequest(EVENT_DELETE_API_URL, request);
			if( response.ok ) {
				for( let i=this.eventList.length-1; i >= 0 ; i--) {
					if( this.eventList[i].eventId == eventId )
						this.eventList.splice( i, 1 );
				}
			}
			this.setState({deletingEvent: null});
		}
		this.messageHandler.confirm = false;
		this.messageHandler.eventId = null;
	}
	
	render() {
		var leftSide = (<AdminLeftSide path={ADMIN_EVENT_LIST_PATH} />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	var holder = props.holder;
	return (
		<React.Fragment>
			<div className={stylesAdmin.divContentHeader}>
				Новини та події
			</div>
			<div style={{marginTop: '20px'}}>
				Бібліотека <CmbLibrary librarySelection={holder.librarySelection} />
				<button type='button' style={{marginLeft: '10px'}} onClick={holder.refresh}>Відновити</button>
			</div>
			<div style={{marginTop: '20px'}}>
				<a href= {ADMIN_EVENT_PATH + '/0'} target='_blank'>Додати</a>
			</div>
			<EventList holder={holder} />
		</React.Fragment>
	)
}

function EventList(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_WORKING ) {
		return ( <Spinner /> );
	}
	if( ! holder.state.ok ) {
		return (
			<div style={{textAlign: 'center'}}>
				Під час виконання виникла помилка
			</div>
		);
	}
	var eventListHtml = holder.eventList.map( (event, index) => {
		return (<Event key={index} event={event} holder={holder} />);
	} );
	return (
		<React.Fragment>
			<hr />
			{eventListHtml}
		</React.Fragment>
	);
}
function Event(props) {
	var holder = props.holder;
	var event = props.event;
	var hrefEdit = getAdminEventUrl( event.eventId );
	var library = getLibrary( event.libraryId );
	if( holder.state.deletingEvent == event.eventId ) {
		return (
			<div>
				<Spinner />
				<hr />
			</div>
		);
	}
	return (
		<div>
			<div>
				Бібліотека: <span style={{fontWeight: 'bold'}}>{library.title} </span>
				Дата: <span style={{fontWeight: 'bold'}}>{event.eventDateFormatted}</span>
			</div>
			<DivInnerHtml style={localStyles.divEventHeader} text={event.title} />
			<DivInnerHtml style={localStyles.divEventBody} text={event.description} />
			<div>
				<a href={hrefEdit}>Змінити</a>&nbsp;
				<button type='button' onClick={ () => {holder.deleteEvent(event.eventId);} }>Вилучити</button>
			</div>
			<hr />
			<Message messageHandler={holder.messageHandler}/>
		</div>
	);
}

const localStyles = {
	divPageTitle: {
		textAlign: 'center',
		fontSize: '20pt',
		fontFamily: 'AkvitaniaModern',
		fontWeight: 'bold'
	},
	divMessage: {
		marginTop: '20px',
		textAlign: 'center',
		fontSize: '16pt',
		color: 'red',
		fontWeight: 'bold'
	},
	divEventHeader: {
		paddingTop: '7px',
		textAlign: 'left',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'bold',
		fontSize: '12pt'
	},
	divEventBody: {
		paddingTop: '7px',
		textAlign: 'justify',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'normal',
		fontSize: '12pt'
	}
}

export default AdminEventListPage;
