import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import DivInnerHtml from '../../components/DivInnerHtml';
import Spinner from "../../components/Spinner";

import {
	MAIN_PATH,
	ECATALOG_PATH,
	BIBLIOGRAPHIC_PATH,
	BOOKS_NEW_PATH,
	BOOKS_INTERESTING_PATH
} from '../../util/routes';
import { performApiRequest, BOOK_LIST_API_URL } from "../../util/restapi";
import { getBookImageUrl } from "../../util/tools";
import { ANY, ONLY_TRUE, ONLY_FALSE } from '../../util/consts';

const STATE_INIT = 'init';
const STATE_WORKING = 'working';
const STATE_READY = 'ready';

class BookListInterestingPage extends Component {
	
	constructor(props) {
		super(props);
		this.ok = true;
		this.bookList = [];
		this.state = {
			formState: STATE_INIT,
		};
	}
	componentDidMount = async () => {
		this.setState({formState: STATE_WORKING});
		var request = {
			query: {
				type: '',
				isNew: ANY,
				isInteresting: ONLY_TRUE,
				showOnMainPage: ANY,
				showOnBookList: ONLY_TRUE,
				skip: 0,
				count: 0
			}
		}
		var response = await performApiRequest(BOOK_LIST_API_URL, request);
		this.ok = response.ok;
		if( response.ok ) {
			this.bookList = response.bookList;
		}
		this.setState({formState: STATE_READY, ok: response.ok});
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Цікаве на полицях', url: '', selected: true }
		];
		var menu = [
			{ title: 'Електронний каталог', url: ECATALOG_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліотека рекомендує', url: '', isGroupHeader: true, isActive: false },
			{ title: 'Цікаве на полицях', url: BOOKS_INTERESTING_PATH, isGroupHeader: false, isActive: true },
			{ title: 'Нові надходження', url: BOOKS_NEW_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={BOOKS_INTERESTING_PATH} menu={menu} body={body} />);
	}
	
}
function Body(props) {
	return (
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Цікаве на полицях
			</div>
			<BookList holder={props.holder} />
		</div>
	);
}
function BookList(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_INIT ) {
		return (null);
	}
	if( holder.state.formState == STATE_WORKING ) {
		return ( <Spinner /> );
	}
	if( ! holder.ok ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				Вибачте, сервіс тимчасово не доступний.
			</div>
		);
	}
	if( holder.bookList.length == 0 ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				Вибачте, інформація відсутня.
			</div>
		);
	}
	var bookListHtml = holder.bookList.map( (book, index) => {
		return (<Book key={index} holder={holder} book={book} />);
	});
	return (
		<div>
			{bookListHtml}
		</div>
	);
}
function Book(props) {
	var book = props.book;
	var urlImage = getBookImageUrl( book.bookId );
	return(
		<div style={{display: 'flex', marginBottom: '30px'}}>
			<div>
				<img src={urlImage}  width="150" />
			</div>
			<div style={{marginLeft: '20px'}}>
				<div style={{fontWeight: 'bold', marginBottom: '10px'}}>
					<DivInnerHtml text={book.header} />
				</div>
				<div style={{marginBottom: '10px'}}>
					<DivInnerHtml text={book.card} />
				</div>
				<div style={{textAlign: 'justify'}}>
					<DivInnerHtml text={book.annotation} />
				</div>
			</div>
		</div>
	);
}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	},
}

	
export default BookListInterestingPage;
