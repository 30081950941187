import config from '../config/config';

export const LOGIN_API_URL = "login.php";
export const MAIN_PAGE_API_URL = "main_page.php";

export const EVENT_LOAD_API_URL = "event_load.php";
export const EVENT_LIST_API_URL = "event_list.php";
export const EVENT_SAVE_API_URL = "event_save.php";
export const EVENT_SAVE_IMAGE_API_URL = "event_add_image.php";
export const EVENT_DELETE_API_URL = "event_delete.php";
export const SLIDE_SAVE_API_URL = "slide_save.php";
export const SLIDE_MOVE_API_URL = "slide_move.php";

export const IMPORTANT_INFO_LOAD_API_URL = "important_info_load.php";
export const IMPORTANT_INFO_SAVE_API_URL = "important_info_save.php";

export const BOOK_LIST_API_URL = "book_list.php";
export const BOOK_LOAD_API_URL = "book_load.php";
export const BOOK_SAVE_API_URL = "book_save.php";
export const BOOK_SET_IMAGE_API_URL = "book_set_image.php";
export const BOOK_CLEAR_IMAGE_API_URL = "book_clear_image.php";
export const BOOK_DELETE_API_URL = "book_delete.php";

export const CLUB_LIST_API_URL = "club_list.php";
export const CLUB_LOAD_API_URL = "club_load.php";
export const CLUB_SAVE_API_URL = "club_save.php";
export const CLUB_SET_IMAGE_API_URL = "club_set_image.php";
export const CLUB_CLEAR_IMAGE_API_URL = "club_clear_image.php";
export const CLUB_DELETE_API_URL = "club_delete.php";

export const POSTER_LIST_API_URL = "poster_list.php";
export const POSTER_LOAD_API_URL = "poster_load.php";
export const POSTER_SAVE_API_URL = "poster_save.php";
export const POSTER_SET_IMAGE_API_URL = "poster_set_image.php";
export const POSTER_DELETE_API_URL = "poster_delete.php";

export const QUESTION_LIST_API_URL = "question_list.php";
export const QUESTION_SAVE_API_URL = "question_save.php";
export const QUESTION_ANSWER_API_URL = "question_answer.php";
export const QUESTION_UPDATE_STATE_API_URL = "question_update_state.php";

export const DOC_SEARCH_API_URL = "doc_search.php";

export const performApiRequest = async (url, requestBody) => {
	url = config.apiGateway.URL + url;
	const request = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json' 
		},
		body: JSON.stringify( requestBody )
	};
	console.log( url, request );
	var text = '';
	try {
		var response = await fetch(url, request);
		text = await response.text();
//		console.log( response, text );
		response = JSON.parse(text);
		console.log( response );
	} catch ( err ) {
		console.log( text );
		console.log( err );
		response = { ok: false };
	}
	return response;
}

export const performApiGetRequest = async (url) => {
	url = getApiUrl(url);
	return performApiGetRequestFinalUrl( url );
}

export const performApiRequestForm = async (url, form) => {
	url = config.apiGateway.URL + url;
	const request = {
		method: 'POST',
		body: form,
		headers: {}
	};
	console.log( url, request );
	var text = '';
	try {
		var response = await fetch(url, request);
		text = await response.text();
//		console.log( response, text );
		response = JSON.parse(text);
		console.log( response );
	} catch ( err ) {
		console.log( text );
		console.log( err );
		response = { ok: false };
	}
	return response;
}

export const performApiGetRequestFinalUrl = async (url) => {
	const request = {
		method: 'GET',
		headers: { 
			'Content-Type': 'application/json' 
		}
	};
	var response = await fetch(url, request);
	response = await response.json();
	return response;
}


export const getApiUrl = ( url ) => {
	var fullUrl = config.apiGateway.URL;
	if( config.apiGateway.BASE_PATH )
		fullUrl += '/' + config.apiGateway.BASE_PATH;
	fullUrl += '/' + url;
	return fullUrl;
}
