import React, { Component } from 'react';
import styles from '../bibliographic.module.css';

import BibliographicPage from "../BibliographicPage";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH
} from '../../../util/routes';

import image01 from './image01.jpg';
import image02 from './image02.jpg';
import image03 from './image03.jpg';
import image04 from './image04.jpg';
import image05 from './image05.png';
import image06 from './image06.jpg';
import image07 from './image07.jpg';
import image08 from './image08.png';
import image09 from './image09.jpg';
import image10 from './image10.jpg';
import image11 from './image11.jpg';
import image12 from './image12.jpg';

class B208 extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, selected: false },
			{title: 'ЧИТАЙ УКРАЇНСЬКОЮ ТА РЕЛАКСУЙ', url: '', selected: true }
		];
		var body = (<Body />);
		return (
			<BibliographicPage breadCrumbs={breadCrumbs} body={body} />
		);
	}
}

function Body(props) {
return(

<div style={{width: '100%', marginBottom: '30px'}}>
	<div className={styles.divBibliographicHeader}>
		ЧИТАЙ УКРАЇНСЬКОЮ ТА РЕЛАКСУЙ
	</div>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image01} width='600'/>
	</div>
	<div className={styles.divHeaderBibliographicType}>
		Бібліографічний список літератури
	</div>
	
	<p className={styles.pCommon}>
		Бібліографічний список «Читай українською та релаксуй» знайомить з сучасними зарубіжними книгами, які допоможуть відволіктися від повсякденних проблем та змінять ставлення користувачів до багатьох речей у житті. 
	</p>
	<p className={styles.pCommon}>
		Список складено на підставі електронного каталогу Центральної районної бібліотеки «Печерська». Матеріал в розділі «Рекомендуємо прочитати» розміщений в алфавітному порядку прізвищ авторів, з анотаціями творів. 
	</p>
	<p className={styles.pCommon}>
		Бібліографічний список адресований широкому загалу читачів, які цікавляться сучасною зарубіжною літературою. 
	</p>
	<p className={styles.pCommon}>
		Ці книги Ви можете отримати на абонементі Центральної районної бібліотеки «Печерська» та в бібліотеках-філіях Печерської ЦБС. 
	</p>
	<p className={styles.pCommon}>
		Відповідальний за випуск: Т.І. Шевченко
	</p>

	<p className={styles.epigraph}>
		Книги - ліки для душі.
	</p>
	<p className={styles.pCommon} style={{fontSize: '16pt', textAlign: 'center'}}>
		ВІД УКЛАДАЧА 
	</p>
	<p className={styles.pCommon}>
		Основне завдання книготерапії - допомагати людям краще зрозуміти власні емоції, подивитися на все, що відбувається в житті, під іншим кутом, скористатися досвідом інших людей, щоб наповнити своє життя позитивом. Книготерапія – це значно більше, ніж просто читання книжок, це про подолання різних викликів, бар’єрів, про небайдужість і підтримку.  
	</p>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image02} width='500'/>
	</div>
	
	<p className={styles.pCommon}>
		Сучасна зарубіжна література охоплює багато різних тем і напрямків, а тому кожен зможе знайти для себе твір до душі.   
	</p>
	<p className={styles.pCommon}>
		В книгах описані різні події з точки зору іншої культури. Тобто ви зможете дізнатися про те, як живуть люди в інших країнах, що їх турбує, про що вони мріють.
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center'}}>
		Отож, читаймо і будьмо здорові.
	</p>
	
	<div style={{marginLeft: '15px', marginRight: '50px'}} >
		<p className={styles.pCommon} style={{fontWeight: 'bold', fontSize: '16pt', textAlign: 'center'}}>
			Рекомендуємо прочитати:
		</p>
	
		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Генна, К. Чотири вітри : роман  / Крістін Генна; пер. з англ. Анастасія Дудченко.- Київ : Видавництво  РМ, 20 23 . - 600 с. - (Серія «Великий роман»).
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image03} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Все життя їй казали, що вона слабка й негарна. Все життя за нею доглядали, але не любили. Все життя вона вважала себе зайвою та поводилася тихо, як мишка, щоб не обтяжувати собою родину.
				</div>
				<div className={styles.divBookDescription}>
					Але одного дня все раптом змінилося, і Ельза опинилася в новому, невідомому їй світі. Їй довелося багато працювати, опиратися голоду та підтримувати близьких людей. Кров піонерів Дикого Заходу, що до часу тихо текла в жилах розніженої дівчини, в лиху годину далася взнаки. Виявилося, що Ельза не з тих, хто легко здається.
				</div>
				<div className={styles.divBookDescription}>
					Перо американської письменниці Крістін Генни належить людям, сильним духом, тим, хто  перед лицем небезпеки не складає зброї, а виявляє найкращі риси свого характеру. Роман «Чотири вітри» — не виняток. Він надихає і кличе до боротьби з обставинами, з людським присудом, до боротьби за любов і світло.
				</div>
				<div className={styles.divBookDescription}>
					Ми самі не знаємо, на що здатні, аж доки доля змусить нас до опору.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Джіо, С. Ранкове сяйво / Сара Джіо; пер. з англ. Л. Александронець. – Харків : Віват, 2023. - 320 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image04} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Життя в гамірному Нью-Йорку не зробило Аду Санторіні щасливою. Її успіхи на роботі затьмарює особиста трагедія: два роки тому вона втратила коханого та доньку. Рятуючись від тяжких буднів, Ада вирішує оселитися в плавучому будиночку на озері Юніон у Сіетлі. Там вона раптом натрапляє на загадкову скриню, яка приховує давні таємниці. Пів століття тому в цьому самому місці юна Пенні Вентворт пережила велике кохання й раптом зникла. Ада розв’язує таємницю її зникнення за участі Алекса, фотографа, який живе в сусідньому будиночку. Поступово жінка дізнається історію, яка дивним чином переплітається з її власною. Чи стане Аді сміливості покохати знову?
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Джордано, П. Самотність простих чисел : роман  / Паоло Джордано; пер. з італ. А. Маслюха. – Львів : Видавництво Старого Лева, 2016. - 320 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image05} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Ця книжка — про любов і про самотність. Про батьків і дітей. Про відгомін давніх трагедій. Про спроможність — і неспроможність — жити і виживати. Про прірви у житті і про мости через ті прірви. Про страхи — глибинні й поверхові. Про втечу від примар минулого та про саму можливість — і неможливість — від них утекти. Про толерантність до іншого і “не такого”. Зрештою, про пару простих чисел-”близнюків”.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Джордж, Н. Маленька паризька книгарня: роман  / Ніна Джордж; пер. з англ. Ольги Захарченко.- 4-те вид. – Київ : Наш формат, 2018. – 306 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image06} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Книга «Маленька паризька книгарня» про те, як стати вільним. Вільним від душевних терзань, марних сподівань і туги за нездійсненним майбутнім. Цей чарівний роман змусить багатьох читачів поринути у спогади про своє перше кохання, знову відчути ніжність, любов та легкий відтінок смутку за минулими почуттями.
				</div>
				<div className={styles.divBookDescription}>
					Жан Одинак – власник книжкової баржі «Літературна аптека» – точно знає, як вилікувати почуття, що не вважаються захворюваннями й не діагностуються лікарями. Адже він читач душ, тож кожному покупцеві добирає книги, які якнайкраще впораються із завданням вилікувати людський смуток, тугу чи зневіру.
				</div>
				<div className={styles.divBookDescription}>
					Єдиний, кого Жан не може зцілити, це він сам. Душевні терзання від спогадів про Манон, котра покинула месьє Одинака двадцять один рік тому, розбили йому серце і досі тривожать душевні рани. Усе, що вона залишила, лист, який Ж2016.- 304 с.ан ніяк не наважується прочитати
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Мадзантіні, М. Не йди: роман / Маргарет Мадзантіні; пер. з італ. В. Степанова.- Харків: Книжковий Клуб «Клуб Сімейного Дозвілля», 2016.- 304 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image07} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Європейський бестселер 21 століття! Роман екранізовано у 2004 році. Кохання — це випадковий збіг у житті невипадкових людей. Тімотео був турботливим батьком, бездоганним чоловіком та хірургом з чудовою репутацією. Він мав усе, про що тільки можна мріяти: щаслива сім’я, гарна донька, вілла на березі моря. Проте одної ночі Тео заходить до бару і ця ніч назавжди змінює його життя. Він закохується у емігрантку з якою не мав нічого спільного окрім сп’яніння. Кохання, зрада, пристрасті — три гострі кути цього роману. Та коли доля забирає жінку від Тімотео, він розуміє, що втратив дещо більш
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Марш, Г. Історії про життя, смерть і нейрохірургію : збірка оповідань  / Генрі Марш ; пер. з англ. Андрія Мизака. - Львів : Видавництво Старого Лева, 2020 . -  312 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image08} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Правда — то його релігія, кажуть про Генрі Марша колеги. Власне, у цій книжці спогадів і роздумів відомого британського нейрохірурга ви знайдете багато захопливих історій, у яких головне — це етика стосунків між лікарем та пацієнтом, тонка межа між гіркою правдою і крихкою надією. Бути висококласним нейрохірургом — цього не досить, щоб бути Генрі Маршем. Треба ще мати найкращі людські якості — доброту, ви­могливість до себе, любов до людей і неабиякий талант оповідача.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Маршалл,  Л. Запит на дружбу / Лора Маршалл;  пер. з англ. Д. Березіної. - Харків : Віват, 2019. - 384 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image09} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Це почалося ще в школі. До класу Луїзи пришла загадкова й потайна Марія. Між дівчатами виникла приязнь, і вони стали подругами. А потім трапилося дещо, про що хотілося б ніколи не згадувати. Те, що зникло назавжди, померло разом з Марією… Та одного дня вже доросла Луїза отримує сповіщення: Марія Вестон хоче стати вашим другом на Facebook. Але цього не може бути. Бо Марії не може бути. Давно забуті жахіття та старанно приховані секрети. І та ніч у далекому минулому, що назавжди пов’язала двох дівчат моторошною таємницею. Якщо Марія жива, Луїза втратить усе: роботу, сина і навіть власне життя.    
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Сафарлі, Е. Дім, де жевріє світло / Ельчін Сафарлі; пер. А. Долгая . - Київ : Форс Україна, 2020. - 288 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image10} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					«...я бачу в цих словах істину — вони про справжню любов, про любов на все життя, про те, що така любов насправді є. І щоб її відшукати, не треба нікуди їхати, достатньо повернути ключ у замку: обіймати міцно-міцно, радіти тому, що стрічаєш у новому дні, й уміти віднаходити спокій. Немов сидиш на березі липневого моря із заплющеними очима, але з відкритим серцем. Коли ти зустрінеш і пізнаєш себе, то зустрінеш і пізнаєш того, з ким захочеться заспівати улюблених пісень і заварити не одне горнятко кави».
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Спаркс, Н.  Любий Джоне…: роман  / Ніколас Спаркс; пер. з англ. Ю. Максимейко. – Харків : Віват, 2019. - 304 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image11} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Джон завжди скептично ставився до свого життя й ніколи не сподівався, що з ним може статися щось особливе. Не маючи планів на майбутнє, він подається на військову службу. Хлопець не знає, чого ще прагнути, і без ентузіазму повертається у відпустку до рідного містечка, де на нього чекає лише завжди мовчазний і заглиблений у себе батько. Утім усе блискавично змінюється, коли одного вечора Джон знайомиться на пляжі з неймовірною дівчиною на ім’я Саванна. Кохання, у яке він не вірив, накочується потужною хвилею й перевертає увесь його світ. Але чи витримає воно випробування часом і відстанню?.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Тевіс, В. Хід королеви: роман  / Волтер Тевіс; пер. з англ. Е. Євтушенко.- Харків : Книжковий Клуб «Клуб Сімейного Дозвілля», 2021. - 352 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image12} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					1950-ті роки. Бет Гармон — звичайна сирота із незвичайним хистом до шахів. Гра — її єдина пристрасть. Доки Бет у грі, доти контролює себе та... згубну жагу до транквілізаторів і алкоголю. Дівчина відчайдушно бажає стати феноменальною шахісткою, але водночас усе дужче хоче втекти подалі від цієї реальності.
				</div>
				<div className={styles.divBookDescription}>
					Наближається вирішальний шаховий двобій із чемпіоном світу, російським гросмейстером Василієм Борґовим. Одна хибна комбінація може зіпсувати всю гру, а неправильний хід — зламати майбутнє. Бет укотре втрачає контроль над собою. Але цього разу заради перемоги вона не шкодуватиме ні шахових фігур, ні живих людей.
				</div>
			</div>
		</div>
		
	</div>
</div>
		
);
}

export default B208;
