import React, { Component } from 'react';

import Spinner from '../../components/Spinner';

import { STATE_INIT, STATE_READY } from './MainPage';
import { getBookImageUrl } from '../../util/tools';
import { BOOK_DESCRIPTION_PATH } from '../../util/routes';

import bgImage from '../../assets/img/mainpage/library_recommends_bg.png';

const TAB_INT_BOOKS = 'int';
const TAB_NEW_BOOKS = 'new';

class Recommendations extends Component {
	
	constructor(props) {
		super(props);
		this.mainPage = props.holder;
		this.state = {
			activeTab: TAB_INT_BOOKS
		}
	}

	setActiveTabInt = () => {
		this.setState({activeTab: TAB_INT_BOOKS});
	}
	setActiveTabNew = () => {
		this.setState({activeTab: TAB_NEW_BOOKS});
	}
	
	render() {
		if( this.mainPage.state.formState == STATE_INIT ) {
			return (
				<div style={localStyles.divMain}>
					<div style={{width: '100%', paddingTop: '50px'}}>
						<Spinner />
					</div>
				</div>
			);
		}
		return (
			<div style={localStyles.divMain}>
				{ this.state.activeTab == TAB_INT_BOOKS ?  
					<InterestingBooks holder={this} /> :
					<NewBooks holder={this} />
				}
			</div>
		)
	}
	
}

function InterestingBooks(props) {
	var holder=props.holder;
	var bookListHtml = holder.mainPage.pageData.intBookList.map( (book, index) => {
		return (<BookImage key={index} holder={holder} book={book} />);
	} );

	return (
		<div>
			<div style={{display: 'flex', width: '100%', paddingBottom: '10px'}}>
				<div style={localStyles.divActiveTabHeader}>Цікаве на полицях</div>
				<div style={localStyles.divPassiveTabHeader} onClick={holder.setActiveTabNew}>Нові надходження</div>
				<div style={localStyles.divMainHeader}>Бібліотека рекомендує</div>
			</div>
			<div style={{display: 'flex', width: '100%', marginBottoms: '10px'}}>
				{bookListHtml}
			</div>
		</div>
	);
}
function NewBooks(props) {
	var holder=props.holder;
	var bookListHtml = holder.mainPage.pageData.newBookList.map( (book, index) => {
		return (<BookImage key={index} holder={holder} book={book} />);
	} );

	return (
		<div>
			<div style={{display: 'flex', width: '100%', paddingBottom: '10px'}}>
				<div style={localStyles.divPassiveTabHeader} onClick={holder.setActiveTabInt}>Цікаве на полицях</div>
				<div style={localStyles.divActiveTabHeader}>Нові надходження</div>
				<div style={localStyles.divMainHeader}>Бібліотека рекомендує</div>
			</div>
			<div style={{display: 'flex', width: '100%', marginBottoms: '10px'}}>
				{bookListHtml}
			</div>
		</div>
	);
}

function BookImage(props) {
	var book = props.book
	var imgUrl = getBookImageUrl(book.bookId);
	var path = BOOK_DESCRIPTION_PATH + '/' + book.bookId;
	return (
		<div style={{width: '210px'}}>
			<a href={path}>
				<img src={imgUrl} width="150" height="220" />
			</a>
		</div>
	);
}

const localStyles = {
	divMain: {
		width: '1050px',
		margin: 'auto',
		paddingTop: '10px',
		marginBottom: '10px',
		paddingLeft: '50px',
		backgroundColor: 'white',
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left bottom'
	},
	divActiveTabHeader: {
		width: '200px',
		fontSize: '13pt',
		fontFamily: 'impact',
		fontWeight: 'normal',
		textDecoration: 'underline',
		color: '#430045'
	},
	divPassiveTabHeader: {
		width: '200px',
		fontSize: '13pt',
		fontFamily: 'impact',
		fontWeight: 'normal',
		cursor: 'pointer'
	},
	divMainHeader: {
		flexGrow: '100',
		textAlign: 'right',
		paddingRight: '15px', 
		fontSize: '15pt',
		fontFamily: 'impact',
		fontWeight: 'normal',
		color: '#4f4f4f'
	}
};

export default Recommendations;
