import React, { Component } from 'react';
import styles from './components.module.css';

import Pagination from './Pagination';

import { getEventImageUrl } from '../util/tools';

const PAGE_SIZE = 5;

class Gallery extends Component {

	constructor(props) {
		super(props);

		this.images = props.images; 
		this.pages = [];
		if( this.images.length > 0 ) {
			var pageNum = 1;
			var page = null;
			for(let i=0; i < this.images.length; i++) {
				if( i % PAGE_SIZE == 0 ) {
					if( page != null )
						this.pages.push( page );
					page = {pageNum: pageNum++, pageImages: []};
				}
				page.pageImages.push( i );
			}
			if( page.pageImages.length < PAGE_SIZE ) {
				let i = this.images.length - page.pageImages.length - 1;
				while( page.pageImages.length < PAGE_SIZE )
					page.pageImages.unshift(i--);
			}
			this.pages.push( page );
		}
		
		this.state = {
			pageCurrent: 1,
			imageCurrent: 0
		}
	}

	onPageClick = (pageIndex) => {
		this.setState({pageCurrent: pageIndex, imageCurrent: this.pages[pageIndex-1].pageImages[0]});
	}
	goBack = () => {
		if( this.state.pageCurrent > 1 )
			this.onPageClick( this.state.pageCurrent - 1 );
	}
	goForward = () => {
		if( this.state.pageCurrent < this.pages.length )
			this.onPageClick( this.state.pageCurrent + 1 );
	}
	setActiveImage = (imageIndex) => {
		this.setState({imageCurrent: imageIndex});
	}
	
	render() {
		
		if( this.images.length == 0 ) {
			return (null);
		}
		
		if( this.images.length <= PAGE_SIZE ) {
			var images = this.images.map( (img, index) => {
				return (
					<Image key={index} holder={this} imageIndex={index} showAll={true}/>
				);
			} );
			return (
				<div style={{marginTop: '30px'}}>
					{images}
				</div>
			);
		}
		
		var pages = this.pages.map( (p, index) => {
			return (
				<Page key={index} holder={this} page={p} />
			);
		});
		var images = this.images.map( (img, index) => {
			return (
				<Image key={index} holder={this} imageIndex={index} />
			);
		});
		var backVisibility = ( this.state.pageCurrent > 1 ) ? '' : 'hidden';
		var forwardVisibility = ( this.state.pageCurrent < this.pages.length ) ? '' : 'hidden';
		return ( 
			<div>
				<div style={{display: 'flex', width: '100%', marginBottom: '10px'}}>
					<div style={{marginTop: '5px', marginLeft: '5px', visibility: backVisibility}}>
						<span className={styles.spanGalleryAction} onClick={this.goBack}>
							&lt;&lt; Назад
						</span>
					</div>
					<div style={{flexGrow: 100}}>&nbsp;</div>
					<div>
						<Pagination skipTitle={true} pageCount={this.pages.length} pageNumber={this.state.pageCurrent} onPageClick={this.onPageClick} />
					</div>
					<div style={{flexGrow: 100}}>&nbsp;</div>
					<div style={{marginTop: '5px', marginRight: '5px', visibility: forwardVisibility}}>
						<span className={styles.spanGalleryAction} onClick={this.goForward}>
							Вперед &gt;&gt;
						</span>
					</div>
				</div>
				{pages}
				{images}
			</div>
		);
	}
}

function Page(props) {
	var holder = props.holder;
	var images = props.page.pageImages.map( (imgIndex, index) => {
		var image = holder.images[ imgIndex ];
		var urlImage = getImageUrl( image, holder.props.imageUrlPrefix );
		var border = ( imgIndex == holder.state.imageCurrent ) ? '2px solid blue' : '';
		return(
			<div key={index} style={{width: 'calc(20% - 10px)', marginLeft: '5px', marginRight: '5px', marginBottom: '10px'}}>
				<img src={urlImage} style={{width: '100%', border: border}} height="100" onClick={ () => holder.setActiveImage(imgIndex) } />
			</div>
		)
	});

	var display = (props.page.pageNum == holder.state.pageCurrent) ? 'flex' : 'none';
	return (
		<div style={{display: display}}>
			{images}
		</div>
	);
}

function Image(props) {
	var holder = props.holder;
	var img = holder.images[ props.imageIndex ];
	var urlImage = getImageUrl( img, holder.props.imageUrlPrefix );
	if( props.showAll )
		var display = 'block';
	else
		var display = (props.imageIndex == holder.state.imageCurrent) ? 'block' : 'none';
	return (
		<div style={{display: display, width:'700px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '15px'}}>
			<img src={urlImage} width='700' />
		</div>
	);
}

function getImageUrl(image, imageUrlPrefix) {
	if( image.url ) {
		return imageUrlPrefix + image.url;
	} else {
		return getEventImageUrl( image.imageId );
	}
}

export default Gallery;
