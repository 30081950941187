import React, { Component } from 'react';
import MessageModal from './MessageModal';

class Message extends Component {

	constructor(props) {
		super(props);
		
		this.message = '';
		this.state = {
			isModalMessageOpen: false
		}
		
		props.messageHandler.showMessage = this.showMessage; 
	}

	openModalMessage = () => {
		this.setState({ isModalMessageOpen: true });	
	}
	closeModalMessage = () => {
		this.setState({ isModalMessageOpen: false }, this.props.messageHandler.doAfterClose);	
		
	}
	showMessage = msg => {
		this.message = msg;
		this.openModalMessage();
	}
	getMessageText = () => {
		return this.message;
	}
	confirm = () => {
		this.props.messageHandler.confirm = true;
	}

	render() {
		return (
			<MessageModal 
				isModalOpen={this.state.isModalMessageOpen} 
				close={this.closeModalMessage} 
				message={this.getMessageText} 
				confirm={this.confirm}
			/>			
		)
	}
}

export default Message;
