import config from '../config/config';
import {
	EVENT_PATH, 
	EXHIBITION_PATH, 
	POSTER_PATH, 
	ADMIN_EVENT_PATH, 
	ADMIN_EXHIBITION_PATH, 
	ADMIN_POSTER_PATH,
	ADMIN_BOOK_PATH,
	ADMIN_BOOK_INTERESTING_PATH,
	ADMIN_BOOK_NEW_PATH,
	ADMIN_CLUB_PATH,
} from './routes';
import {LIST_LIBRARY} from './consts';

const KEY_USER_INFO = 'userInfo';

export const getDefinedValue = (value, defaultValue) => {
	if( value == null || value === undefined )
		value = defaultValue;
	return value;
}

export const getUserInfo = () => {
	try {
		var userInfo = JSON.parse( localStorage.getItem( KEY_USER_INFO ) );
		userInfo.registerDate = new Date(userInfo.registerDate);
		var today = new Date();
		var validDate = true;
		validDate = validDate && today.getFullYear() == userInfo.registerDate.getFullYear(); 
		validDate = validDate && today.getMonth() == userInfo.registerDate.getMonth(); 
		validDate = validDate && today.getDate() == userInfo.registerDate.getDate(); 
		if( ! validDate ) {
			clearUserInfo();
			userInfo = null;
		}
	} catch (err) {
		var userInfo = null;
	}
	return userInfo;
};

export const clearUserInfo = userInfo => {
  localStorage.removeItem(KEY_USER_INFO);
};

export const setUserInfo = (userInfo) => {
	userInfo.registerDate = new Date();
	localStorage.setItem(KEY_USER_INFO, JSON.stringify( userInfo ) );
};

export const getLibrary = (libraryId) => {
	for( let lib of LIST_LIBRARY ) {
		if( lib.cipher == libraryId )
			return lib;
	}
	return {cipher: libraryId, title: ''};
}

// yyyy-mm-dd => yyyymmdd
export const dateInputFormatToRegularFormat = (d) => {
	if( d == undefined || d == null || d.length < 10 )
		return '';
	let year = d.substring(0,4);
	let month = d.substring(5,7);
	let day = d.substring(8,10);
	return year + month + day;
}
// yyyymmdd => yyyy-mm-dd
export const dateRegularFormatToInputFormat = (d) => {
	if( d == undefined || d == null || d.length < 8 )
		return '';
	let year = d.substring(0,4);
	let month = d.substring(4,6);
	let day = d.substring(6,8);
	return year + '-' + month + '-' + day;
}
// yyyymmdd => dd.mm.yyyy
export const dateRegularFormatToShowFormat = (d) => {
	if( d == undefined || d == null || d.length < 8 )
		return '';
	let year = d.substring(0,4);
	let month = d.substring(4,6);
	let day = d.substring(6,8);
	return day + '.' + month + '.' + year;
}
// yyyy-mm-dd => dd.mm.yyyy
export const dateInputFormatToShowFormat = (d) => {
	if( d == undefined || d == null || d.length < 8 )
		return '';
	return dateRegularFormatToShowFormat( dateInputFormatToRegularFormat(d) );	
}
export const formatDate = (d) => {
	if( d == null || d == undefined )
		return '';
	return d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();
}

export const getEventUrl = (eventId) => {
	return EVENT_PATH + '/' + eventId;
}
export const getEventImageUrl = (imageId) => {
	return config.apiGateway.URL + 'image.php?mode=event&id=' + imageId;
}
export const getExhibitionUrl = (eventId) => {
	return EXHIBITION_PATH + '/' + eventId;
}
export const getPosterUrl = (posterId) => {
	return POSTER_PATH + '/' + posterId;
}
export const getAdminEventUrl = (eventId) => {
	return ADMIN_EVENT_PATH + '/' + eventId;
}
export const getAdminExhibitionUrl = (eventId) => {
	return ADMIN_EXHIBITION_PATH + '/' + eventId;
}
export const getAdminPosterUrl = (posterId) => {
	return ADMIN_POSTER_PATH + '/' + posterId;
}
export const getAdminBookUrl = (bookId) => {
	return ADMIN_BOOK_PATH + '/' + bookId;
}
export const getAdminBookInterestingUrl = (bookId) => {
	return ADMIN_BOOK_INTERESTING_PATH + '/' + bookId;
}
export const getAdminClubUrl = (bookId) => {
	return ADMIN_CLUB_PATH + '/' + bookId;
}
export const getAdminBookNewUrl = (bookId) => {
	return ADMIN_BOOK_NEW_PATH + '/' + bookId;
}
export const getBookImageUrl = (bookId) => {
	return config.apiGateway.URL + 'image.php?mode=book&id=' + bookId;
}
export const getClubImageUrl = (clubId) => {
	return config.apiGateway.URL + 'image.php?mode=club&id=' + clubId;
}
