import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Gallery from "../../../components/Gallery";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIBCRB_INFO_PATH, LIBCRB_GALLERY_PATH, } from '../../../util/routes';
import { LIBCRB } from '../../../util/consts';

class LibCrbGalleryPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBCRB.title, url: LIBCRB_INFO_PATH, selected: false },
			{title: 'Фотогалерея', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBCRB_GALLERY_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBCRB_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var imageList = [
		{ url: '/crb/dsc01599.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/p20111102.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/p2189395.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/p2189402.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/pb139343.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/pb139353.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/pb139354.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/002.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/003.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/004.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/005.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/006.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/007.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/008.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/009.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/010.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/011.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/012.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/013.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/crb/014.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
	];
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBCRB.title}<br/>
				Фотогалерея
			</div>
			<Gallery imageUrlPrefix={'/img/'} images={imageList} />
		</div>
	);
}

export default LibCrbGalleryPage;
