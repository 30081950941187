import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import { getEventImageUrl } from "../../../util/tools";

import iconMoveUp from '../../../assets/img/arrow_up.png';
import iconMoveDown from '../../../assets/img/arrow_down.png';
import iconLogo from '../../../assets/img/pechersk_logo_small.png';
import iconDelete from '../../../assets/img/sign_cross.png';

class EventImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imageTitle: props.image.imageTitle
		};
	}
	
	handleTitleChange = (e) => {
		var value = e.target.value;
		this.props.image.imageTitle = value;
		this.setState({imageTitle: value});
	}
	
	render() {
		var holder = this.props.holder;
		var image = this.props.image;
		var url = getEventImageUrl(image.imageId);
		return (
			<div>
				<div style={{display: 'flex'}}>
					<div style={{width: '200px'}}>
						<img src={url} height='100' />
					</div>
					<div>
						<div>
							<button 
								type='button' style={localStyles.buttonImageTools}
								title='Підняти зображення вверх'
								onClick={ () => {holder.moveImageUp(this.props.index) } }
								disabled={this.props.index==0}
							>
								<img src={iconMoveUp} />
							</button>
							<button 
								type='button' style={localStyles.buttonImageTools}
								title='Опустити зображення вниз'
								onClick={ () => {holder.moveImageDown(this.props.index) } }
								disabled={this.props.index==this.props.holder.imageList.length-1}
							>
								<img src={iconMoveDown} />
							</button>
							<button 
								type='button' style={localStyles.buttonImageTools}
								title='Зробити зображення логотипом'
								onClick={ () => {holder.setEventLogo(this.props.index) } }
							>
								<img src={iconLogo} />
							</button>
							<button 
								type='button' style={localStyles.buttonImageTools}
								title='Вилучити зображення'
								onClick={ () => {holder.deleteImage(this.props.index) } }
							>
								<img src={iconDelete} />
							</button>
						</div>
						<div style={{marginTop: '20px'}}>
							Підпис<br/>
							<input type='text' style={{width: '400px'}} 
								value={this.props.image.imageTitle} 
								onChange={this.handleTitleChange} 
							/>
						</div>
					</div>
				</div>
				<hr className={stylesPage.hr} />
			</div>
		);
	}
}

const localStyles = {
	buttonImageTools: {
		marginRight: '20px'
	}
}

export default EventImage;
