import React, { Component } from 'react';
import stylesAdmin from './admin.module.css';

import AdminHeader from "./components/AdminHeader";
import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Footer from "../../components/Footer";
import LeftSideMenu from "../../components/LeftSideMenu";

import { performApiRequest, LOGIN_API_URL } from "../../util/restapi"
import {getUserInfo, setUserInfo, clearUserInfo} from '../../util/tools';

import spinner from '../../assets/img/Spin124px.gif';

const STATE_LOGINED = 'logined';
const STATE_GET_LOGIN_DATA = 'get';
const STATE_WAIT_LOGIN_RESULT = 'wait';

class AdminMainPage extends Component {

	constructor(props) {
		super(props);
		this.userInfo = getUserInfo();
		var state = (this.userInfo == null) ? STATE_GET_LOGIN_DATA : STATE_LOGINED;
		
		this.state = {
			formState: state,
			user: '',
			password: '',
			message: ''
		};
	}
	
	onValueChange = (e) => {
		const {name,value} = e.target;
		if( name == 'user' )
			this.setState({user: value, message: ''});
		else if( name == 'password' )
			this.setState({password: value, message: ''});
	}
	tryLogin = async () => {
		this.setState({formState: STATE_WAIT_LOGIN_RESULT});
		var request = {
			username: this.state.user,
			password: this.state.password
		};
		var response = await performApiRequest(LOGIN_API_URL, request);
		console.log( response );
		if( response.ok ) {
			let userInfo = {username: this.state.user, registerDate: new Date(), userRole: response.userRole};
			setUserInfo( userInfo );
			this.setState({formState: STATE_LOGINED});
		} else {
			let msg = response.message;
			if( ! msg )
				msg = 'Помилка під час реєстрації';
			this.setState({formState: STATE_GET_LOGIN_DATA, message: msg});
		}
	}
	
	render() {
		var leftSide = (<AdminLeftSide path={null} />);
		if( this.state.formState == STATE_LOGINED ) {
			var body = (
				<div className={stylesAdmin.divContentHeader}>
					Адміністрування<br/>
					матеріалів сайту
				</div>
			);
			return(
				<AdminGeneralPage leftSide={leftSide} body={body} />
			);
		} else {
			return( <FormLogin holder={this} /> )
		}
	
	}
}

function FormLogin(props) {
	var holder = props.holder;
	if ( holder.state.formState == STATE_WAIT_LOGIN_RESULT )
		var divInfo = (
			<div style={localStyles.divMessage}>
				<img src={spinner} />
			</div>
		);
	else
		var divInfo = (
			<div style={localStyles.divMessage}>
				{holder.state.message}
			</div>
		);
	return (
		<div>
			<AdminHeader />
			<div style={{width: '300px', margin: 'auto', paddingTop: '10px', paddingBottom: '30px'}}>
				<div style={localStyles.divPageTitle}>Реєстрація в системі</div>
				<div style={{marginTop: '10px', width: '100%'}}>
					Користувач<br/>
					<input 
						type='text' name='user' value={holder.state.user} 
						style={{boxSizing: 'border-box', width: '100%'}} 
						onChange={holder.onValueChange} 
					/>
				</div>
				<div style={{marginTop: '10px', width: '100%'}}>
					Пароль<br/>
					<input 
						type='password' name='password' value={holder.state.password} 
						style={{boxSizing: 'border-box', width: '100%'}} 
						onChange={holder.onValueChange}
					/>
				</div>
				<div style={{marginTop: '10px', width: '100%', textAlign: 'right'}}>
					<button type='button' onClick={holder.tryLogin}>Зареєструватись</button>
				</div>
				{divInfo}
			</div>
			<Footer />
		</div>
	);
}

const localStyles = {
	divPageTitle: {
		textAlign: 'center',
		fontSize: '20pt',
		fontFamily: 'AkvitaniaModern',
		fontWeight: 'bold'
	},
	divMessage: {
		marginTop: '20px',
		textAlign: 'center',
		fontSize: '16pt',
		color: 'red',
		fontWeight: 'bold'
	}
}

export default AdminMainPage;
