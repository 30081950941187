import React, { Component } from 'react';

import spinner from '../assets/img/Spin124px.gif';

class Spinner extends Component {
	render() {
		return (
			<div style={{width: '100px', marginLeft: 'auto', marginRight: 'auto'}}>
				<img src={spinner} />
			</div>
		)
	}
}

export default Spinner;
