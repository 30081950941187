export const CBS = {cipher: 'cbs', title: 'ЦБС Печерського району', titleNbsp: 'ЦБС&nbsp;Печерського&nbsp;району'};

export const LIBCRB = {cipher: 'crb', title: 'ЦРБ "Печерська"', titleNbsp: 'ЦРБ&nbsp;"Печерська"'};
export const LIBVYSHNI = {cipher: 'libvishn', title: 'Бібліотека ім.О.Вишні', titleNbsp: 'Бібліотека&nbsp;ім.О.Вишні'};
export const LIBBILOKUR = {cipher: 'libkosh', title: 'Бібліотека ім.К.Білокур', titleNbsp: 'Бібліотека&nbsp;ім.К.Білокур'};
export const LIBKNYGOLYUB = {cipher: 'libkudri', title: 'Бібліотека "Книголюб"', titleNbsp: 'Бібліотека&nbsp;"Книголюб"'};
export const LIB149 = {cipher: 'lib149', title: 'Бібліотека №149', titleNbsp: 'Бібліотека&nbsp;№149'};
export const LIB150 = {cipher: 'lib150', title: 'Бібліотека №150', titleNbsp: 'Бібліотека&nbsp;№150'};
export const LIBVSESVIT = {cipher: 'librad', title: 'Бібліотека "Всесвіт"', titleNbsp: 'Бібліотека&nbsp;"Всесвіт"'};
export const LIBSVITOGLYAD = {cipher: 'libsvit', title: 'Бібліотека "Світогляд"', titleNbsp: 'Бібліотека&nbsp;"Світогляд"'};
export const LIBESTET = {cipher: 'libestet', title: 'Бібліотека естетичного виховання', titleNbsp: 'Бібліотека&nbsp;естетичного&nbsp;виховання'};

export const LIBVESELKA = {cipher: 'libveselka', title: 'Бібліотека "Веселка"', titleNbsp: 'Бібліотека&nbsp;"Веселка"'};
export const LIBTRUBLAINI = {cipher: 'libtrub', title: 'Бібліотека ім.М.Трублаїні', titleNbsp: 'Бібліотека&nbsp;ім.М.Трублаїні'};
export const LIB8 = {cipher: 'lib8', title: 'Бібліотека №8', titleNbsp: 'Бібліотека&nbsp;№8'};
export const LIBJERELO = {cipher: 'libmakar', title: 'Бібліотека "Джерело"', titleNbsp: 'Бібліотека&nbsp;"Джерело"'};

export const LIST_LIBRARY = [
	CBS,
	LIBCRB, LIBVYSHNI, LIBBILOKUR, LIBKNYGOLYUB, LIB149, LIB150, LIBVSESVIT, LIBSVITOGLYAD, LIBESTET, 
	LIBVESELKA, LIBTRUBLAINI, LIB8, LIBJERELO 
];

export const ROLE_ADMIN = 'admin';
export const ROLE_MANAGER = 'manager';
export const ROLE_LIB = 'lib';
export const ROLE_BIBLIO = 'biblio';

export const ANY = 'any'; 
export const ONLY_TRUE = 'only_true'; 
export const ONLY_FALSE = 'only_false';
