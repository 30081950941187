import React, { Component } from 'react';
import stylesPage from '../page.module.css';
import stylesSiteMap from './siteMap.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";

import {

	MAIN_PATH,
	EVENT_LIST_PATH,
	EXHIBITION_LIST_PATH,
	POSTER_LIST_PATH,
	CLUBS_PATH,
	BLOG_PATH,
	ECATALOG_PATH,
	ONLINE_PATH,
	BIBLIOGRAPHIC_PATH,
	SKS_PATH,
	KKS_PATH,
	BOOKS_NEW_PATH,
	BOOKS_INTERESTING_PATH,
	CONTACTS_PATH,
	SERVICES_PATH,
	RULES_PATH,
	ADMINISTRATION_PATH,
	SCHEDULE_PATH,

	LIBCRB_CONTACTS_PATH, LIBCRB_ECATALOG_PATH, LIBCRB_EVENTS_PATH, LIBCRB_GALLERY_PATH, LIBCRB_INFO_PATH, LIBCRB_SERVICES_PATH,
	LIB149_CONTACTS_PATH, LIB149_ECATALOG_PATH, LIB149_EVENTS_PATH, LIB149_GALLERY_PATH, LIB149_INFO_PATH,
	LIB150_CONTACTS_PATH, LIB150_ECATALOG_PATH, LIB150_EVENTS_PATH, LIB150_GALLERY_PATH, LIB150_INFO_PATH,
	LIB8_CONTACTS_PATH, LIB8_EVENTS_PATH, LIB8_GALLERY_PATH, LIB8_INFO_PATH,
	LIBBILOKUR_CONTACTS_PATH, LIBBILOKUR_ECATALOG_PATH, LIBBILOKUR_EVENTS_PATH, LIBBILOKUR_GALLERY_PATH, LIBBILOKUR_INFO_PATH,
	LIBESTET_CONTACTS_PATH, LIBESTET_ECATALOG_PATH, LIBESTET_EVENTS_PATH, LIBESTET_GALLERY_PATH, LIBESTET_INFO_PATH,
	LIBJERELO_CONTACTS_PATH, LIBJERELO_EVENTS_PATH, LIBJERELO_GALLERY_PATH, LIBJERELO_INFO_PATH,
	LIBKNYGOLYUB_CONTACTS_PATH, LIBKNYGOLYUB_ECATALOG_PATH, LIBKNYGOLYUB_EVENTS_PATH, LIBKNYGOLYUB_GALLERY_PATH, LIBKNYGOLYUB_INFO_PATH,
	LIBSVITOGLYAD_CONTACTS_PATH, LIBSVITOGLYAD_ECATALOG_PATH, LIBSVITOGLYAD_EVENTS_PATH, LIBSVITOGLYAD_GALLERY_PATH, LIBSVITOGLYAD_INFO_PATH,
	LIBTRUBLAINI_CONTACTS_PATH, LIBTRUBLAINI_EVENTS_PATH, LIBTRUBLAINI_GALLERY_PATH, LIBTRUBLAINI_INFO_PATH,
	LIBVESELKA_CONTACTS_PATH, LIBVESELKA_EVENTS_PATH, LIBVESELKA_GALLERY_PATH, LIBVESELKA_INFO_PATH,
	LIBVSESVIT_CONTACTS_PATH, LIBVSESVIT_ECATALOG_PATH, LIBVSESVIT_EVENTS_PATH, LIBVSESVIT_GALLERY_PATH, LIBVSESVIT_INFO_PATH,
	LIBVYSHNI_CONTACTS_PATH, LIBVYSHNI_ECATALOG_PATH, LIBVYSHNI_EVENTS_PATH, LIBVYSHNI_GALLERY_PATH, LIBVYSHNI_INFO_PATH,
	
} from '../../util/routes';

class SiteMapPage extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Карта сайту', url: '', selected: true }
		];
		var menu = [
			{ title: 'Наші послуги', url: SERVICES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Правила користування', url: RULES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Карта сайту', url: '', isGroupHeader: false, isActive: true },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} menu={menu} body={body} />);
	}
	
}

function Body(props) {
	return (
		<div style={{width: '100%', paddingLeft: '50px'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>Карта сайта</div>
			<div className={stylesSiteMap.divInterimNode}>ЦБС Печерського району</div>
			<div className={stylesSiteMap.divSubnodes}>
				<div className={stylesSiteMap.divInterimNode}>Бібліотеки для дорослих</div>
				<div className={stylesSiteMap.divSubnodes}>
					<div className={stylesSiteMap.divInterimNode}>ЦРБ "Печерська"</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBCRB_INFO_PATH}>Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBCRB_EVENTS_PATH}>Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBCRB_ECATALOG_PATH}>Електронний каталог</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBCRB_GALLERY_PATH}>Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBCRB_SERVICES_PATH}>Наші послуги</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBCRB_CONTACTS_PATH}>Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека ім.О.Вишні</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVYSHNI_INFO_PATH}>Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVYSHNI_EVENTS_PATH}>Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVYSHNI_ECATALOG_PATH}>Електронний каталог</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVYSHNI_GALLERY_PATH}>Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVYSHNI_CONTACTS_PATH}>Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека ім.К.Білокур</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBBILOKUR_INFO_PATH}>Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBBILOKUR_EVENTS_PATH}>Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBBILOKUR_ECATALOG_PATH}>Електронний каталог</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBBILOKUR_GALLERY_PATH}>Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBBILOKUR_CONTACTS_PATH}>Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека "Книголюб"</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBKNYGOLYUB_INFO_PATH}>Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBKNYGOLYUB_EVENTS_PATH}>Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBKNYGOLYUB_ECATALOG_PATH}>Електронний каталог</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBKNYGOLYUB_GALLERY_PATH}>Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBKNYGOLYUB_CONTACTS_PATH}>Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека "Всесвіт"</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVSESVIT_INFO_PATH}>Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVSESVIT_EVENTS_PATH}>Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVSESVIT_ECATALOG_PATH}>Електронний каталог</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVSESVIT_GALLERY_PATH}>Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBVSESVIT_CONTACTS_PATH}>Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека "Світогляд"</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBSVITOGLYAD_INFO_PATH}>Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBSVITOGLYAD_EVENTS_PATH}>Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBSVITOGLYAD_ECATALOG_PATH}>Електронний каталог</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBSVITOGLYAD_GALLERY_PATH}>Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBSVITOGLYAD_CONTACTS_PATH}>Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека естетичного виховання</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBESTET_INFO_PATH}>Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBESTET_EVENTS_PATH}>Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBESTET_ECATALOG_PATH}>Електронний каталог</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBESTET_GALLERY_PATH}>Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIBESTET_CONTACTS_PATH}>Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека №149</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB149_INFO_PATH}>Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB149_EVENTS_PATH}>Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB149_ECATALOG_PATH}>Електронний каталог</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB149_GALLERY_PATH}>Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB149_CONTACTS_PATH}>Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека №150</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB150_INFO_PATH}>Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB150_EVENTS_PATH}>Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB150_ECATALOG_PATH}>Електронний каталог</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB150_GALLERY_PATH}>Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href={LIB150_CONTACTS_PATH}>Координати</a>
						</div>
					</div>
				</div>
				<div className={stylesSiteMap.divInterimNode}>Бібліотеки для дітей</div>
				<div className={stylesSiteMap.divSubnodes}>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека "Джерело"</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBJERELO_INFO_PATH">Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBJERELO_EVENTS_PATH">Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBJERELO_GALLERY_PATH">Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBJERELO_CONTACTS_PATH">Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека ім.М.Трублаїні</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBTRUBLAINI_INFO_PATH">Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBTRUBLAINI_EVENTS_PATH">Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBTRUBLAINI_GALLERY_PATH">Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBTRUBLAINI_CONTACTS_PATH">Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека "Веселка"</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBVESELKA_INFO_PATH">Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBVESELKA_EVENTS_PATH">Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBVESELKA_GALLERY_PATH">Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIBVESELKA_CONTACTS_PATH">Координати</a>
						</div>
					</div>
					<div className={stylesSiteMap.divInterimNode}>Бібліотека №8</div>
					<div className={stylesSiteMap.divSubnodes}>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIB8_INFO_PATH">Загальна інформація</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIB8_EVENTS_PATH">Новини та події</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIB8_GALLERY_PATH">Фотогалерея</a>
						</div>
						<div className={stylesSiteMap.divActiveNode}>
							<a href="LIB8_CONTACTS_PATH">Координати</a>
						</div>
					</div>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={SCHEDULE_PATH}>Графік роботи</a>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={EVENT_LIST_PATH}>Новини та події</a>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={EXHIBITION_LIST_PATH}>Віртуальні виставки</a>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={POSTER_LIST_PATH}>Афіша</a>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={CLUBS_PATH}>Клуби та гуртки</a>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={BLOG_PATH}>Блог</a>
				</div>
				<div className={stylesSiteMap.divInterimNode}>Електронний каталог</div>
				<div className={stylesSiteMap.divSubnodes}>
					<div className={stylesSiteMap.divActiveNode}>
						<a href={ECATALOG_PATH}>Електронний каталог ЦБС</a>
					</div>
					<div className={stylesSiteMap.divActiveNode}>
						<a href={SKS_PATH}>Систематична картотека статей</a>
					</div>
					<div className={stylesSiteMap.divActiveNode}>
						<a href={KKS_PATH}>Краєзнавча картотека статей</a>
					</div>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={ONLINE_PATH}>On-line довідка</a>
				</div>
				<div className={stylesSiteMap.divInterimNode}>Мандрівка у світ бібліографії</div>
				<div className={stylesSiteMap.divSubnodes}>
					<div className={stylesSiteMap.divActiveNode}>
						<a href={BIBLIOGRAPHIC_PATH}>Бібліографічні видання</a>
					</div>
					<div className={stylesSiteMap.divActiveNode}>
						<a href={SKS_PATH}>Систематична картотека статей</a>
					</div>
					<div className={stylesSiteMap.divActiveNode}>
						<a href={KKS_PATH}>Краєзнавча картотека статей</a>
					</div>
				</div>
				<div className={stylesSiteMap.divInterimNode}>Бібліотека пропонує</div>
				<div className={stylesSiteMap.divSubnodes}>
					<div className={stylesSiteMap.divActiveNode}>
						<a href={BOOKS_NEW_PATH}>Нові надходження</a>
					</div>
					<div className={stylesSiteMap.divActiveNode}>
						<a href={BOOKS_INTERESTING_PATH}>Цікаве на полицях</a>
					</div>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={SERVICES_PATH}>Наші послуги</a>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={RULES_PATH}>Правила користування</a>
				</div>
				<div className={stylesSiteMap.divActiveNode}>
					<a href={ADMINISTRATION_PATH}>Адміністрація</a>
				</div>
			</div>
		</div>
	);
}

export default SiteMapPage;
