import React, { Component } from 'react';

import AdminHeader from "./AdminHeader";
import Footer from "../../../components/Footer";
import LeftSideMenu from "../../../components/LeftSideMenu";

class GeneralPage extends Component {
	
	/*
		leftSide
		body
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<div>
				<AdminHeader />
				<div style={stylesLocal.divPageGeneral}>
					<div style={stylesLocal.divPageBody}>
						<div style={{width: '100%', display: 'flex'}} >
							<div style={{width: '220px'}} >
								{this.props.leftSide}
							</div>
							<div style={{width: '880px', paddingLeft: '20px', paddingRight: '20px'}} >
								{this.props.body}
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

const stylesLocal = {
	divPageGeneral: {
		width: '100%',
		backgroundColor: '#efefef'
	},
	divPageBody: {
		borderTop: '15px solid #efefef',
		width: '1100px',
		backgroundColor: 'white',
		margin: 'auto'
	},
	divPageHeader: {
		width: '100%',
		textAlign: 'center',
		fontSize: '20pt',
		fontFamily: 'AkvitaniaModern',
		fontWeight: 'bold'
	}
}

export default GeneralPage;
