import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import SpanInnerHtml from '../../components/SpanInnerHtml';
import DivInnerHtml from '../../components/DivInnerHtml';
import Spinner from '../../components/Spinner';

import { performApiRequest, POSTER_LOAD_API_URL } from "../../util/restapi"

import {
	MAIN_PATH,
	EVENT_LIST_PATH,
	EXHIBITION_LIST_PATH,
	POSTER_LIST_PATH,
} from '../../util/routes';

const STATE_INIT = 'init';
const STATE_READY = 'ready';

class PosterPage extends Component {
	
	constructor(props) {
		super(props);
		this.posterId = this.props.match.params.id
		this.breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Афіша', url: POSTER_LIST_PATH, selected: false }
		];
		this.state = {
			formState: STATE_INIT
		};
	}
	componentDidMount = async () => {
		var request = {
			posterId: this.posterId
		};
		var response = await performApiRequest(POSTER_LOAD_API_URL, request);
		this.pageData = response;
		var poster = this.pageData.poster;
		if( poster != null )
			this.breadCrumbs.push( {title: this.pageData.poster.title, url: '', selected: true } );
		this.setState({formState: STATE_READY});
	}
	
	render() {
		var menu = [
			{ title: 'Новини та події', url: EVENT_LIST_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Віртуальні виставки', url: EXHIBITION_LIST_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Афіша', url: POSTER_LIST_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={this.breadCrumbs} menu={menu} body={body} />);
	}
	
}

function Body(props) {

	if( props.holder.state.formState == STATE_INIT ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				<Spinner />
			</div>
		);
	}
	var poster = props.holder.pageData.poster;
	if( poster == null ) {
		return (
			<div style={{width:'100%', marginTop: '30px', marginBottom: '30px', textAlign: 'center'}}>
				Вибачте, інформація відсутня
			</div>
		);
	}
	return (
		<div style={{width: 'calc(100% - 100px)', paddingLeft: '50px'}}>
			<div style={{marginBottom: '15px', textAlign: 'center', fontSize: '14pt', fontWeight: 'bold'}}>
				{poster.title}
			</div>
			<DivInnerHtml text={poster.description} />
		</div>
	);
}

export default PosterPage;
