import React, { Component } from 'react';

import Header from "./Header";
import Footer from "./Footer";
import BreadCrumbs from "./BreadCrumbs";
import LeftSideMenu from "./LeftSideMenu";

class GeneralPage extends Component {
	
	/*
		breadCrumbs 
		leftSide
		activeMenuPath
		menu
		body
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<div>
				<Header activeMenuPath={this.props.activeMenuPath} />
				<div style={stylesLocal.divPageGeneral}>
					<div style={stylesLocal.divPageBody}>
						<BreadCrumbs breadCrumbs={this.props.breadCrumbs} />
						<div style={{width: '100%', display: 'flex'}} >
							<div style={{width: '220px'}} >
								<LeftSide holder={this} />
							</div>
							<div style={{width: '880px'}} >
								{this.props.body}
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

function LeftSide(props) {
	var holder = props.holder;
	if( holder.props.leftSide )
		return (holder.props.leftSide);
	else if( holder.props.menu )
		return (<LeftSideMenu menu={holder.props.menu} />);
	else
		return (null);
}

const stylesLocal = {
	divPageGeneral: {
		width: '100%',
		backgroundColor: '#efefef'
	},
	divPageBody: {
		borderTop: '15px solid #efefef',
		width: '1100px',
		backgroundColor: 'white',
		margin: 'auto'
	},
	divPageHeader: {
		width: '100%',
		textAlign: 'center',
		fontSize: '20pt',
		fontFamily: 'AkvitaniaModern',
		fontWeight: 'bold'
	}
}

export default GeneralPage;
