import React, { Component } from 'react';

import SpanInnerHtml from '../../components/SpanInnerHtml';
import DivInnerHtml from '../../components/DivInnerHtml';

import iconBullet from  '../../assets/img/mainpage/icon_li_bullet.png';

class ImportantInfo extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var listItemsHtml = null;
		var pageData = this.props.holder.pageData;
		if( pageData != null ) {
			var info = pageData.importantInfo;
			listItemsHtml = info.map( (item, index) => {
				if( ! item.text )
					return null;
				return <li key={index} style={{paddingTop: '10px'}}><SpanInnerHtml text={item.text} /></li>;
			} );
		}
		// Summary width must be 550px
		return (
			<div style={{width: '520px', height: '300px', overflow: 'hidden', marginLeft: '10px', marginRight: '20px'}}>
				<div style={{display: 'flex', marginTop: '5px'}}>
					<div style={{width: '350px'}}>
						<hr style={{border: '2px solid #430045'}}/>
					</div>
					<div style={{width: '250px', textAlign: 'right', fontFamily: 'impact', fontWeight: 'normal', fontSize: '15pt', color: '#430045'}}>
						Важлива інформація
					</div>
				</div>
				<ul style={localStyles.ulImportantInfo}>
					{listItemsHtml}
				</ul>
			</div>
		);
	}
}

const localStyles = {
	ulImportantInfo: {
		margin: '0',
		paddingLeft: '5px',
		textAlign: 'justify',
		fontFamily: 'Arial, sans-serif',
		fontSize: '11pt',
		listStyleType: 'none',
		listStylePosition: 'inside',
		listStyleImage: `url(${iconBullet})`
	}
}

export default ImportantInfo;
