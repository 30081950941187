import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";

import {
	MAIN_PATH,
	SERVICES_PATH,
	RULES_PATH,
	ADMINISTRATION_PATH,
} from '../../util/routes';

class ServicesPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Наші послуги', url: '', selected: true }
		];
		var menu = [
			{ title: 'Наші послуги', url: SERVICES_PATH, isGroupHeader: false, isActive: true },
			{ title: 'Правила користування', url: RULES_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Адміністрація', url: ADMINISTRATION_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={SERVICES_PATH} menu={menu} body={body} />);
	}
}

function Body(props) {

	return(
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				До ваших послуг
			</div>

			<div style={localStyles.divBlockHeader}>
				Користувачі бібліотеки мають право безкоштовно:
			</div>
			<ul>
				<li>отримати інформацію про наявність у бібліотеці книг, періодичних видань та інших документів;</li>
				<li>отримати будь-який документ з фонду бібліотеки для подальшого користування у читальних залах або з фонду абонементу для опрацювання поза бібліотекою;</li>
				<li>отримати консультацію та допомогу фахівців бібліотеки в пошуку необхідної інформації;</li>
				<li>отримати інформацію з інших бібліотек, скориставшись каналами зв’язку;</li>
				<li>замовити потрібний документ з інших бібліотек України (МБА);</li>
				<li>відвідувати різноманітні культурні заходи, що проводяться у бібліотеці;</li>
			</ul>

			<div style={localStyles.divBlockHeader}>
				До послуг користувачів:
			</div>
			<ul>
				<li>книги, періодичні видання, CD-диски та інші паперові та електронні документи з усіх галузей знань;</li>
				<li>комфортабельні читальні зали;</li>
				<li>постійно діючі виставки, присвячені новим надходженням та визначним датам і подіям;</li>
				<li>додаткові послуги – карткові каталоги, картотеки та інше;</li>
			</ul>

			<div style={localStyles.divBlockHeader}>
				Розраховуйте на:
			</div>
			<ul>
				<li>ввічливе обходження;</li>
				<li>допомогу бібліотекаря;</li>
				<li>підтримку в реалізації своїх творчих планів;</li>
				<li>те, що співробітники бібліотеки допоможуть вам в повній мірі скористатися можливостями бібліотечної системи;</li>
			</ul>
		</div>
	);
}

const localStyles = {
	divBlockHeader: {
		fontSize: '14pt',
		fontWeight: 'bold',
		fontStyle: 'italic'
	}
}

export default ServicesPage;
