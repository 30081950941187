import React from 'react';

import iconAddress from '../assets/img/footer/icon_address.png';
import iconPhone from '../assets/img/footer/icon_phone.png';
import iconLetter from '../assets/img/footer/icon_letter.png';
import iconFacebook from '../assets/img/footer/icon_facebook.png';

import {
	SITEMAP_PATH
} from '../util/routes';

class Footer extends React.Component {
	
	render() {
		return (
			<div style={localStyles.divFooterMain}>
				<div style={localStyles.divFooterBody}>
					<div style={{width: '250px', marginLeft: '40px'}}>
						<div className={localStyles.divFooterBlockTitle}>Контакти</div>
						<div>
							<img src={iconAddress} />
							<span style={localStyles.spanFooterContacts}>м.Київ, бул. Л. Українки</span>
						</div>
						<div>
							<img src={iconPhone} />
							<span style={localStyles.spanFooterContacts}>(044) 234-25-22</span>
						</div>
						<div>
							<img src={iconLetter} />
							<span style={localStyles.spanFooterContacts}>2351667@ukr.net</span>
						</div>
					</div>
					<div style={{width: '300px'}}>
						<div style={localStyles.divFooterBlockTitle}>Ми в соціальних мережах</div>
						<a href="#"><img src={iconFacebook} /></a>
					</div>
					<div style={{width: '300px'}}>
						<div style={localStyles.divFooterBlockTitle}>КОРИСТУВАЧАМ</div>
						<a href="#" style={localStyles.aFooterForUser}>Правила користування сайтом</a><br/>
						<a href="#" style={localStyles.aFooterForUser}>Політика конфідейційності</a><br/>
						<a href={SITEMAP_PATH} style={localStyles.aFooterForUser}>Карта сайту</a>
					</div>
					<div style={{width: '250px'}}>
						<div style={localStyles.divFooterBlockTitle}>СТАТИСТИКА</div>
						<div style={{marginTop: '1px'}}>
							<Counter_I_UA />
						</div>
					</div>
				</div>
				<div style={localStyles.divFooterCopyright}>
					<div style={{width: '1100px', margin: 'auto', display: 'flex'}}>
						<div style={{width: '50%'}}>
							Всі права захищені @2004-2022 | ЦБС Печерського району
						</div>
						<div style={{width: '50%'}}>
							При використанні матеріалів посилання на наш сайт обов'язкове.
						</div>
					</div>
				</div>
				
			</div>
		)
	}
}

function Counter_I_UA(props) {
/*
	<script type="text/javascript"><!--
		iS='http'+(window.location.protocol=='https:'?'s':'')+'://r.i.ua/s?u236620&p23&n'+Math.random();
		iD=document;
		if(!iD.cookie)
			iD.cookie="b=b; path=/";
		if(iD.cookie)
			iS+='&c1';
		iS+='&d'+(screen.colorDepth?screen.colorDepth:screen.pixelDepth)+"&w"+screen.width+'&h'+screen.height;
		iT=iR=iD.referrer.replace(iP=/^[a-z]*:\/\//,'');
		iH=window.location.href.replace(iP,'');
		((iI=iT.indexOf('/'))!=-1)?(iT=iT.substring(0,iI)):(iI=iT.length);
		if(iT!=iH.substring(0,iI))
			iS+='&f'+escape(iR);
		iS+='&r'+escape(iH);
		iD.write('<img src="'+iS+'" border="0" width="88" height="31" />');
	//--></script>
*/	
	let iS='http'+(window.location.protocol=='https:'?'s':'')+'://r.i.ua/s?u236620&p23&n'+Math.random();
	let iD=document;
	if(!iD.cookie)
		iD.cookie="b=b; path=/";
	if(iD.cookie)
		iS+='&c1';
	iS+='&d'+( window.screen.colorDepth ? window.screen.colorDepth : window.screen.pixelDepth)+"&w" + window.screen.width+'&h' + window.screen.height;
	let iP = /^[a-z]*:\/\//;
	let iR=iD.referrer.replace(iP,'');
	let iT=iR;
	let iH=window.location.href.replace(iP,'');
	let iI; 
	((iI=iT.indexOf('/'))!=-1)?(iT=iT.substring(0,iI)):(iI=iT.length);
	if(iT!=iH.substring(0,iI))
		iS+='&f'+escape(iR);
	iS+='&r'+escape(iH);
	console.log('Counter', iS);
	return ( 
		<a href="https://catalog.i.ua/stat/236620/" target="_blank" onclick="this.href='https://i.ua/r.php?236620';" title="Rated by I.UA">
			<img src={iS} border="0" width="88" height="31" />
		</a>
	)
	//iS = 'https://r.i.ua/s?u236620&p23&n' + Math.random() + '&c1&d24&w1280&h720&rpechersklibss.kiev.ua/'
}

const localStyles = {
	divFooterMain: {
		width: '100%',
		borderTop: '15px solid #84c235',
		backgroundColor: '#588b0e',
	},
	divFooterBody: {
		display: 'flex',
		width: '1100px',
		paddingTop: '15px',
		paddingBottom: '50px', 
		margin: 'auto',
		fontSize: '8pt',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'normal',
		color: '#474747',
	},
	divFooterBlockTitle: {
		paddingBottom: '5px',
		fontSize: '12pt',
		fontFamily: 'impact',
		fontWeight: 'normal',
		color: '#430045',
	},
	spanFooterContacts: {
		marginLeft: '7px',
		fontSize: '10pt',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'normal',
		color: '#474747',
	},
	aFooterForUser: {
		textDecoration: 'none',
		fontSize: '10pt',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'normal',
		color: '#474747',
	},
	divFooterCopyright: {
		width: '100%',
		paddingTop: '10px',
		paddingBottom: '10px',
		backgroundColor: '#1e3900',
		fontSize: '11pt',
		fontFamily: 'sans-serif',
		fontWeight: 'bold',
		color: '#ffffff',
	}
};

export default Footer;
