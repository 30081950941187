import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import {
	LIBCRB, LIBVYSHNI, LIBBILOKUR, LIBKNYGOLYUB, LIB149, LIB150, LIBVSESVIT, LIBSVITOGLYAD, LIBESTET, 
	LIBVESELKA, LIBTRUBLAINI, LIB8, LIBJERELO 
} from '../../util/consts';

export const CBS_SEARCH_FORM = 'cbs_sf';
export const KKS_SEARCH_FORM = 'kks_sf';
export const SKS_SEARCH_FORM = 'sks_sf';

export const LIBCRB_SEARCH_FORM = 'crb_sf';
export const LIBVYSHNI_SEARCH_FORM = 'vyshni_sf';
export const LIBBILOKUR_SEARCH_FORM = 'bilokur_sf';
export const LIBKNYGOLYUB_SEARCH_FORM = 'knygolyb_sf';
export const LIB149_SEARCH_FORM = '149_sf';
export const LIB150_SEARCH_FORM = '150_sf';
export const LIBVSESVIT_SEARCH_FORM = 'vsesvit_sf';
export const LIBSVITOGLYAD_SEARCH_FORM = 'svitoglyad_sf';
export const LIBESTET_SEARCH_FORM = 'estet_sf';

export const LIBVESELKA_SEARCH_FORM = 'veselka_sf';
export const LIBTRUBLAINI_SEARCH_FORM = 'trublaini_sf';
export const LIB8_SEARCH_FORM = '8_sf';
export const LIBJERELO_SEARCH_FORM = 'jerelo_sf';

class SearchForm extends Component {

	/*
		searchFormId
		formData
	*/
	constructor(props) {
		super(props);
		
		this.componentSearchEngine = props.holder;
		this.componentSearchEngine.componentSearchForm = this;
		
		this.initFormDataStoragePlaces();

		this.state={ dirty: false };
	}
	initFormDataStoragePlaces = () => {
		var searchFormId = this.props.searchFormId;
		if( searchFormId == CBS_SEARCH_FORM )
			this.initCbsStoragePlaces();
		else if( searchFormId == LIBCRB_SEARCH_FORM )
			this.initCrbStoragePlaces();
		else if( searchFormId == LIBVYSHNI_SEARCH_FORM )
			this.initVyshniStoragePlaces();
		else if( searchFormId == LIBBILOKUR_SEARCH_FORM )
			this.initBilokurStoragePlaces();
		else if( searchFormId == LIBKNYGOLYUB_SEARCH_FORM )
			this.initKnygolybStoragePlaces();
		else if( searchFormId == LIB149_SEARCH_FORM )
			this.init149StoragePlaces();
		else if( searchFormId == LIB150_SEARCH_FORM )
			this.init150StoragePlaces();
		else if( searchFormId == LIBVSESVIT_SEARCH_FORM )
			this.initVsesvitStoragePlaces();
		else if( searchFormId == LIBSVITOGLYAD_SEARCH_FORM )
			this.initSvitoglyadStoragePlaces();
		else if( searchFormId == LIBESTET_SEARCH_FORM )
			this.initEstetStoragePlaces();
		else if( searchFormId == SKS_SEARCH_FORM )
			this.initSksStoragePlaces();
		else if( searchFormId == KKS_SEARCH_FORM )
			this.initKksStoragePlaces();
	}
	initCbsStoragePlaces = () => {
		this.initCrbStoragePlaces();
		this.initVyshniStoragePlaces();
		this.initBilokurStoragePlaces();
		this.initKnygolybStoragePlaces();
		this.init149StoragePlaces();
		this.init150StoragePlaces();
		this.initVsesvitStoragePlaces();
		this.initSvitoglyadStoragePlaces();
		this.initEstetStoragePlaces();
	}
	initCrbStoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['libCrbAB'] = true;
		formData.storagePlace['libCrbCZ'] = true;
		formData.storagePlace['libCrbVVF'] = true;
		formData.storagePlace['libCrbBV'] = true;
		formData.storagePlace['libCrbIDC'] = true;
		formData.storagePlace['libCrbVTL'] = true;
	}
	initVyshniStoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['libVishnAB'] = true;
		formData.storagePlace['libVishnCZ'] = true;
	}
	initBilokurStoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['libKoshAB'] = true;
		formData.storagePlace['libKoshCZ'] = true;
	}
	initKnygolybStoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['libKudriAB'] = true;
		formData.storagePlace['libKudriCZ'] = true;
	}
	init149StoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['lib149ab'] = true;
		formData.storagePlace['lib149cz'] = true;
	}
	init150StoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['lib150ab'] = true;
		formData.storagePlace['lib150cz'] = true;
	}
	initVsesvitStoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['libRadAB'] = true;
		formData.storagePlace['libRadCZ'] = true;
	}
	initSvitoglyadStoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['libSvitAB'] = true;
		formData.storagePlace['libSvitCZ'] = true;
		formData.storagePlace['libSvitDV'] = true;
	}
	initEstetStoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['libEstetAB'] = true;
		formData.storagePlace['libEstetCZ'] = true;
		formData.storagePlace['libCrbVM'] = true;
	}
	initSksStoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['libCrbSKS'] = true;
		formData.storagePlace['libSvitSKS'] = true;
	}
	initKksStoragePlaces = () => {
		var formData = this.props.formData;
		formData.storagePlace['libCrbKKS'] = true;
	}
	
	handleInputChange = (e) => {
		const {name, value} = e.target;
		var formData = this.props.formData;
		formData[name] = value;
		this.setState({dirty: true});
	}
	handleCheckBoxClick = (e) => {
		const {name, checked} = e.target;
		var formData = this.props.formData;
		formData.storagePlace[name] = checked;
		this.setState({dirty: true});
	}

	render() {
		var formData = this.props.formData;
		var searchFormId = this.props.searchFormId;
		
		if( searchFormId == KKS_SEARCH_FORM || searchFormId == SKS_SEARCH_FORM ) 
			var title = 'Пошук';
		else
			var title = 'Пошук літератури';

		if( searchFormId == CBS_SEARCH_FORM ) 
			var searchFormFooterHtml = (<CbsFooter />);
		else
			var searchFormFooterHtml = (null);
			
		return(
			<div style={{marginLeft: '20px'}}>
				<div style={{fontWeight: 'bold', fontSize: '16pt'}}>
					{title}
				</div>
				<div style={localStyles.divInput}>
					<div style={localStyles.divInputLabel}>
						Автор
					</div>
					<div>
						<input type='text' name='author' value={formData.author} size='70' onChange={this.handleInputChange} />
					</div>
				</div>
				<div style={localStyles.divInput}>
					<div style={localStyles.divInputLabel}>
						Назва
					</div>
					<div>
						<input type='text' name='title' value={formData.title} size='70' onChange={this.handleInputChange} />
					</div>
				</div>
				<div style={localStyles.divInput}>
					<div style={localStyles.divInputLabel}>
						Ключові слова
					</div>
					<div>
						<input type='text' name='keywords' value={formData.keywords} size='70' onChange={this.handleInputChange} />
					</div>
				</div>
				<div style={localStyles.divInput}>
					<div style={localStyles.divInputLabel}>
						Всі поля
					</div>
					<div>
						<input type='text' name='anyfield' value={formData.anyfield} size='70' onChange={this.handleInputChange} />
					</div>
				</div>
				<StoragePlaces holder={this} />
				<button type='button' style={{marginTop: '15px'}} onClick={this.componentSearchEngine.searchBooks} >Шукати</button>
				{searchFormFooterHtml}
			</div>
		);
	}
}

function StoragePlaces(props) {
	var holder = props.holder;
	var searchFormId = holder.props.searchFormId;
	if( searchFormId == CBS_SEARCH_FORM )
		return (
			<CbsStoragePlaces holder={holder} />
		);
	if( searchFormId == LIBCRB_SEARCH_FORM )
		return (
			<div style={{marginLeft: '20px', marginTop: '25px'}}>
				<div style={{fontWeight: 'bold'}}>
					Місце знаходження
				</div>
				<LibCrbStoragePlaces holder={holder} />
			</div>
		);
	if( searchFormId == LIBVYSHNI_SEARCH_FORM )
		return (
			<div style={{marginLeft: '20px', marginTop: '25px'}}>
				<div style={{fontWeight: 'bold'}}>
					Місце знаходження
				</div>
				<LibVyshniStoragePlaces holder={holder} />
			</div>
		);
	if( searchFormId == LIBBILOKUR_SEARCH_FORM )
		return (
			<div style={{marginLeft: '20px', marginTop: '25px'}}>
				<div style={{fontWeight: 'bold'}}>
					Місце знаходження
				</div>
				<LibBilokurStoragePlaces holder={holder} />
			</div>
		);
	if( searchFormId == LIBKNYGOLYUB_SEARCH_FORM )
		return (
			<div style={{marginLeft: '20px', marginTop: '25px'}}>
				<div style={{fontWeight: 'bold'}}>
					Місце знаходження
				</div>
				<LibKnygolybStoragePlaces holder={holder} />
			</div>
		);
	if( searchFormId == LIB149_SEARCH_FORM )
		return (
			<div style={{marginLeft: '20px', marginTop: '25px'}}>
				<div style={{fontWeight: 'bold'}}>
					Місце знаходження
				</div>
				<Lib149StoragePlaces holder={holder} />
			</div>
		);
	if( searchFormId == LIB150_SEARCH_FORM )
		return (
			<div style={{marginLeft: '20px', marginTop: '25px'}}>
				<div style={{fontWeight: 'bold'}}>
					Місце знаходження
				</div>
				<Lib150StoragePlaces holder={holder} />
			</div>
		);
	if( searchFormId == LIBVSESVIT_SEARCH_FORM )
		return (
			<div style={{marginLeft: '20px', marginTop: '25px'}}>
				<div style={{fontWeight: 'bold'}}>
					Місце знаходження
				</div>
				<LibVsesvitStoragePlaces holder={holder} />
			</div>
		);
	if( searchFormId == LIBSVITOGLYAD_SEARCH_FORM )
		return (
			<div style={{marginLeft: '20px', marginTop: '25px'}}>
				<div style={{fontWeight: 'bold'}}>
					Місце знаходження
				</div>
				<LibSvitoglyadStoragePlaces holder={holder} />
			</div>
		);
	if( searchFormId == LIBESTET_SEARCH_FORM )
		return (
			<div style={{marginLeft: '20px', marginTop: '25px'}}>
				<div style={{fontWeight: 'bold'}}>
					Місце знаходження
				</div>
				<LibEstetStoragePlaces holder={holder} />
			</div>
		);
	if( searchFormId == KKS_SEARCH_FORM )
		return (null);
	if( searchFormId == SKS_SEARCH_FORM )
		return (null);
	
}

function CbsStoragePlaces(props) {
	var holder = props.holder;
	return (
		<div style={{marginLeft: '20px', marginTop: '25px'}}>
			<div style={{fontWeight: 'bold'}}>
				Місце знаходження
			</div>
			<div style={localStyles.divLibraryLabel}>
				{LIBCRB.title}
			</div>
			<LibCrbStoragePlaces holder={holder} />
			
			<div style={{display: 'flex'}} >
				<div style={{width: '50%'}}>
					<div style={localStyles.divLibraryLabel}>
						{LIBVYSHNI.title}
					</div>
					<LibVyshniStoragePlaces holder={holder} />
				</div>

				<div style={{width: '50%'}}>
					<div style={localStyles.divLibraryLabel}>
						{LIBBILOKUR.title}
					</div>
					<LibBilokurStoragePlaces holder={holder} />
				</div>
			</div>

			<div style={{display: 'flex'}} >
				<div style={{width: '50%'}}>
					<div style={localStyles.divLibraryLabel}>
						{LIBKNYGOLYUB.title}
					</div>
					<LibKnygolybStoragePlaces holder={holder} />
				</div>

				<div style={{width: '50%'}}>
					<div style={localStyles.divLibraryLabel}>
						{LIB149.title}
					</div>
					<Lib149StoragePlaces holder={holder} />
				</div>
			</div>

			<div style={{display: 'flex'}} >
				<div style={{width: '50%'}}>
					<div style={localStyles.divLibraryLabel}>
						{LIB150.title}
					</div>
					<Lib150StoragePlaces holder={holder} />
				</div>

				<div style={{width: '50%'}}>
					<div style={localStyles.divLibraryLabel}>
						{LIBVSESVIT.title}
					</div>
					<LibVsesvitStoragePlaces holder={holder} />
				</div>
			</div>

			<div style={localStyles.divLibraryLabel}>
				{LIBSVITOGLYAD.title}
			</div>
			<LibSvitoglyadStoragePlaces holder={holder} />

			<div style={localStyles.divLibraryLabel}>
				{LIBESTET.title}
			</div>
			<LibEstetStoragePlaces holder={holder} />
			
		</div>
	);
}

function LibCrbStoragePlaces(props) {
	var holder = props.holder;
	return(
		<React.Fragment>
			<div style={localStyles.divCheckBoxes}>
				<CB holder={holder} name='libCrbAB' title='Абонемент' />
				<CB holder={holder} name='libCrbCZ' title='Читальний зал' />
				<CB holder={holder} name='libCrbVVF' title='Відділ використання фонду' />
			</div>
			<div style={localStyles.divCheckBoxes}>
				<CB holder={holder} name='libCrbBV' title='Бібліографічний відділ' />
				<CB holder={holder} name='libCrbIDC' title='Інформаційно-діловий центр' />
			</div>
			<div style={localStyles.divCheckBoxes}>
				<CB holder={holder} name='libCrbVTL' title='Відділ літератури іноземними мовами' />
			</div>
		</React.Fragment>
	);
}
function LibVyshniStoragePlaces(props) {
	var holder = props.holder;
	return(
		<div style={localStyles.divCheckBoxes}>
			<CB holder={holder} name='libVishnAB' title='Абонемент' />
			<CB holder={holder} name='libVishnCZ' title='Читальний зал' />
		</div>
	);
}
function LibBilokurStoragePlaces(props) {
	var holder = props.holder;
	return(
		<div style={localStyles.divCheckBoxes}>
			<CB holder={holder} name='libKoshAB' title='Абонемент' />
			<CB holder={holder} name='libKoshCZ' title='Читальний зал' />
		</div>
	);
}
function LibKnygolybStoragePlaces(props) {
	var holder = props.holder;
	return(
		<div style={localStyles.divCheckBoxes}>
			<CB holder={holder} name='libKudriAB' title='Абонемент' />
			<CB holder={holder} name='libKudriCZ' title='Читальний зал' />
		</div>
	);
}
function Lib149StoragePlaces(props) {
	var holder = props.holder;
	return(
		<div style={localStyles.divCheckBoxes}>
			<CB holder={holder} name='lib149ab' title='Абонемент' />
			<CB holder={holder} name='lib149cz' title='Читальний зал' />
		</div>
	);
}
function Lib150StoragePlaces(props) {
	var holder = props.holder;
	return(
		<div style={localStyles.divCheckBoxes}>
			<CB holder={holder} name='lib150ab' title='Абонемент' />
			<CB holder={holder} name='lib150cz' title='Читальний зал' />
		</div>
	);
}
function LibVsesvitStoragePlaces(props) {
	var holder = props.holder;
	return(
		<div style={localStyles.divCheckBoxes}>
			<CB holder={holder} name='libRadAB' title='Абонемент' />
			<CB holder={holder} name='libRadCZ' title='Читальний зал' />
		</div>
	);
}
function LibSvitoglyadStoragePlaces(props) {
	var holder = props.holder;
	return(
		<div style={localStyles.divCheckBoxes}>
			<CB holder={holder} name='libSvitAB' title='Абонемент' />
			<CB holder={holder} name='libSvitCZ' title='Читальний зал' />
			<CB holder={holder} name='libSvitDV' title='Дитячий відділ' />
		</div>
	);
}
function LibEstetStoragePlaces(props) {
	var holder = props.holder;
	return(
		<div style={localStyles.divCheckBoxes}>
			<CB holder={holder} name='libEstetAB' title='Абонемент' />
			<CB holder={holder} name='libEstetCZ' title='Читальний зал' />
			<CB holder={holder} name='libCrbVM' title='Відділ мистецтв' />
		</div>
	);
}

function CB(props) {
	var holder = props.holder;
	var formData = holder.props.formData;
	var checked = formData.storagePlace[props.name];
	return (
		<React.Fragment>
			<input type='checkbox' name={props.name} checked={checked} onChange={holder.handleCheckBoxClick} /> 
			<span style={{marginLeft: '10px', marginRight: '40px', fontSize: '11pt'}}>{props.title}</span>
		</React.Fragment>
	);
}

function CbsFooter(props) {
	return(
		<React.Fragment>
			<hr className={stylesPage.hr} style={{marginTop: '10px', marginBottom: '10px'}}/>
			<p>
				Також для пошуку інформації про літературу в бібліотеках нашого району
				можна скористатись електронним каталогом міської публічної бібліотеки
				ім.Лесі Українки.<br/>
				Щоб розпочати пошук, перейдіть за <a href="http://212.1.70.66/F" target="_blank">цим посиланням</a>
			</p>
			<p>
			Знайшовши потрібну книгу, натисніть посилання «всі примірники»,
			що дасть можливість побачити умовні позначки – коди бібліотек.<br/>
			Бібліотеки нашої системи знаходяться в електронному каталозі під такими номерами:
			</p>
			<ul>
				<li>65 - ЦРБ ім.М.Є.Салтикова-Щедріна</li>
				<li>47 - бібліотека "Всесвіт"</li>
				<li>48 - бібліотека "Світогляд"</li>
				<li>67 - бібліотека ім.О.Вишні</li>
				<li>68 - бібліотека ім.К.Білокур</li>
				<li>69 - бібліотека "Книголюб"</li>
				<li>91 - бібліотека №149</li>
				<li>92 - бібліотека №150</li>
				<li>9 - бібліотека естетичного виховання</li>
			</ul>
		</React.Fragment>
	);
}

const localStyles = {
	divInput: {
		display: 'flex',
		marginLeft: '20px',
		marginTop: '15px'
	},
	divInputLabel: {
		width: '130px'
	},
	divLibraryLabel: {
		marginTop: '15px',
		fontStyle: 'italic',
		color: '#674268'
	},
	divCheckBoxes: {
		marginLeft: '30px',
		marginTop: '5px'
	}
}

export default SearchForm;
