import React, { Component } from 'react';
import stylesAdmin from './admin.module.css';

import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Spinner from "../../components/Spinner";
import DivInnerHtml from '../../components/DivInnerHtml';
import Message from "../../components/Message";

import { getAdminClubUrl, dateInputFormatToShowFormat } from "../../util/tools";
import { performApiRequest, CLUB_LIST_API_URL, CLUB_DELETE_API_URL } from "../../util/restapi";
import { ADMIN_CLUB_LIST_PATH, ADMIN_CLUB_PATH } from "../../util/routes";
import { ANY, ONLY_TRUE, ONLY_FALSE } from "../../util/consts";

const STATE_WORKING = 'working';
const STATE_READY = 'ready';

class AdminClubListPage extends Component {

    constructor(props) {
		super(props);
		this.clubList = [];
		this.messageHandler = {
			showMessage: null,
			doAfterClose: this.doDelete,
			confirm: false,
			clubId: null
		};
		this.state = {
			formState: STATE_READY,
			clubList: this.clubList,
			deletingClub: null,
			ok: true
		};
	}

	componentDidMount = async () => {
        // TODO
    }

	render() {
		let leftSide = (<AdminLeftSide path={ADMIN_CLUB_LIST_PATH} />);
		let body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}

}

function Body(props) {
	let holder = props.holder;
	return (
		<div style={{marginBottom: '30px'}}>
			<div className={stylesAdmin.divContentHeader}>
				Гуртки
			</div>
			<div style={{marginTop: '20px', marginBottom: '10px'}}>
				<a href= {ADMIN_CLUB_PATH + '/0'} target='_blank'>Додати</a>
			</div>
			<ClubList holder={holder} />
		</div>
	)
}
function ClubList(props) {
	return (
		<div>
            TODO
		</div>
	);
}

export default AdminClubListPage;
