import React, { Component, createRef } from 'react';
import stylesAdmin from './admin.module.css';
import stylesPage from '../page.module.css';

import AdminHeader from "./components/AdminHeader";
import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import CmbLibrary from "./components/CmbLibrary";
import EventImage from "./components/EventImage";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";

import logo from '../../assets/img/pechersk_logo.png';

import { performApiRequest, performApiRequestForm, 
	EVENT_LOAD_API_URL, EVENT_SAVE_API_URL, EVENT_SAVE_IMAGE_API_URL } 
from "../../util/restapi";
import { ADMIN_EVENT_PATH } from "../../util/routes";
import { getEventImageUrl } from "../../util/tools";

const STATE_INIT = 'init';
const STATE_READY = 'ready';
const STATE_SAVING_EVENT = 'saving_event';
const STATE_SAVING_IMAGE = 'saving_image';

class AdminEventPage extends Component {

	constructor(props) {
		super(props);
		this.eventId = this.props.match.params.id;
		this.event = {};
		this.librarySelection = {};
		this.fileRef = createRef();
		this.state = {
			dirty: false,
			formState: STATE_INIT,
			ok: true,
			imageList: []
		};
		
		this.imageList = [];
		this.imageToDelete = [];
	}
	componentDidMount = async () => {
		if( this.eventId == 0 ) {
			this.event = { 
				eventId: 0, 
				libraryId: 'cbs',
				eventDate: new Date().toISOString().slice(0, 10),
				title: '',
				description: '',
				longDescription: '',
				hasExtendedDescription: false,
				eventSlided: false,
				eventIsVirtualExhibition: false,
				logoImageId: 0
			};
			var imageList = [];
		} else {
			var request = {
				eventId: this.eventId
			};
			var response = await performApiRequest(EVENT_LOAD_API_URL, request);
			this.event = response.event;
			this.imageList = response.imageList;
		}
		if( this.event != null )
			this.librarySelection.selectedLibraryId = this.event.libraryId;
		this.setState({formState: STATE_READY, imageList: imageList});
	}

	handleValueChange = (e) => {
		const {name, value} = e.target;
		var event = this.event;
		if( name == 'eventDate' ) {
			var newValue = null;
			if( value )
				newValue = value.slice(0,4) + value.slice(5,7) + value.slice(8,10);
			event.eventDate = newValue;
		}
		if( name == 'title' )
			event.title = value;
		if( name == 'description' )
			event.description = value;
		if( name == 'longDescription' )
			event.longDescription = value;
		this.setState({dirty: true});
	}
	handleCheckBoxChange = (e) =>	{
		var name = e.target.name;
		var value = e.target.checked;
		var event = this.event;
		if( name == 'virtexhib' )
			event.eventIsVirtualExhibition = value;
		this.setState({dirty: true});
	}
	handleFileSelect = async (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			this.setState({formState: STATE_SAVING_IMAGE});
			var file = files[0];
			const form = new FormData();
			form.append('eventId', this.event.eventId);
			form.append('imageOrder', this.imageList.length);
			form.append('photo', file);
			var response = await performApiRequestForm(EVENT_SAVE_IMAGE_API_URL, form);
			if( response.ok ) {
				this.imageList.push( response.image );
				this.setState({imageList: this.imageList});
			}
			this.setState({formState: STATE_READY});
		}
	}

	moveImageUp = (imageIndex) => {
		if( imageIndex == 0 )
			return;
		var img = this.imageList[ imageIndex-1 ];
		this.imageList[ imageIndex-1 ] = this.imageList[ imageIndex ];
		this.imageList[ imageIndex ] = img;
		this.setState({imageList: this.imageList});
	}	
	moveImageDown = (imageIndex) => {
		if( imageIndex == this.imageList.length-1 )
			return;
		var img = this.imageList[ imageIndex+1 ];
		this.imageList[ imageIndex+1 ] = this.imageList[ imageIndex ];
		this.imageList[ imageIndex ] = img;
		this.setState({imageList: this.imageList});
	}	
	setEventLogo = (imageIndex) => {
		var event = this.event;
		if( imageIndex < 0 )
			event.logoImageId = 0;
		else
			event.logoImageId = this.imageList[imageIndex].imageId;
		this.setState({dirty: true});
	}
	deleteImage = (imageIndex) => {
		var event = this.event;
		var imageId = this.imageList[imageIndex].imageId;
		this.imageToDelete.push( imageId );
		this.imageList.splice(imageIndex, 1);
		if( imageId == event.logoImageId ) {
			event.logoImageId = 0;
		}
		this.setState({dirty: true});
	}
	
	saveEvent = async () => {
		this.setState({formState: STATE_SAVING_EVENT});
		var event = this.event;
		event.libraryId = this.librarySelection.selectedLibraryId;
		for( let i=0; i < this.imageList.length; i++ ) {
			this.imageList[i].eventId = this.eventId;
			this.imageList[i].imageOrder = i+1;
		}
		var request = {
			event: event,
			imageList: this.imageList,
			imageToDelete: this.imageToDelete
		};
		var isNew = event.eventId == 0;
		var response = await performApiRequest(EVENT_SAVE_API_URL, request);
		event.eventId = response.eventId;
		this.setState({formState: STATE_READY});
		if( isNew )
			window.open( ADMIN_EVENT_PATH + '/' + response.eventId, '_self' );
	}
	
	render() {
		var leftSide = (<AdminLeftSide />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_INIT ) {
		var contentHtml = (<Spinner />);
	} else {
		var contentHtml = (<Content holder={holder} />);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={stylesAdmin.divContentHeader}>
				Новина
			</div>
			{contentHtml}
		</div>
	)
}

function Content(props) {
	var holder = props.holder;
	var event = holder.event;
	if( event == null ) {
		return (
			<div style={{width:'100%', marginTop: '30px', marginBottom: '30px', textAlign: 'center'}}>
				Вибачте, інформація відсутня
			</div>
		);
	}
	var dateValue = event.eventDate;
	if( dateValue != null && dateValue.length == 8) {
		dateValue = dateValue.slice(0,4) + '-' + dateValue.slice(4,6) + '-' + dateValue.slice(6,9);
	}
	return (
		<div style={{width:'100%', marginBottom: '30px'}}>
			<div style={{marginTop: '15px'}}>
				Бібліотека 
				<CmbLibrary 
					style={{marginLeft: '10px', marginRight: '20px'}}
					librarySelection={holder.librarySelection} 
				/>
				Дата <input type='date' name='eventDate' value={dateValue} onChange={holder.handleValueChange} />
			</div>
			{/*
			<div style={{marginTop: '15px'}}>
				<input type="checkbox" name='virtexhib' onChange={holder.handleCheckBoxChange} checked={event.eventIsVirtualExhibition} />
				&nbsp;Віртуальна виставка
			</div>
			*/}
			<div style={{marginTop: '15px'}}>
				Заголовок<br/>
				<textarea 
					name="title" rows="2" cols="100" 
					onChange={holder.handleValueChange}
					value={event.title}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				Короткий опис<br/>
				<textarea 
					name="description" rows="4" cols="100" 
					onChange={holder.handleValueChange}
					value={event.description}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				Повний опис<br/>
				<textarea 
					name="longDescription" rows="8" cols="100" 
					onChange={holder.handleValueChange}
					value={event.longDescription}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				<button type='button' onClick={holder.saveEvent}>Записати</button>
			</div>
			<div>
				{holder.state.formState == STATE_SAVING_EVENT ? <Spinner /> : null}
			</div>
			<EventImages holder={holder} />
		</div>
	);
}

function EventImages(props) {
	var holder = props.holder;
	var event = holder.event;
	if( ! event.eventId )
		return (
			<div>
				<div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '16pt'}}>
					Фотографії
				</div>
				<div style={{marginTop: '10px'}}>
					Редагування доступне після запису події
				</div>
			</div>
		);

	var imageListHtml = holder.imageList.map( (image, index) => {
		return( <EventImage key={index} image={image} index={index} holder={holder} /> );
	});

	if( holder.state.formState == STATE_SAVING_IMAGE )
		var divSave = (<Spinner />);
	else {
		var divSave = (
			<div>
				<button type='button' onClick={e => holder.fileRef.current.click()}>Додати</button>
				<input ref={holder.fileRef} type={'file'} style={{display: 'none'}} onChange={e => holder.handleFileSelect(e)} />
			</div>
		);
	}
	return (
		<div style={{width: '100%', marginTop: '10px'}}>
			<div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '16pt'}}>
				Фотографії
			</div>
			<hr className={stylesPage.hr} />
			<div style={{textAlign: 'center'}}>
				Логотип події
			</div>
			<EventLogoImage holder={holder} />
			<hr className={stylesPage.hr} />
			{imageListHtml}
			{divSave}
		</div>
	);
}

function EventLogoImage(props) {
	var holder = props.holder;
	var event = holder.event;
	
	if( event.logoImageId > 0) {
		var urlImage = getEventImageUrl(event.logoImageId);
		return (
			<div style={{display: 'flex'}}>
				<div style={{width: '200px'}}>
					<img src={urlImage} height='100' />
				</div>
				<div>
					<button type='button' onClick={() => {holder.setEventLogo(-1)} }>Очистити</button>
				</div>
			</div>
		);
	} else {
		return (
			<div style={{display: 'flex'}}>
				<div>
					<img src={logo} />
				</div>
			</div>
		);
	}
}

export default AdminEventPage;
