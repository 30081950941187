import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";
import EventListBody from "../../event/EventListBody";

import { MAIN_PATH, LIBSVITOGLYAD_INFO_PATH, LIBSVITOGLYAD_EVENTS_PATH, } from '../../../util/routes';
import { LIBSVITOGLYAD } from '../../../util/consts';

class LibSvitoglyadEventsPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBSVITOGLYAD.title, url: LIBSVITOGLYAD_INFO_PATH, selected: false },
			{title: 'Новини та події', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBSVITOGLYAD_EVENTS_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBSVITOGLYAD_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBSVITOGLYAD.title}<br/>
				Новини та події
			</div>
			<EventListBody libraryId={LIBSVITOGLYAD.cipher} />
		</div>
	);
}

export default LibSvitoglyadEventsPage;
