import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import {getUserInfo, clearUserInfo} from './util/tools';

import Error404Page from './pages/main/MainPage';
import MainPage from './pages/main/MainPage';
import EventListPage from './pages/event/EventListPage';
import EventPage from './pages/event/EventPage';
import ExhibitionListPage from './pages/exhibition/ExhibitionListPage';
import ExhibitionPage from './pages/exhibition/ExhibitionPage';
import PosterListPage from './pages/poster/PosterListPage';
import PosterPage from './pages/poster/PosterPage';

import ECatalogPage from './pages/ecatalog/ECatalogPage';
import OnlineInfoPage from './pages/onlineinfo/OnlineInfoPage';
import SksPage from './pages/ecatalog/SksPage';
import KksPage from './pages/ecatalog/KksPage';
import BookListInterestingPage from './pages/book/BookListInterestingPage';
import BookListNewPage from './pages/book/BookListNewPage';
import BookDescriptionPage from './pages/book/BookDescriptionPage';
import ContactsPage from './pages/contacts/ContactsPage';
import ServicesPage from './pages/services/ServicesPage';
import RulesPage from './pages/rules/RulesPage';
import AdministrationPage from './pages/administration/AdministrationPage';
import SchedulePage from './pages/schedule/SchedulePage';
import SiteMapPage from './pages/sitemap/SiteMapPage';

import LibCrbContactsPage from './pages/library/crb/LibCrbContactsPage';
import LibCrbECatalogPage from './pages/library/crb/LibCrbECatalogPage';
import LibCrbEventsPage from './pages/library/crb/LibCrbEventsPage';
import LibCrbGalleryPage from './pages/library/crb/LibCrbGalleryPage';
import LibCrbInfoPage from './pages/library/crb/LibCrbInfoPage';
import LibCrbServicesPage from './pages/library/crb/LibCrbServicesPage';
import Lib8ContactsPage from './pages/library/lib8/Lib8ContactsPage';
import Lib8EventsPage from './pages/library/lib8/Lib8EventsPage';
import Lib8GalleryPage from './pages/library/lib8/Lib8GalleryPage';
import Lib8InfoPage from './pages/library/lib8/Lib8InfoPage';
import Lib149ContactsPage from './pages/library/lib149/Lib149ContactsPage';
import Lib149ECatalogPage from './pages/library/lib149/Lib149ECatalogPage';
import Lib149EventsPage from './pages/library/lib149/Lib149EventsPage';
import Lib149GalleryPage from './pages/library/lib149/Lib149GalleryPage';
import Lib149InfoPage from './pages/library/lib149/Lib149InfoPage';
import Lib150ContactsPage from './pages/library/lib150/Lib150ContactsPage';
import Lib150ECatalogPage from './pages/library/lib150/Lib150ECatalogPage';
import Lib150EventsPage from './pages/library/lib150/Lib150EventsPage';
import Lib150GalleryPage from './pages/library/lib150/Lib150GalleryPage';
import Lib150InfoPage from './pages/library/lib150/Lib150InfoPage';
import LibBilokurContactsPage from './pages/library/libbilokur/LibBilokurContactsPage';
import LibBilokurECatalogPage from './pages/library/libbilokur/LibBilokurECatalogPage';
import LibBilokurEventsPage from './pages/library/libbilokur/LibBilokurEventsPage';
import LibBilokurGalleryPage from './pages/library/libbilokur/LibBilokurGalleryPage';
import LibBilokurInfoPage from './pages/library/libbilokur/LibBilokurInfoPage';
import LibEstetContactsPage from './pages/library/libestet/LibEstetContactsPage';
import LibEstetECatalogPage from './pages/library/libestet/LibEstetECatalogPage';
import LibEstetEventsPage from './pages/library/libestet/LibEstetEventsPage';
import LibEstetGalleryPage from './pages/library/libestet/LibEstetGalleryPage';
import LibEstetInfoPage from './pages/library/libestet/LibEstetInfoPage';
import LibJereloContactsPage from './pages/library/libjerelo/LibJereloContactsPage';
import LibJereloEventsPage from './pages/library/libjerelo/LibJereloEventsPage';
import LibJereloGalleryPage from './pages/library/libjerelo/LibJereloGalleryPage';
import LibJereloInfoPage from './pages/library/libjerelo/LibJereloInfoPage';
import LibKnygolyubContactsPage from './pages/library/libknygolyub/LibKnygolyubContactsPage';
import LibKnygolyubECatalogPage from './pages/library/libknygolyub/LibKnygolyubECatalogPage';
import LibKnygolyubEventsPage from './pages/library/libknygolyub/LibKnygolyubEventsPage';
import LibKnygolyubGalleryPage from './pages/library/libknygolyub/LibKnygolyubGalleryPage';
import LibKnygolyubInfoPage from './pages/library/libknygolyub/LibKnygolyubInfoPage';
import LibSvitoglyadContactsPage from './pages/library/libsvitoglyad/LibSvitoglyadContactsPage';
import LibSvitoglyadECatalogPage from './pages/library/libsvitoglyad/LibSvitoglyadECatalogPage';
import LibSvitoglyadEventsPage from './pages/library/libsvitoglyad/LibSvitoglyadEventsPage';
import LibSvitoglyadGalleryPage from './pages/library/libsvitoglyad/LibSvitoglyadGalleryPage';
import LibSvitoglyadInfoPage from './pages/library/libsvitoglyad/LibSvitoglyadInfoPage';
import LibTrublainiContactsPage from './pages/library/libtrublaini/LibTrublainiContactsPage';
import LibTrublainiEventsPage from './pages/library/libtrublaini/LibTrublainiEventsPage';
import LibTrublainiGalleryPage from './pages/library/libtrublaini/LibTrublainiGalleryPage';
import LibTrublainiInfoPage from './pages/library/libtrublaini/LibTrublainiInfoPage';
import LibVeselkaContactsPage from './pages/library/libveselka/LibVeselkaContactsPage';
import LibVeselkaEventsPage from './pages/library/libveselka/LibVeselkaEventsPage';
import LibVeselkaGalleryPage from './pages/library/libveselka/LibVeselkaGalleryPage';
import LibVeselkaInfoPage from './pages/library/libveselka/LibVeselkaInfoPage';
import LibVsesvitContactsPage from './pages/library/libvsesvit/LibVsesvitContactsPage';
import LibVsesvitECatalogPage from './pages/library/libvsesvit/LibVsesvitECatalogPage';
import LibVsesvitEventsPage from './pages/library/libvsesvit/LibVsesvitEventsPage';
import LibVsesvitGalleryPage from './pages/library/libvsesvit/LibVsesvitGalleryPage';
import LibVsesvitInfoPage from './pages/library/libvsesvit/LibVsesvitInfoPage';
import LibVyshniContactsPage from './pages/library/libvyshni/LibVyshniContactsPage';
import LibVyshniECatalogPage from './pages/library/libvyshni/LibVyshniECatalogPage';
import LibVyshniEventsPage from './pages/library/libvyshni/LibVyshniEventsPage';
import LibVyshniGalleryPage from './pages/library/libvyshni/LibVyshniGalleryPage';
import LibVyshniInfoPage from './pages/library/libvyshni/LibVyshniInfoPage';

import BibliographicListPage from './pages/bibliographic/BibliographicListPage';
import B201 from './pages/bibliographic/b201/B201';
import B202 from './pages/bibliographic/b202/B202';
import B203 from './pages/bibliographic/b203/B203';
import B204 from './pages/bibliographic/b204/B204';
import B205 from './pages/bibliographic/b205/B205';
import B206 from './pages/bibliographic/b206/B206';
import B207 from './pages/bibliographic/b207/B207';
import B208 from './pages/bibliographic/b208/B208';
import B209 from './pages/bibliographic/b209/B209';

import AdminMainPage from './pages/admin/AdminMainPage';
import AdminImportantInfoPage from './pages/admin/AdminImportantInfoPage';
import AdminSlideListPage from './pages/admin/AdminSlideListPage';
import AdminEventListPage from './pages/admin/AdminEventListPage';
import AdminEventPage from './pages/admin/AdminEventPage';
import AdminExhibitionListPage from './pages/admin/AdminExhibitionListPage';
import AdminExhibitionPage from './pages/admin/AdminExhibitionPage';
import AdminPosterListPage from './pages/admin/AdminPosterListPage';
import AdminPosterPage from './pages/admin/AdminPosterPage';
import AdminBookListPage from './pages/admin/AdminBookListPage';
import AdminBookPage from './pages/admin/AdminBookPage';
import AdminBookInterestingListPage from './pages/admin/AdminBookInterestingListPage';
import AdminBookInterestingPage from './pages/admin/AdminBookInterestingPage';
import AdminBookNewListPage from './pages/admin/AdminBookNewListPage';
import AdminBookNewPage from './pages/admin/AdminBookNewPage';
import AdminClubListPage from './pages/admin/AdminClubListPage';
import AdminClubPage from './pages/admin/AdminClubPage';
import AdminOnlineInfoPage from './pages/admin/AdminOnlineInfoPage';
import AdminUserListPage from './pages/admin/AdminUserListPage';

import {
	ROOT_PATH,
	MAIN_PATH,

	EVENT_LIST_PATH,
	EVENT_PATH,
	EXHIBITION_LIST_PATH,
	EXHIBITION_PATH,
	POSTER_LIST_PATH,
	POSTER_PATH,
	CLUBS_PATH,
	BLOG_PATH,

	ECATALOG_PATH,
	ONLINE_PATH,
	SKS_PATH,
	KKS_PATH,
	BOOK_DESCRIPTION_PATH,
	BOOKS_NEW_PATH,
	BOOKS_INTERESTING_PATH,
	CONTACTS_PATH,
	SERVICES_PATH,
	RULES_PATH,
	ADMINISTRATION_PATH,
	SCHEDULE_PATH,
	SITEMAP_PATH,

	LIBCRB_CONTACTS_PATH, LIBCRB_ECATALOG_PATH, LIBCRB_EVENTS_PATH, LIBCRB_GALLERY_PATH, LIBCRB_INFO_PATH, LIBCRB_SERVICES_PATH,
	LIB149_CONTACTS_PATH, LIB149_ECATALOG_PATH, LIB149_EVENTS_PATH, LIB149_GALLERY_PATH, LIB149_INFO_PATH,
	LIB150_CONTACTS_PATH, LIB150_ECATALOG_PATH, LIB150_EVENTS_PATH, LIB150_GALLERY_PATH, LIB150_INFO_PATH,
	LIB8_CONTACTS_PATH, LIB8_EVENTS_PATH, LIB8_GALLERY_PATH, LIB8_INFO_PATH,
	LIBBILOKUR_CONTACTS_PATH, LIBBILOKUR_ECATALOG_PATH, LIBBILOKUR_EVENTS_PATH, LIBBILOKUR_GALLERY_PATH, LIBBILOKUR_INFO_PATH,
	LIBESTET_CONTACTS_PATH, LIBESTET_ECATALOG_PATH, LIBESTET_EVENTS_PATH, LIBESTET_GALLERY_PATH, LIBESTET_INFO_PATH,
	LIBJERELO_CONTACTS_PATH, LIBJERELO_EVENTS_PATH, LIBJERELO_GALLERY_PATH, LIBJERELO_INFO_PATH,
	LIBKNYGOLYUB_CONTACTS_PATH, LIBKNYGOLYUB_ECATALOG_PATH, LIBKNYGOLYUB_EVENTS_PATH, LIBKNYGOLYUB_GALLERY_PATH, LIBKNYGOLYUB_INFO_PATH,
	LIBSVITOGLYAD_CONTACTS_PATH, LIBSVITOGLYAD_ECATALOG_PATH, LIBSVITOGLYAD_EVENTS_PATH, LIBSVITOGLYAD_GALLERY_PATH, LIBSVITOGLYAD_INFO_PATH,
	LIBTRUBLAINI_CONTACTS_PATH, LIBTRUBLAINI_EVENTS_PATH, LIBTRUBLAINI_GALLERY_PATH, LIBTRUBLAINI_INFO_PATH,
	LIBVESELKA_CONTACTS_PATH, LIBVESELKA_EVENTS_PATH, LIBVESELKA_GALLERY_PATH, LIBVESELKA_INFO_PATH,
	LIBVSESVIT_CONTACTS_PATH, LIBVSESVIT_ECATALOG_PATH, LIBVSESVIT_EVENTS_PATH, LIBVSESVIT_GALLERY_PATH, LIBVSESVIT_INFO_PATH,
	LIBVYSHNI_CONTACTS_PATH, LIBVYSHNI_ECATALOG_PATH, LIBVYSHNI_EVENTS_PATH, LIBVYSHNI_GALLERY_PATH, LIBVYSHNI_INFO_PATH,
	
	BIBLIOGRAPHIC_PATH,
	B201_PATH,
	B202_PATH,
	B203_PATH,
	B204_PATH,
	B205_PATH,
	B206_PATH,
	B207_PATH,
	B208_PATH,
	B209_PATH,
	
	ADMIN_PATH,
	ADMIN_IMPORTANT_INFO_PATH,
	ADMIN_SLIDE_LIST_PATH,
	ADMIN_EVENT_LIST_PATH,
	ADMIN_EVENT_PATH,
	ADMIN_EXHIBITION_LIST_PATH,
	ADMIN_EXHIBITION_PATH,
	ADMIN_POSTER_LIST_PATH,
	ADMIN_POSTER_PATH,
	ADMIN_BOOK_LIST_PATH,
	ADMIN_BOOK_PATH,
	ADMIN_BOOK_INTERESTING_LIST_PATH,
	ADMIN_BOOK_INTERESTING_PATH,
	ADMIN_BOOK_NEW_LIST_PATH,
	ADMIN_BOOK_NEW_PATH,
	ADMIN_CLUB_LIST_PATH,
	ADMIN_CLUB_PATH,
	ADMIN_ONLINE_INFO_PATH,
	ADMIN_USER_LIST_PATH,
	ADMIN_LOGOUT_PATH,
} from './util/routes';


class App extends Component {

	render () {
		return (
			<Router>
					<Switch>
						<Route exact path={ROOT_PATH} render={() => <Redirect to={MAIN_PATH} />} />
						<Route exact path={MAIN_PATH} component={props => <MainPage {...props} />} app={this} />

						<Route exact path={EVENT_LIST_PATH} component={props => <EventListPage {...props} />} app={this} />
						<Route exact path={`${EVENT_PATH}/:id`} component={props => <EventPage {...props} />} app={this} />
						<Route exact path={EXHIBITION_LIST_PATH} component={props => <ExhibitionListPage {...props} />} app={this} />
						<Route exact path={`${EXHIBITION_PATH}/:id`} component={props => <ExhibitionPage {...props} />} app={this} />
						<Route exact path={POSTER_LIST_PATH} component={props => <PosterListPage {...props} />} app={this} />
						<Route exact path={`${POSTER_PATH}/:id`} component={props => <PosterPage {...props} />} app={this} />

						<Route exact path={ECATALOG_PATH} component={props => <ECatalogPage {...props} />} app={this} />
						<Route exact path={`${ECATALOG_PATH}/:search`} component={props => <ECatalogPage {...props} />} app={this} />
						<Route exact path={ONLINE_PATH} component={props => <OnlineInfoPage {...props} />} app={this} />
						<Route exact path={SKS_PATH} component={props => <SksPage {...props} />} app={this} />
						<Route exact path={KKS_PATH} component={props => <KksPage {...props} />} app={this} />
						<Route exact path={`${BOOK_DESCRIPTION_PATH}/:id`} component={props => <BookDescriptionPage {...props} />} app={this} />
						<Route exact path={BOOKS_NEW_PATH} component={props => <BookListNewPage {...props} />} app={this} />
						<Route exact path={BOOKS_INTERESTING_PATH} component={props => <BookListInterestingPage {...props} />} app={this} />
						<Route exact path={CONTACTS_PATH} component={props => <ContactsPage {...props} />} app={this} />
						<Route exact path={SERVICES_PATH} component={props => <ServicesPage {...props} />} app={this} />
						<Route exact path={RULES_PATH} component={props => <RulesPage {...props} />} app={this} />
						<Route exact path={ADMINISTRATION_PATH} component={props => <AdministrationPage {...props} />} app={this} />
						<Route exact path={SCHEDULE_PATH} component={props => <SchedulePage {...props} />} app={this} />
						<Route exact path={SITEMAP_PATH} component={props => <SiteMapPage {...props} />} app={this} />
						
						<Route exact path={LIBCRB_CONTACTS_PATH} component={props => <LibCrbContactsPage {...props} />} app={this} />
						<Route exact path={LIBCRB_ECATALOG_PATH} component={props => <LibCrbECatalogPage {...props} />} app={this} />
						<Route exact path={LIBCRB_EVENTS_PATH} component={props => <LibCrbEventsPage {...props} />} app={this} />
						<Route exact path={LIBCRB_GALLERY_PATH} component={props => <LibCrbGalleryPage {...props} />} app={this} />
						<Route exact path={LIBCRB_INFO_PATH} component={props => <LibCrbInfoPage {...props} />} app={this} />
						<Route exact path={LIBCRB_SERVICES_PATH} component={props => <LibCrbServicesPage {...props} />} app={this} />
						<Route exact path={LIB8_CONTACTS_PATH} component={props => <Lib8ContactsPage {...props} />} app={this} />
						<Route exact path={LIB8_EVENTS_PATH} component={props => <Lib8EventsPage {...props} />} app={this} />
						<Route exact path={LIB8_GALLERY_PATH} component={props => <Lib8GalleryPage {...props} />} app={this} />
						<Route exact path={LIB8_INFO_PATH} component={props => <Lib8InfoPage {...props} />} app={this} />
						<Route exact path={LIB149_CONTACTS_PATH} component={props => <Lib149ContactsPage {...props} />} app={this} />
						<Route exact path={LIB149_ECATALOG_PATH} component={props => <Lib149ECatalogPage {...props} />} app={this} />
						<Route exact path={LIB149_EVENTS_PATH} component={props => <Lib149EventsPage {...props} />} app={this} />
						<Route exact path={LIB149_GALLERY_PATH} component={props => <Lib149GalleryPage {...props} />} app={this} />
						<Route exact path={LIB149_INFO_PATH} component={props => <Lib149InfoPage {...props} />} app={this} />
						<Route exact path={LIB150_CONTACTS_PATH} component={props => <Lib150ContactsPage {...props} />} app={this} />
						<Route exact path={LIB150_ECATALOG_PATH} component={props => <Lib150ECatalogPage {...props} />} app={this} />
						<Route exact path={LIB150_EVENTS_PATH} component={props => <Lib150EventsPage {...props} />} app={this} />
						<Route exact path={LIB150_GALLERY_PATH} component={props => <Lib150GalleryPage {...props} />} app={this} />
						<Route exact path={LIB150_INFO_PATH} component={props => <Lib150InfoPage {...props} />} app={this} />
						<Route exact path={LIBBILOKUR_CONTACTS_PATH} component={props => <LibBilokurContactsPage {...props} />} app={this} />
						<Route exact path={LIBBILOKUR_ECATALOG_PATH} component={props => <LibBilokurECatalogPage {...props} />} app={this} />
						<Route exact path={LIBBILOKUR_EVENTS_PATH} component={props => <LibBilokurEventsPage {...props} />} app={this} />
						<Route exact path={LIBBILOKUR_GALLERY_PATH} component={props => <LibBilokurGalleryPage {...props} />} app={this} /> 
						<Route exact path={LIBBILOKUR_INFO_PATH} component={props => <LibBilokurInfoPage {...props} />} app={this} />
						<Route exact path={LIBESTET_CONTACTS_PATH} component={props => <LibEstetContactsPage {...props} />} app={this} />
						<Route exact path={LIBESTET_ECATALOG_PATH} component={props => <LibEstetECatalogPage {...props} />} app={this} />
						<Route exact path={LIBESTET_EVENTS_PATH} component={props => <LibEstetEventsPage {...props} />} app={this} />
						<Route exact path={LIBESTET_GALLERY_PATH} component={props => <LibEstetGalleryPage {...props} />} app={this} />
						<Route exact path={LIBESTET_INFO_PATH} component={props => <LibEstetInfoPage {...props} />} app={this} />
						<Route exact path={LIBJERELO_CONTACTS_PATH} component={props => <LibJereloContactsPage {...props} />} app={this} />
						<Route exact path={LIBJERELO_EVENTS_PATH} component={props => <LibJereloEventsPage {...props} />} app={this} />
						<Route exact path={LIBJERELO_GALLERY_PATH} component={props => <LibJereloGalleryPage {...props} />} app={this} />
						<Route exact path={LIBJERELO_INFO_PATH} component={props => <LibJereloInfoPage {...props} />} app={this} />
						<Route exact path={LIBKNYGOLYUB_CONTACTS_PATH} component={props => <LibKnygolyubContactsPage {...props} />} app={this} />
						<Route exact path={LIBKNYGOLYUB_ECATALOG_PATH} component={props => <LibKnygolyubECatalogPage {...props} />} app={this} />
						<Route exact path={LIBKNYGOLYUB_EVENTS_PATH} component={props => <LibKnygolyubEventsPage {...props} />} app={this} />
						<Route exact path={LIBKNYGOLYUB_GALLERY_PATH} component={props => <LibKnygolyubGalleryPage {...props} />} app={this} />
						<Route exact path={LIBKNYGOLYUB_INFO_PATH} component={props => <LibKnygolyubInfoPage {...props} />} app={this} />
						<Route exact path={LIBSVITOGLYAD_CONTACTS_PATH} component={props => <LibSvitoglyadContactsPage {...props} />} app={this} />
						<Route exact path={LIBSVITOGLYAD_ECATALOG_PATH} component={props => <LibSvitoglyadECatalogPage {...props} />} app={this} />
						<Route exact path={LIBSVITOGLYAD_EVENTS_PATH} component={props => <LibSvitoglyadEventsPage {...props} />} app={this} />
						<Route exact path={LIBSVITOGLYAD_GALLERY_PATH} component={props => <LibSvitoglyadGalleryPage {...props} />} app={this} />
						<Route exact path={LIBSVITOGLYAD_INFO_PATH} component={props => <LibSvitoglyadInfoPage {...props} />} app={this} />
						<Route exact path={LIBTRUBLAINI_CONTACTS_PATH} component={props => <LibTrublainiContactsPage {...props} />} app={this} />
						<Route exact path={LIBTRUBLAINI_EVENTS_PATH} component={props => <LibTrublainiEventsPage {...props} />} app={this} />
						<Route exact path={LIBTRUBLAINI_GALLERY_PATH} component={props => <LibTrublainiGalleryPage {...props} />} app={this} />
						<Route exact path={LIBTRUBLAINI_INFO_PATH} component={props => <LibTrublainiInfoPage {...props} />} app={this} />
						<Route exact path={LIBVESELKA_CONTACTS_PATH} component={props => <LibVeselkaContactsPage {...props} />} app={this} />
						<Route exact path={LIBVESELKA_EVENTS_PATH} component={props => <LibVeselkaEventsPage {...props} />} app={this} />
						<Route exact path={LIBVESELKA_GALLERY_PATH} component={props => <LibVeselkaGalleryPage {...props} />} app={this} />
						<Route exact path={LIBVESELKA_INFO_PATH} component={props => <LibVeselkaInfoPage {...props} />} app={this} />
						<Route exact path={LIBVSESVIT_CONTACTS_PATH} component={props => <LibVsesvitContactsPage {...props} />} app={this} />
						<Route exact path={LIBVSESVIT_ECATALOG_PATH} component={props => <LibVsesvitECatalogPage {...props} />} app={this} />
						<Route exact path={LIBVSESVIT_EVENTS_PATH} component={props => <LibVsesvitEventsPage {...props} />} app={this} />
						<Route exact path={LIBVSESVIT_GALLERY_PATH} component={props => <LibVsesvitGalleryPage {...props} />} app={this} />
						<Route exact path={LIBVSESVIT_INFO_PATH} component={props => <LibVsesvitInfoPage {...props} />} app={this} />
						<Route exact path={LIBVYSHNI_CONTACTS_PATH} component={props => <LibVyshniContactsPage {...props} />} app={this} />
						<Route exact path={LIBVYSHNI_ECATALOG_PATH} component={props => <LibVyshniECatalogPage {...props} />} app={this} />
						<Route exact path={LIBVYSHNI_EVENTS_PATH} component={props => <LibVyshniEventsPage {...props} />} app={this} />
						<Route exact path={LIBVYSHNI_GALLERY_PATH} component={props => <LibVyshniGalleryPage {...props} />} app={this} />
						<Route exact path={LIBVYSHNI_INFO_PATH} component={props => <LibVyshniInfoPage {...props} />} app={this} />

						<Route exact path={BIBLIOGRAPHIC_PATH} component={props => <BibliographicListPage {...props} />} app={this} />
						<Route exact path={B201_PATH} component={props => <B201 {...props} />} app={this} />
						<Route exact path={B202_PATH} component={props => <B202 {...props} />} app={this} />
						<Route exact path={B203_PATH} component={props => <B203 {...props} />} app={this} />
						<Route exact path={B204_PATH} component={props => <B204 {...props} />} app={this} />
						<Route exact path={B205_PATH} component={props => <B205 {...props} />} app={this} />
						<Route exact path={B206_PATH} component={props => <B206 {...props} />} app={this} />
						<Route exact path={B207_PATH} component={props => <B207 {...props} />} app={this} />
						<Route exact path={B208_PATH} component={props => <B208 {...props} />} app={this} />
						<Route exact path={B209_PATH} component={props => <B209 {...props} />} app={this} />
						
						<Route exact path={ADMIN_PATH} component={props => <AdminMainPage {...props} />} app={this} />
						<AuthorizedRoute exact path={ADMIN_IMPORTANT_INFO_PATH} 
							component={props => <AdminImportantInfoPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_SLIDE_LIST_PATH} 
							component={props => <AdminSlideListPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_EVENT_LIST_PATH} 
							component={props => <AdminEventListPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={`${ADMIN_EVENT_PATH}/:id`}
							component={props => <AdminEventPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_EXHIBITION_LIST_PATH} 
							component={props => <AdminExhibitionListPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={`${ADMIN_EXHIBITION_PATH}/:id`}
							component={props => <AdminExhibitionPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_POSTER_LIST_PATH} 
							component={props => <AdminPosterListPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={`${ADMIN_POSTER_PATH}/:id`}
							component={props => <AdminPosterPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_BOOK_LIST_PATH} 
							component={props => <AdminBookListPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={`${ADMIN_BOOK_PATH}/:id`}
							component={props => <AdminBookPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_BOOK_INTERESTING_LIST_PATH} 
							component={props => <AdminBookInterestingListPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={`${ADMIN_BOOK_INTERESTING_PATH}/:id`}
							component={props => <AdminBookInterestingPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_BOOK_NEW_LIST_PATH} 
							component={props => <AdminBookNewListPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={`${ADMIN_BOOK_NEW_PATH}/:id`}
							component={props => <AdminBookNewPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_CLUB_LIST_PATH} 
							component={props => <AdminClubListPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={`${ADMIN_CLUB_PATH}/:id`}
							component={props => <AdminClubPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_ONLINE_INFO_PATH} 
							component={props => <AdminOnlineInfoPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_USER_LIST_PATH} 
							component={props => <AdminUserListPage {...props} />} app={this} 
						/>
						<AuthorizedRoute exact path={ADMIN_LOGOUT_PATH} 
							component={props => <AdminMainPage {...props} />} 
							app={this} 
						/>

						<Route path="*" component={props => <MainPage {...props} />} app={this} />
					</Switch>
			</Router>
		);
	}
}

function AuthorizedRoute({ component: Component, ...rest }) {
	if( rest.path == ADMIN_LOGOUT_PATH ) {
		clearUserInfo();
		return (<Route {...rest} render={props => <AdminMainPage {...props} app={rest.app} /> } />);
	}
	var userInfo = getUserInfo();
	if( userInfo == null ) {
		return (
			<Route {...rest} render={props => <AdminMainPage {...props} app={rest.app} /> } />
		);
	} 
	return (
		<Route {...rest} render={props => <Component {...props} app={rest.app} /> } />
	);
}

export default App;
