import React, { Component } from 'react';
import styles from './components.module.css';

class BreadCrumbs extends Component {

	render() {
		var items = this.props.breadCrumbs.map((bc, index) => {
			if( bc.selected ) {
				return (
					<span key={index} className={styles.spanBreadCrumbsSelected}>
						&nbsp;{bc.title}
					</span>
				);
			} else {
				return (
					<span key={index} className={styles.spanBreadCrumbsItem}>
						&nbsp;<a href={bc.url}>{bc.title}</a>&nbsp;/
					</span> 
				);
			}
		});
		return (
			<div className={styles.divBreadCrumbs}>
				{items}
			</div>
		)
	}
}

export default BreadCrumbs;
