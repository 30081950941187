import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Gallery from "../../../components/Gallery";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIB150_INFO_PATH, LIB150_GALLERY_PATH, } from '../../../util/routes';
import { LIB150 } from '../../../util/consts';

class Lib150GalleryPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIB150.title, url: LIB150_INFO_PATH, selected: false },
			{title: 'Фотогалерея', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIB150_GALLERY_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIB150_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var imageList = [
		{ url: '/lib150/001.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib150/002.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib150/003.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib150/004.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib150/005.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib150/006.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib150/007.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib150/008.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib150/009.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib150/010.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
	];
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIB150.title}<br/>
				Фотогалерея
			</div>
			<Gallery imageUrlPrefix={'/img/'} images={imageList} />
		</div>
	);
}

export default Lib150GalleryPage;
