import React, { Component } from 'react';
import styles from '../bibliographic.module.css';

import BibliographicPage from "../BibliographicPage";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH
} from '../../../util/routes';

import image01 from './image01.jpg';
import image02 from './image02.jpg';

class B205 extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, selected: false },
			{title: 'Як дорослій людині вивчити українську мову', url: '', selected: true }
		];
		var body = (<Body />);
		return (
			<BibliographicPage breadCrumbs={breadCrumbs} body={body} />
		);
	}
}

function Body(props) {
return(

<div style={{width: '100%'}}>
	<div className={styles.divBibliographicHeader}>Як дорослій людині вивчити українську мову</div>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image01} style={{width: '600px'}} />
	</div>
	<div className={styles.divHeaderBibliographicType}>
		Вебліографічне повідомлення    
	</div>

	<p className={styles.pCommon}>
	Вебліографічне повідомлення «Як дорослій людині вивчити українську мову» ставить за мету пробудити інтерес у користувачів до вивчення української мови та показати шляхи її вивчення. Умовно, відкриваємо вам арсенал, а зброю відточуєте вже ви. 
	</p>
	<p className={styles.pCommon}>
	Зараз вивчати українську мову просто, як ніколи раніше. У нас є розкішні запаси: україномовні книжки, україномовний дубляж фільмів, україномовні веб-сайти… 
	</p>
	<p className={styles.pCommon}>
	Як зробити це, не відвідуючи спеціальних курсів, без підручників та "зубріння", ми обрали для вас 10 найдієвіших порад. Також приводимо «Мобільні додатки для вивчення української мови» та «Безкоштовні онлайн-курси української».
	</p>
	<p className={styles.pCommon}>
	При підготовці вебліографічного повідомлення використано інтернет-ресурси.    
	</p>
	<p className={styles.pCommon}>
	Відповідальний за випуск: Т.І. Шевченко
	</p>

	<p className={styles.epigraph}>
	«Мова – то серце народу: гине мова – гине народ».<br/>
	(Іван Огієнко)
	</p>

	<p className={styles.header}>
	Як дорослій людині вивчити українську мову. Топ-10 порад
	</p>	
	
	<p className={styles.pCommon}>
	Якщо ти живеш в Україні, то маєш знати українську мову та вміти нею говорити. Але що робити, якщо ти виріс у російськомовному оточенні — дитячий садок, школа, університет, робота, друзі, — а в дорослому віці так складно переналаштуватися, тим більше, що часу на навчання залишається дедалі менше. Бо робота. Бо родина. Тому що ще багато інших “бо”. Але ми маємо топ-10 порад, щоб полегшити тобі це завдання. Лови!
	</p>	
	<p className={styles.pCommon}>
	1. Спробуй змінити мовні налаштування всіх гаджетів на україномовні. Чим глибше ти поринеш у мовне середовище, тим легше та швидше вивчиш мову. І одного разу скажеш не відправити повідомлення, а надіслати. Зорове сприйняття дає сильний результат.
	</p>	
	<p className={styles.pCommon}>
	2. Купи збірку віршів чи прози українською. Або ж просто підпишись у соцмережах на україномовного автора та читай. Наприклад, у “Фейсбуку” є багато спільнот, які радять добрі вітчизняні книги та якісні переклади зарубіжної літератури українською мовою.
	</p>	
	<p className={styles.pCommon}>
	3. Додай у плейлист українські пісні. Йдучи на роботу чи з роботи, слухай та насолоджуйся. До того ж, вибір є. І чималий.
	</p>	
	<p className={styles.pCommon}>
	4. Завантаж на гаджет цікаві програми, які спрощують вивчення мови. Наприклад, із вправами на правильне наголос. Або онлайн-підручник. І вчись граючись. А якщо ти віддаєш перевагу паперовим книгам, є великий вибір посібників з української мови у зручному кишеньковому або більшому форматі.
	</p>	
	<p className={styles.pCommon}>
	5. Спілкуйся українською! Звичайно, перші спроби будуть неідеальними, але робити їх необхідно. Почни з вітання, з розмови в магазині біля будинку, з коротких повідомлень другові.
	</p>	
	<p className={styles.pCommon}>
	6. Рильський мав рацію. Не бійся заглядати у словник. Словники допоможуть удосконалити навички. І не обов'язково йти до бібліотеки чи магазину за 5-кілограмовою книгою та скрізь тягати її з собою. В онлайн-доступі є величезний вибір словників на будь-який смак.
	</p>	
	<p className={styles.pCommon}>
	7. Відвідуй експрес-курси з <a href="https://www.staff.ua/uk/corporate/inshi-movy/ukrainska">вивчення української мови</a> та вдосконалення розмовних навичок. І правила, та їх застосування краще сприймаються у формі діалогу чи гри. А ще на таких курсах можна знайти цікавого співрозмовника та спілкуватися з ним, відточувати мову.
	</p>	
	<p className={styles.pCommon}>
	8. Не бійся помилятися. Дитина не з першого кроку починає ходити, але ніхто не забороняє їй продовжувати вчитися. Так і з вивченням мови: у понеділок ти можеш зробити помилку, а в середу – вивчити два нові слова.
	</p>	
	<p className={styles.pCommon}>
	9. Вибери улюблену україномовну радіостанцію та слухай новини України, світу, прогноз погоди та інші цікавості. Можна їхати за кермом або готувати пиріг, а диктор у цей час дасть тобі темп вимови й продемонструє вірні наголоси.
	</p>	
	<p className={styles.pCommon}>
	10. Немає межі досконалості! Шліфуй свої навички правильно та красиво говорити. Навіть якщо тобі здається, що ти вже все знаєш, ніколи не завадить зазирнути у тлумачний словник.
	</p>	
	<p className={styles.pCommon}>
	Сподіваємось, ці топ-10 порад допоможуть тобі вивчити українську мову або хоча б покажуть, що це набагато простіше, ніж ти думаєш. Все починається з малого: сьогодні – «Добрий день!», а завтра – «Доброго ранку! Мені, будь ласка, пакетик, середній. Поповнення мобільного не потрібно, дякую! Так, решту на картку. До побачення!"
	</p>	
	<p className={styles.pCommon}>
	Впусти українську у своє життя та серце!
	</p>	
	<p className={styles.pCommon}>
	І все буде україномовно! 
	</p>	
	<p className={styles.pCommon}>
	Джерело: <a href="https://www.staff.ua/uk/blog/yak-doroslii-liudyni-vyvchyty-ukrainsku-movu-top-10-porad">https://www.staff.ua/uk/blog/yak-doroslii-liudyni-vyvchyty-ukrainsku-movu-top-10-porad</a>
	</p>	

	<p className={styles.header}>
	Мобільні додатки для вивчення української мови
	</p>	

	<p className={styles.subheader}>
	Mova
	</p>	
	<p className={styles.pCommon}>
	Програма створена за ініціативи освітнього проекту «Мова – ДНК нації» для вдосконалення знань української мови. Допоможе згадати основні правила правопису та дізнатися про нові фразеологізми та синоніми. Також є сайт <a href="http://ukr-mova.in.ua">ukr-mova.in.ua</a>.
	</p>	
	<p className={styles.subheader}>
	«ЙОЙ»
	</p>	
	<p className={styles.pCommon}>
	Спочатку додаток розробили для дітей, які навчаються у школах мовами національних меншин, які проживають на території України, проте він буде корисним і для дорослих. В ігровій формі воно навчить українців через моделювання різних життєвих ситуацій.
	</p>	
	<p className={styles.subheader}>
	«Р.І.Д.»
	</p>	
	<p className={styles.pCommon}>
	Додаток допомагає покращити та заглибитись у знання українського слова, культури, історії на території країни та за її межами. Проект пропонує кожному охочому збагатити свій словниковий запас колоритними українськими фразеологізмами та стійкими виразами.
	</p>	
	<p className={styles.subheader}>
	«Слова зі слова»
	</p>	
	<p className={styles.pCommon}>
	Інтерактивна гра у вигляді мовного тренажера, в якому потрібно з одного слова скласти інше українською мовою. Старанна робота дозволяє учасникам гри накопичувати бали та використовувати їх для подальшого зростання.
	</p>	
	<p className={styles.subheader}>
	«Вчимо і граємо. Українська мова”
	</p>	
	<p className={styles.pCommon}>
	Це захоплива та розвивальна гра, мобільний репетитор для самостійного вивчення лексики та фонетики початкового рівня української мови. Список слів спеціально підібраний із різних тем, що використовуються у повсякденному житті.
	</p>	

	<p className={styles.header}>
	Безкоштовні онлайн-курси української
	</p>	

	<p className={styles.subheader}>
	«Офіційний сайт української мови»
	</p>	
	<p className={styles.pCommon}>
	Ресурс <a href="http://ukrainskamova.com/">ukrainskamova.com</a> створений для вивчення української мови з нуля. Тут можна знайти шкільні підручники для всіх класів, правила української мови, а також словник синонімів, цікаві відео пояснення.
	</p>	
	<p className={styles.subheader}>
	«Є-мова»
	</p>	
	<p className={styles.pCommon}>
	Проект <a href="https://emova.language-ua.online/">emova.language-ua.online</a> орієнтований на російськомовних українців. Для кожного заняття є практичні вправи, невеликі відео та тексти з роз’ясненнями. Це допоможе використати українську мову у різних життєвих ситуаціях та сферах спілкування.
	</p>	
	<p className={styles.subheader}>
	«Єдині»
	</p>	
	<p className={styles.pCommon}>
	Просвітницький проект запустили ще у квітні для тих, хто прийняв рішення почати спілкуватися українською мовою та потребує щоденної практики та підтримки однодумців. Курс розрахований на 28 днів. Щоб розпочати навчання, необхідно пройти реєстрацію.
	</p>	
	<p className={styles.subheader}>
	Learning passport
	</p>	
	<p className={styles.pCommon}>
	Безкоштовні <a href="https://uied.org.ua/en/">онлайн-курси</a> рідної мови на освітній платформі “ЮНІСЕФ”. Тут розкажуть дошкільнятам та учням початкової школи, як уникати помилок, говорячи українською.
	</p>	
	<p className={styles.subheader}>
	«Слово на ново»
	</p>	
	<p className={styles.pCommon}>
	На <a href="https://www.facebook.com/SlovonanOvo">сторінці у «Фейсбуку»</a> мовні фахівці публікують лекції, обговорення, корисні матеріали, книги та міркування, які допоможуть у вивченні державної мови.
	</p>	
	<p className={styles.subheader}>
	«Українська для українців»
	</p>	
	<p className={styles.pCommon}>
	Безкоштовні уроки української мови для всіх охочих – дітей та дорослих. Уроки відбуваються щодня з понеділка по четвер, початок о 16:00. Посилання на сайт: <a href="https://pipllyceum.com/">pipllyceum.com</a>
	</p>	
	<p className={styles.subheader}>
	«Відкриті курси УКУ»
	</p>	
	<p className={styles.pCommon}>
	Український католицький університет також пропонує охочим відвідати онлайн-уроки з вивчення рідної мови. Посилання на сайт: <a href="https://open.ucu.edu.ua/">open.ucu.edu.ua</a> 
	</p>	
	<p className={styles.subheader}>
	«Ламай систему – говори по-русски»
	</p>	
	<p className={styles.pCommon}>
	Безкоштовні курси української мови на <a href="https://www.youtube.com/channel/UC6dSeYprtkKF9UaFSSmNivA">каналі «Ютубу»</a>. Основна мета – підвищення загального рівня володіння державною мовою, які проживають у районах, де звужено сферу вживання української мови.
	</p>	
	<p className={styles.subheader}>
	«Національна платформа з вивчення української мови»
	</p>	
	<p className={styles.pCommon}>
	<a href="https://speakukraine.net/">Національна платформа з вивчення української мови</a> Міністерства культури та інформаційної політики України зібрала в одному місці ефективні онлайн та офлайн-ресурси, а також мобільні програми з вивчення української мови з «нуля».
	</p>	
	<p className={styles.subheader}>
	«Українська за 27 уроків»
	</p>	
	<p className={styles.pCommon}>
	Освітня платформа Prometheus запустила <a href="https://prometheus.org.ua/">курси української мови</a>, щоб допомогти українцям подолати мовні бар’єри та наважитися говорити українською.
	</p>	
	<p className={styles.subheader}>
	Busuu
	</p>	
	<p className={styles.pCommon}>
	Мовні курси розроблені експертами-лінгвістами для українців. Мета навчання – розширити можливості спілкування. Посилання на сайт: <a href="https://www.busuu.com/ru">busuu.com</a>
	</p>	

	<p className={styles.pCommon}>
	Якщо способи вивчення української мови за допомогою онлайн-курсів, мобільних додатків або на Ютуб вам не підходять, радимо звернутися до традиційного способу навчання — з уроками та викладачем.
	</p>	

	<p className={styles.pCommon}>
	Джерело: <a href="https://odessa-life.od.ua/uk/article-uk/iak-vyvchyty-ukrainsku-movu-lehko-ta-shvydko">https://odessa-life.od.ua/uk/article-uk/iak-vyvchyty-ukrainsku-movu-lehko-ta-shvydko</a>
	</p>	
	
	<p className={styles.pCommon} style={{textAlign: 'center'}}>
		<img src={image02} />
	</p>

</div>
		
);
}

export default B205;
