import React, { Component } from 'react';
import stylesAdmin from './admin.module.css';

import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Spinner from "../../components/Spinner";
import DivInnerHtml from '../../components/DivInnerHtml';
import Message from "../../components/Message";

import { getAdminBookInterestingUrl, dateInputFormatToShowFormat } from "../../util/tools";
import { performApiRequest, BOOK_LIST_API_URL, BOOK_DELETE_API_URL } from "../../util/restapi";
import { ADMIN_BOOK_INTERESTING_LIST_PATH, ADMIN_BOOK_INTERESTING_PATH } from "../../util/routes";
import { ANY, ONLY_TRUE, ONLY_FALSE } from "../../util/consts";

const STATE_WORKING = 'working';
const STATE_READY = 'ready';

class AdminBookInterestingListPage extends Component {
	
	constructor(props) {
		super(props);
		this.bookList = [];
		this.messageHandler = {
			showMessage: null,
			doAfterClose: this.doDelete,
			confirm: false,
			bookId: null
		};
		this.state = {
			formState: STATE_READY,
			bookList: this.bookList,
			deletingBook: null,
			ok: true
		};
	}
	componentDidMount = async () => {
		this.setState({formState: STATE_WORKING});
		var request = {
			query: {
				type: '',
				isNew: ANY,
				isInteresting: ONLY_TRUE,
				showOnMainPage: ANY,
				showOnBookList: ANY,
				skip: 0,
				count: 0 
			}
		}
		var response = await performApiRequest(BOOK_LIST_API_URL, request);
		console.log( response );
		this.ok = response.ok;
		if( response.ok ) {
			this.bookList = response.bookList;
		}
		this.setState({formState: STATE_READY, ok: response.ok});
	}

	deleteBook = (bookId) => {
		this.messageHandler.bookId = bookId;
		this.messageHandler.doAfterClose = this.doDelete;
		this.messageHandler.showMessage( "Вилучити вибрану книгу?" );
	}
	doDelete = async () => {
		if( this.messageHandler.confirm ) {
			var bookId = this.messageHandler.bookId;
			this.setState({deletingBook: bookId});
			var request = { bookId: bookId };
			var response = await performApiRequest(BOOK_DELETE_API_URL, request);
			if( response.ok ) {
				for( let i=this.bookList.length-1; i >= 0 ; i--) {
					if( this.bookList[i].bookId == bookId )
						this.bookList.splice( i, 1 );
				}
			}
			this.setState({deletingBook: null, bookList: this.bookList});
		}
		this.messageHandler.confirm = false;
		this.messageHandler.bookId = null;
	}
	
	render() {
		var leftSide = (<AdminLeftSide path={ADMIN_BOOK_INTERESTING_LIST_PATH} />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	var holder = props.holder;
	return (
		<div style={{marginBottom: '30px'}}>
			<div className={stylesAdmin.divContentHeader}>
				Цікаве на полицях
			</div>
			<div style={{marginTop: '20px', marginBottom: '10px'}}>
				<a href= {ADMIN_BOOK_INTERESTING_PATH + '/0'} target='_blank'>Додати</a>
			</div>
			<BookList holder={holder} />
		</div>
	)
}

function BookList(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_WORKING ) {
		return ( <Spinner /> );
	}
	if( ! holder.state.ok ) {
		return (
			<div style={{textAlign: 'center'}}>
				Під час виконання виникла помилка
			</div>
		);
	}
	var bookListHtml = holder.bookList.map( (book, index) => {
		return (<Book key={index} book={book} holder={holder} />);
	} );
	return (
		<React.Fragment>
			{bookListHtml}
			<Message messageHandler={holder.messageHandler}/>
		</React.Fragment>
	);
}

function Book(props) {
	var holder = props.holder;
	var book = props.book;
	var hrefEdit = getAdminBookInterestingUrl( book.bookId );
	if( holder.state.deletingBook == book.bookId ) {
		return (
			<div>
				<Spinner />
			</div>
		);
	}
	return (
		<div style={{borderTop: '2px solid green', padding: '10px 0 10px 0'}}>
			<DivInnerHtml style={{marginBottom: '10px'}} text={book.header} />		
			<DivInnerHtml text={book.card} />		
			<div style={{marginTop: '10px'}}>
				Дата: { dateInputFormatToShowFormat(book.bookDate) }
			</div>
			<div style={{marginTop: '10px'}}>
				<a href={hrefEdit}>Змінити</a>&nbsp;
				<button type='button' onClick={ () => {holder.deleteBook(book.bookId);} }>Вилучити</button>
			</div>
		</div>
	);
}

export default AdminBookInterestingListPage;