import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import ConditionText from "./ConditionText";
import Book from "./Book";

import { performApiRequest, DOC_SEARCH_API_URL } from "../../util/restapi"

const STATE_EMPTY = 'empty';
const STATE_WAITING = 'waiting';
const STATE_READY = 'ready';

const PAGE_SIZE = 20;

class SearchResult extends Component {

	constructor(props) {
		super(props);

		this.componentSearchEngine = props.holder;
		this.componentSearchEngine.componentSearchResult = this;
		
		this.query = null;
		this.bookList = [];
		this.bookCount = null;
		this.pageCount = null;
		
		this.state = {
			formState: STATE_EMPTY,
			pageCurrent: 0,
			ok: true
		}
	}

	doSearch = (query) => {
		this.query = query;
		this.query.pageSize = PAGE_SIZE;
		this.populatePage(1);
	}
	populatePage = async (page) => {
		this.setState({formState: STATE_WAITING}, () => {this.doPopulatePage(page); });
	}
	doPopulatePage = async (page) => {
		this.query.page = page;
		var response = await performApiRequest(DOC_SEARCH_API_URL, this.query);
		console.log( response );
		if( response.ok ) {
			this.bookList = response.bookList;
			if( this.bookCount == null ) {
				this.bookCount = response.bookCount;
				this.pageCount = Math.floor( (this.bookCount-1) / PAGE_SIZE) + 1;
			}
		}
		this.setState({formState: STATE_READY, pageCurrent: page, ok: response.ok});
	}
	onPageClick = async (page) => {
		this.populatePage(page)
		this.setState({pageCurrent: page });
	}
	newSearch = () => {
		this.componentSearchEngine.startNewSearchForm();
	}
	
	render() {
		if( this.state.formState == STATE_EMPTY ) {
			return (null);
		}
		if( this.state.formState == STATE_WAITING ) {
			return (
				<Spinner />
			);
		} else { // STATE_READY
			if( ! this.state.ok ) {
				return (
					<div style={{width: '100%', textAlign: 'center'}}>
						Вибачте, сервіс тимчасово недоступний
					</div>
				);
			}
			if( this.bookCount == 0 ) {
				return (
					<React.Fragment>
						<div style={{marginBottom: '20px'}}>
							<span style={{fontWeight: 'bold', fontSize: '16pt'}}>Умови пошуку: </span>
							<ConditionText query={this.query} />
						</div>
						<div style={{width: '100%', textAlign: 'left'}}>
							Вибачте, нічого не знайдено.
						</div>
					</React.Fragment>
				);
			}
			var booksHtml = this.bookList.map( (book, index) => {
				return (<Book key={index} holder={this} book={book} />);
			});
			return (
				<React.Fragment>
					<div style={{display: 'flex', width: '100%', marginBottom: '10px', fontWeight: 'bold', fontSize: '16pt'}}>
						<div style={{fontWeight: 'bold', fontSize: '16pt'}}>Результати пошуку</div>
						<div style={{flexGrow: 100}}>&nbsp;</div>
						<div style={{fontWeight: 'bold', fontSize: '16pt', cursor: 'pointer'}} onClick={this.newSearch} >
							Новий пошук
						</div>
					</div>
					<div style={{marginBottom: '20px'}}>
						<span style={{fontWeight: 'bold', fontSize: '14pt'}}>Умови пошуку: </span>
						<ConditionText query={this.query} />
					</div>
					<Pagination 
						pageCount={this.pageCount} 
						pageNumber={this.state.pageCurrent} 
						onPageClick={this.onPageClick} 
					/>
					<hr className={stylesPage.hr} /> 
					{booksHtml}
					<Pagination 
						style={{marginTop: '15px', marginBottom: '15px'}}
						pageCount={this.pageCount} 
						pageNumber={this.state.pageCurrent} 
						onPageClick={this.onPageClick} 
					/>
				</React.Fragment>
			);
		}
	}
}

export default SearchResult;
