import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";
import SearchEngine from "../../ecatalog/SearchEngine";
import { LIBVYSHNI_SEARCH_FORM } from "../../ecatalog/SearchForm";

import { MAIN_PATH, LIBVYSHNI_INFO_PATH, LIBVYSHNI_ECATALOG_PATH } from '../../../util/routes';
import { LIBVYSHNI } from '../../../util/consts';

class LibVyshniECatalogPage extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBVYSHNI.title, url: LIBVYSHNI_INFO_PATH, selected: false },
			{title: 'Електронний каталог', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBVYSHNI_ECATALOG_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBVYSHNI_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBVYSHNI.title}<br/>
				Електронний каталог
			</div>
			<SearchEngine searchFormId={LIBVYSHNI_SEARCH_FORM} />
		</div>
	);
}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	},
}

export default LibVyshniECatalogPage;
