import React from 'react';
import Modal from 'react-responsive-modal';

class MessageModal extends React.Component {

	constructor(props) {
		super(props);
	}

	state = {
		isOpen: false
	}
	
	confirm = () => {
		this.props.confirm();
		this.props.close();
	}
	cancel = () => {
		this.props.close();
	}

	render() {
		return (
			<Modal open={this.props.isModalOpen} onClose={this.props.close} center styles={localStyles} >
				<div style={localStyles.divHeader}>
					Вилучення
				</div>
				<div style={localStyles.divBody}>
					{this.props.message()}
				</div>
				<div style={{display: 'flex', width: '100%', marginBottom: '30px'}}>
					<div style={{width: '50%', textAlign: 'center'}}>
						<button type='button' style={localStyles.confirmButton} onClick={this.confirm}>Так</button>
					</div>
					<div style={{width: '50%', textAlign: 'center'}}>
						<button type='button' style={localStyles.confirmButton} onClick={this.cancel}>Ні</button>
					</div>
				</div>
			</Modal>
		);
	}
}

const localStyles = {
	modal: {
		background: '#fff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '500px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#F00',
		outline: 'none',
	},
	confirmButton: {
		width: '50px',
		height: '30px',
		fontSize: '12pt'
	},
	divHeader: {
		fontSize: '14pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0'
	},
	divBody: {
		fontSize: '16pt',
		textAlign: 'center',
		marginTop: '30px',
		marginBottom: '30px',
		padding: '10px'
	}
};

export default MessageModal;