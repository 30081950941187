import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";
import EventListBody from "../../event/EventListBody";

import { MAIN_PATH, LIBKNYGOLYUB_INFO_PATH, LIBKNYGOLYUB_EVENTS_PATH, } from '../../../util/routes';
import { LIBKNYGOLYUB } from '../../../util/consts';

class LibKnygolyubEventsPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBKNYGOLYUB.title, url: LIBKNYGOLYUB_INFO_PATH, selected: false },
			{title: 'Новини та події', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBKNYGOLYUB_EVENTS_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBKNYGOLYUB_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBKNYGOLYUB.title}<br/>
				Новини та події
			</div>
			<EventListBody libraryId={LIBKNYGOLYUB.cipher} />
		</div>
	);
}

export default LibKnygolyubEventsPage;
