import config from '../config/config';

export const ROOT_PATH = `${config.PATH}/`;

export const MAIN_PATH = `${config.PATH}/main`;
export const EVENT_LIST_PATH = `${config.PATH}/events`;
export const EVENT_PATH = `${config.PATH}/event`;
export const EXHIBITION_LIST_PATH = `${config.PATH}/exhibitions`;
export const EXHIBITION_PATH = `${config.PATH}/exhibition`;
export const POSTER_LIST_PATH = `${config.PATH}/posters`;
export const POSTER_PATH = `${config.PATH}/poster`;
export const CLUBS_PATH = `${config.PATH}/clubs`;
export const BLOG_PATH = `${config.PATH}/blog`;
export const ECATALOG_PATH = `${config.PATH}/ecatalog`;
export const ONLINE_PATH = `${config.PATH}/online`;
export const SKS_PATH = `${config.PATH}/sks`;
export const KKS_PATH = `${config.PATH}/kks`;
export const BOOK_DESCRIPTION_PATH = `${config.PATH}/book`;
export const BOOKS_NEW_PATH = `${config.PATH}/books/new`;
export const BOOKS_INTERESTING_PATH = `${config.PATH}/books/interesting`;
export const SERVICES_PATH = `${config.PATH}/services`;
export const CONTACTS_PATH = `${config.PATH}/contacts`;
export const SCHEDULE_PATH = `${config.PATH}/schedule`;
export const SITEMAP_PATH = `${config.PATH}/sitemap`;
export const RULES_PATH = `${config.PATH}/rules`;
export const ADMINISTRATION_PATH = `${config.PATH}/administration`;

export const LIBCRB_CONTACTS_PATH = `${config.PATH}/libcrb/contacts`;
export const LIBCRB_ECATALOG_PATH = `${config.PATH}/libcrb/ecatalog`;
export const LIBCRB_EVENTS_PATH = `${config.PATH}/libcrb/events`;
export const LIBCRB_GALLERY_PATH = `${config.PATH}/libcrb/gallery`;
export const LIBCRB_INFO_PATH = `${config.PATH}/libcrb/info`;
export const LIBCRB_SERVICES_PATH = `${config.PATH}/libcrb/services`;

export const LIB149_CONTACTS_PATH = `${config.PATH}/lib149/contacts`;
export const LIB149_ECATALOG_PATH = `${config.PATH}/lib149/ecatalog`;
export const LIB149_EVENTS_PATH = `${config.PATH}/lib149/events`;
export const LIB149_GALLERY_PATH = `${config.PATH}/lib149/gallery`;
export const LIB149_INFO_PATH = `${config.PATH}/lib149/info`;

export const LIB150_CONTACTS_PATH = `${config.PATH}/lib150/contacts`;
export const LIB150_ECATALOG_PATH = `${config.PATH}/lib150/ecatalog`;
export const LIB150_EVENTS_PATH = `${config.PATH}/lib150/events`;
export const LIB150_GALLERY_PATH = `${config.PATH}/lib150/gallery`;
export const LIB150_INFO_PATH = `${config.PATH}/lib150/info`;

export const LIB8_CONTACTS_PATH = `${config.PATH}/lib8/contacts`;
export const LIB8_EVENTS_PATH = `${config.PATH}/lib8/events`;
export const LIB8_GALLERY_PATH = `${config.PATH}/lib8/gallery`;
export const LIB8_INFO_PATH = `${config.PATH}/lib8/info`;

export const LIBBILOKUR_CONTACTS_PATH = `${config.PATH}/libbilokur/contacts`;
export const LIBBILOKUR_ECATALOG_PATH = `${config.PATH}/libbilokur/ecatalog`;
export const LIBBILOKUR_EVENTS_PATH = `${config.PATH}/libbilokur/events`;
export const LIBBILOKUR_GALLERY_PATH = `${config.PATH}/libbilokur/gallery`;
export const LIBBILOKUR_INFO_PATH = `${config.PATH}/libbilokur/info`;

export const LIBESTET_CONTACTS_PATH = `${config.PATH}/libestet/contacts`;
export const LIBESTET_ECATALOG_PATH = `${config.PATH}/libestet/ecatalog`;
export const LIBESTET_EVENTS_PATH = `${config.PATH}/libestet/events`;
export const LIBESTET_GALLERY_PATH = `${config.PATH}/libestet/gallery`;
export const LIBESTET_INFO_PATH = `${config.PATH}/libestet/info`;

export const LIBJERELO_CONTACTS_PATH = `${config.PATH}/libjerelo/contacts`;
export const LIBJERELO_EVENTS_PATH = `${config.PATH}/libjerelo/events`;
export const LIBJERELO_GALLERY_PATH = `${config.PATH}/libjerelo/gallery`;
export const LIBJERELO_INFO_PATH = `${config.PATH}/libjerelo/info`;

export const LIBKNYGOLYUB_CONTACTS_PATH = `${config.PATH}/libknygolyb/contacts`;
export const LIBKNYGOLYUB_ECATALOG_PATH = `${config.PATH}/libknygolyb/ecatalog`;
export const LIBKNYGOLYUB_EVENTS_PATH = `${config.PATH}/libknygolyb/events`;
export const LIBKNYGOLYUB_GALLERY_PATH = `${config.PATH}/libknygolyb/gallery`;
export const LIBKNYGOLYUB_INFO_PATH = `${config.PATH}/libknygolyb/info`;

export const LIBSVITOGLYAD_CONTACTS_PATH = `${config.PATH}/libsvitoglyad/contacts`;
export const LIBSVITOGLYAD_ECATALOG_PATH = `${config.PATH}/libsvitoglyad/ecatalog`;
export const LIBSVITOGLYAD_EVENTS_PATH = `${config.PATH}/libsvitoglyad/events`;
export const LIBSVITOGLYAD_GALLERY_PATH = `${config.PATH}/libsvitoglyad/gallery`;
export const LIBSVITOGLYAD_INFO_PATH = `${config.PATH}/libsvitoglyad/info`;

export const LIBTRUBLAINI_CONTACTS_PATH = `${config.PATH}/libtrublaini/contacts`;
export const LIBTRUBLAINI_EVENTS_PATH = `${config.PATH}/libtrublaini/events`;
export const LIBTRUBLAINI_GALLERY_PATH = `${config.PATH}/libtrublaini/gallery`;
export const LIBTRUBLAINI_INFO_PATH = `${config.PATH}/libtrublaini/info`;

export const LIBVESELKA_CONTACTS_PATH = `${config.PATH}/libveselka/contacts`;
export const LIBVESELKA_EVENTS_PATH = `${config.PATH}/libveselka/events`;
export const LIBVESELKA_GALLERY_PATH = `${config.PATH}/libveselka/gallery`;
export const LIBVESELKA_INFO_PATH = `${config.PATH}/libveselka/info`;

export const LIBVSESVIT_CONTACTS_PATH = `${config.PATH}/libvsesvit/contacts`;
export const LIBVSESVIT_ECATALOG_PATH = `${config.PATH}/libvsesvit/ecatalog`;
export const LIBVSESVIT_EVENTS_PATH = `${config.PATH}/libvsesvit/events`;
export const LIBVSESVIT_GALLERY_PATH = `${config.PATH}/libvsesvit/gallery`;
export const LIBVSESVIT_INFO_PATH = `${config.PATH}/libvsesvit/info`;

export const LIBVYSHNI_CONTACTS_PATH = `${config.PATH}/libvyshni/contacts`;
export const LIBVYSHNI_ECATALOG_PATH = `${config.PATH}/libvyshni/ecatalog`;
export const LIBVYSHNI_EVENTS_PATH = `${config.PATH}/libvyshni/events`;
export const LIBVYSHNI_GALLERY_PATH = `${config.PATH}/libvyshni/gallery`;
export const LIBVYSHNI_INFO_PATH = `${config.PATH}/libvyshni/info`;

export const BIBLIOGRAPHIC_PATH = `${config.PATH}/bibliographic`;
export const B209_PATH = `${config.PATH}/bibliographic/b209`;
export const B208_PATH = `${config.PATH}/bibliographic/b208`;
export const B207_PATH = `${config.PATH}/bibliographic/b207`;
export const B206_PATH = `${config.PATH}/bibliographic/b206`;
export const B205_PATH = `${config.PATH}/bibliographic/b205`;
export const B204_PATH = `${config.PATH}/bibliographic/b204`;
export const B203_PATH = `${config.PATH}/bibliographic/b203`;
export const B202_PATH = `${config.PATH}/bibliographic/b202`;
export const B201_PATH = `${config.PATH}/bibliographic/b201`;

export const ADMIN_PATH = `${config.PATH}/admin`;
export const ADMIN_IMPORTANT_INFO_PATH = `${config.PATH}/admin/info`;
export const ADMIN_SLIDE_LIST_PATH = `${config.PATH}/admin/slides`;
export const ADMIN_EVENT_LIST_PATH = `${config.PATH}/admin/events`;
export const ADMIN_EVENT_PATH = `${config.PATH}/admin/event`;
export const ADMIN_EXHIBITION_LIST_PATH = `${config.PATH}/admin/exhibitions`;
export const ADMIN_EXHIBITION_PATH = `${config.PATH}/admin/exhibition`;
export const ADMIN_POSTER_LIST_PATH = `${config.PATH}/admin/posters`;
export const ADMIN_POSTER_PATH = `${config.PATH}/admin/poster`;
export const ADMIN_BOOK_LIST_PATH = `${config.PATH}/admin/books`;
export const ADMIN_BOOK_PATH = `${config.PATH}/admin/book`;
export const ADMIN_BOOK_INTERESTING_LIST_PATH = `${config.PATH}/admin/booksinterest`;
export const ADMIN_BOOK_INTERESTING_PATH = `${config.PATH}/admin/bookinterest`;
export const ADMIN_CLUB_LIST_PATH = `${config.PATH}/admin/clubs`;
export const ADMIN_CLUB_PATH = `${config.PATH}/admin/club`;
export const ADMIN_BOOK_NEW_LIST_PATH = `${config.PATH}/admin/booksnew`;
export const ADMIN_BOOK_NEW_PATH = `${config.PATH}/admin/booknew`;
export const ADMIN_ONLINE_INFO_PATH = `${config.PATH}/admin/onlineinfo`;
export const ADMIN_USER_LIST_PATH = `${config.PATH}/admin/users`;
export const ADMIN_LOGOUT_PATH = `${config.PATH}/admin/logout`;
