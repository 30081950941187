import React, { Component } from 'react';
import styles from '../bibliographic.module.css';

import BibliographicPage from "../BibliographicPage";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH
} from '../../../util/routes';

import image01 from './image01.png';
import image02 from './image02.jpg';
import image03 from './image03.jpg';
import image04 from './image04.jpg';
import image05 from './image05.jpg';
import image06 from './image06.jpg';
import image07 from './image07.jpg';
import image08 from './image08.jpg';
import image09 from './image09.jpg';
import image10 from './image10.jpg';
import image11 from './image11.jpg';
import image12 from './image12.jpg';
import image13 from './image13.jpg';
import image14 from './image14.png';
import table1 from './table1.png';
import table2 from './table2.png';

class B202 extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, selected: false },
			{title: 'Поради, які завжди порятують', url: '', selected: true }
		];
		var body = (<Body />);
		return (
			<BibliographicPage breadCrumbs={breadCrumbs} body={body} />
		);
	}
}

function Body(props) {
return(

<div style={{width: '100%'}}>
	<div className={styles.divBibliographicHeader}>Поради, які завжди порятують</div>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image01} style={{width: '600px'}} />
	</div>
	<div className={styles.divHeaderBibliographicType}>
		Бібліографічне повідомлення
	</div>
	<p className={styles.pCommon}>
	Бібліографічне повідомлення “Поради, які завжди порятують» містить статті-поради  з  газети 
	<span style={{fontWeight: 'bold'}}>«Вечірній Київ»</span>, які  допоможуть читачам здолати надзвичайні ситуації «принесені» російськими військовими злочинцями  для залякування і терору мирного населення України.
	</p>
	<p className={styles.pCommon}>
	Адресовано всім, хто цікавиться виживанням в екстремальних умовах  під час російсько-української війни.
	</p>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Від упорядника
	</p>

	<p className={styles.pCommon}>
	Від першого дня вторгнення і до сьогодні бібліотеки по всій Україні, де не ведуться бойові дії, є територією безпеки, де кожен, хто потребує, знайде прихисток і допомогу. Бібліотекарі щодня працюють, щоб кожен, хто має потребу, мав доступ до інформації та інтернету. Не менш важливою  стала робота з просвіти населення щодо дій у разі надзвичайної ситуації, поради як реагувати на блекаут та інші.
	</p>
	<p className={styles.pCommon}>
	Надзвичайна ситуація – порушення нормальних умов життя і діяльності людей на об`єкті або території, спричинене аварією, катастрофою, стихійним лихом чи іншою небезпечною подією, яка призвела (може призвести) до загибелі людей та / або значних матеріальних втрат.
	</p>
	<p className={styles.pCommon}>
	Тероризм - це політика залякування. Терор – страх, жах. Організатори терористичних актів мають за мету:
	</p>

	<ul>
		<li className={styles.liCommon}>
		посіяти страх серед населення,
		</li>
		<li className={styles.liCommon}>
		виявити протест проти політики уряду,
		</li>
		<li className={styles.liCommon}>
		спричинити економічні збитки державі, або окремим фірмам, особам,
		</li>
		<li className={styles.liCommon}>
		проведення потаємних терористичних актів.
		</li>
	</ul>

	<p className={styles.pCommon}>
	Щодня в Україні фіксують десятки подій , при яких відбувається порушення нормальних умов життя і діяльності людей і які можуть призвести або призводять до загибелі людей, травмування .
	</p>
	<p className={styles.pCommon}>
	Найбільш ефективний засіб зменшення шкоди та збитків , яких зазнають суспільство, держава і кожна окрема особа в результаті  надзвичайної ситуації , - запобігати їх виникненню, а в разі виникнення виконувати заходи, адекватні ситуації, що склалася.
	</p>
	<p className={styles.pCommon}>
	Загроза ракетних ударів агресора, руйнування інфраструктури та висока ймовірність тотального блекауту — такою прогнозують цьогорічну зиму. У людей виникає безліч питань: як підготуватися, чим запастися, якими мають бути невідкладні дії та головне — як це все зробити безпечно.
	</p>
	<p className={styles.pCommon}>
	На ці та інші найважливіші питання даватимемо відповідь. 
	</p>

	<p className={styles.pCommon} style={{textAlign: 'center', color: 'blue', fontSize: '16pt', fontStyle: 'italic'}}>
	Деякі поради населенню щодо дій в екстремальних умовах
	</p>
	<p className={styles.pCommon} style={{textAlign: 'center', fontSize: '16pt', fontWeight: 'bold', fontStyle: 'italic'}}>
	Вечірній Київ
	</p>
	<p className={styles.pCommon} style={{textAlign: 'center', fontSize: '14pt', fontWeight: 'bold'}}>
	Скільки коштує пережити блекаут: бюджетні поради для киян
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', color: 'blue', fontSize: '12'}}>
	30 ЛИСТОПАДА 2022 
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image02} width="200" /><br/>
	Сублімована їжа, консервація та інші речі, які стануть у пригоді в разі блекаута. 
	<br/>Фото: Борис Корпусенко
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	«Вечірній Київ» зібрав доступні способи зігрітися, приготувати  їжу та залишатися на зв’язку в екстремальних ситуаціях.
	</p>
	<p className={styles.pCommon}>
	Росія не припиняє обстрілювати енергетичні об’єкти України, зокрема і столицю. У результаті обстрілу 23 листопада частина столиці залишилася без електрики, води, опалення та зв’язку на кілька днів. Хоч спеціалісти відносно за короткий час повернули цивілізацію у місто, однак загроза повторної атаки не зникла.
	</p>
	<p className={styles.pCommon}>
	«Вечірній Київ» зібрав бюджетні способи, як пережити блекаут, та підрахував, у скільки приблизно це обійдеться.
	</p>

	<p  className={styles.pCommon} style={{fontSize: '14pt', fontWeight: 'bold'}}>
	АЛЬТЕРНАТИВА ЕЛЕКТРИЦІ
	</p>
	<p className={styles.pCommon}>
	Якщо вам потрібно освітити квартиру, то найбюджетнішими способами будуть свічки, гірлянди та світильники на батарейках. Розкажемо про них детальніше.
	</p>
	<p className={styles.pCommon}>
	Свічки
	</p>
	<p className={styles.pCommon}>
	Вони бувають воскові, парафінові та гелеві. Найбільш безпечними вважаються свічки з воску: вони не отруюють організм шкідливими випарами і не шкодять інтер’єру кіптявою. Якщо врахувати, що відключення світла триває годинами і горіти свічка буде довго, то її екологічність — важливий фактор.
	</p>
	<p className={styles.pCommon}>
	Більш поширені на ринку, відносно недорогі, але шкідливі - парафінові свічки. Парафін роблять з продуктів перероблювання нафти, тому при згорянні він коптить і виділяє шкідливі сполуки. Спалювати його у великій кількості — небезпечно для здоров’я, тож для блекауту — це не найкращий варіант. Хоча ціна на них нижча за воскові.
	</p>
	<p className={styles.pCommon}>
	Якщо столову парафінову свічку на популярному маркетплейсі можна знайти в середньому за 20 гривень за штуку, то воскова такого ж розміру буде коштувати щонайменше удвічі дорожче.
	</p>
	<p className={styles.pCommon}>
	Цікавий факт. Перед тим, як запалити свічки, покладіть її у холодильник. Замерзлий віск ущільниться і буде горіти удвічі довше.
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image03} width="200" /><br/>
	Гелеві свічки. Фото: з відкритих джерел
	</p>
	<p className={styles.pCommon}>
	Існують і гелеві свічки. Їх можна купити або виготовити самостійно з желатину, гліцерину і таніну. Горять вони яскравіше через свою прозорість і у 3-4 рази довше, ніж воскові та парафінову. Використовували гелеві свічки найчастіше як декор, тож і коштують вони дорожче — 80-200 гривень і вище.
	</p>
	<p className={styles.pCommon}>
	Тільки не забудьте запастися сірниками і запальничками.
	</p>

	<p className={styles.pCommon}>
	Гірлянди
	</p>
	<p className={styles.pCommon}>
	Бюджетним і відносно безпечним джерелом світла стануть гірлянди на батарейках. Ціни на них коливаються в середньому від 80 до 200 гривень. Перевага гірлянд у тому, що, на відміну від свічок, їх можна використовувати довше, потрібно буде лиш замінити батарейки.
	</p>
	<p className={styles.pCommon}>
	До речі, якщо «упакувати» гірлянду у трилітрову банку, вийде такий собі бюджетний світильник, який даватиме трохи більше світла, ніж гірлянда у її стандартному вигляді.
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image04} width="200" /><br/>
	Гірлянда на батарейка. Фото: Наталія Слінкіна
	</p>

	<p className={styles.pCommon}>
	Світильники
	</p>
	<p className={styles.pCommon}>
	Хорошою альтернативою звичайного кімнатного освітлення є кільцева лампа, яка працює від павербанку. Купити її можна від 300 до 900 гривень і вище, залежно від розміру. Наприклад, одна лампа з діаметром кільця 26 сантиметрів може освітити цілу кімнату.
	</p>
	<p className={styles.pCommon}>
	Більш бюджетним варіантом стануть світильники на батарейках. Їх можна купити від 80 до 400 гривень, залежно від розміру. Також популярності в останній місяць набув налобний ліхтарик. Замовити його в інтернеті можна за 150-200 гривень.
	</p>
	<p className={styles.pCommon}>
	Портативні акумулятори
	</p>
	<p className={styles.pCommon}>
	Якщо ви хочете живити електроприлади чи заряджати гаджети під час відключень, доведеться витратитися більше. Павербанк з батареєю 30 000 mAh можна купити щонайменше за 2000 гривень, але він зможе зарядити ваш смартфон від шести до дев’яти разів, залежно від моделі.
	</p>

	<p className={styles.pCommon} style={{fontSize: '12pt', fontWeight: 'bold'}}>
	СПОСОБИ ЗІГРІТИСЯ
	</p>

	<p className={styles.pCommon}>
	Кілька туристичних пристосувань і «бабусині лафхаки» допоможуть не замерзнути навіть у разі тривалої відсутності опалення.
	</p>
	<p className={styles.pCommon}>
	Одяг
	</p>
	<p className={styles.pCommon}>
	Все більшої популярності набуває термобілизна, завданням якої є утримання теплового балансу шкіри. Вона легка і швидко сохне. На інтернет-майданчиках комплект можна замовити від 600 гривень і вище.
	</p>
	<p className={styles.pCommon}>
	Серед шкарпеток — найефективнішими будуть пухові, які найчастіше використовують туристи під час походів. Вони допоможуть швидко зігріти ноги навіть в найсуворіших умовах, щоправда, ціна на них стартує від 1200 гривень.
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image05} width="200" /><br/>
	Пухові шкарпетки. Фото: з відкритих джерел
	</p>
	<p className={styles.pCommon}>
	Більш бюджетним варіантом будуть махрові, флісові та шерстяні шкарпетки, які можна купити в середньому за 50-200 гривень.
	</p>
	<p className={styles.pCommon}>
	Добре утримує температуру одяг з флісової тканини. Наприклад, на інтернет-майданчиках флісову кофту можна замовити за 150-400 гривень.
	</p>
	<p className={styles.pCommon}>
	Цікавий факт. Фліс може зберігати здатність до обігріву навіть у вологому стані, тканина швидко сохне і не вимагає особливого догляду.
	</p>
	<p className={styles.pCommon}>
	Грілки
	</p>
	<p className={styles.pCommon}>
	Якщо у вас є можливість нагрівати воду, то водяна грілка не дасть замерзнути протягом кількох годин. Купити її можна за 150-300 гривень і вище. Також її можна виготовити самостійно. Для цього достатньо налити у звичайну пластикову пляшку гарячої води і замотати її у рушник. Але будьте обережні: занадто висока температура змусить пластик плавитися — грілка буде непридатною для використання.
	</p>
	<p className={styles.pCommon}>
	Спальне обладнання
	</p>
	<p className={styles.pCommon}>
	У разі відсутності опалення обладнайте спальне місце ковдрами. Якщо ви лише збираєтеся їх придбати, до зверніть увагу, що ковдри бувають літні і зимні. Теплий наповнювач із синтетичних або натуральних волокон має середню щільність 270 г/м² (до 500 г/м² для найтепліших моделей) і підходить для найхолодніших місяців року.
	</p>
	<p className={styles.pCommon}>
	Можете також використовувати спільний мішок. Ціна на нього коливається від 700-1000 гривень.
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image06} width="200" /><br/>
	Спальний мішок. Фото: з відкритих жерел
	</p>
	<p className={styles.pCommon}>
	Дорожчим, але доволі ефективним варіантом буде електрична ковдра з підігріванням та USB. Вона може працювати від павербанка, але і коштує в середньому 5000 гривень.
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image07} width="200" /><br/>
	Електрична ковдра. Фото: з відкритих джерел
	</p>
	<p className={styles.pCommon}>
	Інші способи
	</p>
	<p className={styles.pCommon}>
	Намагайтеся не випускати з оселі тепло та не запускати туди холод. Для цього перевірте двері та вікна на наявність продувань. Утепліть ці місця за потреби.
	</p>
	<p className={styles.pCommon}>
	Як би банально це не звучало, але частіше рухайтеся. Найчастіше люди мерзнуть, коли сидять на одному місці. За можливості, займіться легким спортом: пострибайте, побігайте на місці, виконайте нахили та присідання тощо.
	</p>
	<p className={styles.pCommon}>
	А ось від алкоголю краще відмовитися. Те, що спиртні напої допоможуть впоратися з холодом — міф. Насправді алкоголь дає зігрівальний ефект на дуже короткий час. Під час вживання алкоголю судини розширяються, кров приливає до поверхні тіла, у результаті внутрішні органи втрачають тепло.
	</p>
	<p className={styles.pCommon}>
	Варто уточнити, що не варто не використовувати газові плити для обігріву, тому що є небезпека отруїтись чадним газом.
	</p>


	<p className={styles.pCommon} style={{fontSize: '12pt', fontWeight: 'bold'}}>
	ЗАПАСИ ЇЖІ ТА ВОДИ
	</p>

	<p className={styles.pCommon}>
	Зробіть запаси питної, технічної води та їжі щонайменше на три доби. У середньому для однієї дорослої людини потрібно на добу: 1,5-2 літрів питної води та 10-12 літрів технічної.
	</p>
	<p className={styles.pCommon}>
	Питна вода
	</p>
	<p className={styles.pCommon}>
	Питну воду можна купити у магазині (в середньому 40 гривень за 5-6 літрів), у пунктах/автоматах розливу питної води (2 гривні за літр) або безкоштовно набрати в бюветі чи колодязі.
	</p>
	<p className={styles.pCommon}>
	Дізнатися, де розташовані бювети у Києві, можна на інтерактивній карті за 
	<a href="https://kyivvodfond.com.ua/elektronna-karta-byuvetnyh-kompleksiv">посиланням</a>.
	</p>
	<p className={styles.pCommon}>
	Якщо сумніваєтеся у якості води, то можете доочистити її перед споживанням. У цьому допоможуть фільтр-глечик для механічного очищення води та таблетки для швидкого знезараження води.
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image08} width="200" /><br/>
	Питну воду можна набраи у бюветах. Фото: Даша Гришина
	</p>
	<p className={styles.pCommon}>
	Цікавий факт. Чим частіше ви кип’ятите одну й ту ж водопровідну воду, тим небезпечнішою для здоров’я вона стає. У такій воді утворюються шкідливі речовини: миш’як, фториди та нітрати.
	</p>
	<p className={styles.pCommon}>
	Технічна вода
	</p>
	<p className={styles.pCommon}>
	Технічну воду можна набрати з крана і зберігати її у пластикових бутлях, скляній тарі чи відрах. Рекомендуємо підписати пляшки, щоб не заплутатися, де питна вода, а де технічна.
	</p>
	<p className={styles.pCommon}>
	Продукти тривалого зберігання
	</p>
	<p className={styles.pCommon}>
	Зробіть запас поживної їжі тривалого зберігання. Сюди входять консерви, хлібці та печиво, горішки, шоколад та сухофрукти. За потреби, цю їжу можна взяти з собою в укриття.
	</p>
	<p className={styles.pCommon}>
	Також подумайте, які продукти споживаєте ви та ваша родина і намагайтеся завжди мати триденний продуктовий запас для дому.
	</p>
	<p className={styles.pCommon}>
	Їжа, яку не треба варити
	</p>
	<p className={styles.pCommon}>
	Якщо можливості готувати у вас не буде, в пригоді стануть сублімовані продукти (каші, супи, локшини), які достатньо запарити гарячою водою. В середньому ціна за порцію становить 20-30 гривень, дешевше буде купувати одразу великою упаковкою.
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image09} width="200" /><br/>
	Сублімовані продукти. Фото: Борис Корусенко
	</p>
	<p className={styles.pCommon}>
	До речі, деякі крупи достатньо залити водою і залишити на деякий час «готуватися». залити окропом можна: ячну, пшоняну, пшеничну каші та дрібний булгур. Вони будуть готові через 1-3 години настоювання.
	</p>
	<p className={styles.pCommon}>
	Також є каші, для приготування яких достатньо холодної води (кефіру або молока): гречана (5-10 годин), перлова (10-24 години), кускус (30 хвилин), вівсянка (до години), манна каша (20 хвилин), кіноа (8 годин).
	</p>
	<p className={styles.pCommon}>
	Найкраще придбати туристичну газову плиту в середньому за 500 гривень та готувати їжу, незалежно від наявності електрики чи газу в будинку. Однак треба стежити за тим, щоб запас балонів з газом також безпечно зберігався вдома.
	</p>

	<p className={styles.pCommon} style={{fontSize: '12pt', fontWeight: 'bold'}}>
	ЗАБЕЗПЕЧЕННЯ ЗВ’ЯЗКУ
	</p>
	<p className={styles.pCommon}>
	Під час тривалих відключень світла у деяких районах столиці зникає мобільний зв’язок та інтернет. Про причини цього «Вечірній Київ» писав раніше. Тож варто подбати про те, щоб не бути «відрізаними від світу» навіть у найекстремальніших ситуаціях.
	</p>
	<p className={styles.pCommon}>
	Новини
	</p>
	<p className={styles.pCommon}>
	Найбюджетніший варіант, хоч і найменш функціональний — радіоприймач на батарейках. Він дозволить отримувати найважливішу інформацію і слідкувати за новинами.
	</p>
	<p className={styles.pCommon}>
	Мобільний зв’язок
	</p>
	<p className={styles.pCommon}>
	Можете придбати 4G антену, яка допоможе «перетягувати» мобільний зв’язок та інтернет від сусідніх базових станцій. Спробуйте приєднатися до національного роумінгу.
	</p>
	<p className={styles.pCommon}>
	Також є кілька застосунків, які допоможуть спілкуватися без мережі:
	</p>
	<p className={styles.pCommon}>
	Bridgefly — дозволяє з’єднуватись зі смартфонами в радіусі 100 м навколо вас через Bluetooth. Але людина, з якою ви плануєте зв’язатись, має бути у вашому списку контактів та мати Bridgefly на смартфоні.
	</p>
	<p className={styles.pCommon}>
	FireChat — ви зможете відправляти текстові повідомлення користувачам у радіусі 60 м. Програма використовує як Bluetooth, так і Wi-Fi мережі.
	</p>
	<p className={styles.pCommon}>
	Briar — дозволяє як чатитись по Bluetooth, так і вести блог або створювати форуми.
	</p>
	<p className={styles.pCommon}>
	Two Way: Walkie Talkie — можна використовувати телефон як рацію. Для цього потрібно налаштуватись на певний канал, та передати значення цього каналу друзям. Втім знайте, що цю розмову зможе підслухувати будь-який користувач, який приєднатися до вашого каналу.
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image10} width="200" /><br/>
	Застосунки, які допоможуть спілкуватися без мережі. Колаж: Юлія Сергеєва
	</p>
	<p className={styles.pCommon}>
	Інтернет
	</p>
	<p className={styles.pCommon}>
	Найефективніший, але найдорожчий варіант — супутниковий інтернет. Обладнання для цього коштує приблизно 14 000 гривень, а абонентська плата — понад 2000 на місяць. 
	</p>
	<p className={styles.pCommon}>
	Цікавий факт: Смартфон не підключатиметься напряму до супутникового Інтернету. Для отримання доступу треба буде купити трансивер, фазовану антену та термінал для управління нею.
	</p>
	<p className={styles.pCommon}>
	Також заздалегідь зробіть запас готівки, адже у разі відсутності електрики більшість магазинів можуть не приймати банківські картки..
	</p>
	<p className={styles.pCommon}>
	Зазначимо, що у Києві нині розгорнуто понад 430 пунктів обігріву, де мешканці та гості столиці можуть зігрітися, перекусити, підзарядити гаджети та отримати іншу необхідну допомогу. 
	</p>
	<p className={styles.pCommon}>
	Юлія СЕРГЕЄВА, «Вечірній Київ»
	</p>


	<p className={styles.pCommon} style={{textAlign: 'center', fontSize: '14pt', fontWeight: 'bold'}}>
	Киянам нагадали про газову безпеку: чого не можна робити вдома
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', color: 'blue', fontSize: '10pt'}}>
	29 ЛИСТОПАДА 2022
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image11} width="200" /><br/>
	Ілюстративне фото з відкритих джерел
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	Рятуючись від холоду та через відключення електроенергії та тепла, деякі громадяни не дотримуються важливих правил безпеки, використовуючи газові плити.
	</p>
	<p className={styles.pCommon}>
	У «Київгазі» попереджають, що стабілізаційні відключення електроенергії — не привід ігнорувати правила газової безпеки, й закликає киян дотримуватися простих правил при користуванні газовими приладами.
	</p>
	<p className={styles.pCommon}>
	Газовики столиці наголошують, що користуватися можна лише справним газовим обладнанням: 
	якщо запідозрили несправність — <a href="https://kyivgaz.ua/service.php">викликайте майстра</a>.
	</p>
	<p className={styles.pCommon}>
	Радять також наглядати за працюючими газовими приладами й стежити за кольором полум’я на пальниках — він має бути синім або фіолетовим, а жовте полум’я — ознака неповного згоряння газу.
	</p>
	<p className={styles.pCommon}>
	Потрібно вчасно перевіряти димоходи і вентиляційні канали на справність і обов’язково стежити за наявністю тяги в них.
	</p>
	<p className={styles.pCommon}>
	Спеціалісти не радять користуватися електровитяжкою у приміщеннях, де працює газовий прилад з відводом продуктів згорання у димохід.
	</p>
	<p className={styles.pCommon}>
	У жодному разі не слід залишати поряд з газовою плитою, котлом легкозаймисті предмети та матеріали (одяг, папір тощо), а також використовувати газові прилади за призначенням: зокрема не ігнорувати те, що газова плита призначена для приготування їжі, а не обігріву приміщення.
	</p>
	<p className={styles.pCommon}>
	При виявленні в приміщенні запаху газу потрібно негайно вимкнути газові прилади, відкрити вікна, залишити приміщення та зателефонувати 104.
	</p>
	<p className={styles.pCommon}>
	Також у «Київгазі» нагадали, що заборонено використовувати індивідуальні газобалонні установки всередині житлових будинків, які вищі двох поверхів.
	</p>
	<p className={styles.pCommon}>
	Протягом 10 місяців 2022 року аварійна газова служба АТ «Київгаз» виконала понад 9,3 тисяч заявок. Головне завдання служби — швидко локалізувати й ліквідувати аварійні випадки, щоб забезпечити надійне газопостачання. Аварійна газова служба працює 24 години на добу, 7 днів на тиждень. Для виклику служби, якщо відчули запах газу чи стали свідком пошкодження газової мережі, телефонуйте 104.
	</p>
	<p className={styles.pCommon}>
	Вже кілька тижнів кияни активно опановують нові прилади, 
	які допомагають зберігати світло і тепло у помешканнях під час відключень електроенергії. 
	Зокрема, купують генератори. У Державній службі України з надзвичайних ситуацій 
	<a href="https://vechirniy.kyiv.ua/news/74840/">попереджають</a>: 
	щоб генератор не наробив біди, треба обов’язково дотримуватись правил пожежної безпеки.
	</p>
	<p className={styles.pCommon}>
	Катерина НОВОСВІТНЯ, «Вечірній Київ»
	</p>


	<p className={styles.pCommon} style={{textAlign: 'center', fontSize: '14pt', fontWeight: 'bold'}}>
	Що робити при відсутності електропостачання: підготовка та заходи безпеки
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', color: 'blue', fontSize: '10pt'}}>
	30 ЛИСТОПАДА 2022
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	Українцям підготували короткі та дієві поради про потрібні запаси, утеплення, як безпечно користуватися приладами для обігріву та приготування їжі, резервними джерелами електропостачання.
	</p>
	<p className={styles.pCommon}>
	Загроза ракетних ударів агресора, руйнування інфраструктури та висока ймовірність 
	тотального блекауту — такою прогнозують цьогорічну зиму. 
	У КМДА <a href="https://vechirniy.kyiv.ua/news/75068/">готують</a> 
	потужну інформаційну кампанію — у громадських місцях розмістять плакати, 
	буклети, наліпки, що інформуватимуть про найважливіше.
	</p>
	<p className={styles.pCommon}>
	Про ключові моменти, які варто знати кожному, розповіли і в ДСНС.
	</p>
	<p className={styles.pCommon}>
	ЯК ПІДГОТУВАТИСЯ ДО МОЖЛИВОГО ВІДКЛЮЧЕННЯ СВІТЛА
	</p>
	<p className={styles.pCommon}>
	Зробіть мінімальні запаси на випадок відсутності газу, електроенергії та централізованого опалення. Необхідно придбати:
	</p>
	<ul>
		<li className={styles.liCommon}>
		декілька упаковок сухого спирту, свічок та сірників;
		</li>
		<li className={styles.liCommon}>
		ліхтар з потужним акумулятором;
		</li>
		<li className={styles.liCommon}>
		сертифіковані оригінальні портативні газові плити та балони, для мешканців багатоквартирних будинків: зарядну станцію, павербанк тощо, а для мешканців приватного житлового сектору дозволяється бензо-газо та дизельні генератори;
		</li>
		<li className={styles.liCommon}>
		наповнені резервуари з водою, термоси з окропом;
		</li>
		<li className={styles.liCommon}>
		продуктові набори тривалого зберігання;
		</li>
		<li className={styles.liCommon}>
		теплі речі (ковдри, пледи, спальні мішки тощо) та одяг.
		</li>
	</ul>
	<p className={styles.pCommon}>
	Зменшіть втрати тепла та електроенергії вдома:
	</p>
	<ul>
		<li className={styles.liCommon}>
		утепліть під’їзд, тамбур та вхідні двері до помешкань;
		</li>
		<li className={styles.liCommon}>
		оберіть найтеплішу кімнату помешкання, герметизуйте щілини у дверних та віконних рамах житла;
		</li>
		<li className={styles.liCommon}>
		наклейте на скло вікон плівку, що зберігає тепло;
		</li>
		<li className={styles.liCommon}>
		встановіть тепловідбивний екран на стіни за батареями;
		</li>
		<li className={styles.liCommon}>
		застеліть підлогу основної кімнати килимовим покриттям.
		</li>
	</ul>
	<p className={styles.pCommon}>
	Не рекомендується використання у квартирах багатоповерхових будинків використання буржуйок, бензо-газо та дизельних генераторів.
	</p>
	<p className={styles.pCommon}>
	Занотуйте інформацію про розташування найближчих пунктів обігріву, підрозділів ДСНС та Нацполіції.
	</p>
	<p className={styles.pCommon}>
	ЩО РОБИТИ, КОЛИ ВИМКНУЛИ ЕЛЕКТРОПОСТАЧАННЯ
	</p>
	<p className={styles.pCommon}>
	Першочергово відключіть електроприлади від мережі або вимкніть запобіжники. Можна залишити увімкненим світло, аби бачити, коли постачання відновиться.
	</p>
	<p className={styles.pCommon}>
	Використовуйте економно резервні джерела електроживлення та тепла.
	</p>
	<p className={styles.pCommon}>
	У нестерпних або скрутних ситуаціях звертайтеся за допомогою до «Пунктів незламності».
	</p>
	<p className={styles.pCommon}>
	Готуйте їжу безпечно на газових плитах чи туристичних пальниках, свічках тільки в нежитлових та добре провітрюваних приміщеннях легковажність може призвести до отруєння або вибуху.
	</p>
	<p className={styles.pCommon}>
	Не рекомендовано зберігати ємності з газом у житлових приміщеннях будинків та квартир, на території сходових кліток та у підвалах, на балконах та горищах. Також розміщення газобалонного обладнання не допускається:
	</p>
	<ul>
		<li className={styles.liCommon}>
		у цокольних та підвальних поверхах;
		</li>
		<li className={styles.liCommon}>
		у приміщеннях, під якими є підвали та погреби, і вхід до них здійснюється з цих приміщень;
		</li>
		<li className={styles.liCommon}>
		на балконах та лоджіях;
		</li>
		<li className={styles.liCommon}>
		у середині приміщень гуртожитків.
		</li>
	</ul>
	<p className={styles.pCommon}>
	ПРАВИЛА БЕЗПЕЧНОГО КОРИСТУВАННЯ ПРИЛАДАМИ ДЛЯ ОБІГРІВУ ТА ПРИГОТУВАННЯ ЇЖІ
	</p>
	<ul>
		<li className={styles.liCommon}>
		купуйте сертифіковану продукцію та дотримуйтеся інструкції виробника;
		</li>
		<li className={styles.liCommon}>
		перевіряйте цілісність газових балонів та зберігайте їх у добре провітрюваному технічному приміщенні або зоовні будівель, не тримайте їх поруч із джерелами високих температур або на сонці;
		</li>
		<li className={styles.liCommon}>
		перевіряйте тягу в димоході, вентиляції приміщень, своєчасно прочищайте їх;
		</li>
		<li className={styles.liCommon}>
		обладнайте приміщення газовими аналізаторами або сповіщувачами;
		</li>
		<li className={styles.liCommon}>
		не використовуйте припливно-витяжні повітроводи й канали для відведення газів від приладів опалення, газових колонок, кип’ятильників та інших нагрівальних приладів;
		</li>
		<li className={styles.liCommon}>
		не вмикайте газові прилади при несправних запобіжниках;
		</li>
		<li className={styles.liCommon}>
		не прикріплюйте до газових приладів сторонні предмети та не вішайте на них одяг;
		</li>
		<li className={styles.liCommon}>
		не пересувайте увімкнуті газові прилади;
		</li>
		<li className={styles.liCommon}>
		не лагодьте газові та електричні прилади самостійно, у разі поломки обов’язково викличте спеціаліста.
		</li>
		<li className={styles.liCommon}>
		не дозволяйте користуватися дітям електро та газовими приладами.
		</li>
	</ul>
	<p className={styles.pCommon}>
	Якщо відчули запах газу:
	</p>
	<ul>
		<li className={styles.liCommon}>
		негайно вимкніть газові прилади та закрийте кран перед ними;
		</li>
		<li className={styles.liCommon}>
		відкрийте двері й вікна, зробіть протяг;
		</li>
		<li className={styles.liCommon}>
		не паліть, не вмикайте й не вимикайте електричні прилади й освітлення;
		</li>
		<li className={styles.liCommon}>
		залиште приміщення та повідомте про евакуацію оточуючих, сусідів;
		</li>
		<li className={styles.liCommon}>
		зателефонуйте в аварійну газову службу за номером 104 або 101.
		</li>
	</ul>
	<p className={styles.pCommon}>
	ЗАХОДИ БЕЗПЕКИ ПІД ЧАС КОРИСТУВАННЯ РЕЗЕРВНИМИ ДЖЕРЕЛАМИ ЕЛЕКТРОПОСТАЧАННЯ (зарядні станції, повербанки тощо)
	</p>
	<ul>
		<li className={styles.liCommon}>
		Не купуйте та не використовуйте дешеві резервні джерела електропостачання невідомих виробників.
		</li>
		<li className={styles.liCommon}>
		Не зберігайте пристрій на сонці або відкритим на вулиці взимку.
		</li>
		<li className={styles.liCommon}>
		Не використовуйте пошкоджені резервні джерела електропостачання.
		</li>
		<li className={styles.liCommon}>
		Не кидайте, не бийте та не сідайте на акумулятори, також слід уникати контакту з водою чи надмірною вологістю.
		</li>
		<li className={styles.liCommon}>
		Не заряджайте акумулятори, станції неоригінальними зарядками.
		</li>
		<li className={styles.liCommon}>
		Не закривайте резервні джерела електропостачання та інші гаджети предметами під час їх використання або зарядки.
		</li>
		<li className={styles.liCommon}>
		Не користуйтеся резервним джерелом електропостачання або іншим гаджетом при підвищенні його звичної температури.
		</li>
		<li className={styles.liCommon}>
		Не використовуйте пошкоджені зарядні електродроти.
		</li>
	</ul>
	<p className={styles.pCommon}>
	За відсутності зв’язку у Києві поліцію можна 
	<a href="https://vechirniy.kyiv.ua/news/75059/">викликати</a> через месенджери. 
	Також кияни можуть <a href="https://vechirniy.kyiv.ua/news/74950/">викликати</a> 
	«швидку» через смс-повідомлення та резервні номери.
	</p>
	<p className={styles.pCommon}>
	Марія КАТАЄВА, «Вечірній Київ»
	</p>


	<p className={styles.pCommon} style={{textAlign: 'center', fontSize: '14pt', fontWeight: 'bold'}}>
	Як зменшити навантаження на енергосистему: актуальні поради киянам
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', color: 'blue', fontSize: '10pt'}}>
	29 ЛИСТОПАДА 2022
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image12} width="200" /><br/>
	Фото: Борис Корпусенко
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	Є прилади, які можна вмикати протягом дня, інші ж — не в пікові години.
	</p>
	<p className={styles.pCommon}>
	Усі електроприлади за навантаженням на енергосистему можна поділити на три групи: червону, жовту та зелену.
	</p>
	<p className={styles.pCommon}>
	У пікові часи, тобто ранкові та вечірні години, потрібно зменшити використання приладів із червоної категорії, не потрібно використовувати одночасно декілька приладів з цієї категорії.
	</p>
	<ul>
		<li className={styles.liCommon}>
		До <span style={{fontWeight: 'bold'}}>червоної групи</span> відносяться прилади, які дають найбільше навантаження на енергосистему — це пральна машина, праска, фен, електроплита, мультиварка, мікрохвильовка, електрочайник, водонагрівач, кондиціонер.
		</li>
		<li className={styles.liCommon}>
		<span style={{fontWeight: 'bold'}}>Жовта група</span> — це посудомийна машина, тостер. Користуватися приладами із жовтої та червоної групи потрібно по черзі, щоб вони не створювали надмірного навантаження на мережу.
		</li>
		<li className={styles.liCommon}>
		<span style={{fontWeight: 'bold'}}>Зелена група</span> — це прилади з найменшим споживанням електроенергії. До них відносяться: комп’ютер, ноутбук, телевізор, планшет, смартфони, освітлення, холодильник.
		</li>
	</ul>
	<p className={styles.pCommon}>
	Ці електроприлади безпечні для системи та включати їх можна протягом дня.
	</p>
	<p className={styles.pCommon}>
	Марія КАТАЄВА, «Вечірній Київ»
	</p>


	<p className={styles.pCommon} style={{textAlign: 'center', fontSize: '14pt', fontWeight: 'bold'}}>
	Кияни можуть викликати «швидку» через смс-повідомлення і резервні номери
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', color: 'blue', fontSize: '10pt'}}>
	28 ЛИСТОПАДА 2022
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	У столиці передбачена альтернативна можливість виклику «швидкої», якщо телефон 103 виявиться недоступним через перебої зі зв’язком.
	</p>
	<p className={styles.pCommon}>
	Департамент охорони здоров’я пропонує киянам алгоритм дій у випадку, 
	якщо телефон 103 виявиться недоступним через перебої зі зв’язком. 
	Про це повідомила директорка Департаменту охорони здоров’я 
	<a href="https://vechirniy.kyiv.ua/dossier/13/">Валентина Гінзбург</a>.
	</p>
	<p className={styles.pCommon}>
	«По-перше, спробуйте набрати номер 103 не один, а хоча б два-три рази. Буває, що така наполегливість спрацьовує. Також для абонентів „Київстар“ існує можливість виклику „швидкої“ смс-повідомленням на номер 10 003», — розповіла Валентина Гінзбург.
	</p>
	<p className={styles.pCommon}>
	Щоб викликати швидку через смс необхідно вказати інформацію про причини виклику, повну адресу (номер будинку, під’їзду, квартири та поверх), контактний телефон, ПІБ та вік хворого.
	</p>
	<p className={styles.pCommon}>
	Якщо телефон 103 недоступний, можна скористатися спеціальними резервними номерами:
	</p>
	<ul>
		<li className={styles.liCommon}>
		стаціонарні номери — 235-71-30, 235-80-54, 235-80-58, 235-80-59, 234-71-28, 234-01-75, 235-80-24, 235-42-53, 235-80-56, 234-75-43, 234-72-94;
		</li>
		<li className={styles.liCommon}>
		мобільні номери — (067) 248-1446, (067) 248-1439, (067) 248-14-38, (067) 248-14-36, (067) 248-14-37, (067) 248-14-35, (067) 248-14-34, (067) 248-14-03, (067) 411-61-76.
		</li>
	</ul>
	<p className={styles.pCommon}>
	Також можна зателефонувати на стаціонарні номери телефонів місцевих підстанцій/пунктів базування бригад ЕМД КНП «Центру екстреної медичної допомоги та медицини катастроф міста Києва»: 235-30-57, 536-19-11, 258-31-90, 400-61-12, 280-22-49, 433-97-47, 518-26-88, 245-88-15, 245-88-16, 456-44-77, 515-26-07, 546-18-76, 573-82-83, 485-11-18.
	</p>
	<p className={styles.pCommon}>
	Якщо додзвонитися не вдалося, але є доступ до інтернету, можна відправити електронний лист на e-mail: ssmp_@ukr.net. Він також має містити необхідні дані: причина звернення, номер будинку, під’їзду, квартири та поверх, контактний телефон, ПІБ, вік хворого.
	</p>
	<p className={styles.pCommon}>
	Також, як варіант, — звернення до пункту постійного чи тимчасового базування бригад екстреної медичної допомоги, поліції, ДСНС, пунктів обігріву. Можна зупинити автомобіль швидкої, поліції, рятувальників і попросити про допомогу або самостійно звернутися до лікарні.
	</p>
	<p className={styles.pCommon}>
	«Маю до киян два прохання. По-перше — потурбуйтеся про тих, хто не може зробити цього сам: людей із інвалідністю, яким складно пересуватися самостійно, а також людей літнього віку, що мешкають поруч. По-друге, викликайте бригади екстреної медичної допомоги та медицини катастроф тільки тоді, коли стан дійсно критичний. Наприклад: втрата свідомості, судоми, розлад дихання, раптовий сильний біль у грудній клітині, анафілактична реакція, кровотеча, травма, що загрожує життю, нещасний випадок тощо», — закликала Валентина Гінзбург.
	</p>
	<p className={styles.pCommon}>
	Медики просять не викликати «швидку» відпрацьовує ваш непрофільний виклик, медиків може очікувати той, для кого відлік справді йде на хвилини.
	</p>
	<p className={styles.pCommon}>
	Наталка МАРКІВ, «Вечірній Київ»
	</p>


	<p className={styles.pCommon} style={{textAlign: 'center', fontSize: '14pt', fontWeight: 'bold'}}>
	Киянам нагадали правила ходьби на льоду та адреси травмпунктів
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', color: 'blue', fontSize: '10pt'}}>
	22 ЛИСТОПАДА 2022
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', fontSize: '8pt'}}>
	<img src={image13} width="200" /><br/>
	Фахівці просять бути уважними на дорогах. Фото: Даша Гришина
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	У випадку травми, киян наполегливо закликають звертатися до травмпункту, 
	інакше можуть виникнути ускладнення з небезпечними наслідками.
	</p>
	<p className={styles.pCommon}>
	Під час ожеледиці існує підвищений ризик травмувань. 
	Тому, щоб їх уникнути, фахівці рекомендують дотримуватися низки простих правил. 
	Про це нагадує директорка Департаменту охорони здоров’я 
	<a href="https://vechirniy.kyiv.ua/dossier/13/">Валентина Гінзбург</a>.
	</p>
	<p className={styles.pCommon}>
	«Терпимо, коли діагноз травмованого забій або розтягнення зв’язок. Після медичної допомоги людина може вирушити додому та відносно швидко відновитися. Гірше, якщо йдеться про складний перелом або травму голови. Тож краще сконцентруватися на виконанні кількох правил, що мінімізують ризики падінь», — зазначає Валентина Гінзбург.
	</p>
	<p className={styles.pCommon}>
	Поради фахівців:
	</p>
	<ul>
		<li className={styles.liCommon}>
		Кращими для ходьби на льоду є підошви з мікропористої або іншої м’якої основи. І звісно — без великих підборів. Якщо підошва слизька, її можна оздобити «льодоходами» (у продажу наявні різні моделі).
		</li>
		<li className={styles.liCommon}>
		Під час прогулянки в ожеледицю слід ходити неспішно, ступати на всю ступню. Руки не повинні бути у кишенях, або зайняті сумками.
		</li>
		<li className={styles.liCommon}>
		Не пересувайтеся краєм проїжджої частини дороги. Не перебігайте проїжджу частину під час снігопаду і в ожеледицю, адже в цей період значно збільшується гальмівний шлях машини. Падіння перед автомобілем, який рухається, призводить як мінімум до каліцтв.
		</li>
	</ul>
	<p className={styles.pCommon}>
	У випадку травми, киян наполегливо закликають звертатися до травмпункту. Якщо за деякий час після падіння відчули біль в голові, нудоту, біль в суглобах, м’язах, утворилися пухлини або великі синці — терміново відвідайте травматолога, інакше можуть виникнути ускладнення з небезпечними наслідками.
	</p>
	<p className={styles.pCommon} style={{textAlign:'center'}}>
	Адреси травматологічних пунктів<br/>
	<img src={table1} width="600" /><br/><br/><br/>
	<img src={table2} width="600" /><br/>
	</p>
	<p className={styles.pCommon}>
	Пішоходам також треба бути обережними та не забувати 
	<a href="https://vechirniy.kyiv.ua/news/73387/">носити</a> флікери в темний час доби. 
	Будьте помітними у темряві — одягайте світловідбивні елементи. 
	Для вашої зручності обирайте флікери у будь-якому вигляді: 
	браслети, наклейки, стрічки, жилети, елементи або навіть рефлективний одяг. 
	Також пам’ятайте про безпеку дитини: прикріпіть флікер на рюкзак, шапку, взуття тощо.
	</p>
	<p className={styles.pCommon}>
	Ольга КОСОВА, «Вечірній Київ»
	</p>


	<p className={styles.pCommon} style={{textAlign: 'center', fontSize: '14pt', fontWeight: 'bold'}}>
	Киянам порадили, як зберігати продукти без електрики
	</p>
	<p className={styles.pCommon} style={{textAlign: 'left', color: 'blue', fontSize: '10pt'}}>
	18 ЛИСТОПАДА 2022
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
	Через тривалі відключення електроенергії перед киянами постало завдання зберегти продукти поза холодильником. Прості поради можуть стати у нагоді людям.
	</p>
	<p className={styles.pCommon}>
	Головне управління Держпродспоживслужби в Києві зокрема рекомендує зробити запас питної води та уточнити, де найближчий від будинку бювет. У разі надзвичайної ситуації бювети столиці під’єднають до генераторів для забезпечення киян питною водою. 
	</p>
	<p className={styles.pCommon}>
	Крім того, кияни можуть зробити запас харчових продуктів, що не потребують особливих умов зберігання — хліба, печива, бубликів, консервів тощо.
	</p>
	<p className={styles.pCommon}>
	Щоби мати свіжі страви їх можна готувати в невеликій кількості і не зберігати тривалий час. А також — зробити запас гарячих напоїв у термосах.
	</p>
	<p className={styles.pCommon}>
	При купівлі харчів у магазинах можна вибрати ті, що найменше втрачають якість без холодильника — молоко тривалого зберігання в тетрапаках, сирокопчені ковбаси тощо.
	</p>
	<p className={styles.pCommon}>
	Держпродспоживслужба радить придбати термопакети з холодоелементами. Й використовувати балкони, де взимку продукти, як привило, зберігаються й при температурі до +4°С. А також якомога рідше відкривати дверцята морозильника та холодильника, які тримають холод до 6 годин без електрики.
	</p>
	<p className={styles.pCommon}>
	Спеціалісти нагадують, що в умовах відключення електроенергії треба мати запас необхідних ліків і засобів особистої гігієни; запастися теплими речами; стежити за своєчасним заряджанням засобів зв’язку, павербанків; придбати cвічки та ліхтарики.
	</p>
	<p className={styles.pCommon}>
	Поради виклали у інфографіці.
	</p>
	<p className={styles.pCommon}>
	<img src={image14} width="600" /><br/>
	</p>
	<p className={styles.pCommon}>
	Катерина НОВОСВІТНЯ, «Вечірній Київ»
	</p>

</div>
		
);
}

export default B202;

/*
*/