import React, { Component } from 'react';
import styles from '../bibliographic.module.css';

import BibliographicPage from "../BibliographicPage";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH
} from '../../../util/routes';

import image01 from './image01.jpg';
import image02 from './image02.jpg';
import image03 from './image03.jpg';
import image04 from './image04.jpg';
import image05 from './image05.jpg';
import image06 from './image06.jpg';
import image07 from './image07.jpg';
import image08 from './image08.jpg';
import image09 from './image09.jpg';

class B206 extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, selected: false },
			{title: 'ОБЕРЕГИ українського народу – символи любовi й незрадливостi', url: '', selected: true }
		];
		var body = (<Body />);
		return (
			<BibliographicPage breadCrumbs={breadCrumbs} body={body} />
		);
	}
}

function Body(props) {
return(

<div style={{width: '100%'}}>
	<div className={styles.divBibliographicHeader}>
		ОБЕРЕГИ<br/>
		українського народу – символи любовi й незрадливостi
	</div>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image01} />
	</div>
	<div className={styles.divHeaderBibliographicType}>
		Бібліографічний список літератури
	</div>

	<p className={styles.pCommon}>
	Бібліографічний список «Обереги українського народу – символи любові і незрадливості» містить статтю «Традиційні обереги українського народу».
	</p>
	<p className={styles.pCommon}>
	Бібліографічна частина складається з розділу: «Рекомендуємо прочитати».    
	</p>
	<p className={styles.pCommon}>
	При підготовці бібліографічного списку літератури використано книжковий фонд ЦРБ «Печерська» та Інтернет-ресурси.
	</p>
	<p className={styles.pCommon}>
	Головна мета бібліографічного видання – привести читачів до розуміння необхідності збереження сучасними українцями родинних символів, оберегів рідної землі, культури та звичаїв українців для наступних поколінь.
	</p>
	 
	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Від укладача
	</p>

	<p className={styles.pCommon}>
	Українські національні обереги – безцінна спадщина минулих часів. Наші предки використовували захисні амулети та знаки ще за часів панування слов’янських племен на території від Карпат до луганських териконів. 
	</p>
	<p className={styles.pCommon}>
	Для прадавніх  українців хрест, молитва, великодня крашанка, рушник, паляниця, вогонь у печі, пучечок зілля, пов’язаного навхрест, а також слова «добрий день», «бувайте здорові», «не убий», «не укради» – були не просто предметами чи словами, а знаками добра і сили, заступниками від нещасть, були оберегами. 
	</p>
	<p className={styles.pCommon}>
	Для кожного з нас носіями духовності є наші батьки, і берегиня родинного вогника - мати. Мати-берегиня береже і оберігає, піклується і дбає про своїх діточок.
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold', fontStyle: 'italic'}}>
	Давайте разом відроджувати обереги.  Нехай вони оберігають від зла, підлості і неправди.
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold', fontStyle: 'italic'}}>
	Підтримуючи свої традиції,  ми продовжуємо існування і життя своєї нації!
	</p>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center'}}>
	Традиційні обереги українського народу.
	</p>

	<p className={styles.pCommon}>
	Обереги – наші прадавні добрі символи-захисники, знаки віри у вище заступництво. Символи - обереги втілювались у побутових речах, їх шанували, про них складали легенди. Без них важко уявити нашу Україну. 
	</p>
	<p className={styles.pCommon}>
	При виготовленні оберегів варто дотримуватись правил наших предків: 
	</p>
	<ul>
	<li className={styles.liCommon}>
	не виготовляти захист для себе, такі предмети не принесуть користі;
	</li>
	<li className={styles.liCommon}>
	правильно підбирати матеріали для впливу на певну сферу життя: здоров’я, кохання, фінансових справ;
	</li>
	<li className={styles.liCommon}>
	не передавати свій оберіг іншій людині, з ним до житла передаються всі проблеми;
	</li>
	<li className={styles.liCommon}>
	пам’ятайте, що найкраще захистить від нечистої сили оберіг, виготовлений зі щирим бажанням та з доброї волі, адже в ньому закладена позитивна енергетика творця.
	</li>
	</ul>
	<p className={styles.pCommon}>
	Наші пращури вірили, що справжні обереги українського народу дарують силу, захищають від зла, допомагають у справах та допомагають мандрівникам щасливо повернутися додому. Своєрідні амулети виготовлялися для всіх, хто жив у домі, а також до конкретних урочистих подій. 
	</p>
	<p className={styles.pCommon} style={{fontStyle: 'italic'}}>
	Ми вирушаємо у цікаву подорож, де згадаємо українські народні традиції, які стали оберегами українців і є найбільш пошанованими в житті предків.
	</p>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Образи (ікони)
	</p>
	<div style={{minHeight: '150px'}}>
		<img src={image02} style={{float: 'left', marginLeft: '30px', marginRight: '30px'}}/>
		<p className={styles.pCommon}>
		Обов'язковим атрибутом в хаті молодят були образи (або ікони) — саме вони виконували одну з найголовніших ролей. Ікони дарували батьки, і здебільшого вони передавалися у спадок. Класти образи будь-де не можна, адже для цього є визначені місця, зокрема вішали образ над входом в оселю, поверх закладали рушник, який, до слова, також був оберегом. В ідеалі місце, де стояла святиня, мало бути чистим від інших предметів. Часто ікону ставили неподалік від печі. Вважалося, що так оберігається сімейний спокій, водночас родина висловлювала вдячніть за їжу на столі та збори всіх членів сім’ї.
		</p>
	</div>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Різдвяний Дідух: яку цінність несе традиційний солом’яний оберіг.
	</p>
	<div style={{minHeight: '150px'}}>
		<img src={image03} style={{float: 'left', marginLeft: '30px', marginRight: '30px'}}/>
		<p className={styles.pCommon}>
		Різдво без Дідуха, як Новий рік без ялинки. Принаймні, так вважали наші пращури. Колись у будинках українців цей солом’яний виріб був обов’язковим атрибутом на Святвечір. Він, за народними уявленнями, захищав оселю від злих духів, приносив злагоду до родини, сприяв затишку та миру у домі. 
		</p>
		<p className={styles.pCommon}>
		Саме слово дід – це той, хто має певний вік. Це не тільки новорічна прикраса, яка відродилася, а й об’єкт, який є основою і символом роду. Чому Дідух є ще символом багатства? Тому що його виготовляли з усіх сортів злакових, які є шанованими в народі. До моменту виготовлення Дідуха готувались цілий рік. Його робили зі стебел останнього періоду жнив, які називались обжинками. Ставили це хлібне дерево на покуті, який покривався рядном. На кінцях столу лежали зубчики часнику від злих сил, поряд – кутя, узвар та підсвічник.
		</p>
		<p className={styles.pCommon}>
		На такому святковому місці Дідух повноцінно заміняв нашим предкам новорічну ялинку. До того ж, його також прикрашали стрічками і квітами. 
		</p>
		<p className={styles.pCommon}>
		В одних населених пунктах Дідух стояв від року до року, в інших – його повинні були обмолотити діти, які приходили до господарів. Зерно клали у засік, де зберігалось зерно для посівів. Потім із зерен, які вимолочували з цього Дідуха, починалися посіви. По суті, ця прикраса замикала сільськогосподарський рік. 
		</p>
		<p className={styles.pCommon}>
		Цікаво, що у деяких регіонах його спалювали і користувались попелом, або ж із соломин використаного «діда» заварювали настій. Але існувала чітка рекомендація для всіх: не викидати, не топтатися, а відноситися до нього як до священного об’єкта. 
		Символічно, чим більший Дідух – тим краще родині, адже він символізує достаток. 
		</p>
		<p className={styles.pCommon}>
		Класичний Дідух стоїть на трьох ногах. Має стовбур, що символізує рік, і розгалуження по 7 колосків, що символізує тиждень. Потім його складають з трьох пір року на один місяць від стовбура 
		</p>
	</div>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Підкова — символ щастя та удачі.
	</p>
	<div style={{minHeight: '150px'}}>
		<img src={image04} style={{float: 'left', marginLeft: '30px', marginRight: '30px'}}/>
		<p className={styles.pCommon}>
		Підкова, пройшовши випробування й очищення вогнем, оберігала житло від усіляких хвороб, притягуючи їх до себе й не даючи змоги зайти їм до оселі. Є повір’я, у якому стверджувалося, що біс завжди ходить колами й нескінченно водить за собою біди та нещастя. Тому підкова у вигляді розірваного кола примушує біса дійти до кінця й повернутися, не даючи закінчити свою диявольську справу. Через те підкову вішали крізь, де сили зла могли б потрапити до житла: над дверима, вікнами, піччю тощо.
		</p>
		<p className={styles.pCommon}>
		Не можна точно визначити, чому кінський атрибут (підкову) обрали символом удачі, багатства та успіху. Можливо, через асоціацію з конем — символом заможності, якого здавна міг собі дозволити тільки вправний успішний господар.
		</p>
		<p className={styles.pCommon}>
		Також підкову вважали символом удачі й щастя, залежно від того, де саме і як її повісити. Найкращим місцем для неї обирали місце над дверима або на самих дверях, всередині або зовні приміщення. Якщо підкову прикріпити зовні — вона врятує оселю і її мешканців від лихого людського ока й сусідських заздрощів, принесе удачу й успіх. Розташування оберегу в середині житла сприяло щасливим сімейним відносинам та збереженню гармонійного подружнього життя.
		</p>
		<p className={styles.pCommon} style={{fontSize: '16pt', fontStyle: 'italic', textAlign: 'center'}}>
		Правила вивішування підкови.
		</p>
		<p className={styles.pCommon}>
		Є два способи вивішування підкови: ріжками донизу або вгору, усе залежить від конкретної мети.
		</p>
		<p className={styles.pCommon}>
		Якщо підкова висить ріжками до неба — це є своєрідною чашею достатку, яка буде притягувати в дім космічну благодатну енергію, наповнювати його добробутом і багатством. Підкова буде захищати від чорних ворожих помислів, нейтралізувати негатив сусідів і перехожих, а також злобу всіх вхожих до оселі. Вивішувати підкову ріжками до землі можна тільки в тій хаті, де вже вдосталь накопичена стабільна добра енергетика, нейтралізовано зовнішню і внутрішню небезпеки. Така чиста енергія буде не назбируватись, а благодатно виливатися на мешканців оселі, циркулюючи всередині житла з новою силою. Навіть непроханих гостей ця енергія буде примушувати мислити позитивно. 
		</p>
	</div>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Рушник - символ вірності, любові, долі, оберіг оселі. 
	</p>
	<div style={{minHeight: '150px'}}>
		<img src={image05} style={{float: 'left', marginLeft: '30px', marginRight: '30px'}}/>
		<p className={styles.pCommon}>
		Рушник – ще один національний оберіг, народний символ України. Все життя українця пов’язане з рушниками. Хліб-сіль на вишитому рушнику – то висока ознака гостинності народу. Рушник на стіні – нeвід’ємний символ хатнього інтер’єру; в Україні не було жодної оселі, яку б вони не прикрашали. Гарно оздоблений рушник висів біля ганку на кілочку в кожній сільській хаті, ним витирали руки і посуд, накривали діжку з тістом, спечені паляниці, діжку після випікання хліба, з ним ходили доїти корову, з ним починали обжинки. 
		</p>
		<p className={styles.pCommon}>
		Рушником ушановували появу немовлят в родині, одруження дітей, зустрічали рідних і гостей, проводжали людину в останню путь, виряджали в далеку дорогу батька, сина, чоловіка й коханого. 
		</p>
		<p className={styles.pCommon}>
		Жінки ткали рушники в жіночі дні – середу, п’ятницю й суботу. Перший витканий рушник призначався для гостей. Він завжди висів на видному місці. Господиня в знак поваги до гостя давала йому рушник на плече чи в руки, брала кухлик із водою і люб’язно пропонувала свої послуги. 
		</p>
		<p className={styles.pCommon}>
		Старі люди говорили: „Не лінуйся, дівонько, рушник вишивати – буде чим гостей шанувати”. 
		</p>
		<p className={styles.pCommon}>
		Рушниками, вишитими яскравими нитками, прикрашали хату на свято або коли в родині відбувалась якась радісна подія. Коли ж наставала лиха година, ставало сумно або траплялось лихо, у хаті вивішували рушники, вишиті червоними і чорними нитками, бо червоний колір означав любов, а чорний – біль та тугу. Отож не диво, що найкращою українською піснею, якій судилося довге життя, стала "Пісня про рушник" А. Малишка. 
		</p>
		<p className={styles.pCommon}>
		У кожній родині, де підростала дівчина, скриня мала повнитися рушниками. Їх дбайливо оберігали, показували гостям, Дати рушники сватам означало, що дівчина дала згоду вийти заміж і готується до весілля. 
		</p>
		<p className={styles.pCommon}>
		Рушником перев'язують старостів та сватів на весіллі, молода молодого. Рушником вистилають дорогу на весіллі від порога до столу, а то й від воріт до дверей хати. 
		</p>
	</div>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Вишиванка – оберіг, яскравий символ  долі  українського народу.
	</p>
	<div style={{minHeight: '150px'}}>
		<img src={image06} style={{float: 'left', marginLeft: '30px', marginRight: '30px'}}/>
		<p className={styles.pCommon}>
		Споконвіку українські жінки і чоловіки свято шанували одяг, а особливо вишиту сорочку. Одягти білу сорочку в неділю чи свято було обов'язковим правилом, відступати від якого не міг найбідніший селянин: "Хоч латаненька, аби біленька", — каже народне прислів'я.
		</p>
		<p className={styles.pCommon}>
		Сорочки на щодень вишивали скромно і просто. Святкові - більш ретельно, а весільним приділялась особлива увага. 
		</p>
		<p className={styles.pCommon}>
		Як би скрутно не жили в різні часи українці, у них не згасав потяг до вишивання. За допомогою голки і нитки творилися неперервні зразки чудового українського вбрання. 
		</p>
		<p className={styles.pCommon}>
		Сорочку не позичали, не продавали, не одягали чужої, аби не "перетягнути" на себе чужої біди, хворіб і не віддати свого здоров'я разом із своєю сорочкою. Сорочки вишивали на комірці, рукаві, по низу - щоб до людини не мало доступу все лихе і недобре. 
		</p>
		<p className={styles.pCommon}>
		Як вишитий рушник оберігав хату, так сорочка оберігала саму людину від поганих людей, недобрих очей, заздрісних думок. Коли хтось заходив до хати, його погляд мимохідь був спрямований на рушник, на те, яким узором він вишитий, а потім уже на самих господарів. Ту саму роль відігравала і сорочка. Вишивка ніби нейтралізувала "недобрий" погляд чи "зле" око. Тому й була оберегом людини чи оселі. 
		</p>
		<p className={styles.pCommon}>
		Сорочку вишивали і дарували не будь-кому, а особливо близьким, рідним: дитині, братові, батькам, чоловікові. 
		</p>
		<p className={styles.pCommon}>
		Вишивала сорочку дівчина своєму нареченому, майбутній свекрусі. Дружина вишивала сорочку для чоловіка, сина. Коли чекали когось із далекої дороги - починали вишивати сорочку, шоб та людина швидше повернулася. 
		</p>
		<p className={styles.pCommon}>
		Всесвітній день вишиванки - Свято, яке відзначають третього четверга травня, було засноване 2007 року та покликане зберегти культуру та традиції створення і носіння етнічного вишитого українського одягу. Нині вишиванка все більше входить у буденне життя українців, її все частіше одягають наречені у день весілля, свідомі громадяни на державні свята, а у день вишиванки більшість українців вбереться у національний одяг на роботу в офіс. 
		</p>
		<p className={styles.pCommon}>
		З давніх-давен вишита сорочка виконувала роль потужного оберега і тепер знову перетворюється на те, чим була завжди для українців ‒ одягом, який зберігає пам’ять роду і генетичний код нації. 
		</p>
		<p className={styles.pCommon}>
		Важливо не забувати про ті потужні коди-символи, які наші пращури закладали в орнамент вишивки. 
		</p>
		<p className={styles.pCommon}>
		Особливе значення у вишивці мають геометричні фігури, саме вони стоять біля витоків вишивки наших предків – це прадавні слов’янські символи. Природні орнаменти з’явилися набагато пізніше і вважаються більш сучасними мотивами. Кожен візерунок та кожне поєднання орнаментів вишивки несуть свій сенс. 
		</p>
	</div>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Лялька–мотанка – на щастя, на долю.
	</p>
	<div style={{minHeight: '150px'}}>
		<img src={image07} style={{float: 'left', width: '150px', marginLeft: '30px', marginRight: '30px'}}/>
		<p className={styles.pCommon}>
		Лялька-мотанка — один із найкрасивіших і водночас наймістичніших українських оберегів.  Мотанка виконувала функцію сакральних оберегів — її брали з собою у подружнє життя молодиці. Здебільшого ляльку ховали у скринях, але інколи їх могли класти на чільне місце в обійсті. Такі мотанки передавали матері своїм донькам, виготовляли їх власноруч. Мотанку ставили у люльку немовляті, щоб відвертати злих духів. Власне, слід зауважити, що мотанка — це не ігрова лялька, а символ родючості.
		</p>
		<p className={styles.pCommon}>
		Один із обов'язкових елементів ляльки — хрест (символ сонця) на обличчі, щоб ніяка нечисть не вселилася в оберіг. Горизонтальні лінії означали жіноче начало, а вертикальні — чоловіче. Однак, попри свої сильні енергетичні властивості, цей атрибут могли використовувати і в поганих цілях, але таке бувало вкрай рідко.
		</p>
	</div>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Українська хустка – оберіг та історія роду. 
	</p>
	<div style={{minHeight: '150px'}}>
		<img src={image08} style={{float: 'left', marginLeft: '30px', marginRight: '30px'}}/>
		<p className={styles.pCommon}>
		Українська хустка - традиційна, різна, легка, тепла… А ще, для кожного рідна. Бо зазвичай хустка переходить від матері до дочки. 
		</p>
		<p className={styles.pCommon}>
		Мамина хустка, як оберіг, як пам’ять роду, супроводжує і не дає забути історію, традиції свого роду-народу. 
		</p>
		<p className={styles.pCommon}>
		З давніх-давен хустка для українців була символом жіночності, вірності й кохання, а також оберегом. Дівчата та жінки носили її протягом усього року. При цьому хустина була обов’язковим головним убором заміжньої жінки. 
		</p>
		<p className={styles.pCommon}>
		Хустка є символом прихильності, любові, вірності, прощання, скорботи, оберегом і важливим ритуальним предметом. Це невід’ємна частина української культурної спадщини. Хустки супроводжували людину впродовж усього життя. Їх традиційно використовували, коли народжувалася дитина, а потім на хрестинах. Юнаків на проводах в армію матері обов’язково перев’язували хустками. Вважалося, що ця хустка збереже від усіх негараздів. Коли до дівчини приходили свататися, то вона мала винести судженому хустку на знак згоди, що виходить заміж по любові. На весіллі і донині збереглася традиція три рази покривати цим головним убором наречену, що означає її перехід у статус заміжньої жінки. 
		</p>
		<p className={styles.pCommon}>
		Сьогодні часто можна побачити на сучасній жінці хустку. Адже хустка - це символ нашого народу, що символізує любов та свободу, вірність традиціям і патріотизм. 
		</p>
	</div>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Писанка – оберіг людської душі
	</p>
	<div style={{minHeight: '150px'}}>
		<img src={image09} style={{float: 'left', width: '200px', marginLeft: '30px', marginRight: '30px'}}/>
		<p className={styles.pCommon}>
		Ще з дохристиянських часів писанку важали оберегом, який має добру магічну силу і несе щастя та достаток. Кожен елемент розпису має своє значення. Писанка не є суто великоднім атрибутом, як більшість гадає. Її писали протягом року до різних свят, на весілля, дарували на добробут.
		</p>
		<p className={styles.pCommon}>
		За традицією, писанки пишуть дівчата і жінки. Дівчата, щоб прикликати щасливу долю. Бездітні, щоб дитину народити. Їм радять три роки підряд писати по 12 писанок і роздавати дітям. Господині, щоб у хаті були злагода і достаток, щоб усі були здорові і веселі. 
		</p>
		<p className={styles.pCommon}>
		За гуцульською легендою, доки люди писатимуть писанки, доти злий нехрист буде ланцюгами прикутий до скелі і не зможе зійти до людей.
		</p>
	</div>

	<p className={styles.pCommon} style={{fontWeight: 'bold', fontStyle: 'italic'}}>
	Шанувати та любити рідне – це насправді так просто. Воно ж не забороняє мати нові традиції, ялинка з дідухом в одній кімнаті не покусаються – перевірено, хоч дідух українцям і ближче, і тепліше. Любов до рідного завжди наповнює життя якимсь глибинним змістом. 
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold', fontStyle: 'italic'}}>
	Тож, відроджуймо, поважаймо, пропагуймо наші українські традиції, бо це генетичні скарби нашого народу. 
	</p>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Рекомендуємо прочитати:
	</p>
	<ul>
	<li className={styles.liCommon}>
	Воропай, О. Звичаї нашого народу: етнографічний нарис / Олекса Воропай.- Том 1.- Київ : Оберіг, 1991. - 455с.
	</li>
	<li className={styles.liCommon}>
	Воропай, О. Звичаї нашого народу : етнографічний нарис / Олекса Воропай.- Том 2.- Київ : Оберіг, 1991. - 447 с.
	</li>
	<li className={styles.liCommon}>
	Лозинський, Й. І. Українське весілля / Й.І. Лозинський. - Київ : Наукова думка, 1992.- 174 с.
	</li>
	<li className={styles.liCommon}>
	Лозко, Г. С. Українське народознавство  / Г.С. Лозко.- Київ : Зодіак – ЕКО, 1995.- 368 с.
	</li>
	<li className={styles.liCommon}>
	Різдвяні свята в Україні: етнографічний нарис з додаванням українських народних пісень. - Київ : Музична Україна, 1992. - 152 с.
	</li>
	<li className={styles.liCommon}>
	Ткач, М. Дерево роду / М. Ткач.- Київ : МПП «АНФАС», 1995.- 111 с.
	</li>
	<li className={styles.liCommon}>
	Українське народознавство / С.П. Павлюка, Г.Й. Горинь, Р.Ф. Кирчіва. – Львів : Фенікс, 1994.- 608 с.
	</li>
	<li className={styles.liCommon}>
	Будлянський, М. Хатні обереги: поріг, долівка // Дім. Сад. Город. - 2021.- № 5.- С. 20-21.
	</li>
	<li className={styles.liCommon}>
	Будлянський, М. Хатні обереги: сволок, колиска // Дім. Сад. Город. - 2021.- № 7.- С. 23.
	</li>
	<li className={styles.liCommon}>
	Капшученко, Ю. Чим покривали голову: віночком чи хусткою? / Ю. Капшученко // Культура і життя.- 2020.- 28 лют.- С. 12.
	</li>
	<li className={styles.liCommon}>
	Сакральні та магічні жіночі прикраси // Жінка. - 2023.- Вересень .- С. 24-25.
	</li>
	<li className={styles.liCommon}>
	Северінова, К. Як святкували Великдень в Україні / К. Северінова //  7я.- 2023.- № 15.- С. 15.
	</li>
	<li className={styles.liCommon}>
	Черемшинська, Р. Дідух / Р. Черемшинська // Слово просвіти. - 2018. - 4-10 січ. - С. 16.
	</li>
	<li className={styles.liCommon}>
	Черемшинська, Р. Український рушник / Р. Черемшинська // Культура і життя. - 2020. - 27 лист. - С. 11.
	</li>
	<li className={styles.liCommon}>
	Шевчук, С. Оберіг ляльку-мотанку створюють за один світловий день / С. Шевчук // Пенсійний кур’єр. - 2023. - № 35. - С. 1, 6.
	</li>
	</ul>

</div>
		
);
}

export default B206;
