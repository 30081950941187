import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import SearchEngine from "./SearchEngine";
import { CBS_SEARCH_FORM } from "./SearchForm";

import {
	MAIN_PATH,
	ECATALOG_PATH,
	SKS_PATH,
	KKS_PATH
} from '../../util/routes';

const STATE_SEARCH_FORM = 'form';
const STATE_SEARCH_RESULT = 'result';

class ECatalogPage extends Component {

	constructor(props) {
		super(props);
		this.anyField = props.match.params.search;
		if( ! this.anyField )
			this.anyField = '';
	}

	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Електронний каталог', url: '', selected: true }
		];
		var menu = [
			{ title: 'Електронний каталог', url: '', isGroupHeader: false, isActive: true },
			{ title: 'Систематична картотека статей', url: SKS_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Краєзнавча картотека статей', url: KKS_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={ECATALOG_PATH} menu={menu} body={body} />);
	}
}

function Body(props) {
	return(
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Електронний каталог
			</div>
			<SearchEngine searchFormId={CBS_SEARCH_FORM} anyField={props.holder.anyField} />
		</div>
	);
}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	}
}

export default ECatalogPage;
