import React, { Component, createRef } from 'react';
import stylesAdmin from './admin.module.css';
import stylesPage from '../page.module.css';

import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Spinner from "../../components/Spinner";

import { 
	performApiRequest, performApiRequestForm, 
	BOOK_LOAD_API_URL, BOOK_SAVE_API_URL,
	BOOK_SET_IMAGE_API_URL, BOOK_CLEAR_IMAGE_API_URL
} from "../../util/restapi";
import { ADMIN_BOOK_INTERESTING_PATH } from "../../util/routes";
import { getBookImageUrl } from "../../util/tools";

import logo from '../../assets/img/pechersk_logo.png';

const STATE_INIT = 'init';
const STATE_READY = 'ready';
const STATE_SAVING_BOOK = 'saving_book';
const STATE_SAVING_IMAGE = 'saving_image';

class AdminBookInterestingPage extends Component {

	constructor(props) {
		super(props);
		this.bookId = this.props.match.params.id;
		this.book = {};
		this.bookImageSalt=1;
		this.fileRef = createRef();
		this.state = {
			dirty: false,
			formState: STATE_INIT,
			book: this.book,
			bookImageSalt: 0,
			ok: true
		};
	}
	componentDidMount = async () => {
		if( this.bookId == 0 ) {
			this.book = { 
				bookId: 0,
				type: 'new',
				isNew: false,
				isInteresting: true,
				showOnMainPage: false,
				showOnBookList: true,
				header: '',
				card: '',
				annotation: '',
				bookDate: null,
				bookOrder: '',
				imageExtension: '',
				imageTime: null,
				imageWidth: 0,
				imageHeight: 0
			};
		} else {
			var request = { bookId: this.bookId };
			var response = await performApiRequest(BOOK_LOAD_API_URL, request);
			this.book = response.book;
		}
		this.setState({formState: STATE_READY});
	}

	handleValueChange = (e) => {
		const {name, value} = e.target;
		console.log( value );
		var book = this.book;
		if( name == 'header' )
			book.header = value;
		if( name == 'card' )
			book.card = value;
		if( name == 'annotation' )
			book.annotation = value;
		if( name == 'bookOrder' )
			book.bookOrder = value;
		if( name == 'bookDate' ) {
			book.bookDate = value;
		}
		this.setState({dirty: true});
	}
	handleCheckBoxChange = (e) =>	{
		const {name, checked} = e.target;
		var book = this.book;
		if( name == 'isNew' )
			book.isNew = checked;
		if( name == 'isInteresting' )
			book.isInteresting = checked;
		if( name == 'showOnMainPage' )
			book.showOnMainPage = checked;
		if( name == 'showOnBookList' )
			book.showOnBookList = checked;
		this.setState({dirty: true});
	}

	clearImage = async () => {
		this.setState({formState: STATE_SAVING_IMAGE});
		var request = { bookId: this.bookId };
		var response = await performApiRequest(BOOK_CLEAR_IMAGE_API_URL, request);
		if( response.ok ) {
			this.book.imageHeight = 0;
			this.book.imageWidth = 0;
		}
		this.setState({formState: STATE_READY, book: this.book, bookImageSalt: this.bookImageSalt++});
	}
	handleFileSelect = async (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			this.setState({formState: STATE_SAVING_IMAGE});
			var file = files[0];
			const form = new FormData();
			form.append('bookId', this.book.bookId);
			form.append('photo', file);
			var response = await performApiRequestForm(BOOK_SET_IMAGE_API_URL, form);
			if( response.ok ) {
				this.book = response.book;
			}
			this.setState({formState: STATE_READY, book: this.book, bookImageSalt: this.bookImageSalt++});
		}
	}
	
	saveBook = async () => {
		this.setState({formState: STATE_SAVING_BOOK});
		var book = this.book;
		var request = { book: book };
		var isNew = book.bookId == 0;
		var response = await performApiRequest(BOOK_SAVE_API_URL, request);
		console.log(response);
		book.bookId = response.bookId;
		this.setState({formState: STATE_READY});
		if( isNew )
			window.open( ADMIN_BOOK_INTERESTING_PATH + '/' + response.bookId, '_self' );
	}
	
	render() {
		var leftSide = (<AdminLeftSide />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_INIT ) {
		var contentHtml = (<Spinner />);
	} else {
		var contentHtml = (<Content holder={holder} />);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={stylesAdmin.divContentHeader}>
				Цікава книга
			</div>
			{contentHtml}
		</div>
	)
}
function Content(props) {
	var holder = props.holder;
	var book = holder.book;
	var formState = holder.state.formState;
	if( book == null ) {
		return (
			<div style={{width:'100%', marginTop: '30px', marginBottom: '30px', textAlign: 'center'}}>
				Вибачте, інформація відсутня
			</div>
		);
	}
	if( formState == STATE_SAVING_BOOK ) {
		var buttonOrSpinnerHtml = <Spinner />;
	} else {
		var disabled = (formState == STATE_SAVING_IMAGE);
		var buttonOrSpinnerHtml = (
			<div style={{marginTop: '15px'}}>
				<hr />
				<button type='button' onClick={holder.saveBook} disabled={disabled}>Записати книгу</button>
			</div>
		);
	}
	
	return (
		<div style={{width:'100%', marginBottom: '30px'}}>
			<div style={{marginTop: '15px'}}>
				Заголовок<br/>
				<textarea 
					name="header" rows="2" cols="100" 
					onChange={holder.handleValueChange}
					value={book.header}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				Бібліографічна картка<br/>
				<textarea 
					name="card" rows="3" cols="100" 
					onChange={holder.handleValueChange}
					value={book.card}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				Анотація<br/>
				<textarea 
					name="annotation" rows="7" cols="100" 
					onChange={holder.handleValueChange}
					value={book.annotation}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				Дата 
				<input type='date' name='bookDate' 
					style={{marginLeft: '10px', marginRight: '20px'}}
					onChange={holder.handleValueChange} 
					value={book.bookDate}
				/>
				{/*
				Пріоритет (порядковий номер) 
				<input type='text' name='bookOrder' size='4'
					style={{marginLeft: '10px'}}
					onChange={holder.handleValueChange} 
					value={book.bookOrder}
				/>
				*/}
			</div>
			{/*
			<div style={{marginTop: '15px'}}>
				<input type='checkbox' name='isNew' 
					style={{marginRight: '10px'}}
					onChange={holder.handleCheckBoxChange} 
					checked={book.isNew} 
				/>
				Нове надходження
				<input type='checkbox' name='isInteresting' 
					style={{marginLeft: '20px', marginRight: '10px'}}
					onChange={holder.handleCheckBoxChange} 
					checked={book.isInteresting} 
				/>
				Цікаве на полицях
			</div>
			<div style={{marginTop: '15px'}}>
				<input type='checkbox' name='showOnMainPage' 
					style={{marginRight: '10px'}}
					onChange={holder.handleCheckBoxChange} 
					checked={book.showOnMainPage} 
				/>
				Відображати на головній сторінці
				<input type='checkbox' name='showOnBookList' 
					style={{marginLeft: '20px', marginRight: '10px'}}
					onChange={holder.handleCheckBoxChange} 
					checked={book.showOnBookList} 
				/>
				Відображати в переліку книжок
			</div>
			*/}
			<div>
				<div style={{marginTop: '15px', marginBottom: '10px', textAlign: 'left', fontWeight: 'bold', fontSize: '16pt'}}>
					Обкладинка
				</div>
				<Image holder={holder} />
			</div>
			{buttonOrSpinnerHtml}
		</div>
	);
}

function Image(props) {
	var holder = props.holder;
	var book = holder.book;
	if( ! book.bookId ) {
		return (
			<div>
				Редагування доступне після запису книги
			</div>
		);
	}

	if( holder.state.formState == STATE_SAVING_IMAGE )
		return ( <Spinner /> );

	if( book.imageHeight > 0 && book.imageWidth > 0) {
		var urlImage = getBookImageUrl(book.bookId) + '&salt=' + holder.bookImageSalt;
		var buttonClearHtml = <button type='button' style={{marginLeft: '10px'}} onClick={holder.clearImage}>Очистити</button>;
	} else {
		var urlImage = logo;
		var buttonClearHtml = null;
	}
	
	return (
		<div style={{display: 'flex'}}>
			<div>
				<img src={urlImage} width='100' />
			</div>
			<div style={{marginLeft: '20px'}}>
				<button type='button' onClick={e => holder.fileRef.current.click()}>Вибрати</button>
				<input ref={holder.fileRef} type={'file'} style={{display: 'none'}} onChange={e => holder.handleFileSelect(e)} />
				{buttonClearHtml}
			</div>
		</div>
	);	
}

export default AdminBookInterestingPage;
