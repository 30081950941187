import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Gallery from "../../../components/Gallery";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIBJERELO_INFO_PATH, LIBJERELO_GALLERY_PATH, } from '../../../util/routes';
import { LIBJERELO } from '../../../util/consts';

class LibJereloGalleryPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBJERELO.title, url: LIBJERELO_INFO_PATH, selected: false },
			{title: 'Фотогалерея', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBJERELO_GALLERY_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBJERELO_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var imageList = [
		{ url: '/libjerelo/imgp2392.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libjerelo/imgp2397.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libjerelo/imgp2398.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libjerelo/20001.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libjerelo/20002.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libjerelo/1.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libjerelo/2.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libjerelo/4.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
	];
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBJERELO.title}<br/>
				Фотогалерея
			</div>
			<Gallery imageUrlPrefix={'/img/'} images={imageList} />
		</div>
	);
}

export default LibJereloGalleryPage;
