import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import { performApiRequest, BOOK_LOAD_API_URL } from "../../util/restapi"
import { getBookImageUrl } from "../../util/tools";

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import SpanInnerHtml from '../../components/SpanInnerHtml';
import DivInnerHtml from '../../components/DivInnerHtml';
import Spinner from '../../components/Spinner';

import {
	MAIN_PATH,
	ECATALOG_PATH,
	BIBLIOGRAPHIC_PATH,
	BOOKS_NEW_PATH,
	BOOKS_INTERESTING_PATH
} from '../../util/routes';

const STATE_INIT = 'init';
const STATE_READY = 'ready';

class BookDescriptionPage extends Component {

	constructor(props) {
		super(props);
		this.bookId = this.props.match.params.id
		this.breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
		];
		this.state = {
			formState: STATE_INIT
		};
	}
	componentDidMount = async () => {
		var request = {
			bookId: this.bookId
		};
		var response = await performApiRequest(BOOK_LOAD_API_URL, request);
		this.pageData = response;
		console.log( this.pageData );
		var book = this.pageData.book;
		if( book != null ) {
			var header = book.header.replace(/(<([^>]+)>)/ig, ''); // Remove html tags
			this.breadCrumbs.push( {title: header, url: '', selected: true } );
		}
		this.setState({formState: STATE_READY});
	}
	
	render() {
		var menu = [
			{ title: 'Електронний каталог', url: ECATALOG_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліотека рекомендує', url: '', isGroupHeader: true, isActive: false },
			{ title: 'Цікаве на полицях', url: BOOKS_INTERESTING_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Нові надходження', url: BOOKS_NEW_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={this.breadCrumbs} activeMenuPath={BOOKS_INTERESTING_PATH} menu={menu} body={body} />);
	}
	
}

function Body(props) {

	if( props.holder.state.formState == STATE_INIT ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				<Spinner />
			</div>
		);
	}
	var book = props.holder.pageData.book;
	if( book == null ) {
		return (
			<div style={{width:'100%', marginTop: '30px', marginBottom: '30px', textAlign: 'center'}}>
				Вибачте, інформація відсутня
			</div>
		);
	}
	var urlImage = getBookImageUrl( book.bookId );
	return (
		<div style={{width: 'calc(100% - 100px)', paddingLeft: '50px'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				<DivInnerHtml text={book.header} />
			</div>
			<div style={{display: 'flex', marginBottom: '30px'}}>
				<div>
					<img src={urlImage}  width="150" />
				</div>
				<div style={{marginLeft: '20px'}}>
					<div style={{marginBottom: '10px'}}>
						<DivInnerHtml text={book.card} />
					</div>
					<div style={{textAlign: 'justify'}}>
						<DivInnerHtml text={book.annotation} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default BookDescriptionPage;
