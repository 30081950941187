import React, { Component } from 'react';
import LeftSideMenu from "../../../components/LeftSideMenu";
import stylesLibrary from "../library.module.css";

import {
	LIBCRB_CONTACTS_PATH, 
	LIBCRB_ECATALOG_PATH, 
	LIBCRB_EVENTS_PATH, 
	LIBCRB_GALLERY_PATH, 
	LIBCRB_INFO_PATH, 
	LIBCRB_SERVICES_PATH,
} from '../../../util/routes';

import iconLiBullet from '../../../assets/img/mainpage/icon_li_bullet.png';

class LeftSide extends Component {

	constructor(props) {
		super(props);
	}

	menuItem = (title, url) => {
		return {title: title, url: url, isGroupHeader: false, isActive: url == this.props.path};
	}
	
	render() {
		var menu = [
			this.menuItem('Загальна інформація', LIBCRB_INFO_PATH),
			this.menuItem('Новини та події', LIBCRB_EVENTS_PATH),
			this.menuItem('Фотогалерея', LIBCRB_GALLERY_PATH),
			this.menuItem('Електронний каталог', LIBCRB_ECATALOG_PATH),
			this.menuItem('Наші послуги', LIBCRB_SERVICES_PATH),
			this.menuItem('Координати', LIBCRB_CONTACTS_PATH),
		]
		return (
			<React.Fragment>
				<LeftSideMenu menu={menu} />
				
				<div className={stylesLibrary.divLeftSideHeader}>
					Адреса бібліотеки
				</div>
				<ul className={stylesLibrary.ulLeftSideContacts}>
					<li style={{marginTop: '15px'}}>
						01133, м.Київ, бул.Л.Українки,7,<br/>
						Центральна районна бібліотека "Печерська"
					</li>

					<li style={{marginTop: '15px'}}>
						<span style={{fontWeight: 'bold'}}>Телефони:</span><br/>
						Читальний зал та абонемент:<br/>
						(044) 234-25-22<br/>
						Інтернет-центр:<br/>
						(044) 235-16-67<br/>
					</li>

					<li style={{marginTop: '15px'}}>
						<span style={{fontWeight: 'bold'}}>E-mail:</span><br/>
						2351667@ukr.net<br/>
					</li>
				</ul>

				<div className={stylesLibrary.divLeftSideHeader}>
					Графік роботи	
				</div>
				<table className={stylesLibrary.tableWorkSchedule}>
					<tr>
						<td>&nbsp;</td>
						<td valign="top">Читальний зал та абонемент</td>
						<td valign="top">Інтернет-центр</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>понеділок</div></td>
						<td valign="top">10:00-19:00</td>
						<td valign="top">11:00-19:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>вівторок</div></td>
						<td valign="top">10:00-19:00</td>
						<td valign="top">11:00-19:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>середа</div></td>
						<td valign="top">10:00-19:00</td>
						<td valign="top">11:00-19:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>четвер</div></td>
						<td valign="top">10:00-19:00</td>
						<td valign="top">11:00-19:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>п'ятниця</div></td>
						<td colspan="2" valign="top">вихідний</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>субота</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">11:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>неділя</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">вихідний</td>
					</tr>
				</table>
				
			</React.Fragment>
		);
	}
}

export default LeftSide;