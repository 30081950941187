import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import ExhibitionListBody from "./ExhibitionListBody";

import {
	MAIN_PATH,
	EVENT_LIST_PATH,
	EXHIBITION_LIST_PATH,
	POSTER_LIST_PATH,
} from '../../util/routes';

class ExhibitionListPage extends Component {
	
	constructor(props) {
		super(props);
		this.libraryId = 'cbs'; 
	}
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Віртуальні виставки', url: '', selected: true }
		];
		var menu = [
			{ title: 'Новини та події', url: EVENT_LIST_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Віртуальні виставки', url: EXHIBITION_LIST_PATH, isGroupHeader: false, isActive: true },
			{ title: 'Афіша', url: POSTER_LIST_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={EXHIBITION_LIST_PATH} menu={menu} body={body} />);
	}
	
}

function Body(props) {

	return (
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Віртуальні виставки
			</div>
			<ExhibitionListBody libraryId={'cbs'} />
		</div>
	);
}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	}
}

export default ExhibitionListPage;
