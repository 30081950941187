import React, { Component } from 'react';
import LeftSideMenu from "../../components/LeftSideMenu";

import {
	BIBLIOGRAPHIC_PATH,  
	ONLINE_PATH,
	SKS_PATH,
	KKS_PATH,
} from '../../util/routes';

class BgLeftSideMenu extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var menu = [
			{ title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, isGroupHeader: false, isActive: false },
			{ title: 'On-line довідка', url: ONLINE_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Систематична картотека статей', url: SKS_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Краєзнавча картотека статей', url: KKS_PATH, isGroupHeader: false, isActive: false },
		];
		return (
			<LeftSideMenu menu={menu} />
		)
	}
}

export default BgLeftSideMenu;
