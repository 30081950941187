import React, { Component, createRef } from 'react';
import stylesAdmin from './admin.module.css';

import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Spinner from "../../components/Spinner";

import { 
	performApiRequest, CLUB_LOAD_API_URL, CLUB_SAVE_API_URL
} from "../../util/restapi";
import { ADMIN_CLUB_PATH } from "../../util/routes";
import { getClubImageUrl } from "../../util/tools";

import logo from '../../assets/img/pechersk_logo.png';

const STATE_INIT = 'init';
const STATE_READY = 'ready';
const STATE_SAVING_CLUB = 'saving_club';
const STATE_SAVING_IMAGE = 'saving_image';

class AdminClubPage extends Component {

	club = {
		clubId: 0,
		title: '',
		contacts: '',
		description: '',
		clubOrder: 1,
		imageWidth: 0,
		imageHeight: 0,
	};
	newImageBinary = null;
	clearImage = false;

	constructor(props) {
		super(props);
		this.club.clubId = this.props.match.params.id;
		this.state = {
			dirtyCount: 0,
			formState: STATE_INIT,
		};
	}
	componentDidMount = async () => {
		if( this.club.clubId > 0 ) {
			let request = { clubId: this.club.clubId };
			let response = await performApiRequest(CLUB_LOAD_API_URL, request);
			this.club = response.club;
		}
		this.setState({formState: STATE_READY});
    }

	handleValueChange = (e) => {
		const {name, value} = e.target;
		let club = this.club;
		if( name == 'title' )
			club.title = value;
		if( name == 'contacts' )
			club.contacts = value;
		if( name == 'description' )
			club.description = value;
		this.setState({dirtyCount: this.state.dirtyCount + 1});
	}

	saveClub = async () => {
		this.setState({formState: STATE_SAVING_CLUB});
		let club = this.club;
		let newImage = null;
		if( this.newImageBinary ) {
			// data:*/*;base64,
			let pos = this.newImageBinary.indexOf("base64,") + "base64,".length;
			newImage = this.newImageBinary.substring(pos);
		}
		let request = { 
			club: club,
			newImage: newImage,
			clearImage: this.clearImage
		};
		let isNew = club.clubId == 0;
		let response = await performApiRequest(CLUB_SAVE_API_URL, request);
		if( response.ok ) {
			if( isNew )
				window.open( ADMIN_CLUB_PATH + '/' + response.clubId, '_self' );
			else {
				club.clubId = response.clubId;
				club.imageWidth = response.imageWidth;
				club.imageHeight = response.imageHeight;
				this.newImageBinary = null;
				this.clearImage = false;
				this.setState({formState: STATE_READY});
			}
		} else {
			// TODO showMessage
		}
	}

	render() {
		let leftSide = (<AdminLeftSide />);
		let body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	let holder = props.holder;
    let contentHtml;
	if( holder.state.formState == STATE_INIT ) {
		contentHtml = (<Spinner />);
	} else {
		contentHtml = (<Content holder={holder} />);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={stylesAdmin.divContentHeader}>
				Гурток
			</div>
			{contentHtml}
		</div>
	)
}

function Content(props) {

	let holder = props.holder;
	var club = holder.club;
	var formState = holder.state.formState;
	if( club == null ) {
		return (
			<div style={{width:'100%', marginTop: '30px', marginBottom: '30px', textAlign: 'center'}}>
				Вибачте, інформація відсутня
			</div>
		);
	}
	let buttonOrSpinnerHtml;
	if( formState == STATE_SAVING_CLUB ) {
		buttonOrSpinnerHtml = <Spinner />;
	} else {
		let disabled = (formState == STATE_SAVING_IMAGE);
		buttonOrSpinnerHtml = (
			<div style={{marginTop: '15px'}}>
				<hr />
				<button type='button' onClick={holder.saveClub} disabled={disabled}>Записати гурток</button>
			</div>
		);
	}
	
	return (
		<div style={{width:'100%', marginBottom: '30px'}}>
			<div style={{marginTop: '15px'}}>
				Назва<br/>
				<input type='text' name="title" style={{width: '800px'}}
					onChange={holder.handleValueChange} value={club.title}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				Контакти<br/>
				<textarea 
					name="contacts" rows="3" 
					style={{width: '800px'}}
					onChange={holder.handleValueChange}
					value={club.contacts}
				/>
			</div>
			<div style={{marginTop: '15px'}}>
				Опис<br/>
				<textarea 
					name="description" rows="6" 
					style={{width: '800px'}}
					onChange={holder.handleValueChange}
					value={club.description}
				/>
			</div>
			<div>
				<div style={{marginTop: '15px', marginBottom: '10px', textAlign: 'left', fontWeight: 'bold', fontSize: '16pt'}}>
					Малюнок
				</div>
				<Image holder={holder} />
			</div>
			{buttonOrSpinnerHtml}
		</div>
	);
}

class Image extends Component {

	fileRef = createRef();

	constructor(props) {
		super(props);
		this.state = { dirtyCount: 0}
	}

	onFileSelected = (e) => {
		let files = e.target.files;
		if( files && files.length > 0 ) {
			let holder = this.props.holder;
			let reader = new FileReader();
			reader.onload = (event) => {
				holder.newImageBinary = event.target.result;
				holder.clearImage = false;
				this.setState({dirtyCount: this.state.dirtyCount+1})
			};
			reader.readAsDataURL(files[0]);
		}
	}
	clearImage = () => {
		let holder = this.props.holder;
		holder.newImageBinary = null;
		holder.clearImage = true;
		holder.club.imageWidth = 0;
		holder.club.imageHeight = 0;
		this.setState({dirtyCount: this.state.dirtyCount+1})
	}

	render() {
		let club = this.props.holder.club;
		let imageSource = logo;
		let buttonClearHtml = null;
		let imageEmpty = true;
		if( this.props.holder.newImageBinary ) {
			imageSource = this.props.holder.newImageBinary;
			imageEmpty = false;
		} else if( club.imageHeight > 0 && club.imageWidth > 0 ) {
			imageSource = getClubImageUrl( club.clubId );
			imageEmpty = false;
		}
		if( ! imageEmpty )
			buttonClearHtml = (<button type='button' style={{width: '100%'}} onClick={this.clearImage}>Очистити</button>)

		return (
			<div style={{display: 'flex'}}>
				<div>
					<img src={imageSource} width='100' />
				</div>
				<div style={{marginLeft: '20px'}}>
					<div style={{width: '80px'}}>
						<button type='button' style={{width: '100%'}} onClick={e => this.fileRef.current.click()}>Вибрати</button>
						<input ref={this.fileRef} type={'file'} style={{display: 'none'}} onChange={this.onFileSelected} />
					</div>
					<div style={{marginTop: '10px', width: '80px'}}>
						{buttonClearHtml}
					</div>
				</div>
			</div>
		);	
	}
}
export default AdminClubPage;
