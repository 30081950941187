import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Gallery from "../../../components/Gallery";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIBKNYGOLYUB_INFO_PATH, LIBKNYGOLYUB_GALLERY_PATH, } from '../../../util/routes';
import { LIBKNYGOLYUB } from '../../../util/consts';

class LibKnygolyubGalleryPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBKNYGOLYUB.title, url: LIBKNYGOLYUB_INFO_PATH, selected: false },
			{title: 'Фотогалерея', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBKNYGOLYUB_GALLERY_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBKNYGOLYUB_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var imageList = [
		{ url: '/libknygolyub/001.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libknygolyub/002.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libknygolyub/imgp2401.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libknygolyub/imgp2403.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libknygolyub/imgp2404.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libknygolyub/imgp2405.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
	];
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBKNYGOLYUB.title}<br/>
				Фотогалерея
			</div>
			<Gallery imageUrlPrefix={'/img/'} images={imageList} />
		</div>
	);
}

export default LibKnygolyubGalleryPage;
