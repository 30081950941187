import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";

import {
	MAIN_PATH,
	LIB149_INFO_PATH, 	
} from '../../../util/routes';

import mainImage from '../../../assets/img/library/lib149/hello.jpg';

class Lib149InfoPage extends Component {
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліотека №149', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIB149_INFO_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIB149_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={{width: 'calc(100% - 80px)', paddingLeft: '40px', fontFamily: 'Arial, sans-serif', fontSize: '12pt'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Бібліотека №149
			</div>
			<div>
				<img style={localStyles.imageCentered} src={mainImage} />
			</div>
			<div style={{marginTop: '30px', marginBottom: '30px'}}>
				Площа приміщення бібліотеки 110 кв.м., <br/> 
				книжковий фонд - 18,2 тис. примірників. <br/>
				Щорічно обслуговує 2,4 тис. користувачів, <br/>
				кількість відвідувань за рік - 13,4 тис., <br/>
				книговидач - 51 тис. примірників.
			</div>
		</div>
	);
}

const localStyles = {
	imageCentered:	{
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
}

export default Lib149InfoPage;