import React, { Component } from 'react';
import styles from '../bibliographic.module.css';

import BibliographicPage from "../BibliographicPage";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH
} from '../../../util/routes';

import image01 from './image01.jpg';
import image02 from './image02.jpg';
import image03 from './image03.jpg';
import image04 from './image04.jpg';
import image05 from './image05.png';

class B209 extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, selected: false },
			{title: 'Безбар’єрність – це про нас і для нас', url: '', selected: true }
		];
		var body = (<Body />);
		return (
			<BibliographicPage breadCrumbs={breadCrumbs} body={body} />
		);
	}
}

function Body(props) {
return(

<div style={{width: '100%', marginBottom: '30px'}}>
	<div className={styles.divBibliographicHeader}>
		Безбар’єрність – це про нас і для нас
	</div>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image01} width='600'/>
	</div>
	<div className={styles.divHeaderBibliographicType}>
		Вебліографічний інформаційний дайджест
	</div>

	<p className={styles.pCommon}>
		Безбар’єрність стає нормою для створення суспільства без обмежень, в якому гарантуються рівні права та можливості для всіх у пересуванні, зайнятості, здобутті освіти, самореалізації, спілкуванні, дозвіллі, розвитку, отриманні публічної інформації та послуг. 
	</p>
	<p className={styles.pCommon}>
		Пропонуємо інформаційний дайджест онлайн-ресурсів «Безбар’єрність – це про нас і для нас», який ознайомлять Вас з електронними ресурсами на тему безбар’єрності. 
	</p>
	<p className={styles.pCommon}>
		Маємо надію, що запропоноване видання стане у пригоді всім, хто цікавиться безбар’єрним простором в Україні.
	</p>

	<p className={styles.epigraph}>
		Безбар’єрність – це передусім повага до людської гідності.
	</p>

	<p className={styles.header}>
		Безбар’єрність
	</p>

	<p className={styles.pCommon} style={localStyles.blueHeader}>
		Національна стратегія зі створення безбар’єрного простору в Україні
	</p>
	<p className={styles.pCommon}>
		<a href='https://zakon.rada.gov.ua/laws/show/366-2021-%D1%80#Text'>
			Розпорядження Кабінету Міністрів України від 14 квітня 2021 р. № 366-р «Про схвалення Національної стратегії із створення безбар’єрного простору в Україні на період до 2030 року» 
		</a>
	</p>
	<p className={styles.pCommon}>
		Нормативно-правові акти
	</p>
	<p className={styles.pCommon}>
		Указ Президента України "Про забезпечення створення безбар’єрного простору в Україні"  
		<a href='https://www.president.gov.ua/documents/5332020-35809'>
			https://www.president.gov.ua/documents/5332020-35809 
		</a>
	</p>
	<p className={styles.pCommon}>
		Закон України «Про внесення змін до деяких законів України про освіту щодо організації інклюзивного навчання» 
		<a href='https://zakon.rada.gov.ua/laws/show/1324-18#Text'>
			https://zakon.rada.gov.ua/laws/show/1324-18#Text 
		</a>
	</p>
	<p className={styles.pCommon}>
		Конвенція ООН про права осіб з інвалідністю 
		<a href='https://zakon.rada.gov.ua/laws/show/995_g71#Text'>
			https://zakon.rada.gov.ua/laws/show/995_g71#Text 
		</a>
	</p>
	<p className={styles.pCommon}>
		Розпорядження Кабінету Міністрів «Про затвердження складу міжвідомчої робочої групи з питань розроблення проекту Національної стратегії із створення безбар’єрного простору в Україні» 
		<a href='https://www.kmu.gov.ua/npas/pro-zatverdzhennya-skladu-mizhvido-a134r'>
			https://www.kmu.gov.ua/npas/pro-zatverdzhennya-skladu-mizhvido-a134r 
		</a>
	</p>
	<p className={styles.pCommon}>
		Постанова Кабінету Міністрів України «Про затвердження плану заходів щодо створення безперешкодного життєвого середовища для осіб з обмеженими фізичними можливостями та інших маломобільних груп населення на 2009—2015 роки «Безбар’єрна Україна» 
		<a href='https://www.kmu.gov.ua/npas/232167617'>
			https://www.kmu.gov.ua/npas/232167617 
		</a>
	</p>
	<p className={styles.pCommon}>
		Постанова Кабінету Міністрів України «Про затвердження критеріїв доступності для осіб з інвалідністю та інших маломобільних груп населення приміщень, що надаються дільничним виборчим комісіям звичайних виборчих дільниць для організації їх роботи та проведення голосування» 
		<a href='https://zakon.rada.gov.ua/laws/show/962-2020-%D0%BF#Text'>
			https://zakon.rada.gov.ua/laws/show/962-2020-%D0%BF#Text  
		</a>
	</p>
	<p className={styles.pCommon}>
		Джерело: <a href='https://www.msp.gov.ua/content/bezbarernist.html'>https://www.msp.gov.ua/content/bezbarernist.html</a>
	</p>

	<p className={styles.pCommon} style={localStyles.blueHeader}>
		Довідник "Безбар'єрності"
	</p>
	<p className={styles.pCommon}>
		Довідник «Безбар’єрності» – це унікальний посібник, що є гідом коректного спілкування та включає в себе правила комунікації та безбар’єрної мови. 
	</p>
	<p className={styles.pCommon}>
		Довідник «Безбар’єрності» створений за ініціативи першої леді України Олени Зеленської. Упорядницею довідника є Тетяна Касьян – журналістка, авторка книги «Про що мовчать». 
	</p>
	<p className={styles.pCommon}>
		Довідник «Безбар’єрності» складається із п’ятьох розділів «Складові безбар’єрності», «Етика взаємодії», «Словник», «Безбар’єрний календар», «У воєнний час». 
	</p>
	<p className={styles.pCommon}>
		У розділі «Складові безбар’єрності» розміщена інформація про базові поняття безбар’єрності, а саме: рівність, різноманіття, толерантність, доступність, стереотипу, множинну дискримінацію, гендерну рівність, інвалідність тощо. 
	</p>
	<p className={styles.pCommon}>
		У розділ «Етика взаємодії» включена інформація про те, як правильно підійти до людини з порушеннями слуху, як пояснити дитині, що таке інвалідність, як вибудувати спілкування з людьми, чиї близькі живуть з психічними порушеннями тощо. 
	</p>
	<p className={styles.pCommon}>
		Розділ «Словник» наповнений дефініціями, які правильно вживати стосовно різних людей, а також поясненнями, чому певні слова є некоректними та створюють дискримінаційні прецеденти. 
	</p>
	<p className={styles.pCommon}>
		У розділі «Безбар’єрний календар» розміщена інформація про пам’ятні дати та визначні дні для суспільства, пов’язані із інклюзією та рівністю. 
	</p>
	<p className={styles.pCommon}>
		Розділ «У воєнний час» містить поради та інструктажі для сімей з дітьми, людей старшого віку та їхніх близьких, людей з інвалідністю, які потерпають від військових дій. 
	</p>
	<p className={styles.pCommon}>
		Детальніше із Довідником «Безбар’єрності» можна ознайомитися за посиланням: <a href='https://bf.in.ua'>https://bf.in.ua</a>
	</p>
	<p className={styles.pCommon}>
		Джерело: <a href='https://mvs.gov.ua/right/nediskriminaciia-ta-bezbarjernist/novini-ta-aktualna-informaciia-1/aktualna-informaciia/dobidnik-bezbarjernosti'>https://mvs.gov.ua/right/nediskriminaciia-ta-bezbarjernist/novini-ta-aktualna-informaciia-1/aktualna-informaciia/dobidnik-bezbarjernosti</a>
	</p>

	<p className={styles.pCommon} style={localStyles.blackHeader}>
		Дослідження: безбар’єрність — нова цінність для українців
	</p>
	<p>
		<img src={image02} style={{float: 'left', marginLeft: '15px', marginRight: '30px'}} width='200'/>
	</p>
	<p className={styles.pCommon}>
		Автор: <a href='https://hubz.ua/author/opalko/'>Наталія Білотіл</a>
	</p>
	<p className={styles.pCommon}>
		Журналістка HUBZ Inform, редакторка новин (Україна)
	</p>
	<p className={styles.pCommon}>
		Безбар’єрність є однією з цінностей для України . Наразі так вважає 83% українців. Це <a href='https://president.gov.ua/news/83-opitanih-ukrayinciv-vvazhayut-bezbaryernist-novoyu-cinnis-91649'>результати</a> дослідження, проведеного Центром соціальних досліджень та поведінкової економіки за підтримки Дитячого фонду і Ради з комунікацій безбар’єрності.
	</p>
	<p className={styles.pCommon}>
		Так, 34% опитаних переконані, що в подоланні бар’єрів у різних сферах слід зосередити увагу на зміні поведінки людей у суспільстві, 32% – на створенні адаптованого середовища та 22% – на забезпеченні доступності освіти й роботи.
	</p>
	<p className={styles.pCommon}>
		Дослідження також показало, що більш як половина опитаних українців вважають, що створенням безбар’єрного простору й суспільства мають займатися держава та місцеві органи влади. Водночас 24% осіб нині й самі долучаються до усунення бар’єрів. Цей показник суттєво зріс порівняно з періодом до війни, коли активно долучилися лише 10%.
	</p>
	<p className={styles.pCommon}>
		“Якщо так багато людей усвідомлює безбар’єрність як цінність — ми на правильному шляху як суспільство”, — наголосила перша леді Олена Зеленська.
	</p>
	<p className={styles.pCommon}>
		За її словами, безбар’єрність потрібна не лише пораненим і людям з інвалідністю, а кожному. Усе для того, щоб почуватися комфортно та вільно.
	</p>
	<p className={styles.pCommon}>
		За результатами дослідження, першочергово розв’язати питання безбар’єрності треба у сфері працевлаштування. Так вважають 57% опитаних. 51% назвали потребу змін в інфраструктурі.  Ще 37% респондентів — в усуненні бар’єрів для людей старшого віку.
	</p>


	<p className={styles.pCommon} style={localStyles.blueHeader}>
		Безбар’єрна Україна: що передбачає Нацстратегія
	</p>
	<p className={styles.pCommon}>
		За ініціативи Олени Зеленської у 2021 році була ухвалена Національна стратегія із створення безбар’єрного простору в Україні на період до 2030 року.
	</p>
	<p className={styles.pCommon}>
		Йдеться про роботу за 6 основними напрямками:
	</p>
	<ul>
		<li style={{fontSize: '11pt', fontStyle: 'italic'}}>
			Суспільна та громадянська безбар’єрність
			<p className={styles.pCommon} style={{fontStyle: 'normal'}}>
				Забезпечення рівних можливостей участі всіх людей та суспільних груп у житті громад та держави.
			</p>
		</li>
		<li style={{fontSize: '11pt', fontStyle: 'italic'}}>
			Освітня безбар’єрність
			<p className={styles.pCommon} style={{fontStyle: 'normal'}}>
				Створення рівних можливостей та вільного доступу до освіти, включаючи освіту протягом життя.
			</p>
		</li>
		<li style={{fontSize: '11pt', fontStyle: 'italic'}}>
			Економічна безбар’єрність
			<p className={styles.pCommon} style={{fontStyle: 'normal'}}>
				Забезпечення умов та можливостей для працевлаштування, заняття підприємництвом молоді, жінок, літніх людей, людей з інвалідністю.
			</p>
		</li>
		<li style={{fontSize: '11pt', fontStyle: 'italic'}}>
			Інформаційна безбар’єрність
			<p className={styles.pCommon} style={{fontStyle: 'normal'}}>
				Умови, за яких люди, незалежно від їх функціональних порушень чи комунікативних можливостей, мають доступ до інформації в різних форматах та з використанням технологій, що враховують їх потреби та можливості.
			</p>
		</li>
		<li style={{fontSize: '11pt', fontStyle: 'italic'}}>
			Фізична безбар’єрність
			<p className={styles.pCommon} style={{fontStyle: 'normal'}}>
				Створення середовища, в якому громадські споруди та публічні місця, транспорт і послуги є фізично доступними для всіх суспільних груп.
			</p>
		</li>
		<li style={{fontSize: '11pt', fontStyle: 'italic'}}>
			Цифрова безбар’єрність
			<p className={styles.pCommon} style={{fontStyle: 'normal'}}>
				Забезпечення доступу до інформації, комунікації, публічних та інших послуг онлайн для всіх суспільних груп, включаючи літніх людей, людей з інвалідністю, людей, які проживають у сільській місцевості.
			</p>
		</li>
	</ul>
	<p className={styles.pCommon}>
		Джерело: <a href='https://hubz.ua/news/doslidzhennya-bezbar-yernist-nova-tsinnist-dlya/'>https://hubz.ua/news/doslidzhennya-bezbar-yernist-nova-tsinnist-dlya/</a>
	</p>
	
	<p className={styles.pCommon} style={localStyles.blackHeader}>
		В Україні з’явилась онлайн-мапа безбар’єрних закладів
	</p>
	<p>
		<img src={image03} style={{float: 'left', marginLeft: '15px', marginRight: '30px'}} width='200'/>
	</p>
	<p className={styles.pCommon}>
		Автор: <a href='https://hubz.ua/author/opalko/'>Наталія Білотіл</a>
	</p>
	<p className={styles.pCommon}>
		Журналістка HUBZ Inform, редакторка новин (Україна)
	</p>
	<p className={styles.pCommon}>
		В Україні створили онлайн-мапу безбар’єрних закладів. Тепер кожен охочий зможе перевірити, наскільки доступна його кав’ярня неподалік будинку, супермаркет чи аптека. А також залучити експертів до адаптації простору. Про це розповіли розробники проєкту ЛУН Місто та громадська організація “Безбар’єрність”.
	</p>
	<p className={styles.pCommon}>
		На <a href='https://misto.lun.ua/barrier-free?ls=barrier-free-places%2Cbarrier-free-infrastructure%2Cbarrier-free-transport%2Cbarrier-free-parks%2Cbarrier-free-sport%2Cbarrier-free-playgrounds%2Cbarrier-free-education%2Cbarrier-free-medicine%2Cbarrier-free-buildings#6.04/48.62/24.922'>мапу</a> “Місто без меж” нанесені заклади, публічні простори, міська інфраструктура, житлові будинки, де вже зроблені певні кроки для вільного доступу маломобільних груп населення.
	</p>
	<p className={styles.pCommon}>
		“Безбар’єрність важлива для кожного з нас. На жаль, з війною питання доступності стало актуальним як ніколи, адже сильно збільшилась кількість представників маломобільних груп населення і наш обов’язок – забезпечувати комфорт в місті. На мою думку, це не лише обов’язок держави, а і може стати відповідальністю маленьких бізнесів”, — каже кураторка проєкту ЛУН Місто Анна Денисенко.
	</p>
	<p className={styles.pCommon}>
		Щоби максимально наповнити мапу. Організатори запустили чеклист “Місто без меж: Заклади”. Це простий онлайн-опитувальник, в якому зібрані найважливіші складові архітектурної доступності. Чеклист допоможе оцінити рівень доступності та більше дізнатися про атрибути безбарʼєрності.
	</p>
	<p className={styles.pCommon}>
		Ініціатори оцінюють, наприклад, наявність вбиральнь для людей із інвалідністю у закладах, простір для сповивання дітей, парковку для людей з інвалідністю, входи та виходи.
	</p>
	<p className={styles.pCommon}>
		Якщо ви представник закладу і хочете допомогти зробити місто більш безбар’єрним, ось кілька простих кроків, які можна зробити:
	</p>
	<p className={styles.pCommon}>
		1. Стати амбасадором безбар’єрності.
	</p>
	<p className={styles.pCommon}>
		2. Заповнити чеклист <a href='https://misto.lun.ua/checklist-place'>тут</a>. Якщо у вас мережа закладів — напишіть на пошту misto@lun.ua
	</p>
	<p className={styles.pCommon}>
		3. Звернутися на пошту misto@lun.ua, якщо маєте бажання адаптувати свій простір.
	</p>
	<p className={styles.pCommon}>
		4. Розказати про ініціативу у своїх соцмережах та спонукати інших долучитися до наповнення карти та популяризувати принципи безбарʼєрності.
	</p>
	<p className={styles.pCommon}>
		Джерело: <a href='https://hubz.ua/news/v-ukrayini-z-yavylas-onlajn-mapa-bezbar-yernyh-zakladiv/'>https://hubz.ua/news/v-ukrayini-z-yavylas-onlajn-mapa-bezbar-yernyh-zakladiv/</a>
	</p>


	<p className={styles.pCommon} style={localStyles.blueHeader}>
		Безбар'єрне майбутнє України
	</p>
	<p className={styles.pCommon}>
		Майже всі види бар'єрів пов'язані між собою, і часто їх неможливо відокремити один від одного. Коли людина з порушеннями зору не може отримати інформацію про те, як проходить евакуація з будівлі - це фізичний бар'єр чи інформаційний? Коли в сільській школі немає інтернету – це цифровий бар'єр чи освітній? Коли людина з інвалідністю не може влаштуватися на роботу – це економічний бар'єр чи суспільний? А раз їх неможливо відокремити один від одного, то й вирішувати потрібно разом.
	</p>
	<p className={styles.pCommon}>
		Робота з впровадження безбар’єрності велась досить давно, але по-справжньому системною вона стала півтора роки тому, після того як перша леді України Олена Зеленська ініціювала спочатку проведення глобального всеукраїнського дослідження щодо бар’єрів, з якими стикаються люди, а потім - і велике міжсекторальне обговорення, яке з часом перетворилася на Національну стратегію за створення безбар’єрного простору в Україні. Сьогодні в межах ініціативи першої леді “Без бар’єрів” реалізуються проєкти, які допомагають популяризувати та втілювати філософію безбар’єрності в наше життя: “Держава без бар'єрів”, "Громади без бар’єрів", "Бізнес без бар’єрів", "Культура без бар’єрів", низка комунікаційних кампаній - задля напрацювання найкращих рішень та практик, аби безбар’єрність стала новою суспільною нормою.
	</p>
	<p className={styles.pCommon}>
		У вас може з’явитися питання: “А що можу зробити я особисто?”. Кожна людина може впливати на діяльність органів місцевого врядування, писати пропозиції та запити, наприклад, якщо у вашій будівлі немає пандусу чи він розташований під дуже гострим кутом. Ви можете розповсюджувати інформацію, наприклад, цей огляд. Ви можете допомогти літнім батькам пройти навчання комп’ютерній грамотності та завести для них сторінки в соціальних мережах.
	</p>
	<p className={styles.pCommon}>
		Ви можете проаналізувати свої упередження щодо вразливих груп та прискіпливо подивитися на свою мову, адже вона проявляє ваші погляди і ставлення, і зрештою навчитися використовувати доброзичливу лексику. Наприклад, почати говорити не “інвалід”, а “людина з інвалідністю”, адже всі ми насамперед люди і фокус маємо робити на цьому, а не на діагнозах. 
	</p>
	<p className={styles.pCommon}>
		Не знаєте, яка саме лексика є прийнятною, а яка - ні, та чому? Зазирніть до  <a href='https://bf.in.ua/'>"Довідника безбар'єрності"</a>! 
	</p>
	<p className={styles.pCommon}>
		Це флагманський проєкт першої леді України Олени Зеленської саме для того, щоб люди навчилися спілкуватися з повагою одне до одного. 
	</p>
	<p className={styles.pCommon}>
		“У комунікації приховано набагато більше сенсів, ніж нам здається. "Довідник безбар’єрності" допоможе розкрити нові, додаткові смисли, коли в центрі уваги – людина. І це стане першим кроком у формуванні нової етики спілкування.” - зазначає Олена Зеленська.
	</p>
	<p className={styles.pCommon}>
		Насправді, це досить просто - звертатися до людини так, як це комфортно для неї. Не транслювали упередження щодо віку, гендеру чи національності. Поважати одне одного, права та можливості інших людей.
	</p>
	<p className={styles.pCommon}>
		І тоді зусилля багатьох людей на всіх рівнях наблизять те, чого ми всі прагнемо - безбар’єрне майбутнє України!
	</p>
	<p className={styles.pCommon}>
		Матеріал підготовлено в партнерстві з ГО “Безбар’єрність”
	</p>
	<p className={styles.pCommon}>
		Джерело: <a href='https://tsn.ua/ukrayina/bar-yeri-ta-bezbar-yernist-scho-nam-zavazhaye-ta-yak-cogo-pozbutisya-1928365.html'>https://tsn.ua/ukrayina/bar-yeri-ta-bezbar-yernist-scho-nam-zavazhaye-ta-yak-cogo-pozbutisya-1928365.html</a>
	</p>

	<p className={styles.pCommon} style={localStyles.blackHeader}>
		По кожному київському закладу культури розроблений план дій із впровадження безбар’єрності, — Ганна Старостенко
	</p>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image04} width='500'/>
	</div>
	<div className={styles.divHeaderBibliographicType} style={{fontSize: '10pt'}}>
		Місто потребує безбар’єрного середовища. Ілюстративне фото: Олексій Самсонов
	</div>
	<p className={styles.pCommon}>
		Місто працює, аби заклади стали доступними для всіх.
	</p>
	<p className={styles.pCommon}>
		Доступність та безбар’єрність — один з найважливіших пріоритетів для всіх міських закладів і сервісів, від лікарень і центрів соцзахисту до бібліотек, театрів і музеїв.
	</p>
	<p className={styles.pCommon}>
		Про це у програмі «КиївВголос» радіо «Київ FM» <a href='https://www.youtube.com/watch?v=tqvNxIFTPHQ'>розповіла</a> депутатка Київради (фракція «УДАР»), заступниця голови КМДА <a href='https://vechirniy.kyiv.ua/dossier/73/'>Ганна Старостенко</a>.
	</p>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image05} width='500'/>
	</div>
	<div className={styles.divHeaderBibliographicType} style={{fontSize: '10pt'}}>
		Ганна Старостенко в ефірі програми «КиївВголос» радіо «Київ FM». Фото: скрін з відео
	</div>
	<p className={styles.pCommon}>
		«Сьогодні кожна міська установа перевірена на стан безбар’єрності та доступності. По кожному закладу розроблена своя програма: що вже зроблено та що має бути здійснене, — зазначила Ганна Старостенко. — Це стосується і наших культурних закладів. Якщо вони розташовані в пам’ятці архітектури, де неможливо встановити ліфт, то можуть надати інформаційні послуги. Наприклад, онлайн екскурсії музеями. В наших театрах є багато проєктів для людей з порушенням зору, йдуть вистави з аудіодискрипцією, тобто описом того, що відбувається на сцені».
	</p>
	<p className={styles.pCommon}>
		За її словами, питання фізичної доступності актуальне, однак воно потребує часу і ресурсів: це будівництво ліфтів. облаштування пандусів, вхідних груп, вбиралень.
	</p>
	<p className={styles.pCommon}>
		«Все можливо обов’язково має зроблено. І у нас по кожному закладу є свій план дій, коли та що там з’явиться, щоб культура була доступна для всіх», — підкреслила Ганна Старостенко.
	</p>
	<p className={styles.pCommon}>
		Марія КАТАЄВА, «Вечірній Київ». 21 грудня 2024 року.
	</p>
	<p className={styles.pCommon}>
		Джерело: <a href='https://vechirniy.kyiv.ua/news/106457/'>https://vechirniy.kyiv.ua/news/106457/</a>
	</p>
	
</div>
		
);
}

const localStyles = {
	blueHeader: {
		fontWeight: 'bold', 
		fontSize: '12pt', 
		color: 'rgb(110,173,232)'
	},
	blackHeader: {
		fontWeight: 'bold', 
		fontSize: '12pt'
	},
}


export default B209;
