import React, { Component } from 'react';

class SpanInnerHtml extends Component {

	render() {
		return (
			<span 
				className={this.props.class} style={this.props.style}
				dangerouslySetInnerHTML={{__html: this.props.text}}
			>
			</span>
		)
	}
}

export default SpanInnerHtml;
