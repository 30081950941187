import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Gallery from "../../../components/Gallery";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIBTRUBLAINI_INFO_PATH, LIBTRUBLAINI_GALLERY_PATH, } from '../../../util/routes';
import { LIBTRUBLAINI } from '../../../util/consts';

class LibTrublainiGalleryPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBTRUBLAINI.title, url: LIBTRUBLAINI_INFO_PATH, selected: false },
			{title: 'Фотогалерея', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBTRUBLAINI_GALLERY_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBTRUBLAINI_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var imageList = [
		{ url: '/libtrublaini/photo.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo2.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo3.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo4.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo5.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo6.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo7.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo8.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo9.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo10.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo11.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo12.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo13.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo14.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo15.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo16.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libtrublaini/photo17.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
	];
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBTRUBLAINI.title}<br/>
				Фотогалерея
			</div>
			<Gallery imageUrlPrefix={'/img/'} images={imageList} />
		</div>
	);
}

export default LibTrublainiGalleryPage;
