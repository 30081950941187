import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Gallery from "../../../components/Gallery";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIBVYSHNI_INFO_PATH, LIBVYSHNI_GALLERY_PATH, } from '../../../util/routes';
import { LIBVYSHNI } from '../../../util/consts';

class LibVyshniGalleryPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBVYSHNI.title, url: LIBVYSHNI_INFO_PATH, selected: false },
			{title: 'Фотогалерея', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBVYSHNI_GALLERY_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBVYSHNI_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var imageList = [
		{ url: '/libvyshni/01.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/02.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/03.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/04.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/05.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/imgp2356.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/imgp2360.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/imgp2365.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/imgp2366.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/imgp2367.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/imgp2368.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/imgp2370.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/imgp2371.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-1-1.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-2-1.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-2-2.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-2-3.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-2-4.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-2-5.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-2-6.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-2-7.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-3-1.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-3-2.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/libvyshni/img2010-3-3.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
	];
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBVYSHNI.title}<br/>
				Фотогалерея
			</div>
			<Gallery imageUrlPrefix={'/img/'} images={imageList} />
		</div>
	);
}

export default LibVyshniGalleryPage;
