import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";

import {
	MAIN_PATH,
	LIBVESELKA_INFO_PATH, 	
} from '../../../util/routes';

import mainImage from '../../../assets/img/library/libveselka/hello.jpg';

class LibVeselkaInfoPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліотека "Веселка"', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBVESELKA_INFO_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBVESELKA_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={{width: 'calc(100% - 80px)', paddingLeft: '40px', fontFamily: 'Arial, sans-serif', fontSize: '12pt'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Бібліотека "Веселка" для дітей
			</div>
			<div>
				<img style={localStyles.imageCentered} src={mainImage} />
			</div>
			<div style={{marginTop: '30px'}}>
				Площа приміщення бібліотеки - 70,2 кв.м., книжковий фонд - 18,7 тис. примірників.<br/>
				Щорічно обслуговує 1,75 тис. користувачів, кількість відвідувань за рік - 13 тис.,<br/>
				книговидач - 37,5 тис. примірників.
			</div>
			<div>
				Бібліотека заснована в 1980 році. 
				З 1989 року бібліотека отримала назву "Веселка". 
				Бібліотечне обслуговування: 2 абонементи, 2 читальні зали.
			</div>
		</div>
	);
}

const localStyles = {
	imageCentered:	{
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
}

export default LibVeselkaInfoPage;
