import React, { Component } from 'react';

import { getLibrary, getEventUrl, getEventImageUrl } from '../../util/tools';
import { performApiRequest, EVENT_LIST_API_URL } from "../../util/restapi"
import { ANY, ONLY_TRUE, ONLY_FALSE } from '../../util/consts';

import Pagination from "../../components/Pagination";
import SpanInnerHtml from '../../components/SpanInnerHtml';
import DivInnerHtml from '../../components/DivInnerHtml';
import Spinner from "../../components/Spinner";

import logo from '../../assets/img/pechersk_logo.png';

const STATE_INIT = 'init';
const STATE_WORKING = 'working';
const STATE_READY = 'ready';

const PAGE_SIZE = 10;

class EventListBody extends Component {

	constructor(props) {
		super(props);
		this.libraryId = props.libraryId;
		this.eventList = [];
		this.eventCount = null;
		this.pageCount = null;
		this.pageCurrent = null;
		this.ok = false;
		this.state = {
			formState: STATE_INIT,
		};
	}

	componentDidMount = async () => {
		this.populatePage(1);
	}
	populatePage = async (page) => {
		this.setState({formState: STATE_WORKING});
		var request = {
			page: page,
			pageSize: PAGE_SIZE,
			skipCountRequest: (this.eventCount != null ),
			libraryId: this.libraryId,
			active: ONLY_TRUE,
			virtualExhibition: ONLY_FALSE,
			slided: ONLY_FALSE
		};
		var response = await performApiRequest(EVENT_LIST_API_URL, request);
		this.ok = response.ok;
		if( response.ok ) {
			this.pageCurrent = page;
			this.eventList = response.eventList;
			if( this.eventCount == null ) {
				this.eventCount = response.eventCount;
				this.pageCount = Math.floor( (this.eventCount-1) / PAGE_SIZE) + 1;
			}
		}
		this.setState({formState: STATE_READY, pageCurrent: page, ok: response.ok});
	}
	
	onPageClick = async (page) => {
		this.populatePage(page)
		this.setState({pageCurrent: page });
	}
	
	
	render() {
		if( this.state.formState == STATE_INIT ) {
			return (null);
		}
		if( this.state.formState == STATE_WORKING ) {
			return (
				<div style={{width: '100%', textAlign: 'center'}}>
					<Spinner />
				</div>
			);
		}
		if( ! this.ok ) {
			return (
				<div style={{width: '100%', textAlign: 'center'}}>
					Вибачте, сервіс тимчасово не доступний.
				</div>
			);
		}
		if( this.eventCount == 0 ) {
			return (
				<div style={{width: '100%', textAlign: 'center'}}>
					Вибачте, інформація відсутня.
				</div>
			);
		}
		var events = this.eventList.map( (event, index) => {
			return (<Event key={index} holder={this} event={event} />);
		});
		return (
			<React.Fragment>
				<Pagination pageCount={this.pageCount} pageNumber={this.pageCurrent} onPageClick={this.onPageClick} />
				{events}
			</React.Fragment>
		);
	}
}

function Event(props) {
	var e = props.event;
	var urlEvent = getEventUrl( e.eventId);
	if( e.logoImageId > 0 ) {
		var urlImage = getEventImageUrl( e.logoImageId );
		var imgHtml = (<img src={urlImage} width={225} height={138} />);
	} else {
		var imgHtml = (<img src={logo} style={{marginTop: '35px'}}/>);
	}
	var library = getLibrary( e.libraryId );
	if( library == null )
		library = {cipher: e.libraryId, title: ''};
	return (
		<div style={{display: 'flex', marginTop: '10px'}}>
			<div style={{ width: '225px', position: 'relative'}}>
				<a href={urlEvent}>
					{imgHtml}
				</a>
				<div style={localStyles.divDateBlock}>{e.eventDateFormatted}</div>
			</div>
			<div style={{width: '800px', paddingLeft: '10px' }}>
				<div style={{width: '100%', display: 'flex'}}>
					<hr style={{width: '200px', border: '2px solid #430045'}}/>
					<div style={localStyles.divLibraryName}>
						{library.title}
					</div>
				</div>
				<DivInnerHtml style={localStyles.divNewsHeader} text={e.title} />
				<DivInnerHtml style={localStyles.divNewsBody} text={e.description} />
				<div style={{textAlign: 'right'}}>
					<a href={urlEvent}>Детальніше...</a>
				</div>
			</div>
		</div>
	)
}

const localStyles = {
	divDateBlock: {
		position: 'absolute',
		top: '5px',
		left: '5px', 
		width: '100px', 
		height: '20px',
		backgroundColor: '#588b0e',
		color: 'white',
		paddingTop: '5px',
		textAlign: 'center',
		fontSize: '10pt',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'bold'
	},
	divLibraryName: {
		flexGrow: '100', 
		textAlign: 'right',
		fontFamily: 'impact',
		fontWeight: 'normal',
		fontSize: '14pt'
	},
	divNewsHeader: {
		textAlign: 'left',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'bold',
		fontSize: '12pt'
	},
	divNewsBody: {
		paddingTop: '7px',
		textAlign: 'justify',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'normal',
		fontSize: '12pt'
	}
}

export default EventListBody;
