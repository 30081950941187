import React from 'react';
import styles from './components.module.css';

import {
	ECATALOG_PATH,
} from '../util/routes';

import iconFind from '../assets/img/header/icon_find.png';

const CATALOG = 'catalog';
const SITE = 'site';

class FastSearchForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			active: CATALOG,
			catalogValue: '',
			siteValue: ''
		}
	}

	setActiveCatalogSearch = () => {
		this.setState({active: CATALOG});
	}
	setActiveSiteSearch = () => {
		this.setState({active: SITE});
	}
	onInputChange = (e) => {
		const {name,value} = e.target;
		if( name == CATALOG )
			this.setState({catalogValue: value});
		if( name == SITE )
			this.setState({siteValue: value});
	}

	searchSite = () => {
	}
	searchCatalog = () => {
		console.log(this.state.catalogValue);
		if( this.state.catalogValue )
			window.open(ECATALOG_PATH + '/' + this.state.catalogValue, '_self');
			//window.open(ECATALOG_PATH + '/' + this.state.catalogValue, '_blank');
	}
	
	render() {
		if( this.state.active == CATALOG )
			return (
				<div style={{marginLeft: '20px'}}>
					<span style={localStyles.spanSelected} onClick={this.setActiveCatalogSearch}>Каталог</span>
					<span style={localStyles.spanUnselected} onClick={this.setActiveSiteSearch}>Сайт</span>
					<div style={{paddingTop: '5px'}}>
						<form className={styles.fastsearch_form} action="" method="post">
							<input id={CATALOG} className={styles.fastsearch_input} name={CATALOG} 
								placeholder="Пошук по електронному каталогу..." type="search" 
								value={this.state.catalogValue} onChange={this.onInputChange}
							/>
							<button className={styles.fastsearch_button} type="button" onClick={this.searchCatalog}>
								<img src={iconFind} />
							</button>
						</form>
					</div>
				</div>
			);
		if( this.state.active == SITE )
			return (
				<div style={{marginLeft: '20px'}}>
					<span style={localStyles.spanUnselected} onClick={this.setActiveCatalogSearch}>Каталог</span>
					<span style={localStyles.spanSelected} onClick={this.setActiveSiteSearch}>Сайт</span>
					<div  style={{paddingTop: '5px'}}>
						<form className={styles.fastsearch_form} action="" method="post">
							<input id={SITE} className={styles.fastsearch_input} name={SITE} 
								placeholder="Пошук на сайті..." type="search" 
								value={this.state.siteValue} onChange={this.onInputChange}
							/>
							<button className={styles.fastsearch_button} type="button" onClick={this.searchSite}>
								<img src={iconFind} />
							</button>
						</form>
					</div>
				</div>
			);
	}
}

const localStyles = {
	spanSelected: {
		textDecoration: 'underline',
		color: '#674268',
		cursor: 'default',
		
		marginRight: '10px',
		fontSize: '12pt',
		fontWeight: 'normal',
		fontFamily: 'impact'	
	},
	spanUnselected: {
		textDecoration: 'none',
		color: 'black',
		cursor: 'pointer',
		
		marginRight: '10px',
		fontSize: '12pt',
		fontWeight: 'normal',
		fontFamily: 'impact'	
	}
};

export default FastSearchForm;
