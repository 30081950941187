import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";

import {
	MAIN_PATH,
	LIBCRB_INFO_PATH, 	
} from '../../../util/routes';

import mainImage from '../../../assets/img/library/crb/bib1.jpg';

class LibCrbInfoPage extends Component {
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'ЦРБ "Печерська"', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBCRB_INFO_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBCRB_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var styles = {
		imageCentered:	{
			display: 'block',
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		h2: {
			textAlign: 'center',
			fontSize: '14pt',
			fontFamily: 'impact',
			fontWeight: 'normal',
			marginTop: '20px',
			marginBottom: '20px'
		},
		text: {
			textAlign: 'justify'
		}
	}

	return (
		<div style={{width: 'calc(100% - 80px)', paddingLeft: '40px', fontFamily: 'Arial, sans-serif', fontSize: '12pt'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				ЦЕНТРАЛЬНА РАЙОННА БІБЛІОТЕКА "ПЕЧЕРСЬКА"				
			</div>
			<div>
				<img style={styles.imageCentered} src={mainImage} />
			</div>
			<div style={styles.h2}>Загальні відомості:</div>
			<div>
				Площа приміщення бібліотеки - 1009 кв.м<br/>
				Книжковий фонд - 85,9 тис.  прим.<br/>
				Щорічно обслуговує: 10,6 тис. користувачів<br/>
				Кількість відвідувань за рік - 56,0 тис.<br/>
				Книговидач - 211,5 тис. примірників.<br/>
			</div>

			<div style={styles.h2}>Структура</div>
			<ul style={{marginLeft: '10px', paddingLeft: '0px'}}>
				<li>абонемент</li>
				<li>читальний зал</li>
				<li>відділ довідково - бібліографічної та інформаційної роботи</li>
				<li>відділл організації і використання книжкового фонду</li>
				<ul style={{ marginLeft: '5px', paddingLeft: '5px', listStyleType: 'none', listStylePosition: 'initial', listStyleImage: 'initial'}}>
					<li>МБА</li>
					<li>ВСО</li>
				</ul>
				<li>відділ тюркомовної літератури</li>
				<li>Internet- центр "Печерськ"</li>
			</ul>

			<div style={styles.h2}>Історична довідка</div>

			<div style={styles.text}>
			Центральна районна бібліотека для дорослих Ленінського району була заснована в 1952 році,
			в 1954 році їй було присвячено ім'я класика російської літератури,
			видатного письменника-сатирика М.Є.Салтикова-Щедріна.
			З 1 січня 1961 року набула статусу Центральної міської бібліотеки
			ім.&nbsp;М.Є.Салтикова-Щедріна на базі приміщення,
			книжкового фонду та майна районної бібліотеки ім.&nbsp;М.Є.Салтикова-Щедріна.
			</div>

			<div style={styles.text}>
			Після переїзду в нове приміщення по бульвару Лесі Українки 7,
			з 1 січня1980 року Центральна міська бібліотека для дорослих
			ім.&nbsp;М.Є.Салтикова-Щедріна реорганізована в Центральну районну бібліотеку та
			одночасно в Центральну бібліотеку Централізованої бібліотечної системи
			Печерського району м. Києва, до якої входить 13 бібліотек-філій.
			</div>

			<hr className={stylesPage.hr} />
			
			<div style={styles.h2}>Відділ довідково - бібліографічної та інформаційної роботи</div>

			<p>Основні завдання:</p>
			
			<p>
			Збирання усієї важливої для користувачів інформації і надання її широкому загалу користувачів<br/>
			Удосконалення довідково-бібліографічного апарату ЦБС шляхом доступності його до різних категорій читачів<br/>
			Задоволення запитів читачів у бібліографічному та інформаційному обслуговуванні<br/>
			Організація бібліографічного навчання читачів<br/>
			Здійснення аналітичного розпису визначених періодичних видань для систематичної картотеки статей<br/>
			Інформування користувачів в режимі "запит-відповідь"<br/>
			Виконання письмових тематичних довідок<br/>
			Створення електронної бази даних<br/>
			</p>
			<p>Інформаційно-бібліографічне обслуговування координується з іншими бібліотеками, бібліотека надає бібліографічні довідки різної тематики в т.ч. по телефону.</p>
			<p>Забезпечує інформаційне обслуговування апарату райдержадміністрації, депутатського корпусу та окремих користувачів.</p>
			<p>Проводить роботу по створенню електронної бази даних на свої інформаційні ресурси, видає поточний інформаційний список "Печерськ на сторінках преси". </p>
			<hr className={stylesPage.hr} />

			<div style={styles.h2}>Читальний зал</div>
			<p>У книжковому фонді відділу є книги з усіх галузей знань, енциклопедії, словники, учбові посібники, видання з економіки і бізнесу, нормативні та законодавчі матеріали, понад 250 найменувань  періодичних видань. Художня класична та сучасна література.</p>
			<p>Вам допоможуть розшукати фактографічний матеріал, підібрати необхідну літературу для реферату чи курсової роботи, підготуватись до лекції чи екзамену</p>
			<p>Тут проходять зустрічі з письменниками, презентації книг, ділові зустрічі бібліотекарів району, громадських організацій.</p>
			<hr className={stylesPage.hr} />
			
			<div style={styles.h2}>Абонемент</div>
			<p>Відділ видач книг додому, що нараховує понад 30тис. примірників художньої та науково-популярної літератури з відкритим доступом</p>
			<p>Популярні періодичні видання:</p>
			<ul>
				<li>літературно художні журнали</li>
				<li>видання по садівництву та городництву</li>
				<li>видання для рукоділля, популярні жіночі журнали</li>
			</ul>
			<hr className={stylesPage.hr} />

			<div style={styles.h2}>Internet центр "Печерський"</div>
			<ul>
				<li>Пропонує доступ в Internet</li>
				<li>Виконує функції інформативної служби</li>
				<li>Здійснює автоматизований пошук законодавчих та нормативних документів України і надає їх тексти у друкованому або електронному вигляді</li>
				<li>Складає списки літератури до дипломних та курсових робіт</li>
				<li>Надає допомогу у складанні каталогів та картотек для особистих бібліотек та бібліотек установ і організацій</li>
				<li>Здійснює ксерокопіювання документів</li>
				<li>Безкоштовні консультації "Основи користування Internet"</li>
			</ul>
			<hr className={stylesPage.hr} />

			<div style={styles.h2}>Відділл організації і використання книжкового фонду</div>
			<p>Якщо видання, яке Вас цікавить відсутнє в фондах Централізованої бібліотечної системи – радимо скористатися послугою міжбібліотечного абонементу (МБА), ви отримаєте замовлення на протязі тижня (для користування в читальній залі вашої бібліотеки).</p>
			<p>Якщо в бібліотеці відсутнє видання, яке Вам потрібно, але воно є у фонді Централізованої бібліотечної системи  – скористайтеся послугою внутрішнього системного обміну (ВСО), ви отримаєте замовлення на протязі декількох діб.</p>

		</div>
	);
}

export default LibCrbInfoPage;