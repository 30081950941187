import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";
import SearchEngine from "../../ecatalog/SearchEngine";
import { LIBCRB_SEARCH_FORM } from "../../ecatalog/SearchForm";

import { MAIN_PATH, LIBCRB_INFO_PATH, LIBCRB_ECATALOG_PATH } from '../../../util/routes';
import { LIBCRB } from '../../../util/consts';

class LibCrbECatalogPage extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIBCRB.title, url: LIBCRB_INFO_PATH, selected: false },
			{title: 'Електронний каталог', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBCRB_ECATALOG_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBCRB_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIBCRB.title}<br/>
				Електронний каталог
			</div>
			<SearchEngine searchFormId={LIBCRB_SEARCH_FORM} />
		</div>
	);
}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	},
}

export default LibCrbECatalogPage;
