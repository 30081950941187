import React, { Component } from 'react';
import styles from '../bibliographic.module.css';

import BibliographicPage from "../BibliographicPage";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH
} from '../../../util/routes';

import image01 from './image01.jpg';
import image02 from './image02.png';
import image03 from './image03.png';
import image04 from './image04.jpg';
import image05 from './image05.jpg';
import image06 from './image06.jpg';
import image07 from './image07.jpg';
import image08 from './image08.jpg';
import image09 from './image09.jpg';
import image10 from './image10.png';
import image11 from './image11.png';
import image12 from './image12.png';
import image13 from './image13.png';
import image14 from './image14.jpg';
import image15 from './image15.jpg';
import image16 from './image16.jpg';
import image17 from './image17.jpg';
import image18 from './image18.jpg';
import image19 from './image19.jpg';

class B207 extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, selected: false },
			{title: 'Ментальне здоров’я: як подбати про себе', url: '', selected: true }
		];
		var body = (<Body />);
		return (
			<BibliographicPage breadCrumbs={breadCrumbs} body={body} />
		);
	}
}

function Body(props) {
return(

<div style={{width: '100%', marginBottom: '30px'}}>
	<div className={styles.divBibliographicHeader}>
		Ментальне здоров’я: як подбати про себе
	</div>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image01} width='600'/>
	</div>
	<div className={styles.divHeaderBibliographicType}>
		Дайджест
	</div>

	<p className={styles.pCommon}>
		Ментальне здоров'я є важливим елементом загального добробуту кожної людини. Стрес, тривога та інші психічні проблеми можуть значно погіршити якість життя та негативно вплинути на фізичне здоров'я. Однак, добре написана та досліджена книга може допомогти краще зрозуміти проблему та знайти шляхи вирішення. 
	</p>
	<p className={styles.pCommon}>
		В дайджесті «Ментальне здоров’я : як подбати про себе» ви знайдете інформацію , яка  допоможе зрозуміти різноманітні аспекти психіки та навчить  піклуватися про власне ментальне здоров'я. 
	</p>
	<p className={styles.pCommon}>
		Відповідальний за випуск: Т.І. Шевченко
	</p>
	
	<p className={styles.pCommon} style={{fontWeight: 'bold', textAlign: 'center'}}>
		ВІД УКЛАДАЧА 
	</p>
	<p className={styles.pCommon}>
		За визначенням Всесвітньої організації охорони здоров'я, ментальне або психічне здоров’я — це стан добробуту, в якому людина може реалізувати свій власний потенціал, справлятися зі звичайними життєвими стресами, може продуктивно і плідно працювати й мати можливості робити внесок у життя своєї спільноти
	</p>
	<p className={styles.pCommon}>
		Як зазначають у Центрі громадського здоров’я МОЗ України, психологічно здорова людина — це людина, яка почуває себе гармонійно та має певний резерв сил до подолання  складнощів і достатній рівень продуктивності в повсякденному житті. Така людина має наснагу розвиватися та допомагати іншим. Попри труднощі вона має сили до адаптації та гнучкості сприйняття.  Гармонія з собою і зовнішнім світом, а також баланс — це одні з найважливіших  критеріїв психологічно здорової людини. 
	</p>
	<p className={styles.pCommon}>
		Триває війна… Ми відчуваємо тривогу та страх, коли чуємо сирену, читаємо та дивимось новини… Війна змусила нас цінувати найпростіші речі, які ми мали до цього: свободу, можливість обіймати рідних, подорожувати та просто бути щасливими. Але український народ — це сильна нація! Ми згуртовані, дружні та сильні духом!
	</p>
	<p className={styles.pCommon}>
		Важко концентруватися на чомусь, крім новин і переживань за рідних, друзів і тих, хто сміливо бореться на фронті. Все це виснажує мозок, але ж цього тільки і чекає ворог. Тому не падаємо духом і зміцнюємо ментальне здоров’я!
	</p>
	<p className={styles.pCommon}>
		Ну і найголовніше — вірте в скору перемогу і уявляйте, яку красиву країну ми потім відбудуємо. Україна продовжує впевнено відбиватися, а росія психує і вже всіма ресурсами намагається вивести нас зі спокою і холодного розуму. Не піддаємося! Все буде УКРАЇНА!
	</p>

	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image02} width='600'/>
	</div>

	<p className={styles.epigraph}>
		Добути і зберегти своє здоров’я може тільки сама людина.<br/>
		(М. Амосов)<br/> 
		<img src={image03} width='150'/>
	</p>

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
		МЕНТАЛЬНЕ ЗДОРОВ’Я: ЯК ПОДБАТИ ПРО СЕБЕ?
	</p>

	<p className={styles.pCommon}>
		Ментальне здоров’я є невід’ємною частиною щасливого життя. Це поняття дуже широке і поєднує різні  аспекти. Як зрозуміти, що стан душевного здоров’я вийшов із рівноваги та настав час звернутися до психотерапевта — розказуємо далі. 
	</p>
	<p className={styles.pCommon}>
		Що таке ментальне здоров’я? Згідно з визначенням ВООЗ, ментальне здоров’я — це стан щастя та добробуту, в якому людина реалізує свої творчі здібності, може протистояти життєвим стресам, продуктивно працювати та робити внесок у суспільне життя. Це означає, що ментальне здоров’я об’єднує здоровий дух та психіку людини із соціальним складником. Воно є важливим для втілення бажань, реалізації цілей, доброго фізичного самопочуття. А з іншого боку  психічне здоров’я кожного індивіда важливе для всього суспільства.
	</p>
	<p className={styles.pCommon}>
		Стан ментального здоров’я залежить від багатьох чинників: соціальних, екологічних та навіть економічних. Психічно здорова людина: може розрізняти свої емоції, керувати ними та гармонійно функціонувати; здатна спілкуватися і будувати стосунки з іншими; розвивається й навчається; позитивно оцінює себе, приймає та любить; приймає власні тверді рішення;нормально адаптовується до нових умов життя; може розв’язувати проблеми. Здатність отримувати задоволення від життя — основна риса ментально здорової людини.
	</p>
	<p className={styles.pCommon}>
		Психічні розлади становлять проблему і навіть загрозу, адже сьогодні близько 14% усіх відомих захворювань стосуються власне ментальних проблем. Чимало людей не визнають своїх труднощів або ж не мають доступу до належного лікування. Існує понад 200 класифікованих видів психічних захворювань. Найбільш  розповсюджені з них: тривожні і поведінкові розлади, гіперактивний розлад з дефіцитом уваги, порушення харчової поведінки, розлади спектру шизофренії, депресія, посттравматичний синдром, залежність від психоактивних речовин.
	</p>
	<p className={styles.pCommon}>
		Від чого залежить психічне здоров’я та як його підтримувати. Психічне здоров’я може порушуватися під дією: генетичних чинників; наркотичних речовин;гормонального дисбалансу; індивідуальних характеристик людини (наприклад, схильності до депресій та песимізму); швидких соціальних та політичних змін (зокрема, внаслідок воєнних дій); соціальної дискримінації на фоні різних чинників: сексуальна орієнтація, расова приналежність, ВІЛ-позитивний статус тощо; сексуального насильства; психологічного тиску; стресу та умов роботи.
	</p>
	<p className={styles.pCommon}>
		Психічне здоров’я значною мірою залежить від атмосфери у сім’ї, дитячого досвіду, соціального оточення. Особливо важливим є сприятливе середовище зростання для дітей та підлітків, у яких психіка лише формується. Що важливо для підтримання ментального здоров’я на належному рівні? Кілька прикладів:
		<ul>
			<li className={styles.liCommon}>
				розвиток емоційного інтелекту в дітей: сприйняття і розуміння своїх емоцій допоможе їм у дорослому віці вправніше керувати своїм станом;
			</li>
			<li className={styles.liCommon}>
				організація здорової атмосфери в родині, налагодження режиму дня для маленьких дітей;
			</li>
			<li className={styles.liCommon}>
				соціальна підтримка незахищених верств населення (наприклад, матерів-одиначок, жінок, що постраждали від насильства, людей з інвалідністю);
			</li>
			<li className={styles.liCommon}>
				локальні програми з підтримання психічного здоров’я  у школах, університетах, на підприємствах.
			</li>
		</ul>
	</p>

	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
		Підтримувати власне ментальне здоров’я можна самостійно. Для цього необхідно:
	</p>
	<p className={styles.pCommon}>
		<ul>
			<li className={styles.liCommon}>
				дбати про режим сну;
			</li>
			<li className={styles.liCommon}>
				уникати стресових ситуацій та навчитись адекватно на них реагувати;
			</li>
			<li className={styles.liCommon}>
				збалансувати  харчування і додати фізичної активності. Іноді достатньо активізувати організм та налагодити гормональний фон, щоб виправити ситуацію.
			</li>
		</ul>
	</p>

	<p className={styles.pCommon} style={{fontWeight: 'bold'}}>
		7 простих способів зберегти ментальне здоров’я :
	</p>
	<p className={styles.pCommon}>
		<ol>
			<li className={styles.liCommon}>
				Чіткий та сталий розпорядок дня.
			</li>
			<li className={styles.liCommon}>
				Фізична активність.
			</li>
			<li className={styles.liCommon}>
				Повноцінне харчування.
			</li>
			<li className={styles.liCommon}>
				Спільні заняття з рідними, друзями.
			</li>
			<li className={styles.liCommon}>
				Тактильні контакти.
			</li>
			<li className={styles.liCommon}>
				Можливість виговоритися.
			</li>
			<li className={styles.liCommon}>
				Спрощений побут.
			</li>
		</ol>
	</p>

	<p className={styles.pCommon}>
		Слід зауважити, що між психікою та всіма системами організму існує міцний зв’язок, тож ще до діагностування психічного розладу можуть проявлятись соматичні захворювання. Головні ознаки порушення психологічної рівноваги та перші “дзвіночки” ментальних розладів за даними ВООЗ:
		<ul>
			<li className={styles.liCommon}>
				фізичні (порушення сну, больові відчуття у будь-якій частині тіла);
			</li>
			<li className={styles.liCommon}>
				емоційні (постійне відчуття страху, тривоги, журби);
			</li>
			<li className={styles.liCommon}>
				когнітивні (труднощі з чітким мисленням, порушення пам’яті, патологічні переконання);
			</li>
			<li className={styles.liCommon}>
				поведінкові (агресія, зловживання алкоголем чи наркотиками, неспроможність виконувати повсякденні функції);
			</li>
			<li className={styles.liCommon}>
				перцептивні (наприклад, пацієнту здається, що він бачить або чує те, чого не помічають інші).
			</li>
		</ul>
	</p>

	<p className={styles.pCommon}>
		Насправді ознаки розладів дуже індивідуальні і відрізнити їх може лише лікар. Тому займатися самодіагностикою не варто.
	</p>

	<p className={styles.pCommon} style={{fontWeight: 'bold', fontStyle: 'italic'}}>
		Як перевірити своє ментальне здоров'я?
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold', fontStyle: 'italic'}}>
		Ментально здорова людина:
	</p>
	<p className={styles.pCommon}>
		<ol>
			<li className={styles.liCommon}>
				здатна будувати стосунки з іншими;
			</li>
			<li className={styles.liCommon}>
				відкрита до навчання та розвитку;
			</li>
			<li className={styles.liCommon}>
				розуміє свої емоційні стани і може керувати ними;
			</li>
			<li className={styles.liCommon}>
				позитивно ставиться до себе;
			</li>
			<li className={styles.liCommon}>
				приймає незалежні рішення;
			</li>
			<li className={styles.liCommon}>
				вміє пристосовуватись до нових умов життя;
			</li>
			<li className={styles.liCommon}>
				може активно долати поточні виклики;
			</li>
			<li className={styles.liCommon}>
				отримує задоволення від життя.
			</li>
		</ol>
	</p>

	<p className={styles.pCommon}>
		Джерело: <a href="https://naurok.com.ua/prezentaciya-mentalne-zdorov-ya-346547.html">https://naurok.com.ua/prezentaciya-mentalne-zdorov-ya-346547.html</a>
	</p>

	<div style={{marginLeft: '15px', marginRight: '50px'}} >
		<p className={styles.pCommon} style={{fontWeight: 'bold', fontSize: '16pt', textAlign: 'center'}}>
			Топ 5 книг про ментальне здоров’я
		</p>
		
		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Бути окей. Що важливо знати про психічне здоров’я.  Дарка Озерна
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image04} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Ця книжка допоможе вилізти з ями депресії, вигорання, посттравматичного стресового та тривожного розладу. Авторка пише про те, що це окей — не завжди бути окей. Але не нормально нічого із цим не робити. Адже від психічного здоров’я залежить наш фізичний стан, здатність про себе подбати, можливість бути успішними у своїй професії та задоволеним життям загалом. 
				</div>
				<div className={styles.divBookDescription}>
					Дарка позиціює свою книгу як певну систему орієнтирів, де ще й можна знайти базову інформацію про психічне здоров’я із посиланнями на медичні протоколи. Що ще класного в цій книзі: орієнтація на український контекст. Тут вона розповідає, як українці ставляться до ментальних проблем, які в нас є стереотипи щодо цього та до яких наслідків це може призвести. 
				</div>
				<div className={styles.divBookDescription}>
					З огляду на війну в країні дуже корисним та практичним, на нашу думку, є розділ «Посттравматичний стресовий розлад» та «Тривожний розлад». Тут чимало порад, які зараз актуальні та потрібні для більшості українців та українок. Авторка пояснює, як жити з ПТСР, чому, якщо нічого із цим не робити, то можна потрапити в яму депресії. А ще – про традиційні психотерапевтичні практики та чому вони не завжди працюють у випадку з ПТСР. 
				</div>
				<div className={styles.divBookDescription}>
					Дарка Озерна – медична журналістка, біологиня і популяризаторка здорового способу життя. Пише колонки для «ВВС Україна», «Український тиждень», «Повага» та «Ногибоги», веде програми про здоров’я і науку на Радіо «Культура».
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Стіни в моїй голові. Жити з тривожністю і депресією. Володимир Станчишин
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image05} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Книга невеличка, тому читається легко та швидко. Автор розповідає нам про тривожність, депресії, соціальні фобії, різні психічні розлади, які впливають на звичний спосіб життя людини в соціумі. Він дуже доступно та м’яко пояснює, як із цим усім впоратися, зрозуміти потреби власної психіки (адже так, у неї теж є потреби) та запобігати складним наслідкам задавнених психологічних проблем.
				</div>
				<div className={styles.divBookDescription}>
					Як каже сам автор, ця книга не вилікує вас від неприємних станів, але допоможе зрозуміти — жити з ними можливо
				</div>
				<div className={styles.divBookDescription}>
					В розділах «Просто зроби це. Соціальна фобія» та «Стрибок у минуле. Посттравматичний стресовий розлад».  Автор пояснює, чому насправді  тривожитися за багато речей немає сенсу. Адже з тривогою просто треба жити, а не намагатися від неї втекти. Також тут зібрані деякі поради про те, як вгамувати тривожні думки й пропрацювати травми минулого, які не дають змогу щасливо та гармонійно жити зараз. 
				</div>
				<div className={styles.divBookDescription}>
					Володимир Станчишин навчався психології у Львівському національному університеті імені Івана Франка та Українському інституті когнітивно-поведінкової терапії.  Пізніше Володимир очолював Центр психічного здоров’я у Львові в Шпиталі імені Митрополита Шептицького, який своїм завданням ставив допомогу хлопцям, що повертаються із зони бойових дій.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Зберігайте спокій. 23 техніки життя без стрессу. Нік Трентон
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image06} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Якщо маєш нав’язливі думки й тебе щодня переслідують тривожні стани, але ще не готовий звернутися до спеціаліста, то можна почати з цієї книги. Автор докладно розповідає про психологічні розлади та їхній фізичний прояв: пришвидшене серцебиття, пітніння рук, тремтіння в колінах. Нік Трентон загалом у цій книзі ніби досліджує феномен тривожності: як вона впливає на життя людини та як її попередити.  
				</div>
				<div className={styles.divBookDescription}>
					Крім докладного пояснення причин тривоги, серед яких є і генетика, і виховання і життєвий досвід, автор також пропонує вправи на пропрацювання цих неприємних ментальних моментів
				</div>
				<div className={styles.divBookDescription}>
					Книга захоплює з першого розділу «Копирсання в собі зовсім не стосується копирсання в собі». Нік просто пояснює теорія, яка стає своєрідним вступом до психотерапії. Також у цьому розділі він розповідає про психічні розлади, щоб читачі, далекі від розуміння теорії, змогли зробити свої перші кроки в її пізнанні. А якщо бажаєш одразу звернутися до практики, то це до розділу «Про формулу боротьби зі стресом і не лише про це». Він містить вправи й завдання, з яких можна скомпонувати діючу схему для самостійної роботи зі своїми страхами та тривогами. Як стверджує автор, «все, що вам потрібно запам’ятати, — це чотири техніки: уникати, змінювати, приймати та адаптуватись». А як ці техніки використовувати — є безліч порад та вправ у книзі. Нік розбиває їх на маленькі кроки, з яких розпочати самостійну роботу набагато легше і простіше. 
				</div>
				<div className={styles.divBookDescription}>
					Свої книги він пише для того, аби полегшити життя не лишень своїх пацієнтів, але й людей з усіх куточків землі.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Дар. 14 уроків, які врятують ваше життя. Едіт Єва Еґер
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image07} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Як зазначили видавці в анотації, Едіт Еґер написала книгу на прохання читачів її бестселера «Вибір». І це видання вона позиціює як практичний посібник про турботу над своїм ментальним здоров’ям. Основний меседж авторки: ти дізнаєшся, як жити повноцінно й не застрягати у в’язниці власного розуму. Книга ця зовсім і не книга наче. Це як 14 паперових сеансів від видатної психотерапевтки, де вона пояснює: 
				</div>
				<ul>
					<li className={styles.liCommon}>
						як сприймати свої емоції, 
					</li>
					<li className={styles.liCommon}>
						як позбутися комплексу жертви, 
					</li>
					<li className={styles.liCommon}>
						як зцілитися від дитячих травм, 
					</li>
					<li className={styles.liCommon}>
						як пережити втрати та горе, 
					</li>
					<li className={styles.liCommon}>
						як виходити з конфліктних ситуацій чи не потрапляти в них,
					</li>
					<li className={styles.liCommon}>
						як прийняти й полюбити себе,
					</li>
					<li className={styles.liCommon}>
						як позбутися відчуття вини, 
					</li>
					<li className={styles.liCommon}>
						як навчитися контролювати свої страхи та впоратися з тривожністю.
					</li>
				</ul>
				<div className={styles.divBookDescription}>
					У кожному розділі докторка Еґер ділиться історіями з власного життя та історіями своїх пацієнтів, а в кінці пропонує ключі до звільнення від проблеми. Це установки, вправи або питання, які допоможуть знайти шлях із наших ментальних в’язниць до свободи й радіти життю.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				Подорож вглиб себе. Янґ Пуебло
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image08} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Автор вміло поєднує психологію, філософію, поезію, мотиваційні тексти та поради щодо саморозвитку. Завдяки цьому його робота не просто допомагає пофіксити ментальні проблеми, а загалом дає змогу подивитися на своє життя наче зі сторони. 
				</div>
				<div className={styles.divBookDescription}>
					У цьому невеличкому виданні описано чимало ментальних проблем, а більшість з них — віршами. Так-так, поезією. Бо це збірка віршів, цитат і прози, яка досліджує рух від самолюбства до безумовної любові, силу відпускання та мудрість, яка приходить, коли ми дійсно намагаємося пізнати себе. Це нагадування, що зцілення, трансформація та свобода можливі. 
				</div>
				<div className={styles.divBookDescription}>
					Читається на одному подиху. Подиху, який нарешті дає змогу дихати на повні груди, без тягаря страхів та тривог. Як зазначає автор, збірка адресована кожному, хто шукає звільнення від болю, незалежно від методу, яким людина послуговується, аби загоїти власні рани. Паралельно цю книгу можна позиціювати як гайд з особистісної трансформації. Про те, як вирости особистісно, як позбутися внутрішніх зажимів та непропрацьованих травм. Як взагалі перевернути своє життя з ніг на голову, але так, щоби після цього воно стало більш комфортним. Достатньо впустити в себе кілька рядків книги, аби зміни почали розкриватися всередині новими усвідомленнями. І загалом, поезії цієї книги підтримують віру в перемогу світла й дають можливість перепочинку посеред бурхливого, руйнівного потоку трагедій і болю. 
				</div>
				<div className={styles.divBookDescription}>
					Дієго Перес — відомий у соцмережах під псевдонімом Янґ Пуебло. Він — медитатор, письменник і оратор. Перша книга Янґа «Подорож вглиб себе» швидко стала бестселером на Amazon.
				</div>
			</div>
		</div>
	
	</div>
	<p className={styles.pCommon}>
		Джерело: <a href="https://journal.maudau.com.ua/svidomi-trendu/top-5-knyg-pro-mentalne-zdorovya/">https://journal.maudau.com.ua/svidomi-trendu/top-5-knyg-pro-mentalne-zdorovya/</a>
	</p>
	
	<div style={{marginLeft: '15px', marginRight: '50px'}} >
		<p className={styles.pCommon} style={{fontWeight: 'bold', fontSize: '16pt', textAlign: 'center'}}>
			Які книги про ментальне здоров`я радимо прочитати
		</p>
		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				"Людина в пошуках справжнього сенсу. Психолог у концтаборі", Віктор Франкл                                                                       
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image09} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Книга австрійського психолога, філософа і колишнього в'язня нацистського концтабору Віктора Франкла у цій добірці посідає окреме місце, і саме тому з неї варто почати. "Людина в пошуках справжнього сенсу" — не лише головна книга Франкла, а й один з ключових текстів психології 20 століття. Психолог написав її в 1946 році, після того, як провів два роки в концтаборах в Аушвіці і Дахау. У ній він описує свій страшний досвід перебування там, а також формулює свій найважливіший психотерапевтичний метод — це пошук сенсу в усіх проявах життя, навіть найстрашніших. Книга написана ясно і неймовірно захоплююче, і тому стала однією з найбільш цитованих у світовій психології, розійшовшись по світу накладом в понад 15 мільйонів. А її головний месендж — знайти собі мету існування навіть у найскладніших умовах і мислити позитивно — елементарно і вкрай складно водночас.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				"Несподівана радість самотності", Кетрін Грей
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image10} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Кетрін Грей — популярна журналістка і колумністка The Guardian, Stylist, The Telegraph. Українською нарешті публікується її бестселер про те, що на самоті теж можна жити щасливо. Письменниця розвіює усталені в нашій культурі уявлення, що всі обов'язково повинні жити в парі. "Книга присвячена одинакам, яких не розуміють, жаліють і вважають за бідолах, які нікого не можуть собі знайти. Кетрін Грей допомагає прийняти свою самотність, знайти в ній позитивні моменти, абстрагуватися від тиску суспільної думки і жити щасливо на самоті. Авторка зовсім не заперечує щастя від взаємин або шлюбу, а відстоює права самотніх, яких вважають білими воронами, і їхній вибір, адже бути щасливим і самотнім водночас можна".
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				"Людина, що хотіла бути щасливою", Лоран Гунель
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image11} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Новинка від французького письменника і фахівця з особистісного розвитку Лорана Гунеля стала всесвітнім бестселером і перекладена більш ніж двадцятьма мовами світу. У цій книзі головний герой намагається знайти щастя. "Жуліано почувається нещасним, хоча, здається, має все. Випадково він опиняється в загубленому селищі, розташованому посеред балійських лісів. Там, далеко від галасливого і швидкоплинного світу, живе мудрець, цілитель душ Самтьян. Він має допомогти Жуліано, адже знає, де ховається щастя. Ця істина могла перевернути життя мільйонів людей і все навколо. Але знання, яке розкриває шлях до щастя, не можна просто так отримати, купити або навіть забрати силою. Його можна тільки зрозуміти".
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				«Вирішити можна все!», Марі Форлео
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image12} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Марі Форлео починала кар'єру як фінансистка на Нью-Йоркській фондовій біржі, але невдовзі перекваліфікувалася на тренерку особистісного зростання і блогерку. Серед її шанувальників — Опра Уїнфрі і Барак Обама. Українською мовою виходить бестселер №1 за версією "The New York Times" — її книга "Вирішити можна все". Назва відображає філософію успіху Марі. В анотації йдеться про те, що Форлео "обґрунтовує свою філософію, яка вчить не пасувати перед труднощами, а знаходити шляхи для їх подолання. Книга допомагає читачам навчитися рухатися до мети, усувати всі відмовки і виправдання, які стоять на шляху до успіху; перемагати свої страхи, зрозуміти нарешті, чого вони хочуть, і почати діяти. Марі Форлео підкріплює свої установки десятками історій із власного життя і життя людей, які скористалися її порадами.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				«Внутрішній голос. Як навчитись слухати себе», Ітан Кросс
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image13} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Книга від американського психолога і письменника Ітана Кросса, яка у 2021 році зайняла топові позиції в багатьох відомих виданнях.Внутрішній голос — це невід’ємна частина свідомості. Однак людина настільки звикла до постійних внутрішніх діалогів, що часто не помічає негативного впливу цього голосу на ментальне здоров’я.Самокопання, самоприниження, підвищена тривожність – це початок впливу, який може довести до серйозних психічних розладів.Вважаєте, що керувати цим неможливо? Автор покаже вам інший бік внутрішнього голосу та розкаже, як використовувати його на свою користь.
				</div>
			</div>
		</div>
		
	</div>
	<p className={styles.pCommon}>
		Джерело: <a href="https://vogue.ua/article/culture/knigi/5-horoshih-knig-o-psihologi">https://vogue.ua/article/culture/knigi/5-horoshih-knig-o-psihologi</a>
	</p>

	<div style={{marginLeft: '15px', marginRight: '50px'}} >
		<p className={styles.pCommon} style={{fontWeight: 'bold', fontSize: '16pt', textAlign: 'center'}}>
			Пять книжок про ментальне здоров’я під час війни
		</p>
		
		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				<a href="https://vivat-book.com.ua/scho-z-toboyu-stalosya-pro-travmu-psiholog-chnu-st-yk-st-zc-lennya-yak-zrozum-ti-svo-minule.html">
					«Що з тобою сталося. Про травму, психологічну стійкість і зцілення. Як зрозуміти своє минуле» . Автори: Брюс Д. Перрі, Опра Вінфрі
				</a>
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image14} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Мозок людини складається з чотирьох відділів:
					<ul>
						<li className={styles.liCommon}>
							кори, яка відповідає за мовлення, мислення та відчуття часу;
						</li>
						<li className={styles.liCommon}>
							лімбічної системи, яка керує пам’яттю, прив’язаністю, емоціями;
						</li>
						<li className={styles.liCommon}>
							проміжного мозку, що відповідає за рух, сон та апетит;
						</li>
						<li className={styles.liCommon}>
							мозкового стовбура, який контролює дихання, частоту серцебиття, температуру тіла.
						</li>
					</ul>
				</div>
				<div className={styles.divBookDescription}>
					Коли кора «підключена» й активна, ми здатні відділити минуле від теперішнього — розуміємо, що вже сталося, а що відбувається зараз. А от мозковий стовбур, або рептильний мозок, не має структур, які розрізняють час. Уся інформація, яка надходить від органів чуття, спочатку опиняється в найнижчому відділі, мозковий стовбур її опрацьовує. Як? Він порівнює отриманий сигнал із тими, які запам’ятав. Гуркіт мотоцикла ототожнює з вибухом — із воєнним спогадом. І щоразу, коли людина чує якийсь гуркіт, він запускає реакцію стресу. 
				</div>
				<div className={styles.divBookDescription}>
					Щоб сигнал зі стовбура потрапив до кори, потрібен час — кілька хвилин, годин чи навіть днів. Тому люди, які мають посттравматичний стресовий розлад, не можуть швидко дати раду своїм почуттям та емоціям. Їхній мозок працює за іншою схемою.   
				</div>
				<div className={styles.divBookDescription}>
					Після прочитання цієї книжки ви зрозумієте, як перепрограмувати мозок, що робити з травматичним досвідом і в чому сила запитання «Що з тобою сталося?»
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				<a href="https://laboratoria.pro/products/emotsijnyj-spadok-terapiya-patsienty-ta-spadschyna-travmy_2">
					«Емоційний спадок. Як подолати травматичний досвід». Авторка: Ґаліт Атлас
				</a>
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image15} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					У 1970-х роках нейронаука підтвердила, що діти отримують від батьків не лише набір генів, а й травми — «неусвідомлені сліди болю». Травматичний досвід предків впливає на життя двох наступних поколінь — дітей та онуків.   
				</div>
				<div className={styles.divBookDescription}>
					Психоаналітики проаналізували генетичні зміни в нащадків людей, які пережили травму, і дійшли висновку, що зміни в організмі відбуваються на хімічному рівні. У нащадків людей, які пережили Голокост, виявили низький рівень кортизолу. Це гормон, який виробляється під час стресу. В організмі підвищується рівень глюкози, і людина мобілізує енергію, необхідну для виживання.  
				</div>
				<div className={styles.divBookDescription}>
					З погляду еволюції це працює так: організм травмованої війною людини на біологічному рівні готує потомство до життя у схожому середовищі. Тому дитина, яка ніколи не знала війни, може жити з неусвідомленими «воєнними» почуттями, які отримала у спадок. 
				</div>
				<div className={styles.divBookDescription}>
					Ця книжка допоможе розібратися з емоційним спадком і навчить спокійно обговорювати заборонені почуття та спогади. 
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				<a href="https://vivat-book.com.ua/tilo-vede-lik-yak-lishiti-psikhotravmi-v-minulomu.html">
					«Тіло веде лік. Як лишити психотравми в минулому». Автор: Бессел ван дер Колк
				</a>
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image16} style={{float: 'left', marginRight: '30px'}} width='100'/>\
				<div className={styles.divBookDescription}>
					Травмованих війною військовослужбовців охоплює відчуття, що їхнє життя марне. Людина емоційно німіє — стає відстороненою, збайдужілою, закритою. Бо її мозок працює по-іншому. Їй буває важко змиритися з утратою побратима, якого «можна було врятувати». Прийняти сором за свою поведінку в момент травматичної події. Людині соромно або за те, що вона зробила, або за те, чого не зробила, або за все разом.
				</div>
				<div className={styles.divBookDescription}>
					Рідні та друзі, які бачать такі душевні страждання, звично пропонують перемкнутися: знайти роботу, поїхати у відпустку абощо. Але це не працює. Людина застряє у травмі між воєнним минулим та буденним теперішнім. Почувається живою лише тоді, коли повертається в минуле. 
				</div>
				<div className={styles.divBookDescription}>
					Цю книжку написав Бессел ван дер Колк, психіатр, який майже 50 років досліджує проблему посттравматичного стресового розладу. У ній він детально пояснює, як залишити минуле в минулому, чому не можна ігнорувати сигнали тіла під час ПТСР, і пропонує дієві методи зцілення, які точно не зашкодять. Після її прочитання ви навчитеся краще розуміти себе та інших.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				<a href="https://www.vikhola.com/product-page/rozdumy-psyhoterapevta-pro-vijnu">
					«Емоційні гойдалки війни» . Автор: Володимир Станчишин
				</a>
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image17} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Упродовж перших місяців повномасштабного вторгнення росії в Україну рівень тривожності українців злетів до небес. Тривога — це нормальна реакція організму на небезпеку та невідомість. Завдяки тривозі ми погано спали й не хотіли їсти, але й діяли. 
				</div>
				<div className={styles.divBookDescription}>
					Хтось ішов у наступ, голими руками зупиняючи ворога. Хтось тікав із міст, куди їхали танки з літерами Z та V, рятуючи рідних і домашніх улюбленців. А хтось заклякав, не розуміючи, що йому робити: залишатися, виїжджати за кордон чи вступати до лав ЗСУ. 
				</div>
				<div className={styles.divBookDescription}>
					Напад, утеча та завмирання — три реакції на стресову ситуацію. Але ми влаштовані так, що не можемо постійно перебувати в одному стані. Тому за кілька місяців могли від нападу перейти до втечі — і навпаки. Емоційні гойдалки — це нормально.   
				</div>
				<div className={styles.divBookDescription}>
					У книжці Володимира Станчишина йдеться про природу тривожності, страху, втоми, смутку, ненависті, провини. Є поради, як вибиратися з емоційних ям, правильно підтримувати себе та інших.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div className={styles.divBookCard}>
				<a href="https://laboratoria.pro/products/u-chuzhomu-domi-travma-vymushenogo-peremischennya-shlyah-do-rozuminnya-i-oduzhannya">
					«У чужому домі. Травма вимушеного переміщення: шлях до розуміння і одужання». Автор: Ренос Попадопулос
				</a>
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image18} style={{float: 'left', marginRight: '30px'}} width='100'/>
				<div className={styles.divBookDescription}>
					Мільйони українців виїхали в інші міста чи країни, стали вимушеними переселенцями. Крім побутових проблем, вони відчувають сильний моральний тиск. А на додаток перестають сприймати свій дім як безпечний простір. Такі травматичні переживання впливають на якість життя. 
				</div>
				<div className={styles.divBookDescription}>
					Саме в час вимушеного переміщення люди замислюються, що таке дім, ідентичність, ностальгія. Згадують про домівки — не їхню комфортабельність і облаштованість, а затишок, тепло та спокій. У багатьох загострюється потреба належати до спільноти.
				</div>
				<div class={styles.divBookDescription}>
					З науковою точністю та чутливістю Ренос Пападопулос досліджує досвід людей, які змушені залишити дім у пошуках безпеки. Відтворює історичний та культурний контексти вимушеного переміщення. Це посібник для тих, хто працює з переселенцями, і тих, хто переживає такий досвід і хоче розібратися в собі.
				</div>
			</div>
		</div>
	</div>
	<p className={styles.pCommon}>
		Джерело: <a href="https://hromadske.ua/posts/pyat-knizhok-pro-mentalne-zdorovya-pid-chas-vij">https://hromadske.ua/posts/pyat-knizhok-pro-mentalne-zdorovya-pid-chas-vij</a>
	</p>

	<p className={styles.pCommon} style={{fontWeight: 'bold', fontSize: '16pt', textAlign: 'center'}}>
		Для більш повного ознайомлення з темою пропонуємо список літератури ЦРБ «Печерська».
	</p>
	<ul>
	<li className={styles.liCommon}>
	Варій, М. Й. Основи психології і педагогіки : навч.посібник для вузів / М. Й. Варій, В. Л. Ортинський. – Київ : Центр учбової літ., 2007. – 375 с.
	</li>
	<li className={styles.liCommon}>
	Військова психологічна підготовка та реабілітація / [ ред. О. В. Філюк ] .- Київ : Видавець Філюк, 2017.- 496 с.
	</li>
	<li className={styles.liCommon}>
	Воронов, Марк. Психологические основы успеха : Практическое руководство по управлению собой и другими с позиций « ноосферного мышления». – Киев : Ника – Центр, 2003. – 368 с. : ил.
	</li>
	<li className={styles.liCommon}>
	Гольдштейн, Арнольд. Тренінг умінь спілкування : Як допомогти проблемним підліткам / пер. з англ. В. Хомика. – Київ : Либідь, 2003. – 520 с.
	</li>
	<li className={styles.liCommon}>
	Джонсон, Девід В. Соціальна психологія : тренінг міжособистісного спілкування / пер. з англ. В. Хомика.- Київ : КМ Академія, 2003.- 288 с. : іл.
	</li>
	<li className={styles.liCommon}>
	Каллан, ДЖ. К. Бонжур, щастя ! Як отримувати радість від життя / Джейм КЄТ Каллан ; [ пер. з англ. Т. Заволоко ] . –  Київ : Видавнича група КМ – Букс,  2017 . – 255 с.
	</li>
	<li className={styles.liCommon}>
	Карьера, здоровье, любовные отношения : советы, тесты, рекомендации  / Л. В. Аксенова,  В. Т. Гридина ; худож. Е. В. Зарби – Гальчук. – Донецк : БАО, 2005. – 288 с. : ил.
	</li>
	<li className={styles.liCommon}>
	Крістенсен, К. Як ви збудуєте своє життя? / Клейтон Крістенсен, Джеймс Олворт, Карен Діллон ; пер. з англ.  Ольги Любарської. – Львів : Видавництво Старого Лева, 2015. – 191 с.
	</li>
	<li className={styles.liCommon}>
	Левітт, С. Думай як Фрік. Нестандартні підходи до розв’язання проблем / Стівен Левітт, Стівен Дабнер ; пер. з англ. Т. Заволоко.- 2-ге вид .- Київ : Наш формат, 2017.- 213 с.
	</li>
	<li className={styles.liCommon}>
	Розов, В. І. Адаптивні антистресові психотехнології : Навч. посібник для вузів.- Київ : Кондор, 2005.- 278 с.
	</li>
	<li className={styles.liCommon}>
	Сейла, Г. Дж. Радість самітності в оточенні подружніх пар : як пізнати  вдоволення, мир, і повноту тепер / Гарольд Дж. Сейла ; [ пер з англ.. В.Четирко] . – Черкаси : Смірна, 2012.-187 с.
	</li>
	<li className={styles.liCommon}>
	Хаббард, Л. Р. Самоаналіз : проста книга для допомоги самому собі, що містить тести й методики, основані на відкриттях  діанетики : [ пер. з англ.] / Л. Рон Хаббард. – [Б.м.] : New  Era, 2009. – 379 с. : іл.
	</li>
	<li className={styles.liCommon}>
	Хаббард, Л. Р. Шлях до щастя : [путівник до кращого життя, оснований на здоровому глузді: пер. з англ.    ] / Л. Рон Хаббард.- Данія : Міжнародний фонд « Шлях до щастя», 2009.- 232 с. : іл.
	</li>
	<li className={styles.liCommon}>
	Бірюкова, І. Сімейна лікарка розповіла, як під час війни захиститися від стресу / І.Бірюкова // Вечірній Київ.- 2022.- 8 листопада.- С.1.
	</li>
	<li className={styles.liCommon}>
	Від нав’язливих думок треба вміти позбавлятися! // 7я.- 2023.- № 49 ( 5 -  11 грудня ).- С.19.
	</li>
	<li className={styles.liCommon}>
	Вольнов, О. Непервісний страх : які фобії та залежності породили технології ХХІ століття / Олег Вольнов // Події тижня : підсумки та факти.- 2023.- № 44 ( жовтень ) - .С.10.
	</li>
	<li className={styles.liCommon}>
	Голодрига, Ю. Коли на душі неспокійно, то й тіло страждає : як війна позначається на здоров’ї цивільних та якими методами можна побороти страх і тривогу / Юлія Голодрига // Експрес.- 2024. - № 11 ( 14 – 21 березня ). – С. 8.
	</li>
	<li className={styles.liCommon}>
	Деякі факти про психологію людини // Українська родина. – 2024. - № 2. – С. 12 – 14.
	</li>
	<li className={styles.liCommon}>
	Золотаренко, І. У насильстві завжди винен кривдник : в Україні працює онлайн-платформа, на якій зібрана повна інформація про допомогу постраждалим від насильства / Іванна Золотаренко // Україна молода.- 2023 .-  № 18 ( 3 травня ) .- С. 9.
	</li>
	<li className={styles.liCommon}>
	Крикуненко, І. Рівень тривоги зростає : як війна позначилася на психіці українців / Ірина Крикуненко // Події тижня : підсумки та факти .- 2023.- № 7 ( 14 - 20 листопада ).- С. 5.
	</li>
	<li className={styles.liCommon}>
	Маджумдар, О. Психотерапевт  Олег Чабан : українцям потрібно планувати майбутнє / Ольга Маджумдар  // Події тижня : підсумки та факти. – 2024.- № 15 ( 9 – 15 квітня ). – С. 7.
	</li>
	<li className={styles.liCommon}>
	Професійне вигорання : як запобігти кризі ? // Жінка. – 2024. - № 3. – С. 28 – 29.
	</li>
	<li className={styles.liCommon}>
	Психогенна  смерть : як вбиває втрата волі до життя? // 7я . – 2024. - № 13 ( 26 березня – 1 квітня ) . – С. 19.
	</li>
	<li className={styles.liCommon}>
	Рибальченко, В. Обставини вимагають нових підходів до психічного здоров»я / Володимир Рибальченко // Голос України.- 2023.- 14 листопада ( № 228 ).- С. 10.
	</li>
	<li className={styles.liCommon}>
	Семенов,  А. Хронічні страх і тривога : як повернути стан спокою в сучасних умовах / Антон Семенов // 7я. – 2024. - № 15 ( 9.04 – 15 квітня ).- С. 19.
	</li>
	<li className={styles.liCommon}>
	Тисячна, Т. Чому психологічне здоров’я – це важливо / Тамара Тисячна // Пенсійний кур»єр.- 2023.- № 39-52 ( 11 жовтня ) .- С. 42.
	</li>
	<li className={styles.liCommon}>
	Трудько, В. Невидимий, але дуже небезпечний наслідок війни : в Україні різко збільшилася кількість хворих на посттравматичний стресовий розлад / Вікторія Трудько // Експрес.- 2024. - № 16 (18 – 25 квітня ). – С. 12.
	</li>
	<li className={styles.liCommon}>
	Франк, А. Булінг, стрес та громадянська позиція : як непросто живеться на нових місцях юним переселенцям зі сходу та півдня України / Анна Франк // Україна молода .-2023. - № 19 ( 10 травня ).- С.7-8.
	</li>
	<li className={styles.liCommon}>
	Хожаїнова, В. Хвиля дитячих самогубств : як виявити проблеми в підлітка й запобігти трагедії / Вікторія Хожаїнова  // Події тижня : підсумки та факти.- 2023. - № 44 ( жовтень ).- С. 3.
	</li>
	<li className={styles.liCommon}>
	Швець, Г. Простір психологічної підтримки на Закарпатті допомагає переселенцям, військовим, волонтерам / Ганна Швець //Пенсійний кур’єр.- 2023 -  № 29 ( 19 липня ) .- С. 6-7.
	</li>
	<li className={styles.liCommon}>
	Шум, Л., Алексєєнко, К. Здоров’я нації і наслідки війни – як бібліотеки можуть зробити свої громади здоровішими / Люсьєна Шум, Катерина Алексєєнко // Бібліотечна планета.- 2023.- № 4.- С. 19 – 21.
	</li>
	<li className={styles.liCommon}>
	Як жити в стані постійної тривоги і чи можна звикнути до війни // 7я.- 2023. - № 10. - С.19.
	</li>
	</ul>
	
	<p className={styles.pCommon} style={{fontSize: '14pt', fontWeight: 'bold', fontStyle: 'italic'}}>
	Інформація про ментальне здоров'я може стати в пригоді кожному, хто хоче піклуватися про свій психічний добробут. Вона допоможе знизити рівень стресу, покращити якість сну, зробити правильний вибір та знайти гармонію в житті. 
	</p>
	<p className={styles.pCommon}>
	Читання книг допомагає пережити важкі події, відрефлексувати свої переживання та зберегти рівновагу. Хочеться вірити, що можливість на якийсь час заглибитись у хорошу книгу або отримати рекомендації знаючих людей — це вже чимало. 
	</p>
	<p className={styles.pCommon} style={{fontSize: '18pt', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center'}}>
	Отож, читаємо !
	</p>

	<div style={{textAlign: 'center'}}>
		<img src={image19} width='400'/>
	</div>
	
</div>
		
);
}

export default B207;
