import React, { Component } from 'react';

import {
	CBS,
	LIBCRB, LIBVYSHNI, LIBBILOKUR, LIBKNYGOLYUB, LIB149, LIB150, LIBVSESVIT, LIBSVITOGLYAD, LIBESTET,
	LIBVESELKA, LIBTRUBLAINI, LIB8, LIBJERELO
} from '../../../util/consts';

class CmbLibrary extends Component {

	/*
		librarySelection
		style
	*/
	constructor(props) {
		super(props);
		
		this.libraryList = [
			CBS,
			LIBCRB, LIBVYSHNI, LIBBILOKUR, LIBKNYGOLYUB, LIB149, LIB150, LIBVSESVIT, LIBSVITOGLYAD, LIBESTET,
			LIBVESELKA, LIBTRUBLAINI, LIB8, LIBJERELO
		];
		this.state = {
			selectedLib: props.librarySelection.selectedLibraryId
		}
	}

	onLibSelected = (e) => {
		this.setState({selectedLib: e.target.value});
		this.props.librarySelection.selectedLibraryId = e.target.value;
	}
	
	render() {
		var style = ( this.props.style ) ? this.props.style : {};
		var optionsHtml = this.libraryList.map( (lib, index) => {
			return (<option key={index} value={lib.cipher}>{lib.title}</option>);
		} );
		return (
			<select style={style} defaultValue={this.state.selectedLib} onChange={this.onLibSelected} >
				{optionsHtml}
			</select>
		);
	}
}

export default CmbLibrary;