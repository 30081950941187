import React, { Component } from 'react';

import DivInnerHtml from '../../components/DivInnerHtml';

import { getBookImageUrl } from "../../util/tools";

class BookBlock extends Component {

	constructor(props) {
		super(props);
		this.state = {
			expanded: props.block.expanded
		}
	}
	
	switchExpand = () => {
		this.setState({expanded: ! this.state.expanded});
	}

	render() {
		var block = this.props.block;
		if( this.state.expanded ) {
			var expandSign = '-';
			var bookListHtml = block.books.map( (book, index) => {
				return <Book key={index} book={book} />
			} );
		} else {
			var expandSign = '+';
			var bookListHtml = null
		}
		var expandSign = this.state.expanded ? '-' : '+';
		return(
			<div>
				<div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
					<div style={{fontWeight: 'bold'}}>Нові надходження від {block.bookDate}:</div>
					<div style={localStyles.divSign} onClick={this.switchExpand}>{expandSign}</div>
				</div>
				<div style={{marginBottom: '10px'}}>
					{bookListHtml}
				</div>
			</div>
		);
	}
}

function Book(props) {
	var book = props.book;
	var urlImage = getBookImageUrl( book.bookId );
	return(
		<div style={{display: 'flex', marginBottom: '30px'}}>
			<div>
				<img src={urlImage} width="150" />
			</div>
			<div style={{marginLeft: '20px'}}>
				<div style={{fontWeight: 'bold', marginBottom: '10px'}}>
					<DivInnerHtml text={book.header} />
				</div>
				<div style={{marginBottom: '10px'}}>
					<DivInnerHtml text={book.card} />
				</div>
				<div style={{textAlign: 'justify'}}>
					<DivInnerHtml text={book.annotation} />
				</div>
			</div>
		</div>
	);
}

var localStyles = {
	divSign: {
		width: '30px',
		marginLeft: '10px',
		paddingTop: '2px',
		paddingBottom: '4px',
		textAlign: 'center',
		border: '1px solid black',
		borderRadius: '7px',
		fontSize: '12pt',
		cursor: 'pointer'
	},
};

export default BookBlock;
