import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import LeftSide from "./LeftSide";

import {
	MAIN_PATH,
	LIBESTET_INFO_PATH, 	
} from '../../../util/routes';

import mainImage from '../../../assets/img/library/libestet/hello.jpg';

class LibEstetInfoPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліотека естетичного виховання', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIBESTET_INFO_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIBESTET_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={{width: 'calc(100% - 80px)', paddingLeft: '40px', fontFamily: 'Arial, sans-serif', fontSize: '12pt'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Бібліотека естетичного виховання
			</div>
			<div>
				<img style={localStyles.imageCentered} src={mainImage} />
			</div>
			<div style={{marginTop: '30px', marginBottom: '30px'}}>
				Площа приміщення бібліотеки 149 кв.м., <br/>
				книжковий фонд - 17,7 тис. примірників. <br/>
				Щорічно обслуговує 2,0 тис. користувачів, <br/>
				кількість відвідувань за рік - 11,2 тис., <br/>
				книговидач - 42,6 тис. примірників.
			</div>
		</div>
	);
}

const localStyles = {
	imageCentered:	{
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
}

export default LibEstetInfoPage;
