import React, { Component } from 'react';
import styles from './components.module.css';

class LeftSideMenu extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var items = this.props.menu.map((item, index) => {
			if( item.isGroupHeader ) {
				return (
					<div key={index} style={localStyles.divLeftSideHeader}>
						{item.title}
					</div>
				);
			} else if( item.isActive ) {
				return (
					<div key={index} className={styles.divPageMenu_item}>
						<div className={styles.divPageMenu_itemSelected}>
							{item.title}
						</div>
					</div>
				);
			} else {
				return (
					<div key={index} className={styles.divPageMenu_item}>
						<a href={item.url}>
							{item.title}
						</a>
					</div>
				);
			}
		});
		return (
			<div style={{marginBottom: '20px'}}>
				{items}
			</div>
		)
	}
}

const localStyles = {
	divLeftSideHeader: {
		marginLeft: '10px',
		marginTop: '25px',
		paddingBottom: '10px',
		borderBottom: '3px solid #588b0e',
		fontFamily: 'impact',
		fontWeight: 'normal',
		fontSize: '14pt',
		color: '#430045',
	}
};

export default LeftSideMenu;
