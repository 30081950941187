import React, { Component } from 'react';

import bgImage from '../../assets/img/mainpage/service_info_bg.png';

class AdvertisementLine extends Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<div style={{backgroundColor: '#aeb5a5'}}>
				<div style={{width: '1100px', backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center bottom 5px', margin: 'auto'}}>
					<div style={localStyle.divHeader}>
						В БІБЛІОТЕКАХ ЦБС ПЕЧЕРСЬКОГО РАЙОНУ ВАС ЧЕКАЮТЬ:
					</div>
					<div style={{display: 'flex'}}>
						<div style={localStyle.divServiceList}>
							<div style={localStyle.divServiceListTitle}>ІНФОРМАЦІЙНІ РЕСУРСИ:</div>
							<div style={localStyle.divServiceListItem}>Підручники та довідники</div>
							<div style={localStyle.divServiceListItem}>Художня література</div>
							<div style={localStyle.divServiceListItem}>Газети та журнали</div>
							<div style={localStyle.divServiceListItem}>Література іноземними мовами</div>
							<div style={localStyle.divServiceListItem}>Рідкісні та цінні видання</div>
							<div style={localStyle.divServiceListItem}>Безкоштовний Wi-Fi</div>
						</div>
						<div style={localStyle.divServiceList}>
							<div style={localStyle.divServiceListTitle}>ОСНОВНІ ПОСЛУГИ:</div>
							<div style={localStyle.divServiceListItem}>Видача книг додому</div>
							<div style={localStyle.divServiceListItem}>Зручні читальні зали</div>
							<div style={localStyle.divServiceListItem}>Різноманітні масові заходи</div>
							<div style={localStyle.divServiceListItem}>Клуби та гуртки за інтересами</div>
							<div style={localStyle.divServiceListItem}>Надання приміщень для заходів</div>
						</div>
						<div style={localStyle.divServiceList}>
							<div style={localStyle.divServiceListTitle}>ДОДАТКОВІ ПЛАТНІ ПОСЛУГИ:</div>
							<div style={localStyle.divServiceListItem}>Користування комп’ютерами ІДЦ</div>
							<div style={localStyle.divServiceListItem}>Ксерокопіювання та друк</div>
							<div style={localStyle.divServiceListItem}>Сканування</div>
							<div style={localStyle.divServiceListItem}>Брошурування</div>
						</div>
						<div style={localStyle.divServiceList}>
							<div style={localStyle.divServiceListTitle}>ЦІКАВЕ ДОЗВІЛЛЯ:</div>
							<div style={localStyle.divServiceListItem}>Виставки, перегляди, полиці</div>
							<div style={localStyle.divServiceListItem}>Майстер-класи, уроки, акції</div>
							<div style={localStyle.divServiceListItem}>Ігрові зони</div>
							<div style={localStyle.divServiceListItem}>Галерея «Образ»</div>
							<div style={localStyle.divServiceListItem}>Зустрічі з цікавими людьми</div>
							<div style={localStyle.divServiceListItem}>Презентації та літературні години</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	
}

const localStyle = {
	divHeader: {
		textAlign: 'center',
		paddingTop: '10px', 
		paddingBottom: '10px',
		fontSize: '16pt',
		fontFamily: 'impact',
		fontWeight: 'normal',
		color: '#424242'
	},
	divServiceList: {
		borderLeft: '2px solid #233d03',
		paddingLeft: '2px',
		marginBottom: '10px',
		marginRight: '30px',
		lineHeight: '25px',
		fontFamily: 'Arial, sans-serif',
		fontSize: '10pt',
		fontWeight: 'bold'
	},
	divServiceListTitle: {
		fontSize: '12pt',
		fontFamily: 'impact',
		fontWeight: 'normal',
		color: '#430045'
	},
	divServiceListItem: {
		marginRight: '20px',
		borderBottom: '1px solid #d4dfc0'
	}
};

export default AdvertisementLine;
