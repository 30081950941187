import React, { Component } from 'react';

import SearchForm from "./SearchForm";
import SearchResult from "./SearchResult";

const STATE_SEARCH_FORM = 'form';
const STATE_SEARCH_RESULT = 'result';

class SearchEngine extends Component {

	/*
		searchFormId
		anyField
	*/
	constructor(props) {
		super(props);
		
		this.componentSearchForm = null;
		this.componentSearchResult = null;
		
		this.initFormData();
		this.state = {formState: STATE_SEARCH_FORM};
	}
	componentDidMount = () => {
		if( this.props.anyField ) {
			this.searchFormData.anyField = this.props.anyField;
			this.searchBooks();
		}
	}
	initFormData = () => {
		this.searchFormData = {
			author: '',
			title: '',
			keywords: '',
			anyfield: '',
			storagePlace: []
		};
	}

	startNewSearchForm = () => {
		this.initFormData();
		this.setState({formState: STATE_SEARCH_FORM});
	}
	searchBooks = () => {
		var formData = this.searchFormData;
		var query = {
			author: formData.author,
			title: formData.title,
			keywords: formData.keywords,
			anyfield: formData.anyField,
			storagePlaces: [],
			skipCount: false,
			page: 1,
			pageSize: 20
		}
		for( let name of Object.keys(formData.storagePlace) ) {
			if( formData.storagePlace[ name ] )
				query.storagePlaces.push(name);
		}
		this.setState({formState: STATE_SEARCH_RESULT}, 
			() => { this.componentSearchResult.doSearch( query ) });
	}
	
	render() {
		if( this.state.formState == STATE_SEARCH_FORM ) {
			return (<SearchForm holder={this} searchFormId={this.props.searchFormId} formData={this.searchFormData} />);
		}
		if( this.state.formState == STATE_SEARCH_RESULT ) {
			return (<SearchResult holder={this} />)
		}
		return (null);
	}
}

export default SearchEngine;
