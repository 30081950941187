import React, { Component } from 'react';
import styles from '../bibliographic.module.css';

import BibliographicPage from "../BibliographicPage";

import {
	MAIN_PATH,
	BIBLIOGRAPHIC_PATH
} from '../../../util/routes';

import image01 from './image01.jpg';
import image02 from './image02.jpg';
import image03 from './image03.png';
import image04 from './image04.jpg';
import image05 from './image05.jpg';
import image06 from './image06.jpg';
import image07 from './image07.jpg';
import image08 from './image08.jpg';

class B204 extends Component {
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Бібліографічні видання', url: BIBLIOGRAPHIC_PATH, selected: false },
			{title: 'Преподобний Антоній, чудотворець Печерський', url: '', selected: true }
		];
		var body = (<Body />);
		return (
			<BibliographicPage breadCrumbs={breadCrumbs} body={body} />
		);
	}
}

function Body(props) {
return(

<div style={{width: '100%'}}>
	<div className={styles.divBibliographicHeader}>Преподобний Антоній, чудотворець Печерський</div>
	<div className={styles.divHeaderBibliographicImage} style={{width: '600px'}}>
		<img src={image01} style={{width: '600px'}} />
	</div>
	<div className={styles.divHeaderBibliographicComment}>
		1040 років із часу народження церковного діяча Київської Русі (983-1073)
	</div>
	<div className={styles.divHeaderBibliographicType}>
		Вебліографічний список літератури
	</div>

	<p className={styles.pCommon}>
	Серед яскравих постатей, що складають гордість людства, особистостей, якими воно пишатиметься вічно – ім’я Антонія Печерського, церковного діяча Київської Русі, одного із засновників Києво-Печерського монастиря. Йому ми і присвячуємо наше вебліографічне видання «Преподобний Антоній, чудотворець  Печерський».
	</p>
	<p className={styles.pCommon}>
	Відкривають вебліографічний список дві статті про життєдіяльність Антонія  Печерського.
	</p>
	<p className={styles.pCommon}>
	Бібліографічна частина складається з розділу: «Рекомендуємо прочитати: Преподобний Антоній Печерський», де представлені книги про життя та діяльність Антонія Печерського. 
	</p>
	<p className={styles.pCommon}>
	При підготовці вебліографічного списку літератури використано Інтернет-ресурси.
	</p>
	<p className={styles.pCommon}>
	Маємо надію, що запропоноване видання стане у пригоді всім, хто вивчає історію України та релігієзнавства.
	</p>
	<p className={styles.pCommon}>
	Відповідальний за випуск: Т.І. Шевченко
	</p>

	
	
	

	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	Сторінки історії: 23 липня день святого Антонія Печерського
	</p>
	<div className={styles.divHeaderBibliographicImage}>
		<img src={image02} />
	</div>

	<p className={styles.pCommon}>
	Антоній Печерський (983–1073 рр.) – святий Православної Церкви, церковний діяч Київської Русі, родоначальник вітчизняного чернецтва, засновник Києво-Печерського монастиря та будівничий церкви Успіння Пресвятої Богородиці.
	</p>
	<p className={styles.pCommon}>
	Про життя преподобного Антонія розповідає Києво-Печерський Патерик в розділі «Сказання, чому прозвався Печерським монастир» записаному Нестором Літописцем. Це «сказання» було зроблене на основі «давнього» житія святого, яке не збереглося. Антоній Печерський народився приблизно 983 р. у містечку Любечі біля Чернігова і мав ім’я Антип. З молоду відрізняючись благочестям, він вирішив посвятити своє життя Богу і відправився до «країни Грецької» – досяг Царгорода, а потім дійшов до Святої Гори, де обійшов усі монастирі Афонські. Побачивши життя тамтешніх Отців, юнак вирішив прийняти чернечий постриг. В одній із обителей Афонських він прийняв постриження під іменем Антоній. Своїм суворим аскетичним життям на Афоні юний чернець здивував навіть старих ченців.
	</p>
	<p className={styles.pCommon}>
	Проживши на Афоні багато років, Антоній повернувся до Києва. Але існуючі тут монастирі його не задовольняли, бо на Афоні він звик до суворого, аскетичного чернечого життя. Шукаючи місце для усамітнення, подвижник оселився за містом, недалеко від села Берестова, в печері, у якій недавно перед тим жив у пості і молитві Іларіон, обраний у 1051 р. Київським митрополитом. У печері Антоній за афонським прикладом проводив час у молитві, посту й праці. Слава про нього скоро розійшлася між людьми й незабаром біля нього зібралися ченці (одним із перших прибув преподобний Феодосій). Це і стало початком заснування славетного Києво-Печерського монастиря.
	</p>
	<p className={styles.pCommon}>
	Коли число ченців збільшилося до 12 осіб, вони влаштували біля печери маленьку дерев’яну церкву. Преподобний Антоній, бажаючи відбувати подвиг на самоті, поставив над братією першим ігуменом цього монастиря преподобного Варлама, а сам подався до окремої печери, яку викопав на сусідньому пагорбі. Ці печери одержали згодом назву Ближніх або Антонієвих, а старі печери стали називатися Дальніми або Феодосієвими. Через князівські усобиці Антоній був змушений деякий час жити в Чернігові, де на Болдіній Горі заснував ще один печерний монастир. Повернувшись до Києва, коли число ченців ще збільшилося, преподобний Антоній благословив збудувати на горі над печерами церкву на честь Успіння Пресвятої Богородиці. Пізніше біля неї був закладений монастир із келіями, до якого ченці перейшли жити з печер. Через те, що перші ченці цього монастиря жили в печерах, він одержав назву Печерського.
	</p>
	<p className={styles.pCommon}>
	Помер преподобний Антоній у 1073 році. Покоїться у Ближніх печерах. Мощі не віднайдені.
	</p>
	<p className={styles.pCommon}>
	Джерело: 
	<a href="https://kplavra.kyiv.ua/ua/23-July-Reserve-Pages-Stories-Day-Anthony-Pechersk-ukr">
		https://kplavra.kyiv.ua/ua/23-July-Reserve-Pages-Stories-Day-Anthony-Pechersk-ukr
	</a>
	</p>
	
	<p className={styles.pCommon} style={{fontSize: '16pt', fontWeight: 'bold', textAlign: 'center'}}>
	23 цікавих фактів про преподобного Антонія Печерського
	</p>
	<p className={styles.pCommon}>
	Православні християни 23 липня святкують день пам'яті преподобного Антонія Печерського, засновника Печерського монастиря і монашества на Русі.
	</p>
	<p className={styles.pCommon}>
	Про життя цього дивного святого відомо не багато. Ми підібрали найцікавіші і найважливіші факти, які можуть допомогти краще пізнати «начальника всіх руських ченців».
	</p>
	<p className={styles.pCommon}>
	1. Святий Антоній народився у 983 році, в Любечі, на Чернігівщині. За деякими даними його мирське ім'я було Антипа.
	</p>
	<p className={styles.pCommon}>
	2. Хотів побачити місця земного життя Іісуса Христа, відвідав Палестину.
	</p>
	<p className={styles.pCommon}>
	3. Прийняв монашество на Святій Горі і прожив там кілька років. Існує три припущення про місце, де жив святий на Афоні: в обителі Есфігмен, у Великій лаврі, в монастирі Ксілургу.
	</p>
	<p className={styles.pCommon}>
	4. Ігумен афонського монастиря, по Божому промислу, відпустив Антонія назад: «Антоній! Іди назад в руську землю, - нехай і там ті, що житимуть через тебе, процвітають і стверджуються в вірі християнській; нехай буде з тобою благословення Святої Гори! ».
	</p>
	<p className={styles.pCommon}>
	5. У Києві він обійшов багато місць, і знайшов на правому березі Дніпра місцевість, яка нагадувала йому Афон - місце, де сьогодні розташована Києво-Печерська лавра.
	</p>
	<p className={styles.pCommon}>
	6. Преподобний оселився в печері священика Іларіона, який потім став митрополитом Київським.
	</p>
	<p className={styles.pCommon}>
	7. Святий вів подвижницький спосіб життя - перебував у молитві та стриманості в їжі.
	</p>
	<p className={styles.pCommon}>
	8. Одним з перших його учнів став преподобний Никон, який у 1032 році звершив чернечий постриг над преподобним Феодосієм Печерським.
	</p>
	<p className={styles.pCommon}>
	9. Коли у святого налічувалося вже 12 учнів, монахи викопали велику печеру, в якій влаштували чернечі келії і храм.
	</p>
	<p className={styles.pCommon}>
	10. Коли насельників ставало все більше - утворилися ще одні печери. Тому є Ближні та Дальні печери.
	</p>
	<p className={styles.pCommon}>
	11. Преподобний Антоній мав дар чудотворення і прозорливості.
	<div className={styles.divHeaderBibliographicImage}>
		<img src={image03} />
	</div>
	</p>
	<p className={styles.pCommon}>
	12. Пророчив поразки руському війську в битві з половцями на річці Альті в 1066 році.
	</p>
	<p className={styles.pCommon}>
	13. Через конфлікт з князем Ізяславом Антоній двічі залишав Київ.
	</p>
	<p className={styles.pCommon}>
	14. Коли в монастирі зібралося 100 ченців, князь подарував їм у володіння гору, на якій був побудований храм, келії й огорожа. Так був заснований Печерський монастир - надалі лавра.
	</p>
	<p className={styles.pCommon}>
	15. Йому і преподобному Феодосію являлась Пресвята Богородиця у Влахернському храмі, де вони дивом опинилися, фізично не виходячи зі своєї обителі.
	</p>
	<p className={styles.pCommon}>
	16. Богородиця дала їм золото, на яке були найняті робітники та куплені матеріали для будівництва Успенського собору.
	</p>
	<p className={styles.pCommon}>
	17. Останні роки життя провів на самоті, полишивши управління монастирем.
	</p>
	<p className={styles.pCommon}>
	18. Сама Богородиця повідомила преподобному про його швидку кончину.
	</p>
	<p className={styles.pCommon}>
	19. Він відійшов до Господа в 90-річному віці, в травні 1073 року.
	</p>
	<p className={styles.pCommon}>
	20. Святі мощі преподобного Антонія не віднайдені.
	</p>
	<p className={styles.pCommon}>
	21. Переказ про недоступність мощей вперше з'явився в щоденнику німецького посла Е. Лясоти, який побував у печерах монастиря в 1594 році. Чернець, який його супроводжував, розповів, що перед своєю смертю преподобний Антоній залишив братії повчання, попрямував углиб печери. У цей момент між подвижником і його учнями обрушилася земля. Коли ченці спробували відкопати завал, з'явилися вогонь і вода.
	</p>
	<p className={styles.pCommon}>
	22. З давніх-давен шанується як «начальник всіх руських ченців».
	</p>
	<p className={styles.pCommon}>
	23. Іменем Антонія названі Ближні (Антонієві) печери Києво-Печерської лаври і печери чернігівського Троїцько-Іллінського монастиря.
	</p>
	<p className={styles.pCommon}>
	Джерело: 
	<a href="https://religions.unian.ua/afon/2044076-23-tsikavih-faktiv-pro-prepodobnogo-antoniya-pecherskogo.html">
		https://religions.unian.ua/afon/2044076-23-tsikavih-faktiv-pro-prepodobnogo-antoniya-pecherskogo.html
	</a>
	</p>
	
	<p className={styles.pCommon} style={{fontWeight: 'bold', textAlign: 'center'}}>
	Рекомендуємо прочитати: 
	</p>
	<div style={{marginLeft: '15px', marginRight: '50px'}} >
		
		<div style={{marginTop: '10px'}}>
			<div class={styles.divBookCard}>
				Руденок, В. Я. Антонієві печери Чернігівського Троїцько-Іллінського монастиря: 950 років / В. Я. Руденок, Т. Г. Новик. Чернігів : Десна Поліграф, 2019. 160 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image04} style={{float: 'left', marginRight: '30px'}} />
				<div class={styles.divBookDescription}>
					Монографія присвячена Антонієвим печерам Троїцько-Іллінського монастиря-найдавнішому культовому печерному комплексу Лівобережжя .В основі роботи багаторічні археологічні дослідження співробітників заповідника, здійснені на території Іллінського яру, завдяки  яким стало можливим розчистити недоступні  ділянки Антонієвих печер, музеєфікувати печери та зробити їх доступними для відвідувачів.
				</div>
			</div>
		</div>
		
		<div style={{marginTop: '10px'}}>
			<div class={styles.divBookCard}>
				Кієво-Печерскій Патерикъ, или сказанія о житіи и подвигахь Святыхъ Угодниковъ Кієво-Печерской Лавры. Киев : Лыбидь, 1991. 255 с.
			</div>
			<div style={{minHeight: '180px'}}>
				<img src={image05} style={{float: 'left', marginRight: '30px'}} />
				<div class={styles.divBookDescription}>
					В книзі описано житіє  святих Угодників Києво-Печерської Лаври зокрема преподобного і богоносного отця нашого Антонія, засновника Києво-Печерської Лаври.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div class={styles.divBookCard}>
				Косов С. Патерик / С. Косов. Київ : ІУАД, 2007. 160 с. — (Пам’ятки укр. православної богословської думки ХVІІ ст. ; Т. 2).
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image06} style={{float: 'left', marginRight: '30px'}} />
				<div class={styles.divBookDescription}>
					Історико-богословський твір присвячений стислому викладу житій святих цієї обителі. Місить у собі ряд оригінальних доповнень стосовно історії Лаври особливо у т. зв. литовсько-польську добу, богословське обґрунтування вшанування мощів святих.
				</div>
			</div>
		</div>
		 
		<div style={{marginTop: '10px'}}>
			<div class={styles.divBookCard}>
				Килессо С. К. Киево-Печерская лавра : памятники архитектуры и искусства / С. К. Килессо. Москва : Искусство, 1975. — 144 с.
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image07} style={{float: 'left', marginRight: '30px'}} />
				<div class={styles.divBookDescription}>
					Територія Києво-Печерської лаври є історико-культурним заповідником, де демонструють унікальні пам’ятки архітектури, де проводиться велика культурно-просвітницька робота. Додається список ілюстрацій.
				</div>
			</div>
		</div>

		<div style={{marginTop: '10px'}}>
			<div class={styles.divBookCard}>
				Вечерський В. В. Українські монастирі / В. В. Вечерський. Київ : Наш час, 2008. 400 с. : іл. — (Невідома Україна).
			</div>
			<div style={{minHeight: '150px'}}>
				<img src={image08} style={{float: 'left', marginRight: '30px'}} />
				<div class={styles.divBookDescription}>
					Книжка присвячена українським монастирям як прадавнім центрам духовності, Більша частина обсягу книжки присвячена розповіді про основні, найвідоміші й найстародавніші монастирі всіх регіонів України. У цих розповідях особливий акцент робиться на тих  історичних діячах та історичних подіях, які пов’язані з монастирями.
				</div>
			</div>
		</div>
		
	</div>
	
	<p className={styles.pCommon}>
	Джерело: 
	<a href="https://library.chnpu.edu.ua/prepodobnij-antonij-pecherskij/">
		https://library.chnpu.edu.ua/prepodobnij-antonij-pecherskij/
	</a>
	</p>

	<p className={styles.pCommon} style={{fontWeight: 'bold', fontStyle: 'italic', fontSize: '14pt'}}>
	У нас сьогодні більше можливостей для розвитку, ніж було у преподобного Антонія: у нас є прекрасні храми, є можливість здобуття богословської освіти, є розвинене та повне глибоких смислів богослужіння, є й багато іншого, але давайте не забувати, з кого все починалося! Тут можна згадати слова Ісаака Ньютона, який одного разу сказав: «Якщо я бачив далі за інших, то тому, що стояв на плечах гігантів».
	</p>
	<p className={styles.pCommon} style={{fontWeight: 'bold', fontStyle: 'italic', fontSize: '14pt'}}>
	Преподобний Антоній Печерський – дивовижний святий, який заслуговує на те, щоб про нього християни згадували набагато частіше, ніж раз на рік.
	</p>

</div>
		
);
}

export default B204;
