import React, { Component, createRef } from 'react';
import stylesPage from '../page.module.css';

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import DivInnerHtml from '../../components/DivInnerHtml';
import Spinner from "../../components/Spinner";

import { performApiRequest, POSTER_LIST_API_URL } from "../../util/restapi"
import { getLibrary, getPosterUrl, getEventImageUrl } from '../../util/tools';

import logo from '../../assets/img/pechersk_logo.png';

import {
	MAIN_PATH,
	EVENT_LIST_PATH,
	EXHIBITION_LIST_PATH,
	POSTER_LIST_PATH,
} from '../../util/routes';

const STATE_INIT = 'init';
const STATE_WORKING = 'working';
const STATE_READY = 'ready';

class PosterListPage extends Component {

	constructor(props) {
		super(props);
		this.ok = true;
		this.posterList = [];
		this.state = {
			formState: STATE_INIT,
		};
	}
	
	componentDidMount = async () => {
		this.setState({formState: STATE_WORKING});
		var response = await performApiRequest(POSTER_LIST_API_URL, {});
		this.ok = response.ok;
		if( response.ok ) {
			this.posterList = response.posterList;
		}
		this.setState({formState: STATE_READY, ok: response.ok});
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Афіша', url: '', selected: true }
		];
		var menu = [
			{ title: 'Новини та події', url: EVENT_LIST_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Віртуальні виставки', url: EXHIBITION_LIST_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Афіша', url: POSTER_LIST_PATH, isGroupHeader: false, isActive: true },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={POSTER_LIST_PATH} menu={menu} body={body} />);
	}
}

function Body(props) {
	return (
		<div style={localStyles.divBody}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				Афіша
			</div>
			<PosterList holder={props.holder} />
		</div>
	);
}

function PosterList(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_INIT ) {
		return (null);
	}
	if( holder.state.formState == STATE_WORKING ) {
		return ( <Spinner /> );
	}
	if( ! holder.ok ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				Вибачте, сервіс тимчасово не доступний.
			</div>
		);
	}
	if( holder.posterList.length == 0 ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				Вибачте, інформація відсутня.
			</div>
		);
	}
	var postersHtml = holder.posterList.map( (poster, index) => {
		return (<Poster key={index} holder={holder} poster={poster} index={index} />);
	});
	return (
		<div>
			{postersHtml}
		</div>
	);
}

function Poster(props) {
	var p = props.poster;
	
	if( ! p.showOnPosterList )
		return null;

	let imageHtml = null;
	if( p.showOnMainPage ) {
		let srcImage = null;
		if( p.logoMode == 'id' && p.logoId > 0) {
			srcImage = getEventImageUrl(p.logoId);
		} else {
			srcImage = p.logoUrl;
		}
		if( srcImage )
			imageHtml = (
				<div style={{marginTop: '15px'}}>
					<img src={srcImage} width='500' />
				</div>
			);
	}
	
	return (
		<div style={{borderTop: '2px solid green', padding: '10px 0 10px 0'}}>
			<div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '14pt'}}>
				{p.title}
			</div>
			<DivInnerHtml text={p.description} />
			{imageHtml}
		</div>
	);
	
}

const localStyles = {
	divBody: {
		width: 'calc(100% - 100px)', 
		paddingLeft: '50px', 
		paddingBottom: '20px'
	},
	divDateBlock: {
		position: 'absolute',
		top: '5px',
		left: '5px', 
		width: '100px', 
		height: '20px',
		backgroundColor: '#588b0e',
		color: 'white',
		paddingTop: '5px',
		textAlign: 'center',
		fontSize: '10pt',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'bold'
	},
	divLibraryName: {
		flexGrow: '100', 
		textAlign: 'right',
		fontFamily: 'impact',
		fontWeight: 'normal',
		fontSize: '14pt'
	},
	divNewsHeader: {
		textAlign: 'left',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'bold',
		fontSize: '12pt'
	},
	divNewsBody: {
		paddingTop: '7px',
		textAlign: 'justify',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 'normal',
		fontSize: '12pt'
	}
}

export default PosterListPage;
