import React, { Component } from 'react';
import styles from './mainPage.module.css';

import DivInnerHtml from '../../components/DivInnerHtml';
import Spinner from '../../components/Spinner';
import { getEventUrl, getEventImageUrl } from '../../util/tools';
import { STATE_INIT, STATE_READY } from './MainPage';

import logo from '../../assets/img/pechersk_logo.png';

class Events extends Component {
	
	render() {
		var holder = this.props.holder;
		if( holder.state.formState == STATE_INIT ) {
			return (
				<div>
					<div style={{display: 'flex', marginTop: '10px', marginLeft: '15px', marginBottom: '15px'}}>
						<div style={{width: '400px'}}>
							<hr style={{border: '2px solid #430045'}} />
						</div>
						<div className={styles.divCentralBlockHeader} style={{width: '250px'}}>
							Новини та події
						</div>
					</div>
					<div className={styles.divCentralDoubleBlock} >
						<div style={{width: '100%', paddingTop: '50px'}}>
							<Spinner />
						</div>
					</div>
				</div>
			);
		}
		if( holder.pageData && holder.pageData.ok ) {
			var eventList = holder.pageData.eventList;
			var event1 = eventList.length > 0 ? eventList[0] : null;
			var event2 = eventList.length > 1 ? eventList[1] : null;
		} else {
			var event1 = null;
			var event2 = null;
		}
		return (
			<div style={{marginTop: '5px', marginRight: '20px'}}>
				<div style={{display: 'flex', marginTop: '5px', marginLeft: '15px', marginBottom: '5px'}}>
					<div style={{width: '400px'}}>
						<hr style={{border: '2px solid #430045'}} />
					</div>
					<div className={styles.divCentralBlockHeader} style={{width: '250px'}}>
						Новини та події
					</div>
				</div>
				<div className={styles.divCentralDoubleBlock} >
					<div style={{width: '50%'}}>
						<Event holder={holder} event={event1} />
					</div>
					<div style={{width: '50%'}}>
						<Event holder={holder} event={event2} />
					</div>
				</div>
			</div>
		);
	}
	
}

function Event(props) {
	var holder = props.holder;
	var e = props.event;
	var useStub = ! holder.pageData.ok || e == null;
	if( useStub ) {
		return(
			<div className={styles.divCentralBlock}>
				<div style={{width: '70px', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px' }}>
					<img src={logo} width="70" />
				</div>
			</div>
		);
	}
	var style = {
		spanH1: {
			fontSize: '9pt',
			fontFamily: 'Arial, sans-serif',
			fontWeight: 'bold',
			marginBottom: '9px'
		},
		spanH2: {
			fontSize: '9pt',
			fontFamily: 'Arial, sans-serif',
			fontWeight: 'normal',
			textAlign: 'justify'
		},
		divDate: {
			position: 'absolute',
			top: '5px',
			left: '5px', 
			width: '100px',
			height: '20px',
			backgroundColor: '#588b0e',
			color: 'white',
			paddingTop: '5px',
			textAlign: 'center',
			fontSize: '10pt',
			fontFamily: 'Arial, sans-serif',
			fontWeight: 'bold'
		},
		divMore: {
			position: 'absolute',
			bottom: '0px',
			right: '0px',
			padding: '0 5px 1px 15px',
			backgroundColor: 'white',
			textAlign: 'right',
			fontSize: '10pt',
			fontFamily: 'sans-serif',
			fontWeight: 'bold',
		}
	}
	var urlEvent = getEventUrl( e.eventId);
	var urlImage = getEventImageUrl( e.logoImageId);
	return(
		<div className={styles.divCentralBlock}>
			<a href={urlEvent} style={{color: 'black', textDecoration: 'none'}}>
				<div style={{width: '225px', boxShadow: '3px 3px 5px 3px LightGrey' }}>
					<img src={urlImage} width="225" height="138" />
				</div>
				<DivInnerHtml style={style.spanH1} text={e.title} />
				<DivInnerHtml style={style.spanH2} text={e.description} />
				<div style={style.divDate}>{e.eventDateFormatted}</div>
				<div style={style.divMore}>...</div>
			</a>
		</div>
	);
}

export default Events;
