import React, { Component } from 'react';
import stylesPage from '../page.module.css';

import { performApiRequest, EVENT_LOAD_API_URL } from "../../util/restapi"

import GeneralPage from "../../components/GeneralPage";
import BreadCrumbs from "../../components/BreadCrumbs";
import LeftSideMenu from "../../components/LeftSideMenu";
import SpanInnerHtml from '../../components/SpanInnerHtml';
import DivInnerHtml from '../../components/DivInnerHtml';
import Spinner from '../../components/Spinner';
import Gallery from "../../components/Gallery";

import {
	LIBCRB, LIBVYSHNI, LIBBILOKUR, LIBKNYGOLYUB, LIB149, LIB150, LIBVSESVIT, LIBSVITOGLYAD, LIBESTET, 
	LIBVESELKA, LIBTRUBLAINI, LIB8, LIBJERELO 
} from '../../util/consts';

import {
	MAIN_PATH,
	EVENT_LIST_PATH,
	LIBCRB_EVENTS_PATH,
	LIB149_EVENTS_PATH,
	LIB150_EVENTS_PATH,
	LIB8_EVENTS_PATH,
	LIBBILOKUR_EVENTS_PATH,
	LIBESTET_EVENTS_PATH,
	LIBJERELO_EVENTS_PATH,
	LIBKNYGOLYUB_EVENTS_PATH,
	LIBSVITOGLYAD_EVENTS_PATH,
	LIBTRUBLAINI_EVENTS_PATH,
	LIBVESELKA_EVENTS_PATH,
	LIBVSESVIT_EVENTS_PATH,
	LIBVYSHNI_EVENTS_PATH,
} from '../../util/routes';

const STATE_INIT = 'init';
const STATE_READY = 'ready';

class EventPage extends Component {
	
	constructor(props) {
		super(props);
		this.eventId = this.props.match.params.id
		this.breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: 'Новини та події', url: EVENT_LIST_PATH, selected: false }
		];
		this.state = {
			formState: STATE_INIT
		};
	}
	componentDidMount = async () => {
		var request = {
			eventId: this.eventId
		};
		var response = await performApiRequest(EVENT_LOAD_API_URL, request);
		this.pageData = response;
		var event = this.pageData.event;
		if( event != null )
			this.breadCrumbs.push( {title: this.pageData.event.title, url: '', selected: true } );
		this.setState({formState: STATE_READY});
	}
	
	render() {
		var menu = [
			{ title: 'ЦБС Печерського району', url: EVENT_LIST_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліотеки для дорослих', url: '', isGroupHeader: true, isActive: false },
			{ title: LIBCRB.title, url:  LIBCRB_EVENTS_PATH, isGroupHeader: false, isActive: false }, 
			{ title: LIBVYSHNI.title, url:  LIBVYSHNI_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBBILOKUR.title, url:  LIBBILOKUR_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBKNYGOLYUB.title, url:  LIBKNYGOLYUB_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIB149.title, url:  LIB149_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIB150.title, url:  LIB150_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBVSESVIT.title, url:  LIBVSESVIT_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBSVITOGLYAD.title, url:  LIBSVITOGLYAD_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBESTET.title, url:  LIBESTET_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: 'Бібліотеки для дітей', url: '', isGroupHeader: true, isActive: false },
			{ title: LIBVESELKA.title, url: LIBVESELKA_EVENTS_PATH, isGroupHeader: false, isActive: false }, 
			{ title: LIBTRUBLAINI.title, url: LIBTRUBLAINI_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIB8.title, url: LIB8_EVENTS_PATH, isGroupHeader: false, isActive: false },
			{ title: LIBJERELO.title, url: LIBJERELO_EVENTS_PATH, isGroupHeader: false, isActive: false },
		];
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={this.breadCrumbs} activeMenuPath={EVENT_LIST_PATH} menu={menu} body={body} />);
	}
	
}

function Body(props) {

	if( props.holder.state.formState == STATE_INIT ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				<Spinner />
			</div>
		);
	}
	var event = props.holder.pageData.event;
	if( event == null ) {
		return (
			<div style={{width:'100%', marginTop: '30px', marginBottom: '30px', textAlign: 'center'}}>
				Вибачте, інформація відсутня
			</div>
		);
	}
	return (
		<div style={{width: 'calc(100% - 100px)', paddingLeft: '50px'}}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				<DivInnerHtml text={event.title} />
			</div>
			<DivInnerHtml text={event.longDescription} />
			<Gallery images={props.holder.pageData.imageList} />
		</div>
	);
}

export default EventPage;
