import React, { Component } from 'react';
import stylesPage from '../../page.module.css';

import GeneralPage from "../../../components/GeneralPage";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Gallery from "../../../components/Gallery";
import LeftSide from "./LeftSide";

import { MAIN_PATH, LIB149_INFO_PATH, LIB149_GALLERY_PATH, } from '../../../util/routes';
import { LIB149 } from '../../../util/consts';

class Lib149GalleryPage extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var breadCrumbs = [
			{title: 'Головна', url: MAIN_PATH, selected: false },
			{title: LIB149.title, url: LIB149_INFO_PATH, selected: false },
			{title: 'Фотогалерея', url: '', selected: true }
		];
		var leftSide = (<LeftSide path={LIB149_GALLERY_PATH} />);
		var body = (<Body holder={this} />);
		return ( <GeneralPage breadCrumbs={breadCrumbs} activeMenuPath={LIB149_INFO_PATH} leftSide={leftSide} body={body} />);
	}
}

function Body(props) {
	var imageList = [
		{ url: '/lib149/001.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/002.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/003.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/004.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/005.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/006.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/007.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/008.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/009.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/imgp2388.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/imgp2390.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
		{ url: '/lib149/imgp2391.jpg', imageTitle: '', imageHeight: 0, imageWidth: 0 },
	];
	return (
		<div className={stylesPage.divPageMainPart}>
			<div className={stylesPage.divPageHeader} style={{marginBottom: '30px'}}>
				{LIB149.title}<br/>
				Фотогалерея
			</div>
			<Gallery imageUrlPrefix={'/img/'} images={imageList} />
		</div>
	);
}

export default Lib149GalleryPage;
