import React, { Component } from 'react';
import styles from './bibliographic.module.css';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BreadCrumbs from "../../components/BreadCrumbs";
import BgLeftSideMenu from "./BgLeftSideMenu";

import { BIBLIOGRAPHIC_PATH } from '../../util/routes';

class BibliographicPage extends Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<div>
				<Header activeMenuPath={BIBLIOGRAPHIC_PATH} />
				<div className={styles.divBibliographicGeneral}>
					<div className={styles.divBibliographicBody}>
						<BreadCrumbs breadCrumbs={this.props.breadCrumbs} />
						<div style={{width: '100%', display: 'flex'}} >
							<div style={{width: '220px'}} >
								<BgLeftSideMenu />
							</div>
							<div style={{width: '880px'}} >
								{this.props.body}
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

export default BibliographicPage;
