import React, { Component } from 'react';
import LeftSideMenu from "../../../components/LeftSideMenu";
import stylesLibrary from "../library.module.css";

import {
	LIBVESELKA_CONTACTS_PATH, 
	LIBVESELKA_EVENTS_PATH, 
	LIBVESELKA_GALLERY_PATH, 
	LIBVESELKA_INFO_PATH, 
} from '../../../util/routes';

class LeftSide extends Component {

	constructor(props) {
		super(props);
	}

	menuItem = (title, url) => {
		return {title: title, url: url, isGroupHeader: false, isActive: url == this.props.path};
	}
	
	render() {
		var menu = [
			this.menuItem('Загальна інформація', LIBVESELKA_INFO_PATH),
			this.menuItem('Новини та події', LIBVESELKA_EVENTS_PATH),
			this.menuItem('Фотогалерея', LIBVESELKA_GALLERY_PATH),
			this.menuItem('Координати', LIBVESELKA_CONTACTS_PATH),
		]
		return (
			<React.Fragment>
				<LeftSideMenu menu={menu} />
				
				<div className={stylesLibrary.divLeftSideHeader}>
					Адреса бібліотеки
				</div>
				<ul className={stylesLibrary.ulLeftSideContacts}>
					<li style={{marginTop: '15px'}}>
						<span style={{fontWeight: 'bold'}}>Адреса:</span> пер. Лабораторний, 24
					</li>

					<li style={{marginTop: '15px'}}>
						<span style={{fontWeight: 'bold'}}>Телефон:</span> 529-52-90
					</li>

				</ul>

				<div className={stylesLibrary.divLeftSideHeader}>
					Графік роботи	
				</div>
				<table className={stylesLibrary.tableWorkSchedule}>
					<tr>
						<td>&nbsp;</td>
						<td valign="top">Абонемент</td>
						<td valign="top">Читальний зал</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>понеділок</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>вівторок</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>середа</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>четвер</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>п'ятниця</div></td>
						<td valign="top">10:00-18:00</td>
						<td valign="top">10:00-18:00</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>субота</div></td>
						<td valign="top">вихідний</td>
						<td valign="top">вихідний</td>
					</tr>
					<tr>
						<td><div className={stylesLibrary.divWorkScheduleDay}>неділя</div></td>
						<td valign="top">вихідний</td>
						<td valign="top">вихідний</td>
					</tr>
				</table>
			</React.Fragment>
		);
	}
}

export default LeftSide;