import React, { Component } from 'react';
import stylesAdmin from './admin.module.css';
import stylesPage from '../page.module.css';

import AdminHeader from "./components/AdminHeader";
import AdminGeneralPage from "./components/AdminGeneralPage";
import AdminLeftSide from "./components/AdminLeftSide";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";

import { 
	performApiRequest, 
	QUESTION_LIST_API_URL, 
	QUESTION_ANSWER_API_URL, 
	QUESTION_UPDATE_STATE_API_URL 
} from "../../util/restapi"
import { ADMIN_ONLINE_INFO_PATH } from "../../util/routes";

const STATE_LIST = 'list';
const STATE_FORM = 'form';
const STATE_LOADING = 'loading';

const QUESTION_STATE_NEW = 'new';
const QUESTION_STATE_RESP = 'resp';
const QUESTION_STATE_BAN = 'ban';

class AdminOnlineInfoPage extends Component {

	constructor(props) {
		super(props);

		this.filter = {
			useNew: true,
			useResp: false,
			useBan: false
		}
		this.questionList = [];
		this.activeQuestion = null;
		this.state = {
			formState: STATE_LIST,
			processedQuestionId: 0
		};
	}
	componentDidMount = () => {
		this.refresh();
	}

	refresh = async () => {
		this.setState({formState: STATE_LOADING});
		var request = {
			filter: this.filter
		}
		var response = await performApiRequest(QUESTION_LIST_API_URL, request);
		this.ok = response.ok;
		if( response.ok ) {
			this.questionList = response.listQuestion;
		}
		this.setState({formState: STATE_LIST, ok: response.ok});
	}

	handleCheckBoxChange = (e) =>	{
		var name = e.target.name;
		var value = e.target.checked;
		var filter = this.filter;
		if( name == 'usenew' )
			filter.useNew = value;
		if( name == 'useresp' )
			filter.useResp = value;
		if( name == 'useban' )
			filter.useBan = value;
		this.setState({dirty: true});
	}
	handleFormEdit = (e) =>	{
		const {name, value} = e.target;
		if( name == 'answerText' ) {
			this.activeQuestion.answerText = value;
		}
	}
	removeQuestion = (questionId) => {
		for( let i=this.questionList.length-1; i >= 0; i-- ) {
			if( this.questionList[i].questionId == questionId )
				this.questionList.splice(i,1);
		}
	}
	
	makeResponse = (questionId) => {
		this.activeQuestion = null;
		for( let q of this.questionList ) {
			if( q.questionId == questionId ) {
				this.activeQuestion = q;
				break;
			}
		}
		this.setState({formState: STATE_FORM});
	}
	sendResponse = async () => {
		var questionId = this.activeQuestion.questionId;
		this.setState({processedQuestionId: questionId });
		var request = {
			questionId: questionId,
			answerText: this.activeQuestion.answerText,
		}
		var response = await performApiRequest(QUESTION_ANSWER_API_URL, request);
		if( ! this.filter.useResp ) {
			this.removeQuestion(questionId);
		}
		this.setState({formState: STATE_LIST, processedQuestionId: 0});
	}
	returnToList = () => {
		this.setState({formState: STATE_LIST});
	}
	banQuestion = async (questionId) => {
		this.setState({processedQuestionId: questionId });
		var request = {
			questionId: questionId,
			questionState: QUESTION_STATE_BAN,
		}
		var response = await performApiRequest(QUESTION_UPDATE_STATE_API_URL, request);
		if( response.ok && ! this.filter.useBan )
			this.removeQuestion(questionId);
		this.setState({processedQuestionId: 0 });
	}
	deleteQuestion = async (questionId) => {
		this.setState({processedQuestionId: questionId });
		var request = {
			questionId: questionId,
			questionState: 'delete',
		}
		var response = await performApiRequest(QUESTION_UPDATE_STATE_API_URL, request);
		if( response.ok )
			this.removeQuestion(questionId);
		this.setState({processedQuestionId: 0 });
	}
	
	render() {
		var leftSide = (<AdminLeftSide path={ADMIN_ONLINE_INFO_PATH} />);
		var body = (<Body holder={this} />);
		return(
			<AdminGeneralPage leftSide={leftSide} body={body} />
		);
	}
}

function Body(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_FORM ) {
		return <QuestionForm holder={props.holder} />;
	}
	var filter = holder.filter;
	return (
		<React.Fragment>
			<div className={stylesAdmin.divContentHeader}>
				Запитання читачів
			</div>
			<div style={{marginTop: '20px'}}>
				<input type="checkbox" name='usenew' onChange={holder.handleCheckBoxChange} checked={filter.useNew} />
				&nbsp;Нові
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<input type="checkbox" name='useresp' onChange={holder.handleCheckBoxChange} checked={filter.useResp} />
				&nbsp;Опрацьовані
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<input type="checkbox" name='useban' onChange={holder.handleCheckBoxChange} checked={filter.useBan} />
				&nbsp;Відкинуті
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<button type='button' style={{marginLeft: '10px'}} onClick={holder.refresh}>Відновити</button>
			</div>
			<QuestionList holder={holder} />
		</React.Fragment>
	)
}
function QuestionForm(props) {
	var holder = props.holder;
	if( holder.activeQuestion == null ) {
		return (
			<div style={{width: '100%', textAlign: 'center'}}>
				Запитання відсутнє
			</div>
		);
	}
	if( holder.state.processedQuestionId > 0 )
		var actionsBlock = ( <Spinner /> );
	else	
		var actionsBlock = (
			<div style={{marginTop: '10px', marginBottom: '30px'}}>
				<span className={stylesPage.spanClickable} onClick={ holder.sendResponse } >
					Надіслати відповідь
				</span>
				<span 
					className={stylesPage.spanClickable} 
					onClick={ holder.returnToList } 
					style={{marginLeft: '50px'}}
				>
					Повернутись до списку
				</span>
			</div>
		);
	return (
		<React.Fragment>
			<div className={stylesAdmin.divContentHeader}>
				Відповідь на запитання
			</div>
			<div>
				Читач: <span style={{fontWeight: 'bold'}}>{holder.activeQuestion.readerName}</span>
			</div>
			<div style={{marginTop: '10px'}}>
				Запитання: <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>{holder.activeQuestion.questionText}</span>
			</div>
			<div style={{marginTop: '10px'}}>
				Відповідь
			</div>
			<textarea name='answerText' rows='10' cols='80' onChange={holder.handleFormEdit}>
				{holder.activeQuestion.answerText}
			</textarea>
			{actionsBlock}
		</React.Fragment>
	)
}
function QuestionList(props) {
	var holder = props.holder;
	if( holder.state.formState == STATE_LOADING ) {
		return ( <Spinner /> );
	}
	if( ! holder.state.ok ) {
		return (
			<div style={{textAlign: 'center'}}>
				Під час виконання виникла помилка
			</div>
		);
	}
	var questionListHtml = holder.questionList.map( (question, index) => {
		return (<Question key={index} question={question} holder={holder} />);
	} );
	return (
		<React.Fragment>
			<hr />
			{questionListHtml}
		</React.Fragment>
	);
}
function Question(props) {
	if( props.question.questionState == QUESTION_STATE_NEW )
		return <QuestionNew question={props.question} holder={props.holder} />;
	if( props.question.questionState == QUESTION_STATE_RESP )
		return <QuestionResp question={props.question} holder={props.holder} />;
	if( props.question.questionState == QUESTION_STATE_BAN )
		return <QuestionBan question={props.question} holder={props.holder} />;
}

function QuestionNew(props) {
	var holder = props.holder;
	var question = props.question;
	if( holder.state.processedQuestionId == question.questionId )
		var actionsBlock = ( <Spinner /> );
	else	
		var actionsBlock = (
			<div style={{marginTop: '10px'}}>
				<span 
					className={stylesPage.spanClickable} 
					onClick={ () => holder.makeResponse(question.questionId) }
				>
					Відповісти
				</span>
				<span 
					className={stylesPage.spanClickable} 
					onClick={ () => holder.banQuestion(question.questionId) } 
					style={{marginLeft: '15px', marginRight: '50px'}}
				>
					Відкинути
				</span>
				<SpanDelete questionId={props.question.questionId} holder={props.holder} />
			</div>
		);
	return (
		<div>
			<div>
				Id: {question.questionId} date: {question.questionDate}
			</div>
			<div>
				Читач: <span style={{fontWeight: 'bold'}}>{question.readerName}</span>
			</div>
			<div style={{marginTop: '10px'}}>
				Запитання: <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>{question.questionText}</span>
			</div>
			{actionsBlock}
			<hr className={stylesPage.hrThin}/>
		</div>
	);
}
function QuestionResp(props) {
	var holder = props.holder;
	var question = props.question;
	if( holder.state.processedQuestionId == question.questionId )
		var actionsBlock = ( <Spinner /> );
	else	
		var actionsBlock = (
			<div style={{marginTop: '10px'}}>
				<SpanDelete questionId={props.question.questionId} holder={props.holder} />
			</div>
		);
	return (
		<div style={{color: 'gray'}}>
			<div>
				Запитання опрацьоване
			</div>
			<div style={{marginTop: '10px'}}>
				Читач: {question.readerName}
			</div>
			<div style={{marginTop: '10px'}}>
				Запитання: {question.questionText}
			</div>
			<div style={{marginTop: '10px'}}>
				Відповідь: {question.answerText}
			</div>
			{actionsBlock}
			<hr className={stylesPage.hrThin}/>
		</div>
	);
}
function QuestionBan(props) {
	var holder = props.holder;
	var question = props.question;
	if( holder.state.processedQuestionId == question.questionId )
		var actionsBlock = ( <Spinner /> );
	else	
		var actionsBlock = (
			<div style={{marginTop: '10px'}}>
				<SpanDelete questionId={props.question.questionId} holder={props.holder} />
			</div>
		);
	return (
		<div style={{color: 'green'}}>
			<div>
				Запитання відкинуте
			</div>
			<div style={{marginTop: '10px'}}>
				Читач: {question.readerName}
			</div>
			<div style={{marginTop: '10px'}}>
				Запитання: {question.questionText}
			</div>
			{actionsBlock}
			<hr className={stylesPage.hrThin}/>
		</div>
	);
}
function SpanDelete(props) {
	return(
		<span 
			className={stylesPage.spanClickable} 
			onClick={ () => props.holder.deleteQuestion(props.questionId) }
		>
			Вилучити
		</span>
	);
}

const localStyles = {
	divPageTitle: {
		textAlign: 'center',
		fontSize: '20pt',
		fontFamily: 'AkvitaniaModern',
		fontWeight: 'bold'
	},
	divMessage: {
		marginTop: '20px',
		textAlign: 'center',
		fontSize: '16pt',
		color: 'red',
		fontWeight: 'bold'
	}
}

export default AdminOnlineInfoPage;
